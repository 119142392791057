import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Box from '@mui/material/Box'

import { theme } from '@copa/design-system-factory.theme'
import { Variants, Themes, ScrollPosition } from './table.types'

export interface TableProps {
  /** This property indicates a children to be rendered rendered */
  children?: ReactNode
  /** Theme variant to style the table */
  variantTheme?: Themes
  /** Variant table to be rendered */
  variant?: Variants
  /** Indicates if the table must have a Y Axis */
  yAxis?: boolean
  /** Indicates if the table must be vertical */
  vertical?: boolean
  /** CSS Properties for the table */
  style?: React.CSSProperties
  // FIXME: This prop needs to be reviewed.
  debounce?: <E extends unknown[]>(
    fn: (...args: E) => void
  ) => (...args: E) => void
}

export const Table = ({
  children,
  variantTheme,
  variant,
  yAxis,
  vertical,
  style,
  debounce,
  ...tableProps
}: TableProps) => {
  const [scrollPosition, setScrollPosition] = useState(ScrollPosition.NO_SCROLL)
  const [, setShowRightDim] = useState(false)
  const [, setShowLeftDim] = useState(false)

  const ref = useRef<HTMLElement>(null)
  const invert = variantTheme === 'invert'

  const getScrollPosition = useCallback(
    (element: HTMLElement): ScrollPosition => {
      if (element.scrollWidth === element.clientWidth) {
        return ScrollPosition.NO_SCROLL
      }
      if (element.scrollLeft === 0) {
        return ScrollPosition.SCROLL_TOP_LEFT
      }

      if (
        Math.ceil(element.scrollLeft) + element.clientWidth + 1 >=
        element.scrollWidth
      ) {
        return ScrollPosition.SCROLL_TOP_RIGHT
      }

      return ScrollPosition.SCROLL_IN_MIDDLE
    },
    [ScrollPosition]
  )

  const getColor = (color: string) =>
    invert ? theme.palette.grey['50'] : color

  const choosableStyles = new Map()
    .set('main', {
      th: {
        borderBottom: `1px solid ${getColor(theme.palette.primary.main)}`,
        color: getColor(theme.palette.primary.main),
        fontWeight: '500',
        fontSize: '14px',
        alignText: 'center',
        lineHeight: '20px',
      },
    })
    .set('secondary', {
      th: {
        borderBottom: `1px solid ${getColor(theme.palette.grey['600'])}`,
        color: getColor(theme.palette.grey['600']),
        fontWeight: '500',
        fontSize: '12px',
        alignText: 'center',
        lineHeight: '26px',
      },
    })
    .set('filled', {
      '& table': {
        height: '1px',
      },
      'table tr th': {
        fontWeight: '500',
        fontSize: '14px',
        color: getColor(theme.palette.primary.main),
        alignText: 'center',
        padding: '0px 0px',
        lineHeight: '20px',
      },
      'th div': {
        padding: '16px 8px',
        marginLeft: '4px',
        marginRight: '4px',
        borderRadius: '8px',
        backgroundColor: invert
          ? theme.palette.primary.light
          : // FIXME: This color isn't valid to material we need to overwrite the exported type
            // @ts-ignore
            theme.palette.primary.ultraLight,
        height: '100%',
      },
      'thead th:first-child div': {
        marginLeft: '0px',
      },
      'thead th:last-child div': {
        marginRight: '0px',
      },
    })

  const ifApplicableYAxisStyle = yAxis
    ? {
        'tr>th:first-child,tr>td:first-child': {
          position: 'sticky',
          left: '0',
        },
        'tr>td:first-child': {
          borderBottom: `1px solid ${theme.palette.grey['600']}`,
        },

        '&[data-scroll="scroll-in-middle"] tr>th:first-child, &[data-scroll="scroll-in-middle"] tr>td:first-child':
          {
            background: theme.palette.grey['50'],
            boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.06)',
          },
        '&[data-scroll="scroll-top-right"] tr>th:first-child, &[data-scroll="scroll-top-right"] tr>td:first-child':
          {
            background: theme.palette.grey['50'],
            boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.06)',
          },
      }
    : {}

  const generalStyles = {
    position: 'relative',
    overflowX: 'auto',
    paddingBottom: '15px',
    table: {
      borderSpacing: '0px',
      width: '100%',
    },
    'table tr th': {
      padding: '16px 8px',
    },
    'table td': {
      padding: '16px 8px',
      minHeight: '80px',
      color: getColor(theme.palette.grey['600']),
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
      textAlign: 'center',
    },
    'table td div, table th div': {
      display: 'flex',
      flexDirection: vertical ? 'column' : 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&>div': {
      top: '0px',
      right: '0px',
      position: 'absolute',
      width: '32px',
      height: '100%',
      background:
        'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    },

    ...ifApplicableYAxisStyle,
  }

  useEffect(() => {
    if (ref.current && debounce) {
      const element = ref.current
      const setScrollPositionCB = () => {
        const newScrollPosition = getScrollPosition(element)

        setShowRightDim(
          newScrollPosition === ScrollPosition.SCROLL_IN_MIDDLE ||
            newScrollPosition === ScrollPosition.SCROLL_TOP_LEFT
        )

        setShowLeftDim(
          !yAxis &&
            (newScrollPosition === ScrollPosition.SCROLL_IN_MIDDLE ||
              newScrollPosition === ScrollPosition.SCROLL_TOP_RIGHT)
        )

        setScrollPosition(newScrollPosition)
      }
      setScrollPositionCB()
      const debounceFN = debounce(setScrollPositionCB)
      element.addEventListener('scroll', debounceFN, { passive: true })
      window.addEventListener('resize', debounceFN)

      return () => {
        element.removeEventListener('scroll', debounceFN)
        window.removeEventListener('resize', debounceFN)
      }
    }
    return undefined
  }, [ScrollPosition, getScrollPosition, ref, yAxis])

  return (
    <Box
      sx={{
        position: 'relative',
        height: 'fit-content',
        marginBottom: '32px',
        width: style?.width ? style.maxWidth : 'unset',
      }}
    >
      <Box
        ref={ref}
        data-scroll={scrollPosition}
        sx={{
          ...generalStyles,
          ...choosableStyles.get(variant),
          width: style?.width ? style.maxWidth : 'unset',
        }}
      >
        <table style={style} {...tableProps}>
          {children}
        </table>
      </Box>
    </Box>
  )
}
