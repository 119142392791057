import { isEmpty } from 'rambda'
import { SrtState } from '../../../../hook/navigate/types'
import { RebookingCalendarOD } from '../../../../utils/types'

export default function getAvailableFlightsCalendar({
  rebookingCalendarODs,
  selectedOds,
}: SrtState): RebookingCalendarOD[] {
  const result: RebookingCalendarOD[] = []

  if (isEmpty(selectedOds)) {
    // @ts-ignore
    return rebookingCalendarODs
  }
  // @ts-ignore
  result.push(rebookingCalendarODs[0])
  // @ts-ignore
  for (let index = 1; index < rebookingCalendarODs.length; index++) {
    // @ts-ignore
    if (index > selectedOds.length) {
      break
    }
    result.push({
      index,
      rebookingCalendar: {
        startCalendarDate:
          // @ts-ignore
          selectedOds[index - 1].segments[0].flightEstimatedDate,
        endCalendarDate:
          // @ts-ignore
          rebookingCalendarODs[index].rebookingCalendar.endCalendarDate,
      },
    })
  }
  return result
}
