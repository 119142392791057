/* eslint-disable */
import React from 'react'
import { useIntl } from 'react-intl'
import { Modal } from '@copa/design-system-factory.modal'
import { useMediaQuery } from '@mui/material'
import theme from '@copa/design-system-factory.theme'
import variantOptions from './utils/variantOptions'
import SRT_MODAL_VARIANTS from './utils/variants'

export type SrtModalOptions = {
  city: string
}

interface IModalSrtProps {
  variant: SRT_MODAL_VARIANTS
  shouldOpen: boolean
  setShowModal: (_show: boolean) => void
  primaryAction: () => void
  secondaryAction?: () => void
  ownerFFN?: string
  onClose?: () => void
  shouldRedirectSeatMap?: boolean
  options?: SrtModalOptions | undefined
}
const ModalSrt: React.FC<IModalSrtProps> = ({
  variant,
  shouldOpen,
  setShowModal,
  primaryAction,
  secondaryAction,
  ownerFFN = '',
  onClose = () => {},
  shouldRedirectSeatMap,
  options,
}) => {
  const { formatMessage } = useIntl()
  const isXS = useMediaQuery(theme.breakpoints.between('xs', 'md'))
  const { content, primaryBtn, secondaryBtn, type, avoidCloseOn, wcag } =
    variantOptions(variant, ownerFFN, shouldRedirectSeatMap, options, isXS)
  //@ts-ignore
  const getBtnProps = btn => ({
    id: btn?.id,
    text: formatMessage({ id: btn.text }),
    'aria-label': formatMessage({ id: btn.ariaLabel }),
    variant: btn?.variant,
  })

  return (
    <Modal
      type={type}
      open={shouldOpen}
      // @ts-ignore
      handleClose={(_, reason) => {
        //@ts-ignore
        if (reason !== avoidCloseOn && !avoidCloseOn.includes(reason)) {
          setShowModal(false)
        }
        onClose()
      }}
      scrollIndicatorProps={{
        wcagdescriptiontext: formatMessage({ id: wcag?.scrollBar }),
      }}
      primaryButtonProps={
        primaryBtn
          ? {
              ...getBtnProps(primaryBtn),
              onClick: () => {
                primaryAction()
              },
            }
          : null
      }
      secondaryButtonProps={
        secondaryBtn
          ? {
              ...getBtnProps(secondaryBtn),
              onClick: () => {
                if (secondaryAction) {
                  secondaryAction()
                }
              },
            }
          : null
      }
      dismissButtonProps={{
        wcagObject: {
          id: 'modal-srt-close-btn',
          message: formatMessage({ id: wcag?.closeBtn }),
        }
      }}
    >
      {content}
    </Modal>
  )
}

export default ModalSrt
