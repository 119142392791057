import { useMemo } from 'react'
import { Box } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Avatar, AvatarProps } from '@copa/design-system-factory.avatar'
import { Typography } from '@copa/design-system-factory.typography'
import { Tooltip } from '@copa/design-system-factory.tooltip'
import { Pill } from '@copa/design-system-factory.pill'
import theme from '@copa/design-system-factory.theme'
import { Link } from '@copa/design-system-factory.link'
import { Markdown } from '@copa/design-system-factory.markdown'
import { LinkDataModel, PillModel } from './types'
import {
  PillColorsByType,
  paxCardStyles,
  LinkColorsByType,
} from './utils/styles'
import { IconLink } from './utils/icons'

export type PaxCardProps = {
  /** This property receives the properties of the Avatar component for its construction. The 'variant' prop is not received since it is of use defined by the component. You can go to the BitCloud component for more details. */
  avatar: Omit<AvatarProps, 'variant'> & {
    ellipsis?: boolean
  }
  /** This property receives a list of elements displayed below the component title. Each object is rendered as Markdown */
  listDescription: string[]
  /** This property receives the properties necessary to display the link that goes below the description, as well as its color and tooltip information */
  linkData?: LinkDataModel
  /** This property receives the properties necessary to display the pill displayed on the right side of the component. Bit Cloud's Pill component is used here. For further reference on the props you can go to the component */
  pill?: PillModel
  /** This property receives the message to be added in the aria-label of the container for wcag */
  wcagText: string
}

export function PaxCard({
  avatar,
  wcagText,
  listDescription = [],
  linkData,
  pill,
}: PaxCardProps) {
  const isXs: boolean = useMediaQuery(theme.breakpoints.down('sm'))
  const isXsSmallXs: boolean = useMediaQuery(
    theme.breakpoints.between('xs', 320)
  )
  const getPillColors = useMemo(
    () => PillColorsByType.get(pill?.state),
    [pill?.state]
  )

  const getLinkColor = useMemo(
    () => LinkColorsByType.get(linkData?.color),
    [linkData?.color]
  )

  const styles = paxCardStyles(
    avatar.ellipsis,
    isXs,
    isXsSmallXs,
    getLinkColor?.color
  )

  const pillComponent = (pillProps: PillModel) => (
    <Box>
      <Pill
        {...pillProps}
        fontColor={getPillColors?.text}
        color={getPillColors?.background}
        size="slim"
      />
    </Box>
  )

  const linkComponent = (linkDataModel: LinkDataModel) => (
    <Box sx={styles.containerLink} data-testid="container-link-data">
      <Typography variant="captionTiny" color="grey.700">
        {linkDataModel?.label}
      </Typography>
      <Typography
        variant="captionTiny"
        color={getLinkColor.color}
        sx={styles.link}
      >
        <Link
          {...linkDataModel?.linkData}
          underline="always"
          onClick={linkDataModel?.onClickLink}
          sx={styles.commonLink}
        >
          {linkDataModel?.labelLink}
        </Link>
        {linkDataModel?.tooltip && (
          <Tooltip
            title={linkDataModel?.tooltip?.title}
            subtitle={linkDataModel?.tooltip.subtitle}
          >
            {IconLink(getLinkColor.color)}
          </Tooltip>
        )}
        {linkDataModel?.onClickIcon && (
          // eslint-disable-next-line
          <Link
            onClick={linkDataModel?.onClickIcon}
            underline="none"
            sx={styles.onClickIcon}
          >
            {IconLink(getLinkColor.color)}
          </Link>
        )}
      </Typography>
    </Box>
  )

  return (
    <Box sx={styles.container} aria-label={wcagText}>
      <Box sx={styles.containerBody}>
        <Box sx={styles.containerData}>
          <Box>
            <Avatar {...avatar} variant={isXsSmallXs ? 'petit' : 'normal'} />
          </Box>
          <Box sx={styles.containerInfo} data-testid="containerInfo">
            <Box sx={styles.containerInfoMain}>
              <Typography variant="h4" sx={styles.title}>
                {avatar?.font}
              </Typography>
              {listDescription?.length > 0 && (
                <Box sx={styles.listDescription}>
                  {listDescription.map((description, index) => (
                    <Markdown
                      key={`itemDescription-description-${description}`}
                      noTextMargin
                    >
                      {description.concat(
                        index < listDescription?.length - 1 && !isXs
                          ? ' | '
                          : ''
                      )}
                    </Markdown>
                  ))}
                </Box>
              )}
              {linkData && !isXs && linkComponent(linkData)}
            </Box>
            {pill && !isXs && pillComponent(pill)}
          </Box>
        </Box>
        {isXs && (
          <Box sx={styles.containerXs}>
            {pill && pillComponent(pill)}
            {linkData && linkComponent(linkData)}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PaxCard
