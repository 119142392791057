import theme from '@copa/design-system-factory.theme'

export const stylesMarkdown = () => {
  return {
    table_header: {
      color: theme.palette.primary.dark,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    table_header_item: {
      borderBottom: '1px solid rgba(0, 40, 128, 0.5)',
      padding: '0.5rem 0',
    },
    table_row: {
      borderBottom: '1px solid rgba(204, 204, 203, 0.5)',
    },
    table_body: {
      color: theme.palette.grey[600],
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
    },
    images: {
      maxWidth: '100%',
      marginBottom: '32px',
    },
    'list_container > li': {
      paddingLeft: '16px',
    },
  }
}
