import React from 'react'

export const Calendar = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.25 2.47H14c.825 0 1.5.676 1.5 1.5v12c0 .826-.675 1.5-1.5 1.5H2c-.825 0-1.5-.674-1.5-1.5v-12c0-.824.675-1.5 1.5-1.5h.75v-.75c0-.412.337-.75.75-.75s.75.338.75.75v.75h7.5v-.75c0-.412.338-.75.75-.75s.75.338.75.75v.75Zm-10.5 13.5h10.5c.412 0 .75-.337.75-.75v-9H2v9c0 .413.337.75.75.75Z"
      fill="#0E68FF"
    />
    <path
      d="M13.25 2.47h-.338v.338h.338v-.337Zm-10.5 0v.338h.337v-.337H2.75Zm1.5 0h-.337v.338h.337v-.337Zm7.5 0v.338h.338v-.337h-.338ZM14 6.22h.338v-.337H14v.338Zm-12 0v-.337h-.337v.338H2Zm12-4.087h-.75v.675H14v-.675Zm1.838 1.838A1.842 1.842 0 0 0 14 2.133v.675c.639 0 1.162.524 1.162 1.163h.675Zm0 12v-12h-.675v12h.675ZM14 17.808a1.842 1.842 0 0 0 1.838-1.837h-.675c0 .638-.524 1.162-1.163 1.162v.675Zm-12 0h12v-.675H2v.675ZM.163 15.971c0 1.011.826 1.837 1.837 1.837v-.675a1.167 1.167 0 0 1-1.163-1.162H.163Zm0-12v12h.675v-12H.162ZM2 2.133A1.842 1.842 0 0 0 .163 3.971h.674c0-.639.524-1.163 1.163-1.163v-.675Zm.75 0H2v.675h.75v-.675Zm-.337-.412v.75h.675v-.75h-.675ZM3.5.633a1.09 1.09 0 0 0-1.087 1.088h.675c0-.226.186-.413.412-.413V.633Zm1.088 1.088A1.09 1.09 0 0 0 3.5.633v.675c.226 0 .413.187.413.413h.675Zm0 .75v-.75h-.675v.75h.675Zm7.162-.338h-7.5v.675h7.5v-.675Zm-.338-.412v.75h.675v-.75h-.675ZM12.5.633a1.09 1.09 0 0 0-1.088 1.088h.675c0-.226.187-.413.413-.413V.633Zm1.088 1.088A1.09 1.09 0 0 0 12.5.633v.675c.226 0 .412.187.412.413h.675Zm0 .75v-.75h-.675v.75h.675Zm-.338 13.162H2.75v.675h10.5v-.675Zm.412-.412a.415.415 0 0 1-.412.412v.675a1.09 1.09 0 0 0 1.088-1.087h-.675Zm0-9v9h.675v-9h-.675ZM2 6.558h12v-.675H2v.675Zm.337 8.663v-9h-.675v9h.675Zm.413.412a.415.415 0 0 1-.413-.412h-.675a1.09 1.09 0 0 0 1.088 1.087v-.675Z"
      fill="#0E68FF"
    />
  </svg>
)
