import { scTemplatesByTypeModify as scTemplates } from '../../flows/SC/templates/templates.map'
import { templates } from '../../flows/SC/templates/templates.enum'
import { REBOOK_ORIGIN } from '../../utils/constants'
// @ts-ignore
export const getScTemplate = (typeModify, origin) => {
  // @ts-ignore
  const selectTemplateByOrigin = (emailTemplate, myTripTemplate) =>
    origin === REBOOK_ORIGIN.EMAIL ? emailTemplate : myTripTemplate

  const scMixTemplates = {
    ...scTemplates,
    [templates.RDP_MIN01]: scTemplates[templates.RDP],
    [templates.NR_MIN01]: scTemplates[templates.NR],
    [templates.NR_MAJ01]: scTemplates[templates.MAJ01],
    [templates.NR_MAJ02]: scTemplates[templates.MAJ02],
    [templates.NR_MIN02]: scTemplates[templates.MIN02],
    [templates.MIN02_RDP]: scTemplates[templates.MIN02],
    [templates.ADHOC_MIN02]: selectTemplateByOrigin(
      scTemplates[templates.ADHOC],
      scTemplates[templates.MIN02]
    ),
    [templates.MIN01_OVS]: scTemplates[templates.OVS],
    [templates.MIN02_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.MIN02]
    ),
    [templates.NR_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.NR]
    ),
    [templates.MAJ01_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.MAJ01]
    ),
    [templates.MAJ02_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.MAJ02]
    ),
    [templates.ADHOC_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.MAJ02]
    ),
    [templates.MIN01_RDP_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.RDP]
    ),
    [templates.RDP_OVS]: selectTemplateByOrigin(
      scTemplates[templates.OVS],
      scTemplates[templates.RDP]
    ),
    [templates.ADHOC_MIN01]: scTemplates[templates.ADHOC],
    [templates.NR_ADHOC]: selectTemplateByOrigin(
      scTemplates[templates.ADHOC],
      scTemplates[templates.NR]
    ),
    [templates.MAJ01_ADHOC]: selectTemplateByOrigin(
      scTemplates[templates.ADHOC],
      scTemplates[templates.MAJ01]
    ),
    [templates.MAJ02_ADHOC]: selectTemplateByOrigin(
      scTemplates[templates.ADHOC],
      scTemplates[templates.MAJ02]
    ),
    [templates.RDP_ADHOC]: selectTemplateByOrigin(
      scTemplates[templates.ADHOC],
      scTemplates[templates.RDP]
    ),
    [templates.MIN01_RDP_ADHOC]: selectTemplateByOrigin(
      scTemplates[templates.ADHOC],
      scTemplates[templates.RDP]
    ),
  }
  // @ts-ignore
  return scMixTemplates[typeModify]
}
