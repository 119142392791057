import theme from '@copa/design-system-factory.theme'
import { Breakpoints, getSpacing } from '../../../../../../utils/spacing'

const useStyles = (breakpoints: Breakpoints) => ({
  destinationCardContainer: {
    padding: `${getSpacing(breakpoints)?.roomy} 0`,
  },
  originalItineraryContainer: {
    marginTop: getSpacing(breakpoints)?.spacious,
  },
  itinerariesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(breakpoints)?.normal,
  },
  disclaimerTitle: {
    marginBottom: getSpacing(breakpoints)?.tiny,
    marginTop: getSpacing(breakpoints)?.roomy,
  },
  disclaimerBullets: {
    marginLeft: '24px',
    '& ul': {
      listStyle: 'disc !important',
    },
  },
  link: {
    color: theme.palette.primary.light,
    fontFamily: 'SuisseIntl',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textDecorationLine: 'underline',
  },
})

export default useStyles
