import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { QueryParams } from 'src/types/QueryParams'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../components/commons/spinner/spinner'
import { EligibleTypes, UpgradeCacheKey } from './types'
import {
  useGetExtendProfileInfoMutation,
  useGetPlusUpgradeInfoMutation,
  useGetTripInfoMutation,
  useGetUpgradeEligibleInfoMutation,
} from '../../api/upgradeHubSlice'
import { isMyAccountOrigin, redirectToErrorPage } from './utils'
import UpgradeHub from './upgrade-hub/upgradeHub'
import { getLanguage } from '../srt/utils/utils'
import { setLocaleLanguage } from '../../actions/locale'
import Saua from './saua/Saua'
import { MyTripsState } from '../../types/state'
import { useAuthentication } from '../../hooks/auth/useAuthentication'

export default function UpgradePage() {
  const locationRoute = useLocation()
  const queryParams = new URLSearchParams(locationRoute.search)
  const origin = queryParams.get('origin')
  const { pnr, surname, language } = useParams<QueryParams>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { loginWithRedirect, isLoggedIn } = useAuthentication()

  const { masterSessionId: sessionToken, lp_ffn: username } = useSelector(
    (state: MyTripsState) => state.auth
  )

  const [
    getUpgradeEligibleInfo,
    {
      data: upgradeEligibleInfo,
      isError: isEligibleError,
      isUninitialized: isElgibleUninitialized,
    },
  ] = useGetUpgradeEligibleInfoMutation({
    fixedCacheKey: UpgradeCacheKey.ELIGIBLE,
  })

  const [
    getTripInfo,
    {
      data: tripInfo,
      isError: isTripError,
      isUninitialized: isTripInfoUninitialized,
    },
  ] = useGetTripInfoMutation({
    fixedCacheKey: UpgradeCacheKey.TRIP_INFO,
  })

  const [
    getExtendProfileInfo,
    {
      data: profileExtendInfo,
      isError: isProfileExtendError,
      isUninitialized: isProfileExtendCallUninitialized,
    },
  ] = useGetExtendProfileInfoMutation({
    fixedCacheKey: UpgradeCacheKey.PROFILE_EXTENDED,
  })

  const [
    getPlusUpgradeInfo,
    {
      data: plusgradeInfo,
      isError: isPlusUpgradeError,
      isLoading: isLoadingPlusgrade,
    },
  ] = useGetPlusUpgradeInfoMutation()

  const fetchTripInfo = useCallback(async () => {
    try {
      if (!tripInfo && isTripInfoUninitialized) {
        await getTripInfo({ pnr, surname })
      }
    } catch (e) {
      redirectToErrorPage({ history, pnr, surname, language, origin })
    }
  }, [
    tripInfo,
    isTripInfoUninitialized,
    getTripInfo,
    pnr,
    surname,
    history,
    language,
    origin,
  ])

  const fetchProfileExtend = useCallback(async () => {
    try {
      await getExtendProfileInfo({
        token: sessionToken,
        membership: username,
      })
    } catch (e) {
      redirectToErrorPage({
        history,
        pnr,
        surname,
        language,
        origin,
      })
    }
  }, [
    getExtendProfileInfo,
    sessionToken,
    username,
    history,
    pnr,
    surname,
    language,
    origin,
  ])

  const fetchUpgradeEligibleInfo = useCallback(async () => {
    try {
      await getUpgradeEligibleInfo({ pnr, lastname: surname })
    } catch (e) {
      redirectToErrorPage({ history, pnr, surname, language, origin })
    }
  }, [getUpgradeEligibleInfo, pnr, surname, history, language, origin])

  const fetchPlusUpgradeInfo = useCallback(() => {
    if (
      !plusgradeInfo &&
      !isPlusUpgradeError &&
      !isLoadingPlusgrade &&
      tripInfo
    ) {
      getPlusUpgradeInfo({
        pnr,
        lastname: tripInfo.flights[0].passengers[0].lastName,
      })
    }
  }, [
    getPlusUpgradeInfo,
    pnr,
    plusgradeInfo,
    isPlusUpgradeError,
    isLoadingPlusgrade,
    tripInfo,
  ])

  useEffect(() => {
    const lang = getLanguage(language)
    dispatch(setLocaleLanguage(lang))
  }, [dispatch, language])

  useEffect(() => {
    const shouldGoToCIAM =
      !profileExtendInfo &&
      !isLoggedIn &&
      !username &&
      !sessionToken &&
      isMyAccountOrigin(origin)

    if (shouldGoToCIAM) {
      loginWithRedirect({
        redirectTo: window.location.pathname + window.location.search,
      })
    }
  }, [
    isLoggedIn,
    loginWithRedirect,
    origin,
    profileExtendInfo,
    sessionToken,
    username,
  ])

  useEffect(() => {
    if (!upgradeEligibleInfo && isElgibleUninitialized) {
      fetchUpgradeEligibleInfo()
    }
  }, [fetchUpgradeEligibleInfo, isElgibleUninitialized, upgradeEligibleInfo])

  useEffect(() => {
    if (!isMyAccountOrigin(origin)) {
      fetchPlusUpgradeInfo()
    }
  }, [fetchPlusUpgradeInfo, origin])

  useEffect(() => {
    if (
      upgradeEligibleInfo &&
      !isEligibleError &&
      (upgradeEligibleInfo.eligibleType === EligibleTypes.UPGRADE_HUB ||
        (!isMyAccountOrigin(origin) &&
          upgradeEligibleInfo.eligibleType === EligibleTypes.SAUA))
    ) {
      fetchTripInfo()
    }
  }, [fetchTripInfo, upgradeEligibleInfo, isEligibleError, origin])

  useEffect(() => {
    if (
      username &&
      sessionToken &&
      !profileExtendInfo &&
      isProfileExtendCallUninitialized
    ) {
      fetchProfileExtend()
    }
  }, [
    fetchProfileExtend,
    username,
    sessionToken,
    profileExtendInfo,
    isProfileExtendCallUninitialized,
  ])

  useEffect(() => {
    if (isTripError || isProfileExtendError || isEligibleError) {
      redirectToErrorPage({ history, pnr, surname, language, origin })
    }
  }, [
    isEligibleError,
    origin,
    isTripError,
    isProfileExtendError,
    history,
    pnr,
    surname,
    language,
  ])

  const pageByEligible = {
    [EligibleTypes.UPGRADE_HUB]: (
      <UpgradeHub
        upgradeInfo={{ tripInfo, profileExtendInfo }}
        plusgradeInfo={{
          ...plusgradeInfo,
          isPlusUpgradeError,
        }}
      />
    ),
    [EligibleTypes.SAUA]: (
      <Saua
        upgradeInfo={{ tripInfo, profileExtendInfo }}
        plusgradeInfo={{
          ...plusgradeInfo,
          isPlusUpgradeError,
        }}
      />
    ),
  }

  return upgradeEligibleInfo?.eligibleType && !isLoadingPlusgrade ? (
    pageByEligible[upgradeEligibleInfo?.eligibleType as EligibleTypes]
  ) : (
    <Spinner />
  )
}
