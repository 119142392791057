import { Layout } from '@copa/design-system-factory.layout'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import theme from '@copa/design-system-factory.theme'
import {
  FlightRequest,
  FlightsRequest,
  MilesOptionRequestData,
} from '@copa/design-system-factory.hooks.endpoints'
import { ItineraryCard } from '@copa/design-system-factory.itinerary-card'
import { Typography } from '@copa/design-system-factory.typography'
import { InContentAlert } from '@copa/design-system-factory.in-content-alert'
import { HeaderBarNavigation } from '@copa/design-system-factory.header-bar-navigation'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { useHistory, useParams } from 'react-router'
import { loadTripFlightToODAndSegments } from 'src/utils/utils'
import { QueryParams } from 'src/types/QueryParams'
import { PaxCard } from '@copa/design-system-factory.cards.pax-card'
import { DataUpgradeBoxModel } from '@copa/design-system-factory.boxes.upgrade-box/types'
import { pageViewEventUpgraHub } from 'src/actions/analytics'
import { useDispatch, useSelector } from 'react-redux'
import { Flight as ItineraryCardFlight } from '@copa/design-system-factory.itinerary-card/types'
import {
  ALERT_TOAST_HIDE_OUT_DURATION,
  HOST_ASSETS,
} from 'src/constants/variables'
import { AlertToast } from '@copa/design-system-factory.alert-toast'
import { FlightResponse } from '@copa/design-system-factory.hooks.use-trip-hub/types'
import { CounterDropdownStateModel } from '@copa/design-system-factory.dropdowns.counter-dropdown/dist/types'
import useStyles from './styles'
import useBitBreakPoints from '../../../../hooks/material/useBitBreakPoints'
import {
  AirlineCode,
  ClassOfService,
  ElegibleResponse,
  UpgradeCacheKey,
  UpgradeLocation,
  UpgradePurchaseOption,
  VoucherForConfirmation,
  VoucherType,
} from '../../types'
import {
  InstaUpgradeResponse,
  UpgradeConfirmationRequest,
  useDeleteInstantUpgradeSessionMutation,
  useGetEligibleSegmentsInfoMutation,
  useGetUpgradeConfirmationMutation,
  useGetVouchersMutation,
} from '../../../../api/upgradeHubSlice'
import { Loyalty, ProfileExtendedForUpgrade, UpgradeInfo } from '../types'
import { capitalizeFirstLetter } from '../../../srt/utils/flights'
import {
  Flight,
  Leg as FlightSegment,
  Leg,
  OD,
  Passenger,
} from '../../../srt/interfaces/flight.interface'
import {
  getPassengerDescription,
  getUniquePassengers,
  getVouchersForConsumption,
  isLoyaltyAvailable,
  pickMultipleVouchersAndModify,
  redirectToErrorPage,
  selectFlightGoBack,
  setBalanceToDefaultLocaleString,
} from '../../utils'
import { DestinationCard } from '../components/DestinationCard'
import { UpgradeBoxMT } from '../../components/UpgradeBox/UpgradeBoxMT'
import PricesSection from '../../components/PricesSection/PricesSection'
import { MyTripsState } from '../../../../types/state'
import { isFetchingGeneral } from '../../../../actions/generalSpinner'
import { formatDate, formatHour } from '../../../../utils/rebooking/format'
import { formatMinutes } from '../../../../utils/dates'
import { useErrorToast } from '../hooks/useErrorToast'
import MemberCardMT from '../../components/MemberCard/MemberCardMT'
import { PASSENGER_TYPE_ADT } from '../../../../constants/passengers'
import {
  AirportsCodes,
  PassengersAmount,
} from '../../components/PricesSection/AppliedDisplay/TripExtras/TripExtras'
import TripExtrasItemUPGH from '../../components/PricesSection/AppliedDisplay/TripExtras/TripExtra/TripExtrasItemUPGH'

type PartialRequest = Partial<MilesOptionRequestData>
interface SelectFlightProps {
  upgradeInfo: Partial<UpgradeInfo>
  origin: string | null
  setLocation: React.Dispatch<React.SetStateAction<UpgradeLocation>>
  setUpgradeBoxResponse?: React.Dispatch<
  React.SetStateAction<DataUpgradeBoxModel | undefined>
  >
}

const isEligibleToUpgrade = (
  flightKey: string,
  eligibleSegmentsInfo?: ElegibleResponse
) =>
  eligibleSegmentsInfo?.flights.find(
    f => f.flightKey === flightKey && f.isEligible
  )

const segmentToFlightForRequest = (segment: Leg): FlightRequest => ({
  key: segment.flightKey,
  flightNumber: segment.flightNumber,
  airlineMarketing: segment.airlineCode,
  departure: {
    airportCode: segment.departureCode,
    date: moment(segment.flightDate).format('YYYY-MM-DD'),
    time: moment(segment.flightDate).format('HH:mm:ss'),
  },
  arrival: {
    airportCode: segment.arrivalCode,
    date: moment(segment.flightArrivalDate).format('YYYY-MM-DD'),
    time: moment(segment.flightArrivalDate).format('HH:mm:ss'),
  },
  classOfService: {
    code: ClassOfService.Z,
  },
})

type GetUpgradeConfirmRequestProps = {
  priceResponse: DataUpgradeBoxModel
  tripInfo: Flight
  loyalty: Loyalty
  getVoucherForConfirmationRequest: (response: FlightResponse) => Array<{
    type: string
    amount: number
    currency: string
  }>
}

const getUpgradeConfirmRequest = ({
  priceResponse: { selectValue, responseRequest },
  tripInfo,
  loyalty,
  getVoucherForConfirmationRequest,
}: GetUpgradeConfirmRequestProps): UpgradeConfirmationRequest => {
  const purchaseOptionsSelected =
    selectValue === 'miles'
      ? UpgradePurchaseOption.MILES
      : UpgradePurchaseOption.VOUCHER

  return {
    pnr: tripInfo.pnr,
    fop: purchaseOptionsSelected,
    totalMiles:
      purchaseOptionsSelected === UpgradePurchaseOption.MILES
        ? responseRequest?.summary?.totalMiles
        : null,
    totalVouchers:
      purchaseOptionsSelected === UpgradePurchaseOption.VOUCHER
        ? responseRequest?.summary?.totalVouchers
        : null,
    airlineCode: AirlineCode.CM,
    classOfService: ClassOfService.Z,
    customers: tripInfo.flights[0].passengers.map((pass, idx) => ({
      key: (idx + 1).toString(),
      typeCode: pass.typeCode,
      firstName: pass.givenName,
      surname: pass.lastName,
    })),
    flights:
      responseRequest?.flights &&
      responseRequest?.flights.map(f => ({
        key: f.flightKey,
        vouchers:
          purchaseOptionsSelected === UpgradePurchaseOption.VOUCHER
            ? getVoucherForConfirmationRequest(f)
            : [],
        miles:
          purchaseOptionsSelected === UpgradePurchaseOption.MILES
            ? f.miles.map(m => ({
              ...m,
              currency: 'MILES',
            }))
            : [],
      })),
    loyaltyPrograms: [
      {
        ffn: loyalty.membershipID,
        code: loyalty.programID,
      },
    ],
  }
}

export const formatSegmentToItineraryFlight = (
  segment: FlightSegment
): ItineraryCardFlight => ({
  date: formatDate(segment.flightEstimatedDate),
  numbers: [segment.airlineCode + segment.flightNumber],
  time: {
    departure:
      formatHour(segment.flightDate) !== formatHour(segment.flightEstimatedDate)
        ? formatHour(segment.flightEstimatedDate)
        : formatHour(segment.flightDate),
    arrival:
      formatHour(segment.flightArrivalDate) !==
      formatHour(segment.flightEstimatedArrivalDate)
        ? formatHour(segment.flightEstimatedArrivalDate)
        : formatHour(segment.flightArrivalDate),
    duration: formatMinutes(
      segment.flightTime,
      (h: string, m: string) => `${h}h ${m}m`
    ),
  },
  route: {
    origin: `${segment.departureLocation} (${segment.departureCode})`,
    destination: `${segment.arrivalLocation} (${segment.arrivalCode})`,
  },
})

function isLegArray(flights: any): flights is Leg[] {
  return (
    flights &&
    Array.isArray(flights) &&
    flights.every(flight => 'flightKey' in flight)
  )
}

function isFlightsRequest(flights: any): flights is FlightsRequest {
  return (
    flights &&
    Array.isArray(flights) &&
    flights.every(flight => 'key' in flight)
  )
}

function getAirportsCodesByFlightKey(
  flights: Leg[] | FlightsRequest | undefined,
  flightKey: string
): AirportsCodes {
  if (!flights) {
    return { departureCode: undefined, arrivalCode: undefined }
  }

  let selectedSegment: Leg | FlightRequest | undefined

  if (isLegArray(flights)) {
    selectedSegment = (flights as Leg[]).find(
      flight => flight.flightKey === flightKey
    )
  } else if (isFlightsRequest(flights)) {
    selectedSegment = (flights as FlightsRequest).find(
      flight => flight.key === flightKey
    )
  }

  if (!selectedSegment) {
    return { departureCode: undefined, arrivalCode: undefined }
  }

  const departureCode =
    (selectedSegment as Leg).departureCode ??
    (selectedSegment as FlightRequest)?.departure?.airportCode

  const arrivalCode =
    (selectedSegment as Leg).arrivalCode ??
    (selectedSegment as FlightRequest)?.arrival?.airportCode

  return { departureCode, arrivalCode }
}

export function getTripExtraPassengerCount(
  tripExtra: FlightResponse
): PassengersAmount {
  let adultAmount = 0
  let childAmount = 0

  tripExtra.miles.map(customerGroup => {
    if (customerGroup.customerType === PASSENGER_TYPE_ADT) {
      adultAmount = customerGroup.customerAmount
    } else {
      childAmount = customerGroup.customerAmount
    }

    return customerGroup
  })

  return {
    adultAmount,
    childAmount,
  }
}

export type PriceSectionTripExtra = {
  amount: string
  passengersAmount?: PassengersAmount
  type: string
  key: number
  airportsCodes: AirportsCodes
}

export function getGetTripExtras(
  prices: DataUpgradeBoxModel | null | undefined,
  flights: Leg[] | FlightsRequest,
  ItemComponent: FC<PriceSectionTripExtra>
): React.JSX.Element[] {
  const purchaseOptionsSelected = prices?.selectValue ? prices.selectValue : ''
  const milesOptionFlights: FlightResponse[] = prices?.responseRequest?.flights
    ? prices.responseRequest?.flights
    : []

  return milesOptionFlights.map(flight => (
    <ItemComponent
      key={Number(flight.flightKey)}
      airportsCodes={getAirportsCodesByFlightKey(flights, flight.flightKey)}
      passengersAmount={getTripExtraPassengerCount(flight)}
      amount={
        purchaseOptionsSelected === 'vouchers'
          ? flight.totalVouchers.toString()
          : setBalanceToDefaultLocaleString(flight.totalMiles)
      }
      type={purchaseOptionsSelected}
    />
  ))
}

export function SelectFlightUPGH({
  upgradeInfo: { tripInfo, profileExtendInfo },
  origin,
  setLocation,
  setUpgradeBoxResponse,
}: SelectFlightProps) {
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const history = useHistory()
  const dispatch = useDispatch()
  const { pnr, surname: lastname, language } = useParams<QueryParams>()
  const classes = useStyles({ theme, breakpoints })
  const { masterSessionId: token } = useSelector(
    (state: MyTripsState) => state.auth
  )
  const {
    loyalty,
    name,
  } = profileExtendInfo as ProfileExtendedForUpgrade

  const [
    getEligibleSegmentsInfo,
    {
      data: eligibleSegmentsInfo,
      isLoading: isEligibleSegmentsLoading,
      isError: isElegibleError,
    },
  ] = useGetEligibleSegmentsInfoMutation({
    fixedCacheKey: UpgradeCacheKey.ELIGIBLE_SEGMENTS,
  })

  const [_, { data: voucherTotal }] = useGetVouchersMutation({
    fixedCacheKey: UpgradeCacheKey.VOUCHERS,
  })

  const [
    getUpgradeConfirmation,
    {
      data: upgradeConfirmation,
      isError: upgradeConfirmationError,
      isLoading: isUpgradeConfirmationLoading,
    },
  ] = useGetUpgradeConfirmationMutation({
    fixedCacheKey: UpgradeCacheKey.CONFIRM_UPGRADE,
  })

  const [itineraries, setItineraries] = useState<OD[]>([])
  const [selectedFlights, setSelectedFlights] = useState<FlightsRequest>([])
  const [upgradeBoxRequest, setUpgradeBoxRequest] = useState<PartialRequest>()
  const [passengers, setPassengers] = useState<Passenger[]>([])
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false)
  const [shouldHidePriceSection, setShouldHidePriceSection] = useState(true)
  const [instaUpgradeSession, setInstaUpgradeSession] =
    useState<InstaUpgradeResponse>()
  const [priceResponse, setPriceResponse] =
    useState<DataUpgradeBoxModel | null>()
  const [
    deleteInstantUpgradeSession,
    {
      data: responseDeleteSession,
      isError: deleteSessionError,
      isLoading: isDeleteSessionLoading,
    },
  ] = useDeleteInstantUpgradeSessionMutation()
  const [deleteErrorCounter, setDeleteErrorCounter] = useState<number>(0)
  const MAX_DELETE_SESSION_TRIES = 3
  const { open, handleClose, setOpen } = useErrorToast({
    isError: deleteSessionError,
  })
  const [vouchersForConsumption, setVouchersForConsumption] = useState<
  VoucherForConfirmation[]
  >([])

  useEffect(() => {
    dispatch(
      pageViewEventUpgraHub({
        path: history.location.pathname,
        pageName: 'Select flight',
        loggedIn: !!loyalty.membershipID,
        ffTier: loyalty?.loyalLevel,
        referSource: origin,
      })
    )
  }, [loyalty, origin, history, dispatch])

  useEffect(() => {
    if (
      !eligibleSegmentsInfo &&
      !isEligibleSegmentsLoading &&
      tripInfo?.flights[0].passengers.length
    ) {
      getEligibleSegmentsInfo({
        pnr,
        lastname,
        sizePassengers: tripInfo?.flights[0].passengers.length,
      })
    }
  }, [
    getEligibleSegmentsInfo,
    pnr,
    lastname,
    tripInfo?.flights,
    eligibleSegmentsInfo,
    isEligibleSegmentsLoading,
  ])

  useEffect(() => {
    dispatch(
      isFetchingGeneral(
        isUpgradeConfirmationLoading ||
          isDeleteSessionLoading ||
          isEligibleSegmentsLoading
      )
    )
  }, [
    isUpgradeConfirmationLoading,
    isDeleteSessionLoading,
    dispatch,
    isEligibleSegmentsLoading,
  ])

  const deleteInstantUpgradeSessionWrapper = useCallback(
    (reservationCode: string) => {
      deleteInstantUpgradeSession(reservationCode)
      setPriceResponse(undefined)
    },
    [deleteInstantUpgradeSession]
  )

  useEffect(() => {
    if (responseDeleteSession && !deleteSessionError) {
      setShouldHidePriceSection(true)
    }
  }, [responseDeleteSession, deleteSessionError])

  useEffect(() => {
    if (priceResponse) {
      setShouldHidePriceSection(false)
      getVouchersForConsumptionUpHub(priceResponse.counterDropdownValue)
    }
  }, [priceResponse])

  useEffect(() => {
    if (tripInfo) {
      setItineraries(loadTripFlightToODAndSegments(tripInfo.flights))
      const customerLoyalty = {
        ffn: loyalty.membershipID,
        code: loyalty.programID,
      }
      const customers = tripInfo.flights[0].passengers.map((pass, idx) => ({
        typeCode: pass.typeCode,
        key: (idx + 1).toString(),
        ...(idx === 0 && { loyalty: customerLoyalty }),
      }))
      const customerAvailableVouchers = []
      if (voucherTotal?.regional) {
        customerAvailableVouchers.push({
          type: VoucherType.REGIONAL,
          amount: voucherTotal?.regional,
        })
      }
      if (voucherTotal?.global) {
        customerAvailableVouchers.push({
          type: VoucherType.GLOBAL,
          amount: voucherTotal?.global,
        })
      }
      setUpgradeBoxRequest({
        pnr: tripInfo.pnr,
        customers,
        customerAvailableVouchers,
      })
      const uniquePassengers = getUniquePassengers(tripInfo)
      setPassengers(uniquePassengers)
    }
  }, [tripInfo, loyalty, voucherTotal])

  useEffect(() => {
    setUpgradeBoxRequest(prev => ({
      ...prev,
      flights: [...selectedFlights],
    }))
  }, [selectedFlights, setUpgradeBoxRequest])

  useEffect(() => {
    if (upgradeConfirmation) {
      setLocation(UpgradeLocation.UPGH_CONFIRMATION)
    }
  }, [setLocation, upgradeConfirmation])

  useEffect(() => {
    if (upgradeConfirmationError) {
      redirectToErrorPage({ pnr, surname: lastname, origin, history, language })
    }
  }, [history, language, lastname, origin, pnr, upgradeConfirmationError])

  useEffect(() => {
    if (shouldRedirect && !isDeleteSessionLoading) {
      selectFlightGoBack({
        pnr,
        surname: lastname,
        origin,
        history,
        language,
      })
    }
  }, [
    history,
    isDeleteSessionLoading,
    language,
    lastname,
    origin,
    pnr,
    shouldRedirect,
  ])

  useEffect(() => {
    if (deleteSessionError) {
      setOpen(true)
      setDeleteErrorCounter(prevCounter => prevCounter + 1)
    }
  }, [deleteSessionError, setOpen])

  useEffect(() => {
    if (
      (deleteSessionError && deleteErrorCounter >= MAX_DELETE_SESSION_TRIES) ||
      isElegibleError
    ) {
      redirectToErrorPage({ history, pnr, surname: lastname, language, origin })
    }
  }, [
    deleteErrorCounter,
    deleteSessionError,
    history,
    language,
    origin,
    pnr,
    setOpen,
    lastname,
    isElegibleError,
  ])

  const handleCheckboxesChange = (isSelected: boolean, segment: Leg) => {
    setSelectedFlights(prev => {
      if (isSelected) {
        return [...prev, segmentToFlightForRequest(segment)]
      }

      return prev.filter(flight => flight.key !== segment.flightKey)
    })
  }

  const confirmUpgrade = useCallback(() => {
    const getVoucherForConfirmationRequest = (
      flightResponse: FlightResponse
    ): Array<{
      type: string
      amount: number
      currency: string
    }> => {
      const selectedVouchers = pickMultipleVouchersAndModify(
        vouchersForConsumption,
        flightResponse.totalVouchers
      )
      setVouchersForConsumption(selectedVouchers.vouchersLeft)
      return selectedVouchers.selectedVouchers
    }
    const upgradeConfirmRequest = getUpgradeConfirmRequest({
      priceResponse,
      loyalty,
      tripInfo,
      getVoucherForConfirmationRequest,
    } as GetUpgradeConfirmRequestProps)

    getUpgradeConfirmation({ token, request: upgradeConfirmRequest })
  }, [
    priceResponse,
    loyalty,
    tripInfo,
    getUpgradeConfirmation,
    token,
    vouchersForConsumption,
  ])

  const handleGoBack = () => {
    setShouldRedirect(true)

    if (instaUpgradeSession && priceResponse) {
      deleteInstantUpgradeSessionWrapper(pnr)
    }
  }

  const getVouchersForConsumptionUpHub = (
    vouchersCounter: CounterDropdownStateModel
  ) => {
    const vouchers = getVouchersForConsumption(vouchersCounter)
    setVouchersForConsumption(vouchers)
  }

  return (
    <>
      <HeaderBarNavigation
        title={formatMessage({ id: 'upgradeHub.header.title' })}
        wcagText={formatMessage({ id: 'upgradeHub.header.wcagText' })}
        onClick={handleGoBack}
      />
      <Box sx={classes.container}>
        <Layout>
          <AlertToast
            type="error"
            text={formatMessage({ id: 'upgradeHub.selectFlight.errorToast' })}
            autoHideDuration={ALERT_TOAST_HIDE_OUT_DURATION}
            open={open}
            handleClose={() => handleClose()}
          />
          <Box>
            <MemberCardMT
              loyalty={loyalty}
              vouchers={voucherTotal}
              name={name}
            />
          </Box>
          <Box sx={classes.Box}>
            <Typography color="primary.secondary" variant="h3">
              {formatMessage({ id: 'upgradeHub.selectFlight.title' })}
            </Typography>
          </Box>
          <Box sx={classes.Alert}>
            <InContentAlert
              message={formatMessage({
                id: 'upgradeHub.selectFlight.alert.text',
              })}
              variant="info"
              icon={`${HOST_ASSETS}InfoinfoIcon.svg`}
            />
          </Box>
          <Box sx={classes.Cards}>
            {itineraries.map(od => (
              <>
                <DestinationCard
                  location={od.destination.location}
                  destinationImage={od.destination.image}
                />

                {od.segments.map(segment => (
                  <ItineraryCard
                    key={segment.flightKey}
                    id={`test-select-flight-${segment.flightKey}`}
                    // @ts-ignore
                    extraInfo={{
                      checkboxOption: {
                        name: `flight-${segment.flightKey}`,
                        label: isEligibleToUpgrade(
                          segment.flightKey,
                          eligibleSegmentsInfo
                        )
                          ? formatMessage({
                            id: 'upgradeHub.selectFlight.itineraryCard.available',
                          })
                          : formatMessage({
                            id: 'upgradeHub.selectFlight.itineraryCard.notAvailable',
                          }),
                        value: !!selectedFlights.find(
                          flight => flight.key === segment.flightKey
                        ),
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleCheckboxesChange(event.target.checked, segment),
                        disabled: !isEligibleToUpgrade(
                          segment.flightKey,
                          eligibleSegmentsInfo
                        ),
                        'aria-label': isEligibleToUpgrade(
                          segment.flightKey,
                          eligibleSegmentsInfo
                        )
                          ? formatMessage({
                            id: 'upgradeHub.selectFlight.itineraryCard.ariaLabelCheckboxActivated',
                          })
                          : formatMessage({
                            id: 'upgradeHub.selectFlight.itineraryCard.ariaLabelCheckboxDisabled',
                          }),
                      },
                    }}
                    flight={formatSegmentToItineraryFlight(segment)}
                  />
                ))}
              </>
            ))}
          </Box>
          <Box sx={classes.typographyBox}>
            <Typography variant="h3" color="gray.700">
              {formatMessage({
                id: 'upgradeHub.selectFlight.passengerText.title',
              })}
            </Typography>
            <Typography variant="body1" color="gray.600">
              {formatMessage({
                id: 'upgradeHub.selectFlight.passengerText.subtitle',
              })}
            </Typography>
          </Box>
          <Box sx={classes.paxCardBox}>
            {passengers.map(passenger => (
              <PaxCard
                key={passenger.eTicket}
                avatar={{
                  id: 'basic',
                  font: capitalizeFirstLetter(
                    `${passenger.givenName} ${passenger.lastName}`
                  ),
                  stroke: true,
                  inverted: !isLoyaltyAvailable(passenger),
                  color:
                    // @ts-ignore
                    theme.palette.status[
                      passenger.loyalty?.statusConnectMiles.toLowerCase()
                    ],
                }}
                listDescription={[
                  getPassengerDescription(passenger, formatMessage),
                ]}
                wcagText=""
              />
            ))}
          </Box>
        </Layout>
        <Box
          sx={classes.UpgradeBox}
          tabIndex={0}
          aria-label={formatMessage({
            id: 'upgradeHub.upgradeBox.upgradeBoxContainerWCAG',
          })}
        >
          <Layout>
            <Box sx={classes.UpgradeLayout}>
              <Box sx={classes.UpgradeBoxBackground}>
                <UpgradeBoxMT
                  loyalty={{
                    loyalLevel: loyalty?.loyalLevel as string,
                    balance: loyalty?.balance as number,
                  }}
                  vouchers={voucherTotal}
                  selectedFlights={selectedFlights}
                  upgradeBoxRequest={upgradeBoxRequest}
                  onPriceResponse={response => setPriceResponse(response)}
                  deleteInstantUpgradeSession={() =>
                    deleteInstantUpgradeSessionWrapper(pnr)
                  }
                  setUpgradeBoxResponse={setUpgradeBoxResponse}
                  setInstaUpgradeSession={setInstaUpgradeSession}
                />
              </Box>
            </Box>
          </Layout>
        </Box>

        <Layout>
          <PricesSection
            tripExtras={getGetTripExtras(
              priceResponse,
              tripInfo?.flights as Leg[],
              TripExtrasItemUPGH
            )}
            pricesResponse={priceResponse}
            onConfirmUpgrade={confirmUpgrade}
            shouldHide={shouldHidePriceSection}
          />
        </Layout>
      </Box>
    </>
  )
}
