export const IconLink = (color: string) => (
  <svg
    style={{
      cursor: 'pointer',
      margin: '0'
    }}
    data-testid='tooltip'
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 13.3333C10.9452 13.3333 13.333 10.9455 13.333 8C13.333 5.05448 10.9452 2.66667 7.99967 2.66667C5.05416 2.66667 2.66634 5.05448 2.66634 8C2.66634 10.9455 5.05416 13.3333 7.99967 13.3333ZM7.99967 14.6667C11.6816 14.6667 14.6663 11.6819 14.6663 8C14.6663 4.3181 11.6816 1.33334 7.99967 1.33334C4.31778 1.33334 1.33301 4.3181 1.33301 8C1.33301 11.6819 4.31778 14.6667 7.99967 14.6667Z"
      fill={color}
    />
    <path
      d="M7.33301 7.33334C7.33301 6.96515 7.63148 6.66667 7.99967 6.66667C8.36786 6.66667 8.66634 6.96515 8.66634 7.33334V10.6667C8.66634 11.0349 8.36786 11.3333 7.99967 11.3333C7.63148 11.3333 7.33301 11.0349 7.33301 10.6667V7.33334Z"
      fill={color}
    />
    <path
      d="M7.33301 5.33334C7.33301 4.96515 7.63148 4.66667 7.99967 4.66667C8.36786 4.66667 8.66634 4.96515 8.66634 5.33334C8.66634 5.70153 8.36786 6 7.99967 6C7.63148 6 7.33301 5.70153 7.33301 5.33334Z"
      fill={color}
    />
  </svg>
)
