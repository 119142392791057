import { InlineAlertProps } from '@copa/design-system-factory.inline-alert/dist/inline-alert'
import Icon from '../../../../utils/icon'
import { SauaFlight } from '../components/FlightForm/FlightForm'

export enum SAUAConfirmationScenario {
  Success = 'success',
  Partial = 'partial',
  Error = 'error',
}

export type SAUA_ALERTCONFIG_TYPE = Pick<InlineAlertProps, 'variant'> & {
  color: string
  icon: string
  type: SAUAConfirmationScenario
}

const ALERT_CONFIG: {
  [key in SAUAConfirmationScenario]: SAUA_ALERTCONFIG_TYPE
} = {
  [SAUAConfirmationScenario.Success]: {
    variant: 'main',
    color: 'primary.main',
    icon: Icon.INFO_FILLED,
    type: SAUAConfirmationScenario.Success,
  },
  [SAUAConfirmationScenario.Partial]: {
    variant: 'error',
    color: 'error.main',
    icon: Icon.WARNING_FILLED,
    type: SAUAConfirmationScenario.Partial,
  },
  [SAUAConfirmationScenario.Error]: {
    variant: 'error',
    color: 'error.main',
    icon: Icon.WARNING_FILLED,
    type: SAUAConfirmationScenario.Error,
  },
}

const TEXT_BASE_PATH = 'upgradeHub.saua.confirmationPage.cases'

export const getUpgradeScenarioConfig = (state: SAUAConfirmationScenario) => {
  const config = ALERT_CONFIG[state]
  return {
    ...config,
    title: `${TEXT_BASE_PATH}.${state}.title`,
    alertText: `${TEXT_BASE_PATH}.${state}.alertText`,
    pillText: `${TEXT_BASE_PATH}.${state}.pillText`,
  }
}

export const getUpgradeScenario = (flights: SauaFlight[] | undefined) => {
  if (!flights?.length) {
    return SAUAConfirmationScenario.Error
  }
  const successFlights = flights?.filter(({ isUpgraded }) => isUpgraded) || []
  if (successFlights.length === flights.length) {
    return SAUAConfirmationScenario.Success
  }
  if (!successFlights.length) {
    return SAUAConfirmationScenario.Error
  }
  return SAUAConfirmationScenario.Partial
}
