import { Breakpoints, getSpacing } from '../../../../../../../../utils/spacing'

const useStyles = (breakpoints: Breakpoints) => ({
  container: {
    marginTop: getSpacing(breakpoints)?.roomy,
  },
  titleContainer: {
    marginBottom: getSpacing(breakpoints)?.normal,
  },
  itinerariesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(breakpoints)?.petit,
    marginTop: getSpacing(breakpoints)?.normal,
  },
  alert: {
    marginTop: getSpacing(breakpoints)?.normal,
  },
})

export default useStyles
