import { getFeatureFlag } from '../../../../../../../constants/featureFlags'
import {
  CM_MASTERSESSION,
  CM_MEMBERSHIP,
  CM_STATUS,
} from '../../../../../../../constants/cookies'
import { REDIRECT_ACTION } from '../../../../../../../constants/auth'
import { getCookie } from '../../../../../../../utils/cookies'
import { rebookingElegibilityRequest } from '../../../../../../../actions/rebooking'
import { logOutWithShowLoginRequest } from '../../../../../../../actions/connectMiles'
import { isFetchingGeneral } from '../../../../../../../actions/generalSpinner'
import { templates } from '../../../templates/templates.enum'
import { ScheduleChangeTemplate } from '../../../templates/interfaces/schedule-change.template'
import { WCITemplate } from '../../../../WCI/templates/interfaces/WCITemplate'

interface ShouldShowContentParams {
  template: ScheduleChangeTemplate | WCITemplate
  isLoggedIn?: boolean
  isElegible?: boolean
}

export const shouldShowContent = ({
  template: { name },
  isLoggedIn = false,
  isElegible,
}: ShouldShowContentParams) => {
  let show = false

  switch (name) {
    case templates.RDP:
      show = isLoggedIn
      break
    default:
      show = name !== templates.DEFAULT || !isElegible
  }

  return show
}

export const handleUnauthenticatedUser = ({
  // @ts-ignore
  pnr,
  // @ts-ignore
  surname,
  // @ts-ignore
  history,
  // @ts-ignore
  template: { validations },
  // @ts-ignore
  auth: { loginWithRedirect, isLoggedIn },
}) => {
  if (validations.userShouldBeUnauthenticated && !isLoggedIn) {
    // TODO: Clean after 100% rollout of FF IS_CIAM_ON
    if (getFeatureFlag('IS_CIAM_ON')) {
      loginWithRedirect({ redirectTo: window.location.pathname })
    } else {
      history.push(`/trip-detail/${pnr}/${surname}`)
    }
  }
}

export const handleNotMatchingAccount = ({
  // @ts-ignore
  showModal,
  // @ts-ignore
  dispatch,
  // @ts-ignore
  template: { validations },
  flight: {
    // @ts-ignore
    flights,
    // @ts-ignore
    redemption: { ownerFFN, isRedemption },
  },
}) => {
  const cmMembership = getCookie(CM_MEMBERSHIP)
  const cmToken = getCookie(CM_MASTERSESSION)
  const membershipStatus = getCookie(CM_STATUS)

  if (validations.userShouldBeUnauthenticated) {
    if (cmMembership !== ownerFFN) {
      showModal()
    } else if (flights.length) {
      const payload = {
        isRedemption,
        classOfService: flights[0].classOfService,
        memberStatus: membershipStatus,
        departureDate: flights[0].flightDate,
      }
      dispatch(rebookingElegibilityRequest(cmMembership, payload, cmToken))
    }
  }
}

export const logoutAndRedirectToLogin = ({
  // @ts-ignore
  dispatch,
  // @ts-ignore
  history,
  // @ts-ignore
  auth: { logout },
}) => {
  dispatch(isFetchingGeneral(true))

  const cmMembership = getCookie(CM_MEMBERSHIP)
  const cmToken = getCookie(CM_MASTERSESSION)

  // TODO: Clean after 100% rollout of FF IS_CIAM_ON
  if (getFeatureFlag('IS_CIAM_ON')) {
    logout({
      redirectTo: window.location.pathname,
      callActionOnCiamReturn: REDIRECT_ACTION.LOGIN,
    })
  } else {
    dispatch(
      logOutWithShowLoginRequest({
        sessionToken: cmToken,
        username: cmMembership,
        history,
      })
    )
  }
}
