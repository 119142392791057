import Immutable from 'seamless-immutable'

const PassengerState = Immutable({
  areApisCompleted: false,
  boardingGroup: '',
  checkedIn: false,
  documents: null,
  email: null,
  ffn: null,
  ffp: null,
  givenName: '',
  isAbleToUpdateTIF: false,
  isInfant: false,
  isTIFLocked: false,
  lastName: '',
  loyaltyLevelDescription: null,
  refNumber: '',
  seat: '',
  travelerKey: '',
  typeCode: '',
  specialRequests: [],
})

const flighsState = Immutable({
  aircraft: '',
  airlineCode: '',
  airlineName: '',
  applyExtendedApis: false,
  arrivalAirport: '',
  arrivalCode: '',
  arrivalGMT: '',
  arrivalLocation: '',
  arrivalLocationImage: '',
  departureAirport: '',
  departureCode: '',
  departureGMT: '',
  departureLocation: '',
  entertainment: '',
  flightArrivalDate: '',
  flightBoardingTime: '',
  flightDate: '',
  flightEstimatedArrivalDate: '',
  flightEstimatedDate: '',
  flightNumber: '',
  flightTime: '',
  gate: '',
  isAutoCheckable: false,
  isCheckInOpen: false,
  isDomesticFlight: false,
  isUSFlight: false,
  layoverTime: null,
  meal: '',
  paperBP: false,
  passengers: [PassengerState],
  scheduleChanged: false,
  showUpgradeStandByLists: false,
  status: '',
  terminal: '',
  specialServicesPerFlight: [],
  canRequestUpgrade: false,
  wkParentFlight: null,
})

export default flighsState
