import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { ButtonCo } from '@copa/design-system-factory.button'
import { AcceptItineraryProps } from './types'
import useStyles from './styles'
import { createItineraryCards } from '../../../../../../utils/flights'
import useBitBreakPoints from '../../../../../../../../hooks/material/useBitBreakPoints'
import { templates } from '../../../../templates/templates.enum'

function AcceptItinerary({
  template: { title, subtitle, modifyBtn, acceptBtn },
  suggestedItinerary,
  onModifyItinerary,
  onAcceptSuggestedItinerary,
  flow,
  typeModify,
}: AcceptItineraryProps) {
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  const buttonsContainerClass =
    modifyBtn.display || acceptBtn.display
      ? 'buttonsContainer'
      : 'emptyContainer'

  return (
    <>
      <Box sx={classes.title}>
        <Typography color="primary.main" variant="h2">
          {formatMessage({ id: title })}
        </Typography>
        <Typography color="grey.600" variant="body1">
          {formatMessage({ id: subtitle })}
        </Typography>
      </Box>

      <Box sx={classes.itinerariesContainer}>
        {createItineraryCards(suggestedItinerary, {
          shouldShowExtraInfo: !(
            typeModify === templates.RDP ||
            typeModify === templates.NR ||
            // @ts-ignore
            typeModify.includes(templates.ADHOC)
          ),
          shouldShowDelayInfo: false,
          formatMessage,
          flow,
        })}
      </Box>

      {(acceptBtn.display || modifyBtn.display) && (
        <Box sx={classes[buttonsContainerClass]}>
          {modifyBtn.display && (
            <ButtonCo
              sx={classes.button}
              variant="outlinePrimaryMain"
              aria-label={formatMessage({ id: modifyBtn.text })}
              rounded
              size="large"
              onClick={() => onModifyItinerary()}
            >
              {formatMessage({ id: modifyBtn.text })}
            </ButtonCo>
          )}

          {acceptBtn.display && (
            <ButtonCo
              sx={classes.button}
              variant="solidPrimaryMain"
              aria-label={formatMessage({ id: acceptBtn.text })}
              rounded
              size="large"
              onClick={() => onAcceptSuggestedItinerary()}
            >
              {formatMessage({ id: acceptBtn.text })}
            </ButtonCo>
          )}
        </Box>
      )}
    </>
  )
}

export default AcceptItinerary
