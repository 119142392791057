import { useStylesProps } from '../upgrade-hub/types'
import { getSpacing } from '../../../utils/spacing'

const useStyles = ({ theme, breakpoints }: useStylesProps) => ({
  container: {
    // @ts-ignore
    backgroundColor: theme.palette.background.paper,
    minHeight: '100%',
    // @ts-ignore
    paddingTop: getSpacing(breakpoints)?.roomy,
    // @ts-ignore
    paddingBottom: getSpacing(breakpoints)?.roomy,
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    // @ts-ignore
    gap: getSpacing(breakpoints)?.roomy,
  },
  sectionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    // @ts-ignore
    gap: getSpacing(breakpoints)?.normal,
  },
})

export default useStyles
