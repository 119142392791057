import React from 'react'
import { compose, setPropTypes } from 'react-recompose'
import { injectIntl } from 'react-intl'
import * as PropTypes from 'prop-types'
import InfoLabelCO from '../infoLabel/infoLabelCO'

const FlightStatus = ({ status, intl: { formatMessage }, extraClass }) => {
  switch (status) {
    case 'on-time':
      return (
        <InfoLabelCO type="success" extraClass={extraClass}>
          {formatMessage({
            id: 'flightCard.onTime',
          })}
        </InfoLabelCO>
      )
    case 'in-air':
      return (
        <InfoLabelCO type="success" extraClass={extraClass}>
          {formatMessage({
            id: 'flightCard.onTime',
          })}
        </InfoLabelCO>
      )
    case 'delayed':
      return (
        <InfoLabelCO type="danger" extraClass={extraClass}>
          {formatMessage({
            id: 'flightCard.delayed',
          })}
        </InfoLabelCO>
      )
    case 'cancelled':
      return (
        <InfoLabelCO type="danger" extraClass={extraClass}>
          {formatMessage({
            id: 'flightCard.canceled',
          })}
        </InfoLabelCO>
      )
    case 'departing-early':
      return (
        <InfoLabelCO type="blue" extraClass={extraClass}>
          {formatMessage({
            id: 'flightCard.advanced',
          })}
        </InfoLabelCO>
      )
    case 'completed':
      return (
        <InfoLabelCO type="gray" extraClass={extraClass}>
          {formatMessage({
            id: 'flightCard.completed',
          })}
        </InfoLabelCO>
      )
    default:
      return <span />
  }
}

const propTypes = {
  status: PropTypes.string.isRequired,
  extraClass: PropTypes.string,
}

export default compose(injectIntl, setPropTypes(propTypes))(FlightStatus)
