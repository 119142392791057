import React from 'react'
import { TitleCombination } from '@copa/design-system-factory.title-combination'
import { useIntl } from 'react-intl'

function CabinDowngradedContent({ city }: { city: string }) {
  const { formatMessage } = useIntl()

  return (
    <TitleCombination
      avatarProps={{
        id: 'cabin-downgraded',
        variant: 'huge',
        font: 'https://www.copaair.com/webassets/pictogramas/funcionales/anuncios_importantes_b.svg',
      }}
      titleProps={{
        children: formatMessage(
          {
            id: 'srt.wci.cfc.resultSearchPage.upx.modal.title',
          },
          {
            city,
          }
        ),
      }}
      descriptionProps={{
        children: formatMessage({
          id: 'srt.wci.cfc.resultSearchPage.upx.modal.text',
        }),
      }}
    />
  )
}

export default CabinDowngradedContent
