import { Breakpoints, getSpacing } from '../../../../../utils/spacing'

const useStyles = (breakpoints: Breakpoints) => ({
  header: { marginBottom: getSpacing(breakpoints).roomy },
  titleCombination: {
    paddingX: getSpacing(breakpoints).roomy,
    '& p': { textAlign: 'center' },
  },
})

export default useStyles
