import { Reader } from 'monet'
import {
  MORE_THAN_TWO_CHARACTERS_LAST_NAME_FIELD_REGEX,
  ONLY_SPACES,
} from '../constants/regex'

export const formatPriceLockRemainingTime = deadlineToPay =>
  Reader(({ formatMessage, getCountdown }) =>
    deadlineToPay && getCountdown(deadlineToPay) !== ''
      ? `${formatMessage({
        id: 'flightCard.flightPaidRequired',
      })} ${deadlineToPay !== '' ? getCountdown(deadlineToPay) : '00:00'}`
      : `${formatMessage({
        id: 'stackableAlerts.expiredBookAndHold.title',
      })}`
  )

export const filterLastName = lastName => {
  if (MORE_THAN_TWO_CHARACTERS_LAST_NAME_FIELD_REGEX.test(lastName)) {
    const lastNameModified = lastName.match(
      MORE_THAN_TWO_CHARACTERS_LAST_NAME_FIELD_REGEX
    )
    return lastNameModified[0]
  }
  return lastName.replace(ONLY_SPACES, '')
}

export const deleteSpaces = phrase => phrase.trim().replaceAll(' ', '')
