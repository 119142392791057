import React from 'react'

export const TicketIcon = ({ props }) => (
  <svg
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4_35995)">
      <path
        d="M96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48Z"
        fill="#EFF5FF"
      />
      <path
        d="M51.1244 22.3971C54.2486 19.2729 59.3139 19.2729 62.4381 22.3971L73.6032 33.5622C76.7274 36.6864 76.7274 41.7517 73.6032 44.8759L44.8418 73.6373C41.7176 76.7615 36.6523 76.7614 33.5281 73.6373L22.363 62.4722C19.2388 59.348 19.2388 54.2827 22.363 51.1585L51.1244 22.3971Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1816 21.4551C53.8265 17.8102 59.736 17.8102 63.3809 21.4551L74.546 32.6202C78.1908 36.265 78.1912 42.1743 74.5463 45.8193L68.5927 51.7728C68.072 52.2935 67.2278 52.2935 66.7071 51.7728C66.1864 51.2521 66.1864 50.4079 66.7071 49.8872L72.6606 43.9337C75.2641 41.3302 75.2639 37.1094 72.6604 34.5058L61.4953 23.3407C58.8918 20.7373 54.6707 20.7372 52.0672 23.3407L23.3058 52.1021C20.7023 54.7056 20.7023 58.9267 23.3058 61.5302L34.4709 72.6953C37.0744 75.2988 41.2955 75.2988 43.899 72.6953L49.5715 67.0228C50.0922 66.5021 50.9364 66.5021 51.4571 67.0228C51.9778 67.5435 51.9778 68.3877 51.4571 68.9084L45.7846 74.5809C42.1397 78.2258 36.2302 78.2258 32.5853 74.5809L21.4202 63.4158C17.7753 59.7709 17.7753 53.8614 21.4202 50.2165L50.1816 21.4551Z"
        fill="#0032A0"
      />
      <path
        d="M45.8123 57.3712L39.8123 54.1874L39.7866 52.1626L39.7738 51.0111C39.7728 50.7244 39.7141 50.4409 39.6013 50.1774C39.4885 49.9138 39.3239 49.6757 39.1172 49.4771C38.9105 49.2785 38.666 49.1236 38.3982 49.0214C38.1303 48.9192 37.8447 48.872 37.5582 48.8824C37.2714 48.888 36.9886 48.9512 36.7267 49.0683C36.4648 49.1854 36.2291 49.354 36.0337 49.564C35.8382 49.774 35.687 50.0212 35.5891 50.2909C35.4911 50.5605 35.4484 50.8471 35.4634 51.1335L35.5067 52.2719L35.628 54.3054L29.8136 57.8086C29.7081 57.8729 29.6213 57.9637 29.5618 58.0719C29.5023 58.1802 29.4723 58.3022 29.4746 58.4257L29.5135 59.8552C29.5171 59.9663 29.547 60.075 29.6006 60.1724C29.6542 60.2697 29.73 60.3531 29.8219 60.4157C29.9094 60.4783 30.0102 60.5198 30.1165 60.5371C30.2227 60.5545 30.3316 60.547 30.4345 60.5155L35.9574 58.7808C36.0579 59.7898 36.2158 60.7922 36.4305 61.7832L35.0789 63.491C34.9839 63.6189 34.9348 63.7751 34.9397 63.9343L34.9656 64.9858C34.9697 65.1032 35.0028 65.2179 35.0619 65.3194C35.121 65.421 35.2043 65.5064 35.3044 65.5679C35.4046 65.6303 35.5192 65.6659 35.6372 65.6712C35.7551 65.6765 35.8725 65.6514 35.9779 65.5982L38.0029 64.5898L40.0797 65.4845C40.2351 65.5519 40.4099 65.5596 40.5707 65.5062C40.6322 65.4851 40.6907 65.4559 40.7445 65.4192C40.8367 65.3541 40.9115 65.2674 40.9623 65.1666C41.013 65.0658 41.0383 64.9541 41.0357 64.8413L41.0229 63.768C41.0201 63.6105 40.9634 63.4586 40.8622 63.3379L39.446 61.6436C39.6003 60.6578 39.6977 59.664 39.7377 58.6671L45.3512 60.0913C45.479 60.1218 45.6126 60.1172 45.738 60.0782C45.8179 60.0529 45.8919 60.0115 45.9552 59.9565C46.0473 59.8905 46.1216 59.8029 46.1716 59.7013C46.2217 59.5997 46.2459 59.4874 46.2421 59.3742L46.2033 57.9447C46.1942 57.8232 46.1532 57.7062 46.0846 57.6056C46.016 57.5049 45.9221 57.4241 45.8123 57.3712Z"
        fill="#00A3E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.8803 26.6802C53.401 26.1595 54.2452 26.1595 54.7659 26.6802L56.6431 28.5574C57.1638 29.0781 57.1638 29.9223 56.6431 30.443C56.1224 30.9637 55.2782 30.9637 54.7575 30.443L52.8803 28.5659C52.3596 28.0452 52.3596 27.2009 52.8803 26.6802ZM58.5118 32.3117C59.0325 31.791 59.8767 31.791 60.3974 32.3117L64.1517 36.066C64.6724 36.5867 64.6724 37.4309 64.1517 37.9516C63.631 38.4723 62.7868 38.4723 62.2661 37.9516L58.5118 34.1973C57.9911 33.6766 57.9911 32.8324 58.5118 32.3117ZM66.0204 39.8203C66.5411 39.2996 67.3853 39.2996 67.906 39.8203L69.7831 41.6974C70.3038 42.2181 70.3038 43.0623 69.7831 43.583C69.2624 44.1037 68.4182 44.1037 67.8975 43.583L66.0204 41.7059C65.4997 41.1852 65.4997 40.341 66.0204 39.8203Z"
        fill="#0032A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5061 36.371C54.0268 36.8917 54.0268 37.7359 53.5061 38.2566L45.0652 46.6973C44.5445 47.218 43.7003 47.218 43.1796 46.6973C42.6589 46.1766 42.6589 45.3323 43.1796 44.8117L51.6205 36.371C52.1412 35.8503 52.9854 35.8503 53.5061 36.371Z"
        fill="#0032A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7694 43.3554C55.2901 43.8761 55.2901 44.7203 54.7694 45.241L49.1902 50.82C48.6695 51.3407 47.8253 51.3407 47.3046 50.82C46.7839 50.2993 46.7839 49.4551 47.3046 48.9344L52.8838 43.3554C53.4045 42.8347 54.2487 42.8347 54.7694 43.3554Z"
        fill="#0032A0"
      />
    </g>
    <path
      d="M80.8941 65.7791C80.8941 74.1247 74.1286 80.8902 65.783 80.8902C57.4373 80.8902 50.6719 74.1247 50.6719 65.7791C50.6719 57.4334 57.4373 50.668 65.783 50.668C74.1286 50.668 80.8941 57.4334 80.8941 65.7791Z"
      fill="#00A3E0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.783 78.1407C72.6101 78.1407 78.1446 72.6062 78.1446 65.7791C78.1446 58.952 72.6101 53.4175 65.783 53.4175C58.9559 53.4175 53.4214 58.952 53.4214 65.7791C53.4214 72.6062 58.9559 78.1407 65.783 78.1407ZM65.783 80.8902C74.1286 80.8902 80.8941 74.1247 80.8941 65.7791C80.8941 57.4334 74.1286 50.668 65.783 50.668C57.4373 50.668 50.6719 57.4334 50.6719 65.7791C50.6719 74.1247 57.4373 80.8902 65.783 80.8902Z"
      fill="#0032A0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.7888 59.127C66.5481 59.127 67.1636 59.7425 67.1636 60.5017V65.4667L72.8623 68.1694C73.5484 68.4948 73.8407 69.3147 73.5154 70.0007C73.19 70.6867 72.3701 70.9791 71.6841 70.6537L65.1997 67.5783C64.7199 67.3508 64.4141 66.8672 64.4141 66.3362V60.5017C64.4141 59.7425 65.0296 59.127 65.7888 59.127Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_4_35995">
        <rect
          width="96"
          height="96"
          fill="white"
          transform="matrix(-1 0 0 1 96 0)"
        />
      </clipPath>
    </defs>
  </svg>
)
