/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { injectIntl } from "react-intl";
import { compose } from "react-recompose";
// @styles
import "./saveCard.css";

// @commons
import Swoosh from "../../../assets/images/TripCard-swoosh.svg";
import iconAlert from "../../../assets/images/alert-white-icon.svg";
import Ellipsis from "../ellipsisCO/ellipsisCO";
import SegmentCount from "../segmentCount/segmentCount";

const saveCardStyles = {
  ellipsisSavecard: 'savecard__ellipsis-savecard',
  __button: 'savecard__ellipsis-savecard__button',
}

const validateBorderBottom = (
  reservationIsCancelled,
  flightIsCancelled,
  isPaidRequired
) =>
  !reservationIsCancelled && !flightIsCancelled && !isPaidRequired
    ? '0 8px 8px 0'
    : '0 8px 0 0'

const saveCard = ({
  id,
  pnr,
  idContainer,
  idContentArea,
  idCountSegment,
  idAlertContent,
  idAlertText,
  idIconAlert,
  idImageContent,
  idEllipsis,
  idEllipsisOptions,
  idEllipsisBtnRename,
  idEllipsisBtnRemove,
  idEllipsisBtnCalendar,
  reservationIsCancelled,
  reservationIsCancelledText,
  flightIsCancelled,
  flightIsCancelledText,
  isPaidRequired,
  alertPaidRequiredText,
  children,
  img,
  segmentCount,
  openEditModal,
  openDeleteModal,
  tripAlias,
  modalName,
  goToDetails,
  ariaLabel,
  intl: { formatMessage },
  rest,
}) => (
  <div
    className="savecard"
    id={id}
    tabIndex="0"
    aria-pressed="true"
    aria-label={ariaLabel}
    onClick={goToDetails}
    onKeyDown={goToDetails}
    {...rest}
  >
    <div className="savecard__container" id={idContainer}>
      <Ellipsis
        idOptions={idEllipsisOptions}
        idBtnRename={idEllipsisBtnRename}
        idBtnRemove={idEllipsisBtnRemove}
        idBtnCalendar={idEllipsisBtnCalendar}
        color="white"
        id={idEllipsis}
        updateFn={() => {
          openEditModal(pnr, tripAlias, modalName)
        }}
        deleteFn={() => {
          openDeleteModal(pnr, modalName)
        }}
        saveCardStyles={saveCardStyles}
        wcagTitle={formatMessage({
          id: 'ellipsisMenuWCAG.title',
        })}
        wcagDescription={formatMessage({
          id: 'ellipsisMenuWCAG.descriptionSaveCard',
        })}
      />
      <div className="savecard__content-area" id={idContentArea}>
        {children}
      </div>
      <div className="savecard__segment">
        <SegmentCount id={idCountSegment} counter={segmentCount} />
      </div>
      <div
        className="savecard__image-area"
        id={idImageContent}
        style={{
          background: `
          url(${Swoosh}) -1px -1px / 79px 110% no-repeat,
          linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%) 0px 0px / cover,
          url(${img}) 0px 0px / cover no-repeat`,
          borderRadius: `${validateBorderBottom(
            reservationIsCancelled,
            flightIsCancelled,
            isPaidRequired
          )}`,
        }}
      />
    </div>
    {reservationIsCancelled && (
      <div className="savecard__alert--danger" id={idAlertContent}>
        <img
          className="savecard__alert-icon"
          src={iconAlert}
          alt=""
          id={idIconAlert}
        />
        <span id={idAlertText}>{reservationIsCancelledText}</span>
      </div>
    )}
    {flightIsCancelled && (
      <div className="savecard__alert--danger" id={idAlertContent}>
        <img
          className="savecard__alert-icon"
          src={iconAlert}
          alt=""
          id={idIconAlert}
        />
        <span id={idAlertText}>{flightIsCancelledText}</span>
      </div>
    )}
    {isPaidRequired && (
      <div className="savecard__alert" id={idAlertContent}>
        <img
          className="savecard__alert-icon"
          src={iconAlert}
          alt=""
          id={idIconAlert}
        />
        <span id={idAlertText}>{alertPaidRequiredText}</span>
      </div>
    )}
  </div>
)

export default compose(injectIntl)(saveCard)
