/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from "react";
// @styles
import "./upcomingCard.css";

// @commons
import SwooshVertical from "../../../assets/images/TripCard-swoosh.svg";
import SwooshHorizontal from "../../../assets/images/UpcomingTrip-swoosh-SmXs.svg";
import iconAlert from "../../../assets/images/alert-white-icon.svg";
import useBitBreakPoints from "../../../hooks/material/useBitBreakPoints";

const UpcomingCard = ({
  id,
  idContainer,
  idContentArea,
  idAlertContent,
  idAlertText,
  idImageContent,
  idIconAlert,
  reservationIsCancelled,
  reservationIsCancelledText,
  flightIsCancelled,
  flightIsCancelledText,
  isPaidRequired,
  alertPaidRequiredText,
  children,
  img,
  ariaLabel,
  rest,
}) => {
  const { isBPBiggerThanMD, isBPXS, isBPSM, isBPMD } = useBitBreakPoints()
  return (
    <div
      className="upcomingcard"
      tabIndex="0"
      aria-label={ariaLabel}
      id={id}
      {...rest}
    >
      <div className="upcomingcard__container" id={idContainer}>
        <div className="upcomingcard__content-area" id={idContentArea}>
          {children}
        </div>
        {(isBPBiggerThanMD || isBPMD) &&
          (<div
            className="upcomingcard__image-area"
            id={idImageContent}
            style={{
              background: `
                url(${SwooshVertical}) -1px -1px /  168px 110% no-repeat,
                linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%) 0px 0px / cover,
                url(${img}) 0px 0px / cover no-repeat`,
            }}
          />
        )}
        {isBPXS && (
          <div
            className="upcomingcard__image-area"
            id={idImageContent}
            style={{
              background: `
                url(${SwooshHorizontal}) -1px 101% / 101% 72px no-repeat,
                linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%) 0px 0px / cover,
                url(${img}) center 35% / cover no-repeat`,
            }}
          />
        )}
        {isBPSM &&
          (<div
            className="upcomingcard__image-area"
            id={idImageContent}
            style={{
              background: `
                url(${SwooshHorizontal}) -1px 101% / 101% 100px no-repeat, 
                linear-gradient(rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 100%) 0px 0px / cover, 
                url(${img}) center 35% / cover no-repeat`,
            }}
          />
        )}
      </div>
      {reservationIsCancelled && (
        <div className="upcomingcard__alert--danger" id={idAlertContent}>
          <img
            className="upcomingcard__alert-icon"
            src={iconAlert}
            alt=""
            id={idIconAlert}
          />
          <span id={idAlertText}>{reservationIsCancelledText}</span>
        </div>
      )}
      {flightIsCancelled && (
        <div className="upcomingcard__alert--danger" id={idAlertContent}>
          <img
            className="upcomingcard__alert-icon"
            src={iconAlert}
            alt=""
            id={idIconAlert}
          />
          <span id={idAlertText}>{flightIsCancelledText}</span>
        </div>
      )}
      {isPaidRequired && (
        <div className="upcomingcard__alert" id={idAlertContent}>
          <img
            className="upcomingcard__alert-icon"
            src={iconAlert}
            alt=""
            id={idIconAlert}
          />
          <span id={idAlertText}>{alertPaidRequiredText}</span>
        </div>
      )}
    </div>
  )
}

export default UpcomingCard
