/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { compose, setPropTypes } from 'react-recompose'
import { FormattedMessage, injectIntl } from 'react-intl'

// @commons
import InputCO from '../../commons/input/textInput/inputCO'
import ButtonCO from '../../commons/button/buttonCO'
import InfoLabelCO from '../../commons/infoLabel/infoLabelCO'

// @validations
import validate from './loginFormValidations'

// @utils
import { linkTo, LinkToComplements } from '../../../utils/utils'
import getText from '../../../utils/errorService.jsx'
import ErrorFocus from '../../../utils/errorFocus.jsx'

// @styles
import './loginForm.css'

// @logos
import connectMilesLogo from '../../../assets/images/connect-miles-logo.png'
import connectMilesLogo2x from '../../../assets/images/connect-miles-logo@2x.png'
import connectMilesLogo3x from '../../../assets/images/connect-miles-logo@3x.png'

function ForgotElement({ formatMessage }) {
  return (
    <a
      className="login-form__forgot-element"
      id="forgotPassword"
      href={linkTo(LinkToComplements.FORGOT)}
      target="_blank"
      rel="noreferrer noopener"
      title={formatMessage({ id: 'loginFormWCAG.forgotPrivatePhrase' })}
    >
      {formatMessage({
        id: 'loginForm.forgotPrivatePhrase',
      })}
    </a>
  )
}

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.userField = null
  }

  render() {
    const {
      intl: { formatMessage },
      id,
      handleSubmit,
      isLoading,
      loginError,
      touched,
      errors,
      values,
      handleBlur,
      handleChange,
      focusFirstField,
    } = this.props

    const errorPresent = Object.keys(loginError).length > 0
    const textError =
      errorPresent &&
      getText(loginError.message, Number(loginError.code), null, formatMessage)
        .text
    return (
      <article className="login-form" id={id || 'login'}>
        <header className="login-form__header">
          <img
            id="conectMilesLogoLogin"
            className="login-form__connect-miles-logo"
            src={connectMilesLogo}
            srcSet={(connectMilesLogo2x, connectMilesLogo3x)}
            alt={formatMessage({
              id: 'findReservationWCAG.cmLogoAlt',
            })}
          />
          {errorPresent && (
            <InfoLabelCO
              icon="info"
              type="alert"
              extraClass="login-form__alert"
              id="messageErrorLogin"
            >
              {textError}
            </InfoLabelCO>
          )}
        </header>
        <form
          className="login-form__form"
          onSubmit={handleSubmit}
          id={`${id}-form` || 'login-form'}
        >
          <InputCO
            type="text"
            size="fullwidth"
            theme="light"
            extraClass="login-form__input"
            id="user"
            name="user"
            value={values.user}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.user}
            touched={touched.user}
            valid={touched.user && !errors.user}
            setFieldToBeFocused={focusFirstField}
            label={formatMessage({
              id: 'loginForm.user',
            })}
            placeholder={formatMessage({
              id: 'loginForm.inputPlaceHolderUser',
            })}
            wcagObject={{
              id: 'cm-login-username-wcag-description',
              message: formatMessage({
                id: 'loginFormWCAG.userNameField',
              }),
            }}
          />
          <InputCO
            type="password"
            size="fullwidth"
            theme="light"
            extraClass="login-form__input"
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password}
            touched={touched.password}
            valid={touched.user && !errors.password}
            label={formatMessage({
              id: 'loginForm.passwordField',
            })}
            link={<ForgotElement formatMessage={formatMessage} />}
            placeholder={formatMessage({
              id: 'loginForm.inputPlaceHolderPrivatePhrase',
            })}
            wcagObject={{
              id: 'cm-login-password-wcag-description',
              message: formatMessage({
                id: 'loginFormWCAG.passwordField',
              }),
            }}
          />
          <ButtonCO
            idName="loginButton"
            extraClass="login-form__button"
            size="fullwidth"
            type="submit"
            relevance="primary"
            loading={isLoading}
            spinnerType="secondary"
            wcagObject={{
              id: 'cm-custom-login-button-wcag-description',
              message: formatMessage({
                id: 'loginFormWCAG.loginButton',
              }),
            }}
          >
            <FormattedMessage
              id="loginForm.loginButton"
              defaultMessage="Login"
            />
          </ButtonCO>
          <ErrorFocus />
          <div className="login-form__join">
            <p className="login-form__join-now-text" id="joinNowTextLogin">
              <FormattedMessage id="loginForm.account" />
            </p>
            <a
              className="login-form__join-now-link"
              id="joinNowLoginLink"
              href={linkTo(LinkToComplements.ENROLLMENT)}
              target="_blank"
              rel="noreferrer noopener"
              title={formatMessage({ id: 'loginFormWCAG.joinNow' })}
            >
              {formatMessage({
                id: 'loginForm.accountLink',
              })}
            </a>
          </div>
        </form>
      </article>
    )
  }
}

const _propTypes = {
  isLoading: PropTypes.bool.isRequired,
  logInAction: PropTypes.func.isRequired,
  loginError: PropTypes.object,
  focusFirstField: PropTypes.func,
}

const setPropTypesEnhance = setPropTypes(_propTypes)

const formikSetUp = withFormik({
  mapPropsToValues({ user, password }) {
    return {
      user: user || '',
      password: password || '',
    }
  },
  validate,
  handleSubmit(values, { props: { logInAction } }) {
    logInAction({
      user: values.user,
      password: values.password,
    })
  },
})

export default compose(injectIntl, formikSetUp, setPropTypesEnhance)(LoginForm)
