export type Variants = 'main' | 'secondary' | 'filled'

export type Themes = 'invert' | 'normal'

export enum ScrollPosition {
  NO_SCROLL = 'no-scroll',
  SCROLL_IN_MIDDLE = 'scroll-in-middle',
  SCROLL_TOP_LEFT = 'scroll-top-left',
  SCROLL_TOP_RIGHT = 'scroll-top-right',
}
