import React from 'react'

export default function LoginIcon({...props}) {
  return (
  <svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16Z" fill="#EFF5FF"/>
    <path d="M7.1665 16.0378C7.1665 21.1925 11.3452 25.3711 16.4998 25.3711C18.2924 25.3711 19.9669 24.8658 21.3887 23.9898C24.0555 22.3468 25.8332 19.3999 25.8332 16.0378C25.8332 10.8831 21.6545 6.70447 16.4998 6.70447C11.3452 6.70447 7.1665 10.8831 7.1665 16.0378Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4999 7.1489C11.5907 7.1489 7.61106 11.1286 7.61106 16.0378C7.61106 20.947 11.5907 24.9267 16.4999 24.9267C18.2078 24.9267 19.802 24.4454 21.1557 23.6114C23.6966 22.0459 25.3888 19.2393 25.3888 16.0378C25.3888 11.1286 21.4091 7.1489 16.4999 7.1489ZM6.72217 16.0378C6.72217 10.6377 11.0998 6.26001 16.4999 6.26001C21.9001 6.26001 26.2777 10.6377 26.2777 16.0378C26.2777 19.5605 24.4145 22.6477 21.622 24.3682C20.1321 25.2861 18.3772 25.8156 16.4999 25.8156C11.0998 25.8156 6.72217 21.4379 6.72217 16.0378Z" fill="#0032A0"/>
    <path d="M19.2528 18.3711L20.0557 19.4822L19.2528 20.1489L18.3352 20.4822L17.1883 20.9266L15.4679 20.8155L14.0915 20.1489L12.9446 19.4822L13.8621 18.26L14.8944 17.6489L16.5001 17.3711L17.8765 17.6489L19.2528 18.3711Z" fill="#00A3E0"/>
    <path d="M16.4999 17.3711C15.2726 17.3711 14.2777 16.3762 14.2777 15.1489C14.2777 13.9216 15.2726 12.9266 16.4999 12.9266C17.7272 12.9266 18.7222 13.9216 18.7222 15.1489C18.7222 16.3762 17.7272 17.3711 16.4999 17.3711Z" fill="#00A3E0"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4998 13.3711C17.4817 13.3711 18.2776 14.167 18.2776 15.1488C18.2776 16.1307 17.4817 16.9266 16.4998 16.9266C15.518 16.9266 14.7221 16.1307 14.7221 15.1488C14.7221 14.167 15.518 13.3711 16.4998 13.3711ZM19.1665 15.1488C19.1665 13.6761 17.9726 12.4822 16.4998 12.4822C15.0271 12.4822 13.8332 13.6761 13.8332 15.1488C13.8332 16.6216 15.0271 17.8155 16.4998 17.8155C17.9726 17.8155 19.1665 16.6216 19.1665 15.1488Z" fill="#0032A0"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.4999 17.8155C17.1706 17.8155 17.8239 17.9671 18.3867 18.2484C18.9494 18.5298 19.3928 18.9263 19.6784 19.3841C19.8083 19.5924 20.0825 19.6559 20.2907 19.5259C20.499 19.396 20.5625 19.1219 20.4326 18.9136C20.0507 18.3015 19.4765 17.7995 18.7842 17.4534C18.0919 17.1073 17.3016 16.9266 16.4999 16.9266C15.6983 16.9266 14.908 17.1072 14.2157 17.4534C13.5234 17.7995 12.9492 18.3015 12.5673 18.9136C12.4374 19.1219 12.5009 19.396 12.7091 19.5259C12.9174 19.6559 13.1915 19.5924 13.3215 19.3841C13.6071 18.9263 14.0504 18.5298 14.6132 18.2484C15.176 17.9671 15.8293 17.8155 16.4999 17.8155Z" fill="#0032A0"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3851 11.6823C15.5391 11.3073 13.7269 12.1352 12.7633 13.6296C12.6303 13.8359 12.3552 13.8953 12.1489 13.7622C11.9427 13.6292 11.8833 13.3542 12.0163 13.1479C13.1716 11.3562 15.3453 10.3609 17.562 10.8112C20.4486 11.3976 22.3133 14.2129 21.7269 17.0995C21.6781 17.3401 21.4434 17.4955 21.2029 17.4466C20.9623 17.3977 20.807 17.1631 20.8558 16.9226C21.3445 14.5171 19.7906 12.171 17.3851 11.6823ZM11.7978 14.629C12.0383 14.6779 12.1937 14.9125 12.1448 15.1531C11.6562 17.5585 13.2101 19.9047 15.6156 20.3933C17.4616 20.7683 19.2737 19.9404 20.2373 18.446C20.3704 18.2397 20.6454 18.1803 20.8517 18.3134C21.058 18.4464 21.1174 18.7215 20.9844 18.9277C19.8291 20.7194 17.6554 21.7147 15.4386 21.2644C12.5521 20.678 10.6874 17.8627 11.2737 14.9761C11.3226 14.7356 11.5572 14.5802 11.7978 14.629Z" fill="#0032A0"/>
  </svg>

)
}
