import React from 'react'

export default function ScrollIndicatorIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.5704C9.25 20.5704 7 18.2329 7 15.376V8.76488C7 5.90793 9.25 3.57043 12 3.57043C14.75 3.57043 17 5.90793 17 8.76488V15.376C17 18.2329 14.75 20.5704 12 20.5704ZM11.9991 4.51477C9.74911 4.51477 7.9082 6.42727 7.9082 8.76477V15.3759C7.9082 17.7134 9.74911 19.6259 11.9991 19.6259C14.2491 19.6259 16.09 17.7134 16.09 15.3759V8.76477C16.09 6.42727 14.2491 4.51477 11.9991 4.51477ZM11.5449 9.00088C11.5449 9.2606 11.7495 9.4731 11.9995 9.4731C12.2495 9.4731 12.454 9.2606 12.454 9.00088V7.11199C12.454 6.85227 12.2495 6.63977 11.9995 6.63977C11.7495 6.63977 11.5449 6.85227 11.5449 7.11199V9.00088Z"
        fill="#999999"
      />
    </svg>
  )
}
