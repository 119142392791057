import React, { useCallback, useEffect, useState } from 'react'
import { DataUpgradeBoxModel } from '@copa/design-system-factory.boxes.upgrade-box/types'
import { useHistory, useLocation, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { UpgradeCacheKey, UpgradeLocation, UpgradeOrigin } from '../types'
import UpgradeOnboarding from '../onboarding/UpgradeOnboarding'
import { SelectFlightUPGH } from './select-flight/SelectFlightUPGH'
import UpgradeHubConfirmation from './confirmation/UpgradeHubConfirmation'
import Spinner from '../../../components/commons/spinner/spinner'
import useUpgradeHubFlow from '../hooks/useUpgradeHubFlow'
import { useGetVouchersMutation } from '../../../api/upgradeHubSlice'
import { isValidMemberForVouchers, redirectToErrorPage } from '../utils'
import { upgradeChoosentUpgraHub } from '../../../actions/analytics'
import { QueryParams } from '../../../types/QueryParams'
import { MyTripsState } from '../../../types/state'
import { UpgradePageProps } from '../saua/Saua'

export default function UpgradeHub({
  plusgradeInfo,
  upgradeInfo: { tripInfo, profileExtendInfo },
}: UpgradePageProps) {
  const history = useHistory()
  const locationRoute = useLocation()
  const queryParams = new URLSearchParams(locationRoute.search)
  const origin = queryParams.get('origin') as UpgradeOrigin
  const { pnr, surname, language } = useParams<QueryParams>()
  const dispatch = useDispatch()

  const { masterSessionId: sessionToken } = useSelector(
    (state: MyTripsState) => state.auth
  )

  const [upgradeBoxResponse, setUpgradeBoxResponse] =
    useState<DataUpgradeBoxModel>()

  const { location, showContent } = useUpgradeHubFlow({
    origin,
    upgradeInfo: { tripInfo, profileExtendInfo },
  })

  const [upgradeLocation, setUpgradeLocation] = useState<UpgradeLocation>(
    location as UpgradeLocation
  )

  const [
    getVouchers,
    { data: vouchers, isLoading: isVouchersLoading, isError: isVouchersError },
  ] = useGetVouchersMutation({
    fixedCacheKey: UpgradeCacheKey.VOUCHERS,
  })

  const goToSelectFlightsPage = useCallback(() => {
    dispatch(upgradeChoosentUpgraHub({ option: 'Miles-Certificates' }))
    if (vouchers || vouchers === null) {
      setUpgradeLocation(UpgradeLocation.UPGH_SELECT_FLIGHT)
    } else if (!isValidMemberForVouchers(profileExtendInfo?.loyalty)) {
      setUpgradeLocation(UpgradeLocation.UPGH_SELECT_FLIGHT)
    }
  }, [dispatch, vouchers, profileExtendInfo])

  useEffect(() => {
    if (!vouchers && isValidMemberForVouchers(profileExtendInfo?.loyalty)) {
      const fetchVouchersData = async () =>
        getVouchers({
          costumerID: profileExtendInfo?.loyalty?.customerID!,
          token: sessionToken,
        })

      fetchVouchersData()
    }
  }, [getVouchers, profileExtendInfo?.loyalty, sessionToken, vouchers])

  useEffect(() => {
    if (isVouchersError) {
      redirectToErrorPage({ history, pnr, surname, language, origin })
    }
  }, [isVouchersError, pnr, surname, language, origin, history])

  useEffect(() => {
    setUpgradeLocation(location as UpgradeLocation)
  }, [location])

  const upgradeByLocation: { [p: string]: React.JSX.Element } = {
    [UpgradeLocation.BLANK]: <Spinner />,
    [UpgradeLocation.UPGH_ONBOARDING]: (
      <UpgradeOnboarding
        upgradeInfo={{ tripInfo, profileExtendInfo }}
        origin={origin}
        onNextPageClick={goToSelectFlightsPage}
        plusgradeInfo={plusgradeInfo}
      />
    ),
    [UpgradeLocation.UPGH_SELECT_FLIGHT]: (
      <SelectFlightUPGH
        upgradeInfo={{ tripInfo, profileExtendInfo }}
        setLocation={setUpgradeLocation}
        origin={origin}
        setUpgradeBoxResponse={setUpgradeBoxResponse}
      />
    ),
    [UpgradeLocation.UPGH_CONFIRMATION]: (
      <UpgradeHubConfirmation
        upgradeInfo={{ tripInfo, profileExtendInfo }}
        origin={origin}
        upgradeBoxResponse={upgradeBoxResponse}
      />
    ),
  }
  return showContent && !isVouchersLoading ? (
    upgradeByLocation[upgradeLocation]
  ) : (
    <Spinner />
  )
}
