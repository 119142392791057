import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { useStyles } from './styles'
import confirmItinerary from '../../../../../../assets/images/srt/confirmItinerary.svg'

function ConfirmDefaultItinerary({
  shouldRedirectSeatMap,
}: {
  shouldRedirectSeatMap: boolean | undefined
}) {
  const { formatMessage } = useIntl()
  const classes = useStyles()

  return (
    <>
      <Box className={classes.header}>
        {/* @ts-ignore */}
        <img className={classes.icon} alt="" src={confirmItinerary} />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h2" color="primary.main">
          {formatMessage({
            id: 'srt.sc.default.onboardingPage.confirmDefaultItineraryModal.title',
          })}
        </Typography>
      </Box>
      <Box className={classes.description}>
        <Typography variant="body1" color="grey.600">
          {formatMessage({
            id: 'srt.sc.default.onboardingPage.confirmDefaultItineraryModal.subtitle',
          })}
        </Typography>
      </Box>
      <Box className={classes.description}>
        <Typography variant="body1" color="grey.600">
          {formatMessage({
            id: shouldRedirectSeatMap
              ? 'srt.sc.default.onboardingPage.confirmDefaultItineraryModal.seatmapDescription'
              : 'srt.sc.default.onboardingPage.confirmDefaultItineraryModal.oldDescription',
          })}
        </Typography>
      </Box>
    </>
  )
}

export default ConfirmDefaultItinerary
