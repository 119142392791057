// @vendors
import React from 'react'
import PropTypes from 'prop-types'
import { compose, defaultProps, mapProps, setPropTypes } from 'react-recompose'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { isMobileDevice } from '../../../utils/getBoardingPass'
// @commons
import CloseButtonCO from '../button/closeButton/closeButtonCO'
// @styles
import './modalCO.css'

const modalBasicCO = ({
  id,
  handleClose,
  showHeader,
  children,
  classNameOverlay,
  intl: { formatMessage },
  wcagObject,
}) => {
  let divInput
  return (
    <div
      id={id}
      onClick={event => handleClose(event, divInput)}
      role="presentation"
    >
      {showHeader && <div className="modal-overlay__head" />}
      <div
        className={classNameOverlay}
        role="presentation"
        aria-label={
          wcagObject &&
          formatMessage({
            id: wcagObject.id,
          })
        }
      >
        {isMobileDevice && (
          <CloseButtonCO
            extraClass="modal-overlay__close-button"
            onClick={handleClose}
            wcagObject={
              wcagObject && {
                id: 'modal-close-button-wcag-description',
                message: formatMessage({
                  id: 'customModalWCAG.closeButton',
                }),
              }
            }
          />
        )}
        <section
          className="modal-overlay__modal"
          ref={el => {
            divInput = el
          }}
        >
          {children}
        </section>
      </div>
    </div>
  )
}

const _propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  wcagObject: PropTypes.object,
}

const _defaultProps = {
  showModal: false,
}

const withPropsEnhacer = mapProps(({ showModal, ...rest }) => ({
  classNameOverlay: classNames('modal-overlay', {
    [`modal-overlay--open`]: showModal,
    [`modal-overlay--close`]: !showModal,
  }),
  ...rest,
}))

const mainEnhancer = compose(
  injectIntl,
  setPropTypes(_propTypes),
  withPropsEnhacer,
  defaultProps(_defaultProps)
)(modalBasicCO)

export { mainEnhancer as default, modalBasicCO }
