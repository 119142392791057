import React from 'react'
export default function CheckIcon({ color } : { color : string }) {
    return <svg data-testid='check-icon' className="check-icon" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.77718 11.293C5.38665 11.6835 4.75349 11.6835 4.36296 11.293L0.770069 7.7001C0.38347 7.3135 0.383469 6.6867 0.770068 6.3001C1.15667 5.9135 1.78347 5.9135 2.17007 6.3001L5.07007 9.2001L13.9701 0.300098C14.3567 -0.086501 14.9835 -0.0865017 15.3701 0.300098C15.7567 0.686697 15.7567 1.3135 15.3701 1.7001L5.77718 11.293Z" fill={color}/>
    </svg>    
}

export const ClearIcon = ()=>{
   return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM5.2929 6.70712C5.68343 7.09764 6.31659 7.09764 6.70712 6.70712C7.09764 6.31659 7.09764 5.68343 6.70712 5.2929L5.2929 6.70712ZM6.70711 5.29291C6.31658 4.90238 5.68342 4.90238 5.29289 5.29291C4.90237 5.68343 4.90237 6.3166 5.29289 6.70712L6.70711 5.29291ZM10.2929 11.7071C10.6834 12.0977 11.3166 12.0977 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM6.70712 6.70712C7.09764 6.3166 7.09764 5.68343 6.70712 5.29291C6.31659 4.90238 5.68343 4.90238 5.2929 5.29291L6.70712 6.70712ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0977 1.31658 12.0977 1.70711 11.7071L0.292893 10.2929ZM5.29289 5.2929C4.90237 5.68343 4.90237 6.31659 5.29289 6.70712C5.68342 7.09764 6.31658 7.09764 6.70711 6.70712L5.29289 5.2929ZM11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 1.70711L5.2929 6.70712L6.70712 5.2929L1.70711 0.292893L0.292893 1.70711ZM5.29289 6.70712L10.2929 11.7071L11.7071 10.2929L6.70711 5.29291L5.29289 6.70712ZM5.2929 5.29291L0.292893 10.2929L1.70711 11.7071L6.70712 6.70712L5.2929 5.29291ZM6.70711 6.70712L11.7071 1.70711L10.2929 0.292893L5.29289 5.2929L6.70711 6.70712Z" fill="#AAAAAB"/>
    </svg>
}