import Box from '@mui/material/Box'
import MuiDivider from '@mui/material/Divider'

import { theme } from '@copa/design-system-factory.theme'

import { Props } from './types'
import { dividerStyles } from './dividerStyles'

export const Divider: React.FC<Props> = ({
  base = 'lightSolid',
  sx,
  ...props
}: Props): JSX.Element => {
  const classes = dividerStyles(theme)

  return (
    <Box sx={classes.root}>
      <MuiDivider
        sx={[classes[base], ...(Array.isArray(sx) ? sx : [sx])]}
        variant="fullWidth"
        {...props}
      />
    </Box>
  )
}
