import { getSpacing } from '../../../../../../utils/spacing'
import { useStylesProps } from '../../../../upgrade-hub/types'

const useStyles = ({ breakpoints }: useStylesProps) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    // @ts-ignore
    columnGap: getSpacing(breakpoints)?.tiny,
  },
  firstInput: {
    width: '33%',
  },
  input: {
    width: '33%',
    marginTop: '28px',
  },
})

export default useStyles
