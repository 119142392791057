// @vendors
import React from 'react'
import PropTypes from 'prop-types'
import { compose, defaultProps, setPropTypes } from 'react-recompose'

// @icons
import infoIconPrimary from '../../../assets/images/Outline.svg'
import infoIcon from '../../../assets/images/Info.svg'
import closeIconGrey from '../../../assets/images/grey-x-cancel.svg'
import closeIconWhite from '../../../assets/images/close-icon.svg'

import './alertInfo.css'

const AlertInfo = ({ id, onClickClose, children, className }) => (
  <div className={className} id={id}>
    <div className={`${className}__text`} id={`${id}-content`}>
      <img
        alt="Alert Toast"
        className={`${className}__icon`}
        src={className === 'primary-alert' ? infoIconPrimary : infoIcon}
        id={`${id}-icon`}
      />
      {children}
    </div>
    <div className={`${className}__wrapper`} id={`${id}-wrap`}>
      <div
        className={`${className}__close-icon`}
        role="button"
        onClick={onClickClose}
        tabIndex={0}
      >
        <img
          src={className === 'primary-alert' ? closeIconGrey : closeIconWhite}
          alt=""
          className={`${className}__close-icon__image"`}
        />
      </div>
    </div>
  </div>
)

const _propTypes = {
  onClickClose: PropTypes.func,
  className: PropTypes.oneOf([
    'primary-alert',
    'secundary-alert',
    'third-alert',
  ]),
}
const _defaultProps = {
  className: 'primary-alert',
}
export default compose(
  setPropTypes(_propTypes),
  defaultProps(_defaultProps)
)(AlertInfo)
