import React from 'react'

export const ArrowLeft = ({ props }) => (
  <svg
    {...props}
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.2 9.3L2.4 5.5L11.5 5.5V4.5L2.4 4.5L6.2 0.7L5.5 0L0.5 5L5.5 10L6.2 9.3Z"
      fill="white"
    />
  </svg>
)
