import { Box } from '@mui/material'
import { SelectRegular } from '@copa/design-system-factory.select-regular'
import { Option } from '@copa/design-system-factory.option'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment/moment'
import useBitBreakPoints from '../../../../../../hooks/material/useBitBreakPoints'
import useStyles from './styles'

function generateYears() {
  const years = []
  const currentYear = moment().year()
  for (let i = currentYear; i <= currentYear + 20; i++) {
    years.push(i)
  }
  return years
}

function generateMonths(year: string) {
  const months = []
  const currentYear = moment().year().toString()
  const startMonth = year === currentYear ? moment().month().toString() + 1 : 1
  // @ts-ignore
  for (let i = startMonth; i <= 12; i++) {
    months.push(i)
  }
  return months
}

function generateDays(inputYear: string, inputMonth: string) {
  const days = []
  let year = inputYear
  let month = inputMonth

  if (!year || !month) {
    year = moment().year().toString()
    month = (moment().month() + 1).toString()
  }

  const isCurrentYear = year === moment().year().toString()
  const isCurrentMonth = month === moment().month().toString() + 1
  const startDay = isCurrentYear && isCurrentMonth ? moment().date() : 1
  const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

  for (let i = startDay; i <= daysInMonth; i++) {
    days.push(i)
  }
  return days
}

interface InputsDatePickerProps {
  index: number
  setDate: (date: string) => void
  helperText: string | undefined
}

// eslint-disable-next-line import/prefer-default-export
export function InputsDatePicker({
  index,
  setDate,
  helperText,
}: InputsDatePickerProps) {
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles({ breakpoints })

  const [year, setYear] = useState<string>()
  const [month, setMonth] = useState<string>()
  const [day, setDay] = useState<string>()

  useEffect(() => {
    if (day && month && year) {
      setDate(`${month}/${day}/${year}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, month, year])

  return (
    <Box sx={classes.container}>
      <Box sx={classes.firstInput}>
        <SelectRegular
          id={`flights[${index}].date.month`}
          label={formatMessage({
            id: 'upgrade.saua.flightsForm.flightDate',
          })}
          placeholder={formatMessage({
            id: 'upgrade.saua.flightsForm.flightDatePlaceholder.month',
          })}
          defaultValue=""
          onChange={event => setMonth(event.target.value as string)}
          required
          // @ts-ignore
          hasErrors={helperText}
          helperText={helperText}
        >
          {/* @ts-ignore */}
          {generateMonths(year).map(m => (
            <Option key={m} value={m}>
              {m}
            </Option>
          ))}
        </SelectRegular>
      </Box>

      <Box sx={classes.input}>
        <SelectRegular
          id={`flights[${index}].date.day`}
          label=""
          placeholder={formatMessage({
            id: 'upgrade.saua.flightsForm.flightDatePlaceholder.day',
          })}
          defaultValue=""
          onChange={event => setDay(event.target.value as string)}
          // @ts-ignore
          hasErrors={helperText}
          required
        >
          {/* @ts-ignore */}
          {generateDays(year, month).map(d => (
            <Option key={d} value={d}>
              {d}
            </Option>
          ))}
        </SelectRegular>
      </Box>

      <Box sx={classes.input}>
        <SelectRegular
          id={`flights[${index}].date.year`}
          label=""
          placeholder={formatMessage({
            id: 'upgrade.saua.flightsForm.flightDatePlaceholder.year',
          })}
          defaultValue=""
          // @ts-ignore
          hasErrors={helperText}
          onChange={event => setYear(event.target.value as string)}
          required
        >
          {generateYears().map(y => (
            <Option key={y} value={y}>
              {y}
            </Option>
          ))}
        </SelectRegular>
      </Box>
    </Box>
  )
}
