import { Dialog, DialogProps } from '@mui/material'
import Lottie from 'lottie-react'
import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Typography } from '@copa/design-system-factory.typography'
import theme from '@copa/design-system-factory.theme'

import { InformativeBoxType, Translation } from './types'
import { useStylesInterstitial } from './styles'
import { InformativeBoxCo } from './informativeBox/informativeBox'
import { airplineAnimationLightColor } from './animations/animation-light'
import { airplaneAnimationInvertedColor } from './animations/animation-inverted'

export type InterstitialComponentProps = {
  /** @param invertedColors indicates if the styles should apply the inverted colors variant of the figma design. */
  invertedColors?:boolean,
  /** @param translations handles the translation text */
  translations?:Translation,
  /** @param informativeBox handles all the parameters to render the informative box. */
  informativeBox?:InformativeBoxType,
} & Omit<DialogProps, 'open' | 'fullScreen'>

export function Interstitial({
  invertedColors = false,
  translations = {},
  informativeBox = {},
  ...props
}:InterstitialComponentProps) {

  const styles = useStylesInterstitial(invertedColors, isEmpty(informativeBox))
  const isMobileBreakpoint: boolean = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      {...props}
      open
      fullScreen 
      sx={styles.dialog}
    >
      <Box sx={styles.internalContent}>
          <Lottie animationData={invertedColors ? airplaneAnimationInvertedColor : airplineAnimationLightColor} loop />
          {translations?.loadingText && (
            <Typography
              variant={isMobileBreakpoint ? 'body3' : 'caption'}
              color={invertedColors ? "common.white" : "grey.600"}
            >
              {translations.loadingText}
            </Typography>
          )}
          {!isEmpty(informativeBox) && (
            <InformativeBoxCo invertedColors={invertedColors} informativeBox={informativeBox} />
          )}
      </Box>
    </Dialog>
  )
}
