import { forwardRef } from 'react'
import MuiTypography from '@mui/material/Typography'

import { Props } from './types'

export const Typography = forwardRef<HTMLSpanElement, Props>(
  ({ children, ...otherProps }, ref) => (
    <MuiTypography ref={ref} {...otherProps}>
      {children}
    </MuiTypography>
  )
)
