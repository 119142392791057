import { useStylesProps } from '../../../upgrade-hub/types'
import { getSpacing } from '../../../../../utils/spacing'

const useStyles = ({ breakpoints }: useStylesProps) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(breakpoints)?.tiny,
  },
  flightTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: getSpacing(breakpoints)?.tiny,
  },
  flightDetail: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    rowGap: getSpacing(breakpoints)?.tiny,
  },
  item: {
    width: {
      xs: '100%',
      md: '34%',
    },
  },
})

export default useStyles
