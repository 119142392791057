import { Breakpoints, getSpacing } from '../../../../../../../../utils/spacing'

export const useStyles = (breakpoints: Breakpoints) => ({
  container: {
    display: 'flex',
    flexDirection: ' column',
    gap: getSpacing(breakpoints)?.minimal,
    marginBottom: getSpacing(breakpoints)?.normal,
  },
})
