// @vendors
import React from 'react'
import PropTypes from 'prop-types'
import { compose, defaultProps, mapProps, setPropTypes } from 'react-recompose'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

// @styles
import './footerItinerary.css'

import logo from '../../../assets/images/logo-star-alliance.svg'

const footerItinerary = ({
  classNameContainer,
  idName,
  idText,
  idLogo,
  intl: { formatMessage },
}) => (
  <footer className={classNameContainer} id={idName}>
    <section className="itinerary__copy-right">
      <p className="itinerary__copy-right-text" id={idText}>
        {formatMessage({
          id: 'dashboard.copyRight',
        })}
      </p>
    </section>
    <section className="itinerary__star-aliance-section">
      <img
        id={idLogo}
        alt={formatMessage({
          id: 'dashboard.starAllianceMemberAltText',
        })}
        className="itinerary__star-aliance-logo"
        src={logo}
      />
    </section>
  </footer>
)

const withPropsEnhacer = mapProps(({ showHorizontalBar, ...rest }) => ({
  classNameContainer: classNames('itinerary__footer', {
    [`itinerary__footer--sticky-bar`]: showHorizontalBar,
  }),
  ...rest,
}))

const _propTypes = {
  showHorizontalBar: PropTypes.bool.isRequired,
}
const _defaultProps = {
  showHorizontalBar: false,
}

export default compose(
  injectIntl,
  setPropTypes(_propTypes),
  defaultProps(_defaultProps),
  withPropsEnhacer
)(footerItinerary)
