import { Breakpoints, getSpacing } from '../../../../../../../../utils/spacing'

const useStyles = (breakpoints: Breakpoints) => ({
  title: {
    marginBottom: getSpacing(breakpoints)?.normal,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: getSpacing(breakpoints)?.tiny,
    justifyContent: 'end',
    marginTop: getSpacing(breakpoints)?.roomy,
  },
  emptyContainer: {
    marginTop: getSpacing(breakpoints)?.roomy,
  },
  button: {
    width: {
      xs: '100%',
      md: '33%',
    },
  },
  itinerariesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(breakpoints)?.normal,
  },
})

export default useStyles
