import { SeatTypes } from '../../utils/seatSelection/seatSelection'

export default {
  // ////////////////////////////// start translations My trips //////////////////////////////////
  loading: 'La página está cargando',

  findReservation: {
    title: 'Mis Viajes',
    description:
      'En Mis Viajes puedes ver, manejar y guardar tus reservas de Copa Airlines.',
    welcome: '¡Hola! Encontremos tu reserva.',
    innerDescription:
      'En Mis Viajes puedes ver, manejar y guardar tus reservas de Copa Airlines.',
    informationAlert:
      'Estás utilizando Mis Viajes Beta. ¿Tienes comentarios o sugerencias? ',
    linkInformationAlert: 'Deja un comentario.',
    errorInvalidToken:
      'Tu sesión de ConnectMiles ha expirado. Inicia sesión nuevamente.',
  },
  connectMiles: {
    description:
      'También puedes iniciar sesión en tu cuenta ConnectMiles para manejar tus reservas guardadas.',
    loginButton: 'Iniciar Sesión',
  },

  findTrip: {
    title: 'Buscar una reservación',
    buttonFind: 'Busca tu reservación',
    checkLabel: 'Guardar en Mis Viajes',
    alreadyAdded: 'Tu reservación ya ha sido agregada a Mis Viajes.',
    succesfully: 'Viaje guardado con éxito',
    notFound:
      '¡Lo sentimos! No pudimos encontrar tu reserva con la información proporcionada. Por favor, revisa tus datos y vuelve a intentarlo.',
  },

  emptyState: {
    title: 'Aún no tienes viajes guardados.',
    description:
      'Viajes asociados a tu número de ConnectMiles aparecerán aquí automáticamente. También puedes buscar una reserva para añadirla aquí.',
    buttonAdd: 'Añadir Viaje',
  },

  holderCard: {
    title: 'Añadir otro viaje',
  },
  holderCardWCAG: {
    description: 'Añadir otro viaje.',
  },
  pastTrips: {
    title: 'Viajes Anteriores',
    destination: 'Destino',
    flightDates: 'Fechas de vuelo',
    reservation: 'Reservación',
  },

  pastTripsWCAG: {
    wcagTitle: 'Panel de Viajes Anteriores',
    wcagDescription:
      'Viajes anteriores: Esta sección muestra viajes que ya han sido completados. Sus viajes anteriores desaparecen automáticamente luego de 2 meses.',
    wcagAvatarImage:
      'Avatar de Destino: Este es el Avatar de {destinationCity}',
    wcagDestinationColumn:
      'Columna de Destino: Esta columna muestra los destinos de sus viajes anteriores.',
    wcagFlightColumn:
      'Columna de Fechas de Vuelo: Esta columna muestra las fechas de Vuelo de sus viajes anteriores.',
    wcagReservationColumn:
      'Columna de Reservación: Esta columna muestra los códigos de reservación de sus viajes anteriores.',
    wcagPastTripsDetail:
      'Los detalles de su viaje son los siguientes: {pasengerInformation} + {aditionalPassengers} {od} - {destinationCity} Fechas de vuelo {startDate} - {endDate} Código de reservación: {reservationCode}',
    wcagTripSelection:
      'Estás en el panel de tus viajes anteriores. Aquí puedes navegar entre viajes y escuchar los detalles de su viaje',
    od: 'hacia',
  },

  removeModal: {
    title: '¿Deseas eliminar este viaje?',
    description:
      'No te preocupes, tu reservación se mantendrá activa - solo desaparecerá de tu página de Mis Viajes.',
    pastTripDescription:
      'Si eliminas tu viaje ahora, no podrás volver a ver los detalles asociados a él. ¿Estás seguro que deseas continuar?',
    cancelBtn: 'Cancelar',
    deleteBtn: 'Eliminar',
    errorToastMessage:
      '¡Lo sentimos! No pudimos eliminar su viaje. Por favor, intente de nuevo más tarde.',
    successToastMessage: 'Tu viaje fue eliminado exitosamente.',
  },

  editModal: {
    renameTrip: 'Renombrar este viaje',
    tripName: 'Nombre del viaje',
    renameButton: 'Renombrar',
    editModalError: 'No se permiten caracteres especiales.',
  },

  fareRulesModal: {
    title: 'Reglas tarifarias',
    description:
      'Ciertos aspectos de tu reserva como maletas, asientos, cambios y reembolsos pueden tener restricciones dependiendo del tipo de tarifa que hayas comprado. Aquí podrás leer las reglas tarifarias que aplican para cada uno de los vuelos en esta reserva.',
    link: 'Conoce más acerca de nuestras familias tarifarias.',
    href: '{copa_url}/es-gs/informacion-al-viajar/familias-tarifarias/',
    infoNonCopa:
      'No tenemos información de reglas tarifarias para este vuelo ya que no es operado por Copa Airlines.',
  },

  fareRulesModalWCAG: {
    description:
      'Modal de reglas tarifarias. Desde aquí podrás ver las reglas que aplican a la tarifa que compraste.',
    previousButton:
      'Usa este botón para ver las reglas tarifarias que aplican al vuelo anterior.',
    nextButton:
      'Usa este botón para ver las reglas tarifarias que aplican al próximo vuelo.',
  },

  specialServicesModal: {
    title: 'Servicios especiales',
    subtitle:
      'Copa ofrece servicios para pasajeros con necesidades especiales. Puedes solicitar comidas especiales, asistencia durante el vuelo, entre otros.',
    introTitle: 'Añadir servicios especiales',
    passengerLabel: 'Pasajero',
    specialServiceLabel: 'Servicio',
    appliesToLabel: 'Aplica para',
    serviceLabel: 'Tipo de servicio',
    passengerSelectPlaceholder: 'Seleccione pasajero',
    serviceSelectPlaceholder: 'Seleccione servicio',
    mealLabel: 'Tipo de comida',
    chairLabel: 'Tipo de silla',
    mealPlaceholder: 'Elegir tipo de comida',
    wheelChairPlaceholder: 'Motivo',
    addButton: '+ Añadir',
    removeButton: 'Remover',
    disclaimer: {
      title: 'Solicitud de Servicios especiales',
      meal: {
        title: 'Comidas especiales',
        description:
          'Las solicitudes de comidas especiales se pueden realizar hasta 24 horas antes de la salida del vuelo. Tipos de comidas especiales disponibles:',
        errorAlreadyAdded:
          'Este servicio ya ha sido añadido para este pasajero.',
        item1: {
          title: 'Clase Ejecutiva: ',
          description:
            'vegetariana, kosher y libre de gluten en ciertos vuelos. {linkDesc}',
          linkDesc: 'Conoce nuestro servicio de alimentos y bebidas a bordo.',
          link: '{copa_url}/es-gs/descubre-copa-airlines/experiencia-a-bordo/comida-a-bordo/',
        },
        item2: {
          title: 'Cabina Principal: ',
          description:
            'libre de gluten solo en vuelos desde y hacia Argentina y Paraguay.',
        },
      },
      assistance: {
        title: 'Asistencia Especial',
        item1: {
          title:
            'Asistencia para personas con discapacidad auditiva o visual: ',
          description: 'Disponible en todos nuestros vuelos.',
        },
        item2: {
          title: 'Transporte de animales de servicio y apoyo emocional: ',
          description:
            'Puedes solicitarlo a través nuestro Centro de Reservaciones, oficinas de venta o aeropuerto. {linkDesc}',
          linkDesc:
            'Conoce las condiciones para el transporte de animales de servicio y apoyo emocional.',
          link: '{copa_url}/es-co/informacion-al-viajar/asistencia-especial/viajando-con-mascotas/',
        },
      },
      footer: {
        description:
          'Más información sobre nuestros servicios especiales, aquí.',
        link: '{copa_url}/es-gs/informacion-al-viajar/asistencia-especial/',
      },
    },
    goBackToReservationButton: 'Regresar a tu reserva',
    saveServicesButton: 'Guardar servicios',
    specialFood: 'Comida especial',
    wheelChair: 'Silla de ruedas',
    errorAlert:
      'Hubo un error al intentar guardar tus servicios especiales. Por favor, intenta de nuevo más tarde.',
    succesToastText: 'Servicios especiales guardados con éxito.',
    partialErrorsToastText:
      'Hubo un error al intentar guardar algunos de tus servicios especiales. Por favor, intenta de nuevo más tarde.',
  },

  specialServicesModalWCAG: {
    description:
      'Modal de servicios especiales. Desde aquí puedes solicitar servicios especiales para pasajeros específicos en tu reservación.',
    passengerSelectionDescription:
      'MENÚ. Estás en el menú desplegable de pasajero.',
    serviceSelectionDescription:
      'MENÚ. Estás en el menú desplegable de tipo de servicio.',
    addButtonDescription: 'Añadir tu selección a tu viaje.',
    removeButtonDescription:
      'Remover el servicio seleccionado de su reservación.',
    goBackToReservationButton: 'Regresar a su reserva',
    saveServicesButton: 'Guardar los servicios seleccionados en tu reservación',
  },

  sendReceiptModal: {
    title: 'Enviar recibo de la reserva',
    subtitle:
      'Puedes enviar el recibo de esta reserva a uno o más correos de tu elección.',
    emailLabel: 'Ingresa correo(s) electrónico(s)',
    emailPlaceholder: 'Ingresa uno o más correos.',
    descriptionLabel:
      'Puedes añadir múltiples direcciones utilizando la tecla de espacio o coma (“,”).',
    goToSendReceipt: 'Enviar recibo',
    resultOk: 'Recibo enviado con éxito.',
    resultError:
      'Hubo un error al enviar tu recibo. Por favor, intenta de nuevo más tarde.',
  },

  sendReceiptModalWCAG: {
    description:
      'Puedes obtener el recibo de esta reserva a uno o más correos de su elección.',
  },

  changeItinerary: {
    title: 'Tu itinerario ha cambiado',
    subtitle_1: 'Ocurrió un cambio de itinerario en tus planes de viaje.',
    subtitle_2:
      'Verifica que el nuevo itinerario se ajusta con tus planes de viaje y que tienes tiempo suficiente para hacer tus conexiones.',
    alert: 'Los cambios en tu itinerario están resaltados abajo.',
    newItinerary: 'Nuevo itinerario de viaje',
    modifiedItinerary: 'Itinerario modificado',
    titleFlight: 'Vuelo',
    titleFrom: 'Desde',
    titleDeparture: 'Salida',
    titleTo: 'Hacia',
    titleArrival: 'Llegada',
    disclaimer_1:
      'Si el cambio de itinerario no funciona para tus planes de viaje, pueden haber otras opciones disponibles.',
    disclaimer_2:
      'Por favor revisa el nuevo itinerario arriba antes de escoger una opción debajo.',
    btnLearnMore: 'Conoce más sobre cambios de itinerario',
    link: '{copa_url}/es/web/gs/cambios-de-itinerario',
    btnAcept: 'Aceptar itinerario y continuar',
  },

  confirmCancelReservation: {
    title: '¿Estás seguro que deseas cancelar esta reserva?',
    warningLabel: 'Una vez canceles tu reserva, no podrás recuperarla.',
    subtitle:
      'Tu reserva será cancelada en su totalidad y recibirás un reembolso por {refundAmount} a tu tarjeta {creditCardFrachise} terminada en {creditCardNumberCensure}.',
    btnConfirmText: 'Cancelar esta reserva',
    btnBackText: 'Retroceder',
    flightDate: 'Fechas de viaje',
    reservationCode: 'Código de reserva',
  },

  confirmCancelReservationWCAG: {
    description:
      'Tu reserva será cancelada en su totalidad y recibirás un reembolso por {refundAmount} a tu tarjeta {creditCardFrachise} terminada en {creditCardNumberCensure}.',
  },

  cancelledBooking: {
    title: 'Hemos recibido la solicitud de reembolso.',
    subtitle:
      'Próximamente recibirás una confirmación vía correo electrónico, cuando hayamos completado el proceso de cancelación de tu reserva.',
    btnNext: 'Continuar',
    flightDate: 'Fechas de viaje',
    reservationCode: 'Código de reserva',
  },

  cancelledBookingWCAG: {
    description:
      'Próximamente recibirás una confirmación vía correo electrónico, cuando hayamos completado el proceso de cancelación de tu reserva.',
  },

  plusGrade: {
    resultError:
      'No podemos acceder al servicio de solicitud de ascenso en estos momentos. Por favor, intenta de nuevo más tarde.',
  },

  requestRefund: {
    title: 'No podemos procesar tu solicitud',
    description: {
      link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
      linkText: 'Centro de Reservaciones',
      startText:
        'No podemos procesar la cancelación web de esta reserva, sin embargo, puedes escalar tu solicitud de cancelación a través de nuestro ',
      endText:
        '. También puedes cancelar tu reserva y mantener el monto para un viaje futuro. Para optar por esta opción, haz clic en el botón y sigue los pasos indicados.',
    },
    btnCta: 'Cancela y planifica después',
    linkCta: '{copa_url}/es-gs/atencion-al-cliente/cambios-y-reembolsos/',
  },
  changeStatus: {
    onWindow: 'Disponible',
    outOfWindow: 'No Disponible',
  },
  flightCard: {
    onTime: 'A Tiempo',
    advanced: 'Adelantado',
    delayed: 'Atrasado',
    canceled: 'Cancelado',
    duration: 'Duración',
    checkInOpen: 'Check-In abre en',
    completed: 'Completado',
    alertSeatPassengers: 'Algunos pasajeros no han seleccionado asientos.',
    alertMissingPassengerInfo: 'Falta información de pasajeros',
    reservationCode: 'Código de reserva',
    nextFlight: 'Próximo vuelo',
    atTime: 'a las',
    numberReservation: '# de reserva:',
    to: 'hacia',
    btnCheckIn: 'Check-In',
    btnPayReservation: 'Pagar reserva',
    btnBoardingPasses: 'Pases de abordar',
    btnSeeReservation: 'Ver reserva',
    btnAutomaticSubscription: 'Suscribirse a Check-In automático.',
    flightCancelled: 'Un vuelo en tu reserva fue cancelado',
    tripCancelled: 'Tu viaje ha sido cancelado.',
    flightPaidRequired: 'Pago requerido. Su reserva expirará en',
    missingPassengerInfo: 'Falta información de pasajeros',
    passengersNotSelectedSeats:
      'Algunos pasajeros no han seleccionado asientos.',
    yourUpcomingTrip: 'Tu próximo viaje',
  },
  ellipsisMenu: {
    remove: 'Borrar',
    rename: 'Renombrar',
  },

  ellipsisMenuWCAG: {
    title: 'MENÚ.',
    remove:
      'BORRAR, botón. Utiliza esta opción para remover este viaje de su Panel de Mis Viajes.',
    rename:
      'RENOMBRAR, botón. Utiliza esta opción para cambiar el nombre de este viaje.',
    descriptionPastTrips:
      'Está en el menú con una opción, presione Enter para remover este vuelo de su Panel de Viajes Anteriores, presione la tecla Esc para cerrar este menú',
    descriptionSaveCard: 'Estás en el menú con dos opciones.',
    descriptionTripDetailCard: 'Estás en el menú.',
  },

  segmentCard: {
    layover: 'Escala',
    header: {
      to: 'Hacia',
      segments: `{stopsNumber} {stopsNumber, plural,
        one {segmento}
        other {segmentos}
      }`,
    },
    flightDetailedInformation: {
      duration: 'Duración',
      flightNumberTitle: 'VUELO',
      date: 'Fecha',
      departureTime: 'Hora de salida',
      arrivalTime: 'Hora de llegada',
    },
    flighBasicInformation: {
      emptySeats: 'Sin asientos seleccionados',
      title: 'INFORMACIÓN DEL VUELO',
      cabin: 'Cabina',
      fareFamily: 'Familia tarifaria',
      seats: 'Asientos',
      aircraft: 'Modelo de aeronave',
      meals: 'Comidas',
      entertainment: 'Entretenimiento',
      interline: 'Interlínea',
    },
  },

  tripDetail: {
    sendReceipt: 'Enviar recibo',
    operated: 'Operado:',
    printItinerary: 'Imprimir itinerario',
    addCalendar: 'Añadir a calendario',
    selectSeats: 'Seleccionar asientos',
    requestUpgrade: 'Solicitar un ascenso',
    cancelReservation: 'Cancelar reserva',
    titlePassengerMultitraveller: 'Pasajeros en esta reserva',
    checkedIn: 'Check-In realizado',
    readyforCheckIn: 'Información completa',
    infoNotcompleted: 'Información no completa',
    addSpecialServices: 'Añadir servicios especiales',
    noSpecialServices: 'No hay servicios especiales para este pasajero.',
    group: 'Grupo',
    tooltip: 'La selección de asiento no está disponible para este pasajero.',
    cancelledReservation: 'Reserva cancelada',
    changeNRItinerary: 'Cambiar itinerario NR',
    exchangesAccess: 'Cambiar itinerario',
    sectionTitle: 'Itinerario de Viaje',
    subtitle: 'Revisa los detalles de tu vuelo y mantente informado',
    flightDetailsTitle: 'Información del vuelo',
    cabin: 'Cabina {cabin_type}',
    gateLabel: 'Puerta:',
    terminalLabel: 'Terminal:',
    noGate: '-',
    noTerminal: '-',
    gateAndTerminalLabel: 'Puerta y Terminal:',
    noGateNoTerminal: 'No Asignadas',
    noFlightInformation: 'Información no disponible',
    fareFamilyAndEntertainment: {
      firstDescription: 'Para conocer más sobre tu familia tarifaria, haz ',
      secondDescription:
        'Si deseas descubrir cómo funciona el entretenimiento abordo y obtener información adicional, ',
      firstCTA: 'clic aquí.',
      secondCTA: {
        text: 'visita nuestro sitio web para conocer más.',
        link: '{copa_url}/es-gs/descubre-copa-airlines/experiencia-a-bordo/entretenimiento/',
      },
    },
  },

  stackableAlerts: {
    economyBasic: {
      title: `Estás volando en clase Económica Básica en uno o más segmentos de tu viaje. Algunas opciones podrían estar limitadas:`,
      description: '',
      callToActionText: '',
      itemList1:
        'El equipaje de bodega está disponible por un costo adicional (aplican excepciones para Venezuela)',
      itemList2:
        'Cambios en la reserva están disponibles por un cargo adicional',
      itemList3:
        'La selección de asientos está disponible por un costo adicional ',
      itemList4: 'Los reembolsos no están disponibles',
      itemList5: 'Miembros ConnectMiles acumularán 50% de millas y segmentos',
      preferMemberNote:
        'Si eres un PreferMember ten en cuenta que algunas de las condiciones mencionadas arriba pueden variar.',
    },
    migratory: {
      title: 'Información importante sobre tu viaje',
      descriptionStart:
        'Revisa los requisitos migratorios y sanitarios para tu próximo viaje en nuestro ',
      descriptionEnd: '.',
      link: '{copa_url}/travel-preparation-hub/es',
      linkText: 'centro de información',
      callToActionEvent: '{copa_url}/es/web/gs/requisitos-de-viaje',
      WCAGButtonClose: 'Botón. Cerrar alerta.',
    },
    bookHold: {
      title: 'Pago requerido',
      description:
        'Tu reserva expirará en {time}. Para evitar que se cancele, recuerda completar tu compra.',
      callToActionText: 'Completar compra',
    },
    expiredBookAndHold: {
      title: 'Reserva expirada',
      description: {
        link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
        linkText: 'Centro de Reservaciones',
        startText:
          'El tiempo para pagar esta reserva ha expirado. Por favor realiza una nueva reserva o contacta a nuestro ',
        endText: ' para más información.',
      },
    },
    bookAndHoldWithoutSSR: {
      title: 'Pago requerido',
      description: {
        startText:
          'Para evitar que se cancele tu reserva, recuerda completar tu compra contactando a nuestro ',
        reservationCenter: {
          text: 'Centro de Reservaciones',
          link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
        },
        middleText: ' o visitando una de nuestras ',
        salesOfices: {
          text: 'Oficinas de Ventas',
          link: '{copa_url}/es-gs/atencion-al-cliente/oficina-de-ventas/',
        },
      },
    },
    indirectChannel: {
      title: 'Información importante sobre tu reservación',
      description:
        'Esta reservación fue adquirida a través de un intermediario. Para hacer cambios, cancelar tu reservación u otras transacciones, contacta a tu punto de compra.',
    },
    cancelledReservation: {
      title: 'Esta reserva fue cancelada',
    },
    paymentAttemptsFailed: {
      title: 'No pudimos procesar tu pago',
      description:
        'Has agotado todos los intentos para procesar el pago. Por favor, contacta a nuestro',
      textLink: 'Centro de Reservaciones.',
      link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
    },
    pendingPayment: {
      title: 'Procesando pago',
      description:
        'Se está procesando el pago para tu viaje a {to}; este proceso puede variar dependiendo del método de pago utilizado. Por el momento, algunas acciones para la reserva no están habilitadas.',
    },
    minor01Alert: {
      title: 'Tu itinerario ha sido ajustado',
      description:
        'Ha sido necesario hacer cambios menores a tu itinerario. Por favor, revisa el detalle de los horarios de tus vuelos.',
      button: 'Revisado',
    },
    sharePNRAlert: {
      title: 'Uno o más vuelos son operados por otra aerolínea',
      description:
        'Este es el código de reserva: {pnr}. Para más detalles, visita su página web.',
    },
  },

  tripDetailWCAG: {
    specialServicesSeeMoreWCAG:
      'Ver más información sobre tus solicitudes de servicios especiales para este viaje',
    specialServicesSeeLessWCAG:
      'Contraer información sobre tus solicitudes de servicios especiales para este viaje',
    addSpecialServices: 'Solicitar servicios especiales para este viaje',
    sendReceiptWCAG: 'Enviar un recibo de este viaje',
    printItineraryWCAG: 'Imprimir una copia de este itinerario',
    addCalendarWCAG:
      'Agregar las fechas de este viaje al calendario de tu preferencia',
    renameTripWCAG:
      'RENOMBRAR, botón. Utiliza esta opción para cambiar el nombre de este viaje.',
    removeTripWCAG:
      'BORRAR, botón. Utiliza esta opción para remover este viaje de su Panel de Mis Viajes.',
    selectSeatsWCAG:
      'Selección de Asientos, botón. Use este botón para seleccionar los asientos de cada pasajero en este viaje',
    requestUpgradeWCAG:
      'Solicitar un ascenso, botón. Use este botón para Solicitar un ascenso para cada pasajero en este viaje',
    cancelReservationWCAG:
      'Cancelar Reservación, botón. Usa este botón para ser redirigido al flujo de cancelación de reservación.',
    changeItineraryButtonWCAG:
      'Cambiar Itinerario, botón. Usa este botón para ser redirigido al flujo de cambio de itinerario.',
  },

  tripsButtons: {
    backToTripHub: 'Volver a Mis Viajes',
    backToFindReservation: 'Buscar otra reserva ',
    save: 'Guardar en Mis Viajes',
    saved: 'Viaje Guardado',
  },

  tripsButtonsWCAG: {
    backToTripHubWCAG: 'Regresar a Mis Viajes',
    backToFindReservationWCAG: 'Regresar a Buscar Mi Reservación',
    saveWCAG: 'Guardar este viaje a tu panel de Mis Viajes',
    savedWCAG: 'Trip Saved',
    findReservation: 'Buscar su reserva, botón',
  },

  accordionSection: {
    seeMoreCTA: 'Ver más',
    seeLessCTA: 'Ver menos',
    specialServices: 'Servicios especiales',
    specialServicesHyphen: '—',
    withMeal: 'Comidas',
    withSpecialAssistance: 'Asistencia especial',
    withoutSpecialServicesAdded: 'Sin servicios especiales',
  },

  pastTripsDetail: {
    itinerary: 'Itinerario de viaje',
    from: 'Desde',
    toward: 'Hacia',
    date: 'Fecha',
    flight: 'Vuelo',
    operated: 'Operado por',
    passenger: 'Pasajeros en esta reserva',
    name: 'Nombre del pasajero',
    number: 'Número del tiquete',
    nameAndNumber: 'Nombre y número de tiquete',
  },
  memberMenu: {
    logOut: 'Cerrar sesión',
    myAccount: 'Mi cuenta',
    greeting: 'Hola,',
    status: 'Estatus',
    connectMiles: '# ConnectMiles',
    balance: 'Balance de millas',
  },
  memberMenuWCAG: {
    pageDescription:
      'Menú de miembros ConnectMiles: Te permite cerrar sesión, acceder a tu cuenta ConnectMiles y ver tu número y estatus de ConnectMiles',
    connectMilesLogo: 'Logo de ConnectMiles',
    logOut:
      'Botón cerrar sesión: Presiona Enter para cerrar la sesión de tu cuenta de ConnectMiles.',
    myAccount:
      'Botón mi cuenta: Presiona Enter para ser redirigido a la página de tu cuenta ConnectMiles.',
    connectMiles: 'Número de ConnectMiles',
  },
  copaSPTooltip: {
    description:
      'Copa Showpass es nuestro sistema de entretenimiento abordo, que te permite reproducir contenido de manera inalámbrica en tu dispositivo a través de nuestra aplicación Copa Airlines.',
    linkText: 'Conoce más',
  },
  touchScreenOnDemand: {
    description:
      'Pantalla táctil personal es nuestro sistema de entretenimiento personal, donde puedes usar pantallas localizadas en tu asiento para seleccionar, controlar, y reproducir contenido de tu preferencia.',
  },
  overheadScreen: {
    description:
      'Pantalla general es nuestra opción compartida de entretenimiento abordo. Ve algunas de las mejores películas del momento y los shows más populares en nuestras pantallas generales.',
  },
  upcomingTripCardWCAG: {
    description:
      'Grupo Tu Próximo Viaje. En este grupo podrás ver y administrar detalles de tu próximo viaje a {destination} el {departureDate}.',
    btnSeeReservation: 'Ver detalles de este viaje.',
    btnCheckIn: 'Check-In, botón',
  },
  saveTripWCAG: {
    description:
      'Botón. Viaje guardado a {destination} el {departureDate}. Utiliza este botón para ver y administrar los detalles de este viaje.',
  },

  FAQ: {
    title: 'Preguntas frecuentes',
    viewMore: 'Más preguntas frecuentes',
    linkViewMore:
      'https://help.copaair.com/hc/es-419/categories/360004256754-Preguntas-m%C3%A1s-frecuentes',
    contact: 'Obtener ayuda',
    linkContact: '{copa_url}/es-gs/atencion-al-cliente/',
    listOfFQA: {
      equipmentAdd:
        '¿Puedo llevar más equipaje del que aparece en la sección de Equipaje Permitido?',
      equipmentAddBody:
        '¡Sí puedes! Durante el registro de tu equipaje, nuestros agentes te podrán asistir con el registro de equipaje adicional. Deberás cancelar los cargos de equipaje adicional. Revisa nuestras {link} para más información. Cuidando de tu salud y la de nuestros colaboradores, muchos aeropuertos han implementado protocolos de bioseguridad. Recuerda llegar al aeropuerto con suficiente tiempo de anticipación para que puedas realizar tu proceso de registro de equipajes sin inconvenientes.',
      nameLinkequipmentAdd: 'políticas de equipaje',
      linkequipmentAdd:
        '{copa_url}/es-gs/informacion-al-viajar/informacion-de-equipaje/',
      webCheckIn: '¿Puedo hacer Web Check-In a través de Mis Viajes?',
      webCheckInBody:
        '¡Sí puedes! La opción de hacer Check-In se habilitará 24 horas antes de tu vuelo. Adelanta tu Check-In llenando tu información personal en la sección de Información de Pasajero.',
      accumulateMiles: '¿Cómo puedo acumular millas por mi viaje?',
      accumulateMilesBody:
        'Para acumular valiosas millas por tu viaje, ingresa tu número de ConnectMiles o de tu programa de viajero frecuente en la sección de Información de Pasajero. Si todavía no eres miembro de nuestro programa ConnectMiles, puedes ingresar a nuestro {link} para inscribirte y luego agregar tu nuevo número en la reservación.',
      linkAccumulateMiles: '{copa_url}/es-gs/enrollment',
      nameLinkAccumulateMiles: 'formulario de afiliación',
      selectSeat: '¿Cómo puedo seleccionar un asiento antes de mi viaje?',
      selectSeatBody:
        'Puedes seleccionar un asiento a través de la sección de Información de Pasajero. Dependiendo de la tarifa que hayas adquirido, puedes seleccionar un asiento de manera gratuita si aplica, o comprar un asiento desde el momento que tu boleto es confirmado. Tenemos diferentes tipos de asiento para tu comodidad. Puedes encontrar más detalles en nuestra {link}.',
      linkSelectSeat:
        '{copa_url}/es-gs/legal/terminos-y-condiciones/seleccion-de-asientos/',
      nameLinkSelectSeat: 'página de información de asientos',
    },
  },

  alertScheduleChanges: {
    descriptionSing: {
      start:
        'Ha habido una actualización en tu vuelo de {from} a {to}. Por favor contacta a nuestro ',
      end: ' para brindarte asistencia. Disculpa los inconvenientes.',
    },
    descriptionPlu: {
      start:
        'Ha habido una actualización en más de uno de tus vuelos. Por favor contacta a nuestro ',
      end: ' para brindarte asistencia. Disculpa los inconvenientes.',
    },
    link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
    linkText: 'Centro de Reservaciones',
  },

  addToCalendar: {
    subjectFlight: 'Vuelo CM',
    subjectFrom: 'de',
    subjectTo: 'a',
    reservationCode: 'Código de Reserva:',
    itinerary: 'ITINERARIO: CM Vuelo',
    departure: 'Salida:',
    on: 'el',
    at: 'a las',
    arrival: 'Llegada:',
    fareFamily: 'Familia Tarifaria:',
    travelerInfo: 'Información de pasajero:',
  },
  retract: {
    header: {
      title: 'Cancelar tu reserva',
      infoText: 'Tu viaje a {destination}',
    },
    title: '¿Deseas cancelar esta reserva?',
    subTitle:
      'Tu reserva está dentro del período de compra flexible de 24 horas. Esto quiere decir que al cancelar, te reembolsaremos el 100% del monto pagado, según lo indicado en nuestra política. Por favor, lee con detenimiento la siguiente información antes de proceder.',
    dateTrip: 'Fechas de viaje',
    reservationCode: 'Código de reserva',
    fareFamily: 'Familia tarifaria',
    refundDetails: 'Detalles del reembolso',
    baseAirfare: 'Tarifa aérea',
    taxes: 'Impuestos',
    seats: 'Asientos',
    subtotal: 'Subtotal',
    noRefundable: 'No reembolsable',
    refundTotal: 'Total a reembolsar',
    infoImportant: 'Información importante sobre tu cancelación',
    item1: {
      start:
        'Copa Airlines ofrece reembolso completos para las reservas que cumplan los requisitos de la política de compra flexible de 24 horas. Conoce los detalles de esta política, ',
      linkText: 'aquí',
      link: '{copa_url}/es-gs/legal/terminos-y-condiciones/cancelacion-flexible/',
      end: '.',
    },
    item2:
      'El costo de la reserva será reembolsado al método de pago utilizado para realizar la compra.',
    item3:
      'El monto del reembolso debe reflejarse en un plazo no mayor a los dos (2) estados de cuenta bancaria o de tarjeta de crédito siguientes, desde la fecha de solicitud de reembolso. Ese período puede variar dependiendo de la fecha de corte de su estado de cuenta.',
    cancelReservation: 'Cancelar esta reserva',
    insuranceRefund1:
      'Has comprado un seguro de viaje provisto por Chubb por la suma de {value} {currency}. Para procesar el reembolso de este servicio, deberás comunicarte diréctamente con Chubb a través de sus',
    insuranceRefundLink: 'puntos de contacto',
    insuranceRefundUrl:
      'https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=es',
    insuranceRefund2:
      ', utilizando tu código de reserva como referencia de compra.',
    cancelSummary:
      'Tu reserva será cancelada en su totalidad y recibirás un reembolso por {amount}',
    refundLink: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
    error: {
      toast: 'Hubo un error, inténtalo de nuevo.',
      alert: {
        start:
          '¡Lo sentimos! Debido a un error, no pudimos cancelar tu reserva. Por favor, inténtalo más tarde o contacta a nuestro ',
        end: '.',
        link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
        linkText: 'Centro de Reservaciones',
      },
    },
  },

  shoppingCart: {
    title: 'Extras de viaje',
    subtitulo:
      'Estos son los extras de viaje que has escogido. Puedes pagarlos ahora o seguir manejando tu viaje.',
    verDetalle: 'Ver detalles',
    subtotal: 'Subtotal',
    impuestos: 'Impuestos',
    total: 'Total ',
    cerrar: 'Cerrar',
    pagoTotal: 'TOTAL A PAGAR',
    impuetso: ' en impuestos',
    tiempoRestante: 'Tiempo restante:',
    pagarExtras: 'Pagar extras de viaje',
    seatsPremium: 'Asiento Premium ',
    barraTitle:
      'Tu selección de asientos expirará en {time}. Completa tu compra ahora.',
    expireTitle: 'Tu selección de asientos ha expirado',
    expireSubTitle:
      'El tiempo máximo para completar la compra de los asientos seleccionados ha expirado. Puedes seleccionar asientos nuevamente.',
    expireBtn: 'Regresar a la reserva',
    WCAGBoton: 'Botón. Regresar a la reserva.',
    WCAGClose: 'Botón. Cerrar modal.',
    WCAGTitle: 'Modal de selección expirada.',
  },

  extraTrip: {
    title: 'Extras de viaje comprados',
    subtitle: 'Estos son los extras de viaje que ya compraste.',
    tableDescription: 'Descripción',
    tableTraveler: 'Pasajero',
    tableFlight: 'Vuelo',
    tableValue: 'Valor',
    seat: 'Asiento',
    'extra-bag': 'Equipaje',
    'pet-in-cabin': 'Mascota en cabina',
    'sport-equipment': 'Equipaje deportivo',
    'music-instrument': 'Equipaje musical',
  },

  reservationConfirmed: {
    title: 'Estamos procesando tu reserva ',
    subtitle1: 'Estamos procesando tu viaje a {to}.',
    subtitle2: ' Tu código de reserva es ',
    subtitle3:
      'Una vez hayamos procesado tu pago, recibirás un correo de confirmación a ',
    subtitle4: '.',
    summary: 'Resumen de compra',
    successfully: 'Estamos procesando tu pago de {monto}.',
    authorizationCode: 'Código de autorización',
    nowYouCan: 'Ahora puedes:',
    downloadCopaApp: 'Descargar el App de Copa Airlines ',
    downloadCopaAppText:
      'Con el App de Copa Airlines puedes recibir notificaciones sobre el estado de tu vuelo en tiempo real, realizar Check-In y acceder al entretenimiento a bordo.*',
    downloadCopaAppSubText:
      '* El entretenimiento a bordo solo aplica para vuelos que cuenten con el sistema Copa Showpass.',
    laptobTitle: 'Manejar tu reserva a través de Mis Viajes',
    laptobText:
      'A través de la sección de Mis Viajes en copa.com podrás elegir o cambiar tus asientos, ver tu itinerario de viaje y hacer cambios a tu reserva.',
    qrTitle: 'Hacer Web Check-In 24 horas antes de tu vuelo',
    qrText:
      'Ahorra tiempo en el aeropuerto haciendo Web Check-In desde 24 horas antes de tu vuelo. Podrás elegir o cambiar tus asientos y obtener tus pases de abordar digitales.',
    btnGoToReservation: 'Ir a la reserva',
    lateBookingFee: 'Tasa por reserva tardía',
    serviceFee: 'Tasa por servicio',
  },
  payReservation: {
    titleBar: 'Pagar reserva',
    title: 'Detalles del viaje',
    subTitle:
      'Revisa con detenimiento tu reserva antes de proceder con la compra.',
    resumen: 'Resumen de la reserva ',
    btn: 'Continuar a selección de asientos',
    layover: 'escala',
    airfare: 'Tarifa aérea',
    uplift: {
      description: 'Completaste esta compra utilizando Uplift.',
      linkText: 'Más información.',
      link: '{copa_url}/es-pa/descubre-copa-airlines/uplift/',
    },
    alerts: {
      missingMilesTitle: 'Te faltan {miles} millas para comprar esta reserva',
      missingMilesText:
        '¡Garantiza este viaje! Tenemos para ti diferentes opciones para que puedas completar esta reserva: {link}',
      buyMiles: 'Comprar millas',
      buyMilesLink:
        'https://miles.copaair.com/login.html?product=buy&language=es',
      restrictedAccountTitle: 'Tu cuenta está restringida',
      restrictedAccountText:
        'Tu cuenta ha sido bloqueada temporalmente por razones de seguridad. Comunícate con nuestro {link} de ConnectMiles para obtener asistencia.',
    },
    redemptions: {
      miles: 'Millas',
      others: 'Otros',
      passengersGrouped: `{itemCount} {itemCount, plural,
        one { pasajero}
        other { pasajeros}
      }`,
    },
  },

  taxes: {
    smallTitle: 'TOTAL DE LA RESERVA',
    bigTitle: 'Detalle de tasas e impuestos',
    titleDescription: 'DESCRIPCIÓN',
    titleMonto: 'MONTO',
    total: 'Total tasas e impuestos',
  },

  pseInformation: {
    title: 'Información de Pago (PSE Colombia)',
    subtitle:
      'Esta es la información relacionada a la compra para referencia futura.',
    bank: 'Banco',
    date: 'Fecha de transacción',
    status: 'Estado',
    pendingStatus:
      'Transacción pendiente (por favor, revisar si el débito se hizo efectivo en el banco)',
    approvedStatus: 'Transacción aprobada',
    orderReference: 'Referencia de pedido',
    ipAddress: 'Dirección IP',
    value: 'Valor',
    transaction: 'Transacción',
    descriptionLabel: 'Descripción del pago',
    descriptionText: 'Compra en Copa Airlines Colombia',
    transactionNumber: 'Número de referencia',
    company: {
      title: 'Contacto de la Empresa',
      companyLabel: 'Empresa',
      companyText: 'COMPAÑÍA PANAMEÑA DE AVIACIÓN S.A.',
      nitLabel: 'NIT',
      nitText: '860.025.338-2',
      addressLabel: 'Dirección',
      addressText:
        'AV EL DORADO N° 103 – 08 INT 1 TERMINAL AEREO SIMON BOLIVAR Bogotá',
      phoneNumberLabel: 'Teléfono',
      phoneNumberText: '(571) 3578080',
    },
  },

  originalItinerary: {
    accordiontitle: 'Ver itinerario original',
    subtitle: 'Una vez confirmado el nuevo itinerario, este ya no será válido.',
    close: 'Ver detalles',
    open: 'Ocultar detalles',
    noChanges: 'Sin cambios',
    flightModified: 'Vuelo {flight} sufrió un cambio',
  },

  onBoardingModal: {
    title:
      'Hemos hecho cambios al itinerario de este viaje. Tu acción es requerida.',
    descriptionBold:
      'Hemos tenido que realizar un cambio de itinerario en uno o más segmentos de tu viaje a {destination}.',
    description2:
      'Por favor, revisa el nuevo itinerario; tendrás la opción de aceptar los cambios o modificar tus fechas de viaje.',
    subtitle: 'Sigue estos pasos para aceptar o modificar el nuevo itinerario:',
    step1: 'Paso 1',
    step1des: '— Revisa el itinerario de viaje actualizado.',
    step2: 'Paso 2',
    step2des: '— Acepta o modifica el nuevo itinerario.',
    step3: 'Paso 3',
    step3des: '— Continúa a Mis Viajes para confirmar tu selección.',
    newItineraryBtn: 'Ver nuevo itinerario',
    ctaWcag: 'Presiona aquí para cerrar e ir a tu itinerario.',
    buttonWcag: 'Presiona aquí para revisar tu nuevo itinerario',
  },

  loginBox: {
    title: 'Inicia tu sesión de ConnectMiles para continuar con esta reserva.',
    disclaimer:
      'Debes iniciar sesión con la cuenta con la que originalmente realizaste esta reserva.',
    button: 'Iniciar sesión y continuar',
  },

  loginSRT: {
    title: 'Inicia tu sesión de ConnectMiles para continuar.',
    userBox: 'Ingresa tu usuario',
  },
  // ////////////////////////////// end translations My trips //////////////////////////////////

  languages: {
    english: 'English',
    spanish: 'Español',
    portuguese: 'Português',
    french: 'Français',
    dropdownDescription: 'IDIOMA/LANGUAGE/LÍNGUA',
  },

  headerNavigation: {
    signup: 'Afíliese',
    language: 'Lenguaje',
    miles: '{number} millas',
  },
  loginForm: {
    user: 'Correo electrónico, número de ConnectMiles o usuario',
    passwordField: 'Contraseña',
    loginButton: 'Iniciar sesión',
    logoutButton: 'Cerrar Sesión',
    inputPlaceHolderUser: 'Ingrese su usuario',
    inputPlaceHolderPrivatePhrase: 'Ingresa tu contraseña',
    forgotPrivatePhrase: '¿Olvidaste tu contraseña?',
    account: '¿No tienes una cuenta?',
    accountLink: ' Crear una ahora.',
  },
  loginFormAlerts: {
    loginError:
      '¡Lo sentimos! La información que proporcionaste no coincide con nuestros registros. Por favor, revísala y vuelve a intentarlo.',
    logOutError:
      'Hubo un error al tratar de cerrar su sesión. Inténtalo de nuevo.',
    logInUnknownError: 'Hubo un error al actualizar. Inténtalo de nuevo.',
    invalidCredentials:
      'La información que proporcionaste no coincide con nuestros registros. Por favor, revísala y vuelve a intentarlo.',
    blockedAccount: {
      title:
        'Tu cuenta ha sido bloqueada temporalmente por razones de seguridad. Comunícate con nuestro centro de servicio de ConnectMiles para obtener ayuda. {link}',
      link: '{copa_url}/es-gs/',
      text: 'al número de centro de llamadas de ConnectMiles',
    },
    wrongInformation:
      'La información que proporcionaste no coincide con nuestros registros. Por favor, revísala y vuelve a intentarlo.',
  },
  clientService: {
    callCenter: 'Call Center',
    linkCallCenter: '{copa_url}/es/web/ca/reservation-center',
    serviceCenter: 'Centro de Servicio',
    linkServiceCenter:
      '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
  },
  findReservationAlerts: {
    notFound:
      'Viajas en un grupo grande que requiere asistencia de un agente. Dirígete al aeropuerto para hacer Check-In.',
    tooEarly:
      'Es muy pronto para realizar Check-In. El Check-In abre 24 horas antes de la salida de tu vuelo, por favor regresa luego.',
    tooLate:
      'Es demasiado tarde para realizar Check-In. Contacta a un agente en el aeropuerto para obtener ayuda.',
    otherCarrier:
      'Tu primer vuelo es operado por otra aerolínea.  Por favor realiza Check-In a través de su página web.',
    largeGroup:
      'Viajas en un grupo grande que requiere asistencia de un agente. Dirígete al aeropuerto para hacer Check-In.',
    outOfSync:
      'Parece que tu reserva debe ser revisada por uno de nuestros agentes. Por favor contacta a nuestro {link} para asistencia.',
    noETKT:
      'Parece que tu reserva debe ser revisada por uno de nuestros agentes. Por favor contacta a nuestro {link} para asistencia.',
    cancelledFlights:
      'Parece que tu reserva debe ser revisada por uno de nuestros agentes. Por favor contacta a nuestro {link} para asistencia.',
    restrictedScenarios:
      'Hay una solicitud especial asociada con tu reserva. Por favor, realiza Check-In en el aeropuerto.',
    wrongLastName:
      'El apellido que proporcionaste no coincide con nuestros registros. Por favor, revísalo y vuelve a intentarlo.',
    incorrectETKT:
      '¡Lo sentimos! No pudimos encontrar tu reserva con la información proporcionada. Por favor, revisa tus datos y vuelve a intentarlo.',
    eTicketRequired:
      'Parece que tu reserva debe ser revisada por uno de nuestros agentes. Por favor contacta a nuestro {link} para asistencia',
    groupLarge:
      'Viajas en un grupo grande que requiere asistencia de un agente. Dirígete al aeropuerto para hacer Check-In.',
    PNRNoSegments:
      'Parece que tu reserva debe ser revisada por uno de nuestros agentes. Por favor contacta a nuestro {link} para asistencia.',
    serviceUnavailable:
      'Hubo un error al encontrar tu reserva. Inténtalo de nuevo.',
  },
  findReservationWCAG: {
    pageTitle: 'Mis Viajes',
    pageDescription:
      'Primera página del flujo de Mis Viajes, debes proporcionar tu número de reserva y tu apellido para poder encontrar tu reservación',
    textSubtitle:
      'Desde Mis Viajes puedes ver, gestionar y guardar tus reservas de Copa Airlines',
    textWellcome: 'Para comenzar, busquemos tu reservación',
    feebackBetaLink:
      'Estás usando la versión beta de web checkin. Presiona enter para enviar un email con tus comentarios y sugerencias desde tu aplicación de email',
    oldCheckinUrl: 'To check-in using the old version, click here ',
    copaLogoLink: 'Logo de Copa. Ir a la página de inicio de Copa',
    copaLogoAltMobile: 'Logo de Copa. Ir a la página de inicio de Copa',
    extendedCopaLogo: 'Logo de Copa. Ir a la página de inicio de Copa',
    cmLogoAlt: 'Logo de ConnectMiles',
    cmSignupButton:
      'Si no tiene una cuenta de ConnectMiles, presione Enter para crear una ahora',
    cmLoginButton:
      'Inicie sesión, puede usar su perfil ConnectMiles para para encontrar sus viajes aún más rápido. Presione Enter para ingresar la información',
    cmCloseLoginButton:
      'Presione enter para cerrar este panel de Connect miles',
    cmLogoutButton:
      'Presione enter para cerrar sesión de su cuenta ConnectMiles.',
    languageSelection:
      'Está en un campo desplegable con 3 opciones, use las teclas arriba y abajo y luego presione Enter para seleccionar su idioma preferido',
    reservationSearchCodeField:
      'Puede escribir un código alfanumérico de 6 dígitos en caso de tener un código PNR, o un código numérico de 13 dígitos en caso de que tenga un número de boleto electrónico, o un número de 9 dígitos si es un número de ConnectMiles.',
    reservationSearchLastNameField:
      'Ingrese el apellido que aparece en su reserva',
    searcReservationButton:
      'Presione enter para buscar su reserva y continuar con su proceso de registro.',
  },
  loginFormWCAG: {
    pageDescription:
      'Caja de Login ConnectMiles. Te permite acceder a tu perfil de usuario. Por favor usa la tecla de escape para volver al menu principal.',
    userNameField: 'Ingresa tu email, número de ConnectMiles o usuario',
    passwordField: 'Ingresa tu contraseña de ConnectMiles',
    loginButton:
      'Presiona Enter para iniciar sesión con las credenciales ingresadas',
    forgotPrivatePhrase:
      'Presiona enter para ir a ConnectMiles y recuperar tu contraseña',
    joinNow:
      'Si no tienes una cuenta de ConnectMiles, presiona Enter para crear una ahora',
  },
  customModalWCAG: {
    closeButton:
      'Botón de cierre, pulse enter para cerrar la ventana emergente.',
  },
  searchNumberForm: {
    inputLabelNumber: `Código de reservación o número de E-Ticket`,
    inputPlaceholderNumber: 'Ingresa código o número',
    inputPlaceholderLastName: 'Ingresa tu apellido',
    inputLabelLastName: 'Apellido',
    search: 'Busca tu reservación',
  },
  ancillaries: {
    title: 'Opciones de Viaje',
    description: 'Mejora tu experiencia de viaje con estas opciones.',
    carTitle: 'Alquiler de auto',
    carDescription:
      'Diferentes opciones para disfrutar al máximo de nuestros destinos.',
    carLinkDescription: 'Reservar ahora',
    carLink: 'https://cars.cartrawler.com/copa/es/?clientId=538733',
    hotelTitle: 'Reservar hotel',
    hotelDescription: 'Encuentra el alojamiento perfecto para tu viaje.',
    hotelLinkDescription: 'Buscar hoteles',
    hotelLink:
      'https://sp.booking.com/index.html?aid=2027762;label=pagemanagebooking-ban-2027762-click_brand-mmbcanales',
    insuranceTitle: 'Seguro de viaje',
    insuranceDescription: '¿Ya tienes tu seguro de viaje?',
    insuranceLinkDescription: 'Asegura tu viaje aquí',
    redemption: {
      milesTitle: 'Reponga su balance de millas',
      milesDescription:
        'Más millas ahora. Más aventuras después. Reponga su balance de millas y empiece a planificar sus siguientes vacaciones.',
      milesCTA: 'Compre millas',
      milesLink: 'https://miles.copaair.com/?product=buy&language=es',
      hotelsTitle: '¡Acumule más millas en sus estadías!',
      hotelsDescription:
        'ConnectMiles Hotels es una plataforma creada exclusivamente para nuestros miembros donde podrá ganar desde 500 hasta 15,000 millas por noche con sus reservas de hotel. Seleccione entra más de 400,000 hoteles y gane millas que le permitirán seguir viajando a su destino soñado.',
      hotelsCTA: '¡Reserve ahora!',
      hotelsLink: 'https://connectmileshotels.copaair.com/cm/home/es-MX/',
    },
  },
  ancillariesWCAG: {
    contentDescription:
      'Sección de Opciones de Viaje. En esta sección podras escoger entre nuestras opciones de viaje adicionales para mejorar tu viaje.',
    carImageDescription: 'Imagen. Familia en un auto.',
    carLinkDescription: 'Reservar ahora.',
    hotelImageDescription: 'Imagen. Hombre en un cuarto de hotel.',
    hotelLinkDescription: 'Buscar hoteles.',
    insuranceImageDescription: 'Imagen. Mujer cerca de un río en el bosque.',
    insuranceLinkDescription: 'Asegura tu viaje aquí.',
  },
  entertainment: {
    title: 'Entretenimiento',
    description:
      'Explora nuestras opciones de entretenimiento y disfruta de toda nuestra selección de contenidos.',
    copaShow: 'Copa Showpass',
    showpassDescription:
      'Nuestro sistema de entretenimiento a bordo te permitirá disfrutar de películas, series, música y revistas utilizando tu dispositivo personal.',
    showpassLinkDescription: 'Conocer Más',
    showpassLink:
      '{copa_url}/es-gs/descubre-copa-airlines/experiencia-a-bordo/entretenimiento/',
    panoramaTitle: 'Panorama de las Américas',
    panoramaDescription:
      'Descubre nuestra revista digital y disfruta de su amplia variedad de contentidos en cualquier lugar.',
    panoramaLinkDescription: 'Descúbrela aquí',
    panoramaLink: 'https://www.revistapanorama.com/digital/',
  },
  entertainmentWCAG: {
    contentDescription:
      'Sección de Entretenimiento. En esta sección podras escoger entre nuestras opciones de entretenimiento para disfrutar su gran variedad de conotenidos.',
    showpassImageDescription: 'Imagen. Persona sosteniendo una tablet',
    showpassLinkDescription:
      'Enlace a la página de Copa Showpass. Toca dos veces para ir a nuestra página de entretenimiento a bordo y saber de qué se trata.',
    panoramaImageDescription:
      'Imagen. Playa y teléfono con título de la revista',
    panoramaLinkDescription:
      'Enlace a nuestra revista digital. Doble toque para ir a la página de Panorama de las Américas.',
  },
  whoPage: {
    title: '¿Quienes viajan contigo?',
    description:
      'Estás viajando en una reserva de grupo y conseguimos más de un pasajero con la información proporcionada.',
    instruction: 'Por favor selecciona los pasajeros que viajan contigo:',
    chooseWhoButton: 'Continuar con estos pasajeros',
  },
  whoPageWCAG: {
    modalContainer:
      'Estás viajando en una reserva de grupo y hay más de un pasajero con la misma información proporcionada. Por favor selecciona de la lista tu nombre y el nombre de los pasajeros a los cuales deseas continuar.',
    checkboxWho: 'nombre de pasajero ',
    buttonSelectedPassengers:
      'Presiona enter para seleccionar los pasajeros seleccionados.',
    closeModal: 'Presiona enter para cerrar esta sección.',
  },
  travelerInfoForm: {
    year: 'Año',
    month: 'Mes',
    day: 'Día',
    month01: 'Enero',
    month02: 'Febrero',
    month03: 'Marzo',
    month04: 'Abril',
    month05: 'Mayo',
    month06: 'Junio',
    month07: 'Julio',
    month08: 'Agosto',
    month09: 'Septiembre',
    month10: 'Octubre',
    month11: 'Noviembre',
    month12: 'Diciembre',
    reviewTravelerInfo: 'Información del Pasajero',
    travelerNOfN: 'Pasajero {current} de {total}',
    flightCheckIn: 'Mis Viajes',
    passportInformation: 'Información del Pasaporte',
    travelerInfoIntro:
      'Asegúrate de proporcionar la información correcta sobre los detalles de viaje y pasaporte de cada pasajero. Si este requisito no se cumple, las autoridades pueden no permitirte viajar o ingresar al área de embarque.',
    ESTAText1:
      'Se requiere que todos los pasajeros que planeen viajar a Estados Unidos utilizando el “Programa de Exención de Visa” reciban autorización a través de ESTA “Sistema Electrónico para la Autorización de Viaje” antes de abordar el avión a ese destino; ',
    ESTAText2:
      'por favor asegúrate de tener la autorización ESTA antes de completar el Check-In.',
    multipleProfiles:
      'Puedes seleccionar un pasajero de tu perfil para auto completar la información requerida:',
    passportOptionUseProfile: 'Utilizar Perfil Guardado',
    inputPlaceHolderProfile: 'Seleccione el Perfil',
    passportOptionManual: 'Ingrese Manualmente Información del Pasaporte',
    selectPassport:
      'Tienes guardado dos pasaportes, por favor, selecciona el que deseas utilizar:',
    inputLabelFirstName: 'Nombre',
    inputPlaceHolderFirstName: 'Ingrese Nombre',
    inputLabelLastName: 'Apellido',
    inputPlaceHolderLastName: 'Ingrese Apellido',
    dateOfBirth: 'Fecha de Nacimiento',
    dateOfBirthDescription:
      'El formato de fecha es: 2 dígitos para el día desde 01 hasta 31, 2 dígitos para el día desde 01 hasta 12 y 4 digitos para el año',
    residencyCardDateDescription:
      'El formato de fecha es: 2 dígitos para el día desde 01 hasta 31, 2 dígitos para el mes desde 01 hasta 12 y 4 dígitos para el año',
    genderOptionMale: 'Masculino',
    genderOptionFemale: 'Femenino',
    nationality: 'País de Nacionalidad',
    inputPlaceHolderNationality: 'Ingrese Nacionalidad',
    passportNumber: 'Número de Pasaporte',
    inputPlaceHolderPassportNumber: 'Ingresa Número de Pasaporte',
    expirationDate: 'Fecha de Vencimiento',
    expirationDateDescription:
      'El formato de fecha es: 2 dígitos para el día desde 01 hasta 31, 2 dígitos para el mes desde 01 hasta 12 y 4 dígitos para el año',
    expirationDateError: 'Por favor ingresa una fecha válida',
    countryOfIssue: 'País Emisor',
    inputPlaceHolderCountryOfIssue: 'Ingrese País Emisor',
    email: 'Correo Electrónico para enviar Pase de Abordar',
    emailError: 'Ingrese un correo electrónico válido',
    useEmailForEverybody:
      'Utilice este correo electrónico para todos los pasajeros',
    knownTravelerInformation: 'Número de Viajero Conocido',
    emergencyContactTitle: 'Contacto de Emergencia',
    emergencyContactLabel: 'Nombre y Apellido',
    phoneCodeLabel: 'Código de País',
    phoneLabel: 'Número de Teléfono',
    travelerNumber: 'Número de Viajero Conocido',
    inputPlaceHolderTravelerNumber: 'Ingrese Número',
    redressNumber: 'Número de Desagravio',
    redressNumberInputLabel: 'Número de Desagravio',
    inputPlaceHolderRedressNumber: 'Ingrese Número',
    frequentFlyerProgram: 'Programa de Viajero Frecuente',
    inputPlaceHolderFrequentFlyerProgram: 'Seleccione el Programa',
    frequentFlyerNumber: 'Número de Viajero Frecuente',
    inputPlaceHolderFrequentFlyerNumber: 'Ingrese Número',
    warningFrequentFlyerNumber:
      'Este es un boleto premio, si este no es su número de viajero frecuente y lo cambia, puede perder los beneficios del dueño de la cuenta.',
    emergencyContact: 'Contacto de Emergencia',
    fullName: 'Nombre Completo',
    inputPlaceHolderFullName: 'Ingrese Nombre Completo',
    PhoneNumber: 'Número de Teléfono',
    inputPlaceHolderPhoneNumber: 'Ingrese Número de Teléfono',
    countryOfResidence: 'País de Residencia',
    inputPlaceHolderCountryOfResidence: 'Ingrese País de Residencia',
    noResultMsgCountryOfIssue: 'No se encontraron resultados',
    noResultMsgNationality: 'No se encontraron resultados',
    noResultMsgCountryOfResidence: 'No se encontraron resultados',
    noResultMsgState: 'No se encontraron resultados',
    gender: 'Sexo',
    whereAreYouStaying: '¿Dónde te vas a hospedar en EE. UU.?',
    stayingStreetAddress: 'Dirección',
    inputPlaceHolderStreetAddress: 'Ingrese Dirección',
    stayingZipCode: 'Código postal',
    inputPlaceHolderZipCode: 'Ingrese Código postal',
    stayingCity: 'Ciudad',
    inputPlaceHolderCity: 'Ingrese Ciudad',
    stayingState: 'Estado',
    inputPlaceHolderState: 'Ingrese Estado',
    usResidencyCard: 'Tarjeta de Residencia de EE. UU.',
    residencyCardNumber: 'Número de Tarjeta',
    inputPlaceHolderCardNumber: 'Ingrese Número de Tarjeta',
    residencyCardDate: 'Fecha de Vencimiento',
    sentenceInfo:
      'Asegúrese de proporcionar información completa y precisa sobre los detalles de viaje y pasaporte de cada pasajero. Si no cumple con este requisito, las autoridades pueden no permitirle viajar o ingresar al área de embarque.',
    addButton: 'Agregar',
    removeButton: 'Eliminar',
    estaText:
      'Se requiere que todos los ciudadanos que planeen viajar a Estados Unidos utilizando “Visa Waiver Program” reciban una autorización a través de ESTA “Electronic System for Travel Authorization” antes de abordar un avión con destino a Estados Unidos. Por favor asegúrese de tener la autorización ESTA antes de completar su registro',
    enterFFProgram: 'Ingrese Programa de Viajero Frecuente',
    optionalSectionLabel: '(Opcional)',
    saveButton: 'Continuar',
    inputLabelEmail: 'Correo Electrónico para enviar Pase de Abordar',
    inputPlaceHolderemail: 'Ingrese Correo Electrónico',
    redemptionText:
      'Este es un boleto premio, si este no es su número de viajero frecuente y lo cambia, puede perder los beneficios del dueño de la cuenta.',
    connectMilesSectionTitle:
      'Ya tienes guardada información en tu perfil, selecciona el perfil deseado para auto completar la información de pasaporte requerida a continuación.',
    prePopulateEmail:
      'Utilizar este correo electrónico para todos los pasajeros',
    prePopulatedWhereAreYouStaying:
      'Utilizar esta dirección para todos los pasajeros',
    cmEnrollmentTitle:
      '¿Aún no eres miembro de ConnectMiles? ¡Afíliate gratis ahora y recibe 500 millas extras en este viaje!',
    cmEnrollmentParagraph:
      'Afíliate a ConnectMiles y empieza a ganar millas y beneficios hoy. Al afiliarte te enviaremos un correo electrónico a {email} para que finalices la activación de tu cuenta. Recuerda que ConnectMiles requiere que cada miembro utilice un correo electrónico único para crear su cuenta.',
    cmEnrollmentAccept:
      'Sí quiero afiliarme a ConnectMiles y acepto los {link}',
    cmEnrollmentLink:
      '{copa_url}/es-gs/legal/terminos-y-condiciones/terminos-y-condiciones-connectmiles/',
    cmEnrollmentLinkText: 'Términos y Condiciones del programa.',
    linkAgreeWcag:
      'Presiona enter para revisar los Términos y Condiciones del programa.',
    updaProfileCheckBox:
      'Guardar los datos actualizados a mi perfil de ConnectMiles',
    inTransitCheckBox: 'En tránsito',
    inTransitCheckBoxWCAG:
      'Presione para indicar que está en tránsito en los Estados Unidos',
  },
  formValidations: {
    requiredFieldError: 'Este campo es requerido',
    max20CharactersError:
      'Has entrado demasiados caracteres. El número máximo de caracteres es 20.',
    max32CharactersError: 'Máximo de 32 caracteres permitido',
    max50CharactersError: 'Máximo de 50 caracteres permitido',
    max64CharactersError: 'Máximo de 64 caracteres permitido',
    max100CharactersError:
      'Has entrado demasiados caracteres. El número máximo de caracteres es 100.',
    max255CharactersError: 'Máximo de 255 caracteres permitido',
    onlyLettersError: 'Solo se permiten letras',
    onlyNumbersError: 'Solo se permiten números',
    min3AndMax12CharactersError:
      'Mínimo de 3 y máximo de 12 caracteres permitidos',
    alphaNumericError: 'Letras y números permitidos',
    dateFormatError: 'Formato de fecha inválido',
    dateLowerThanCurrentDateError: 'Se requiere una fecha previa al día de hoy',
    dateGreaterThanCurrentDateError:
      'La fecha debe ser mayor a la fecha actual',
    dateOfBirthError: 'Ingrese una fecha válida',
    emailFormatError: 'Formato de email inválido',
    invalidFormat: 'Formato inválido',
    lengthZipCode: 'Mínimo de 5 y máximo de 10 caracteres permitidos',
    lengthAdress: 'Mínimo de 1 y máximo de 31 caracteres permitidos',
    lengthCity: 'Mínimo de 1 y máximo de 15 caracteres permitidos',
    lengthEmergencyContact: 'Máximo de 15 caracteres permitidos',
    lengthPhoneCode: 'Máximo 8 caracteres permitidos',
    lengthPhoneNumber: 'Máximo de 10 caracteres permitidos',
    ssrRepeatedValue: 'Este servicio ya ha sido añadido para este pasajero.',
  },
  dashboard: {
    pendingPayment: 'Pago pendiente',
    greetingTitle: '¡Bienvenido! Realiza Check-In para tu viaje',
    checkInCompleteTitle:
      '¡Check-In completado! Puedes generar tu pase de abordar o cambiar tu asiento',
    reviewUpcomingFlights: 'Detalles de Vuelo',
    layover: 'Escala',
    date: 'Fecha',
    flight: 'Vuelo',
    airline: 'Aerolínea',
    terminalGate: 'Terminal / Puerta',
    onTime: 'A Tiempo',
    advanced: 'Adelantado',
    delayed: 'Atrasado',
    canceled: 'Cancelado',
    duration: 'Duración',
    operatedBy: 'Operado por',
    travelerInformation: 'INFORMACIÓN DE PASAJEROS',
    seatTo: 'ASIENTO A {}',
    SeatSelectedButtonName: 'Cambiar',
    altImagen: 'Imagen seleccionar',
    NoSelectedSeatButtonName: 'Seleccionar',
    readyCheckIn: 'Listo para Check-In',
    checkedIn: 'Check-In realizado',
    failedCheckIn: 'Check-In fallido',
    edit: 'Editar',
    getBoardingPass: 'Pase de Abordar',
    selected: 'Seleccionado',
    noSelected: 'Elige tu asiento',
    infoApis:
      'Debes completar la información del pasajero para poder realizar Check-In',
    updateInformation: 'Completa Información',
    titleSegment: '{departure} A {arrival}',
    changeSeat: 'Cambiar',
    businessSeat: 'Ejecutiva',
    emergencySeat: 'Emergencia',
    seatSelected:
      'Viajero {passengerName}, tiene el asiento {seatCode} seleccionado para el vuelo {origin} y {destination}. Presione enter para cambiar de asiento.',
    seatNotSelected:
      'Pasajero {passengerName}, asiento no seleccionado para el vuelo {origin} y {destination}. Presione enter si quiere cambiar el asiento.',
    editInformation: 'Editar información',
    starAllianceMemberAltText: 'Copa es miembro de la red de Star Alliance',
    copyRight: 'Copyright © 2020 Copa Airlines. Todos los derechos reservados.',
    LAXAirport:
      'Debido a trabajos de construcción en LAX, le recomendamos que llegue al menos cuatro horas antes de la salida de su vuelo para tener suficiente tiempo para llegar a su puerta.',
  },
  travelerInfoFormAlerts: {
    pageTitle: 'Página de información de pasajeros',
    pageDescription:
      'Para completar el flujo de Check-In debed proporcionar información, algunos campos son obligatorios y otros son opcionales.',
    FFNChanged:
      'Tu información de viajero frecuente ha sido actualizada. Por favor, genera un nuevo pase de abordar para reflejar este cambio.',
    UpdateErrorFFN:
      'Hubo un error al actualizar la información de viajero frecuente para este pasajero. Intenta de nuevo.',
    UpdateError:
      'Algo falló al buscar tu reserva. Por favor, inténtalo de nuevo más tarde.',
    UpdateDefaultError:
      'Hubo un error al actualizar la información de viajero frecuente para este pasajero. Intenta de nuevo.',
    UpdateErrorAPIS:
      'Hubo un error al actualizar la información de viajero frecuente para este pasajero. Intenta de nuevo.',
    saveSuccess: 'La información del pasajero se ha guardado con éxito.',
    saveError:
      'Hubo un error al actualizar la información de viajero frecuente para este pasajero. Intenta de nuevo.',
    leaveScreenWarning:
      'No has terminado de completar todos los campos requeridos; si retrocedes, tendrás que volver a comenzar. ¿Estás seguro de que quieres regresar al itinerario?',
    repeatedPassport:
      'El número de pasaporte que ingresaste es utilizado por otro pasajero de tu grupo. Por favor ingresa un número de pasaporte único.',
    FFNMatchError:
      '¡Lo sentimos! El número de viajero frecuente que ingresaste no coincide con tu nombre. Por favor, revisa tus datos y vuelve a intentarlo.',
    FFNNotFoundError:
      '¡Lo sentimos! El número de viajero frecuente que ingresaste no coincide con nuestros registros. Por favor, revisa tus datos y vuelve a intentarlo.',
    expiredPassport:
      'Tu pasaporte ha expirado. Por favor, introduce una fecha de vencimiento válida.',
    expiredResidenceCard:
      'Tu tarjeta de residencia ha expirado. Por favor, introduce una fecha de vencimiento válida.',
    emailError:
      'Hubo un error al actualizar tu información de correo electrónico. Inténtalo de nuevo.',
    leavePageTitle: '¿Estás seguro que deseas salir de esta página?',
    leavePageMessage:
      'No has guardado la información, si vuelves a la página de itinerario perderás toda la información y tendrás que comenzar de nuevo cuando vuelvas. ¿Estás seguro que deseas salir?',
    leavePageCTA: 'Salir de esta Página',
    stayCTA: 'Permanecer en esta Página',
    leaveTraveler: '¿Estás seguro que deseas salir?',
    leavePageTravelerMessage:
      'No has guardado la información, si pasas al siguiente pasajero perderás todo la información. ¿Estás seguro que deseas continuar?',
    leaveTravelerCTA: 'Continuar',
    stayOnTravelerCTA: 'Permanecer en esta Página',
    fillOutInfantInfo:
      'Estás viajando con un infante, por favor asegúrate que su información esté completa para poder continuar.',
    fillOutAdultInfo:
      'Estás viajando con un infante, por favor llena la información de los adultos primero.',
    genericEnterError:
      'Algo falló mientras se cargó la pagina de información del pasajero. Por Favor intente de nuevo.',
    cmEnrollmentConfirmation: '¡Gracias por elegir ConnectMiles!',
    cmEnrollmentContinue: 'Continuar',
    cmEnrollmentNumber: 'Tu número de ConnectMiles es',
    cmEnrollmentEmail: 'El correo electrónico de tu cuenta es',
    cmEnrollmentInstrucction:
      'Te hemos enviado un correo electrónico con instrucciones para completar la configuración de tu nuevo perfil',
    cmEnrollmentError:
      'Hubo un error al crear tu cuenta de viajero frecuente. Intenta de nuevo.',
  },
  travelerInfoFormWCAG: {
    pageTitle: 'Pagina de información de pasajeros',
    pageDescription:
      'Para completar el flujo de Check-In debes proporcionar información, algunos campos son obligatorios y otros son opcionales. Estás en la sección de información de {name}',
    travelerName:
      'Te encuentras en la seccion de información de {name}, donde necesita proporcionar toda la información del pasaporte; Si no cumple con esto, las autoridades no pueden permitirle viajar o ingresar al área de embarque.',
    backButton:
      'Si pulsa Enter volverá al panel de inicio, para completar la información pulse Tab',
    backButtonAlt: 'Icono de volver atras',
    firstName:
      'Escriba su nombre, solo se permiten letras y espacios en blanco.',
    lastName:
      'Escriba su apellido, solo se permiten letras y espacios en blanco.',
    gender:
      'Género, utilice la tecla táctil para cambiar entre el género masculino y femenino, después presione enter o espacio.',
    passportNumber:
      'Escriba su número de pasaporte. Este es un campo alfanumérico, Mínimo 3, máximo 12 caracteres son permitidos.',
    countryOfIssue:
      'Campo de autocompletar, escriba las primeras 3 letras de su país de emisión y presiona Enter.',
    nationality:
      'Campo de autocompletar, escriba las primeras 3 letras de su país de nacimiento y presiona Enter.',
    countryOfResidence:
      'Campo de autocompletar, escriba las primeras 3 letras de su país de residencia y presiona Enter.',
    emergencyContact:
      'Contacto de emergencia de una persona que no este viajando con usted.',
    address:
      'Escriba la dirección donde va a permanecer en los Estados Unidos.',
    city: 'Escriba la ciudad de destino.',
    state: 'Campo de autocompletar, escriba las primeras 3 letras del Estado.',
    residentCardNumber:
      'Escriba su número de tarjeta de residencia. Este es un campo alfanumérico, Mínimo 3, máximo 12 caracteres son permitidos.',
    residentCardExpirationDay:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el día de expiración de su tarjeta de residencia.',
    residentCardExpirationMonth:
      'Use las  flechas hacia arriba y hacia abajo para seleccionar el mes de expiración de su tarjeta de residencia.',
    residentCardExpirationYear:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el año de expiración de su tarjeta de residencia.',
    useProfileRadioB:
      'Sección de botón de radio. Use las teclas hacia arriba y hacia abajo para cambiar entre las opciones, la opción 1 usa tu perfil para completar automáticamente la información.',
    manualInfoRadioB:
      'Sección de botón de radio. Use las teclas hacia arriba y hacia abajo para cambiar entre las opciones, Opción 2, ingrese manualmente la información.',
    radioButtonPassportOptions:
      'Sección de botón de radio. Este perfil tiene más de 1 pasaporte, usa las teclas hacia arriba y hacia abajo para seleccionar el pasaporte que deseas usar.',
    selectProfile:
      'Desplegable, use las teclas de flecha hacia arriba y hacia abajo para seleccionar el perfil para autocompletar.',
    knownTravelerNumberAdd:
      'El número de Viajero conocido, es un registro de identificación de EE. UU. Para el programa PreCheck de EE. UU. TSA, que permite a los viajeros pasar por un control de seguridad acelerado en ciertos aeropuertos de EE. UU. Presiona enter.',
    emergencyContactAdd: 'Presione para agregar contacto de emergencia ',
    emergencyContactRemove: 'Presione para ocultar el contacto de emergencia',
    enterEmergencyContact:
      'Ingrese Nombre y Apellido del contacto de emergencia',
    enterCodeEmergencyContact: 'Ingrese Código de País',
    enterPhoneEmergencyContact: 'Ingrese Número de Teléfono',
    knownTravelerNumberRemove:
      'Para eliminar o cerrar el Número de Viajero Conocido por favor presiona enter',
    knownTravelerNumber:
      'Escriba su Número de Viajero Conocido (TSA), este es un campo alfanumérico de mínimo 3 caracteres y máximo 12 caracteres',
    redressNumberAdd:
      'El número de desagravio, es un número de autorización de seguridad emitido por el Departamento de Seguridad Nacional para ciertos viajeros. Presiona enter.',
    redressNumberRemove:
      'Para eliminar o cerrar el Número de desagravio presione enter',
    redressNumber:
      'Escriba su número de desagravio, es un campo alfanumérico, se permiten un mínimo de 3 y un máximo de 12 caracteres.',
    frequentFlyerAdd:
      'Si tiene un programa de viajero frecuente puede ingresarlo. Presiona enter',
    frequentFlyerRemove:
      'Para eliminar o cerrar el número de viajero frecuente presiona enter',
    frequentFlyerNumber:
      'Escriba su número de viajero frecuente, es un campo alfanumérico .',
    frequentFlyerProgram:
      'Programa de viajero frecuente desplegable. Use las teclas de flecha hacia arriba y hacia abajo para mover y seleccionar el programa correspondiente.',
    dayOfBirth:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el dia de nacimiento del pasajero.',
    monthOfBirth:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el mes de nacimiento del pasajero.',
    yearOfBirth:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el año de nacimiento del pasajero.',
    dayOfExpiration:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el dia de vencimiento del pasaporte del pasajero.',
    monthOfExpiration:
      'Use las flechas hacia arriba y hacia abajo para seleccionar el mes de vencimiento del pasaporte del pasajero.',
    yearOfExpiration:
      'Use las flecha hacia arriba y hacia abajo para seleccionar el año de vencimiento del pasaporte del pasajero.',
    email:
      'Escriba el correo electrónico donde desea recibir su pase de abordar.',
    saveTIFButton:
      'Presione enter para guardar la información y volver al panel de control, si hay más de un pasajero, lo redireccionará al siguiente pasajero.',
    continueModal:
      'No ha completado toda la información del pasaporte; si presiona Enter perderá la información. Si hace clic en Tab, puede permanecer en esta página y completar la información.',
    stayModal:
      'Presione enter para permanecer en la página de información del viajero y completar los campos requeridos.',
    zipCode:
      'Escriba el código postal de la dirección donde va a permanecer en los Estados Unidos.',
  },
  dashboardAlerts: {
    scheduleChange:
      'Tu itinerario ha sido actualizado, revisa los nuevos detalles de tu vuelo. Disculpa los inconvenientes.',
    delay:
      'Ha habido una actualización de tu itinerario, revisa los nuevos detalles de tu vuelo. Disculpa por los inconvenientes.',
    paxINF: 'Selección de asiento no disponible para infantes.',
    paxNRS:
      'Selección de asiento no disponible para pasajeros stand-by. Tu asiento será asignado en la puerta.',
    interline:
      'Vuelo no operado por Copa Airlines. Tu asiento será asignado por la aerolínea operadora.',
  },
  connectMilesBlock: {
    message: '¡Inicia sesión para realizar Check-In más rápido!',
  },
  wcag: {
    pageTitle: 'Página principal de Web Check-In',
    pageDescription: 'Página principal de Web Check-In',
    openPanel: 'Agregar {title} sección',
    closePanel: 'Eliminar {title} sección',
    seatSelectedDashboardMessage:
      'Viajero {passengerName}, silla {seat} del vuelo {flightNumber} operado por {airlineName} desde el aeropuerto {departureAirportName} de {departureCity} al aeropuerto {arrivalAirportName} de {arrivalCity}. Presione enter para cambiar esta silla',
    seatNoSelectedDashboardMessage:
      'Viajero {passengerName}, silla no seleccionada para el vuelo {flightNumber} operado por {airlineName} desde el aeropuerto {departureAirportName}  de {departureCity} al aeropuerto {arrivalAirportName} de {arrivalCity}. Presione enter para escoger una silla',
    editTravelerInfoButtonMessage:
      'Viajero {passengerName}, Para editar la información relacionada a este y otros viajeros por favor presiona enter',
    boardingPassButtonMessage:
      'Viajero {passengerName}, para obtener un pase de abordar para este viajero por favor presiona enter',
    updateinfoButtonMessage:
      'Viajero {passengerName}, Para actualizar la información relacionada a este y otros viajeros por favor presiona enter',
    termsAndConditionsCheckboxDescription:
      'Materiales restringidos: Algunos productos cotidianos pueden ser peligrosos cuando se transportan en el avión en equipaje de mano y / o equipaje registrado. Por favor, asegúrese de que no haya prohibido mercancías peligrosas en su equipaje como: armas, baterías de litio, gases, líquidos o sólidos inflamables, venenos, y otros peligrosos. Documentos requeridos: El pasajero es responsable de todos los documentos de viaje necesarios, incluidos el pasaporte, las visas y cualquier otro documento adicional requerido por ley o reglamento en los países de destino o tránsito. Se recomienda a todos los pasajeros estar al tanto de su vacunación e historial de salud, específicamente, el Certificado Internacional de Vacunación contra la Fiebre Amarilla, si viajan a un país endémico.',
    nextSegmentsBTN: 'Siguientes segmentos',
    previousSegmentsBTN: 'Segmentos Anteriores',
    subscribeACI:
      'Usted tiene vuelos futuros que son elegibles para el Check-In Automático. Todos los pasajeros se registrarán automáticamente cuando se abra el periodo de Check-In, 24 horas antes de la salida. Presione enter si quiere suscribirse.',
    restrictedItemsLink:
      'Some everyday products can be dangerous when transported on the aircraft in carry-on and/or checked baggage. Please ensure there are no forbidden dangerous goods in your baggage like: Weapons, Batteries, Gases, Flammables liquids or solids, Powder Substances, Other Dangerous',
    segmentsmessage:
      'Vuelo {current} de {total},  Numero de Vuelo  {flightNumber} fecha {flightDate} salida desde {departureCity} aeropuerto {departureAirport} a las {departureTime} terminal {departureTerminal} Puerta {departureGate} silla: {seatCode} duración del tiempo de vuelo {duration} con llegada a {arrivalCity} aeropuerto {arrivalCityAirport}  a las {arrivalTime}  Terminal {arrivalTerminal}, puerta: {arrivalGate}. ',
  },
  seatMap: {
    norecline: 'No reclina',
    seat: 'Asiento {seatType}',
    mouseoverPrice: 'Desde',
    legendTitle: 'Leyenda',
    section: 'Sección {section}',
    descriptionTravellerList: 'Seleccione pasajero',
    yourFlight: 'Tu vuelo',
    flightNOfN: 'Vuelo {current} de {total}',
    flightTo: 'hacia {destination}',
    seatN: 'Asiento {seat}',
    noSeatSelected: 'No hay ningún asiento seleccionado',
    tapOnSeat: 'Elige quién se sienta aquí',
    tapOnSeatMobile: 'Haz clic en un asiento para seleccionarlo',
    chooseWhoSitsHere: 'Elige quién se sienta aquí',
    travelTime: 'Tiempo de viaje: ',
    notApplicable: 'N/A',
    businessClass: 'Clase Ejecutiva',
    economyClass: 'Clase Económica',
    dreamsBusinessClass: 'Clase Dreams Ejecutiva',
    backToTop: 'Volver arriba',
    restroom: 'Baños',
    galley: 'Servicio',
    seatDetails: 'Detalle del Asiento',
    extraLeg: 'Más espacio para las piernas',
    favorableLocation: 'Ubicación conveniente',
    businessSeat: 'Ejecutiva',
    unavailable: 'No disponible',
    preferMembersAlert:
      'Los PreferMembers de ConnectMiles disfrutan de preselección de asientos de forma gratuita',
    premiumSeat: 'Premium',
    convenientSeat: 'Favorable',
    regularSeat: 'Regular',
    exitRow: 'Salida de Emergencia',
    row: 'Fila {row}',
    boardingTittle: 'Abordaje',
    boardingSeatGroup: 'Abordaje {group}',
    boardingGroup: 'Grupo de Abordaje: {group}',
    pitch: 'Pitch: {pitch}',
    flatBed: 'Cama',
    seatRecline: '{recline} de Reclinación',
    readingLight: 'Luz para leer',
    copaShowPass: 'Copa Showpass',
    outlets: 'Toma corriente y USB',
    usbPort: 'Puerto USB',
    rests: 'Soporte de pies y piernas',
    sharedAcOutlet: 'Toma corriente compartida',
    electronicDeviceHolder: 'Porta Dispositivos Electrónicos',
    inFlightEntertainment: 'Entretenimiento a Bordo',
    overheadScreen: 'Pantalla general',
    dispositivoPersonal: 'Dispositivo Personal',
    onDemandScreen: 'Pantalla táctil personal',
    onDemandScreen16: 'Pantalla táctil personal de 16"',
    seatbackOnDemand: 'Pantalla en asiento',
    scroll: 'Bajar',
    exitSeat: 'Salida',
    legendPrice: 'Desde {price}',
    legendFreePrice: 'Gratis',
    addFFNModalTitle: 'Incluye tu número ConnectMiles',
    addFFNModalLabel: 'Número ConnectMiles',
    addFFNModalPlaceHolder: 'Ingresa número ConnectMiles',
    addFFNModalParagraph:
      'Los PreferMembers de ConnectMiles disfrutan de beneficios exclusivos al volar con Copa Airlines, incluyendo preselección de asientos de forma gratuita. Incluye tu número ConnectMiles ahora para recibir este beneficio.',
    previousFlight: 'Vuelo Anterior',
    nextFlight: 'Siguiente Vuelo',
  },
  seatMapButtonBar: {
    addConnetMilesNumber: 'Incluir',
  },
  seatMapWCAG: {
    seat: 'Usted se encuentra en el asiento {seat} {location} , {seatType}, {topPerk}, {norecline}, Desde: {price}. Presione enter si desea asignarle este asiento a algún pasajero.',
    pageDescription:
      'Página de selección de asientos hacia {arrival}. Puedes seleccionar asiento para todos los pasajeros en la reserva (a exepción de los infantes); recuerde la selección de asiento es por vuelo.',
    backToDashboard:
      'Botón, si pulsa Enter volverá a la página de Trip Detail.',
    passengerNumberAndSeat: 'pasajero {number}, asiento {seat}',
    passengerNumber: 'pasajero {number}',
    passengersList:
      'Seleccione a un pasajero de la lista: {passengers}. Para navegar entre pasajeros presione Tab y para seleccionar presione Enter.',
    continueButton: 'Continue button.',
    backArrowButton:
      'Botón atrás, si presiona Enter volverá a la lista de pasajeros.',
    addConnetMiles: 'Presione enter para agregar el número de ConnectMiles',
    openLegend: 'Presione enter para ver la leyenda del mapa de asientos.',
    addFFNTitleModal:
      'Agregue su número de ConnectMiles para disfrutar de la seleccioón de asientos  de forma gratuita. Puede agregar un número de viajero por cada pasajero',
    addFFNCloseModal:
      'Botón de cierre, pulse enter para cerrar la ventana emergente',
    addFFNContinueModal:
      'Presione enter para continuar con la selección de asientos',
    addFFNFieldWCAG:
      'Escriba el número de viajero frecuente de {passenger}. Recuerde que es un campo alfanumérico',
  },
  pageNotFound: {
    error: 'ERROR 404',
    description: 'Lo sentimos, esta página no pudo ser encontrada.',
    btn: 'Volver a Mis Viajes',
  },
  scrollAdvisor: {
    text: 'Desplazar',
  },
  baggageAllowance: {
    priceLockMessage:
      'Por favor, ten en cuenta que tu información de equipaje permitido estará disponible una vez completes el pago de tu reserva.',
    title: 'Equipaje Permitido',
    checkedBag: {
      plural: '{amount} Equipaje en Bodega',
      singular: '{amount} Equipajes en Bodega',
    },
    carryOnBag: {
      plural: '{amount} Equipajes de Mano',
      singular: '{amount} Equipaje de Mano',
    },
    personalItems: {
      plural: '{amount} Piezas Personales',
      singular: '{amount} Pieza Personal',
    },
    moreInfoBanner: {
      startText: 'Para más información, consulta la ',
      linkText: 'Política de Equipaje de Copa Airlines',
      endText: '.',
      link: '{copa_url}/es-gs/informacion-al-viajar/informacion-de-equipaje/',
    },
    interline: {
      startText:
        'Uno de tus vuelos no es operado por Copa Airlines. Por favor verifica nuestra referencia a las ',
      linkText: 'Políticas de Equipaje Interlínea de aerolíneas asociadas ',
      endText: ' o ponte en contacto con uno de nuestros agentes de servicio.',
      link: '{copa_url}/es-gs/descubre-copa-airlines/alianzas-con-aerolineas/acuerdos-interlinea/',
    },
    infant: {
      startText:
        'Los infantes no podrán registrar equipaje. Para más información, consulta la ',
      linkText: 'Política de Equipaje de Infantes de Copa Airlines',
      endText: '.',
      link: '{copa_url}/es-gs/informacion-al-viajar/informacion-de-equipaje/equipaje-para-infantes/',
    },
    baggageInfoNotAvailable: 'Información no disponible',
    link: 'Enlace.',
    WCAG: {
      checkedBag:
        'Tienes permitidas {amount} piezas de equipaje en bodega de {weight}{unit}',
      carryOnBag:
        'Tienes permitidas {amount} una pieza de equipaje de mano de {weight}{unit}',
      personalItem: 'Tienes permitidas {amount} piezas personales',
      openSection:
        'Ver más información sobre tu equipaje permitido para este viaje {baggage}',
      closeSection:
        'Contraer información sobre tu equipaje permitido para este viaje',
      title: 'Este es tu equipaje permitido para tu vuelo hacia',
    },
    error: {
      message:
        'No podemos conseguir tu información de equipaje permitido en este momento.',
      startText: 'Asegúrate de consultar la ',
      linkText: 'Política de Equipaje de Copa Airlines',
      link: '{copa_url}/es-gs/informacion-al-viajar/informacion-de-equipaje/',
      endText: ' para más información.',
    },
  },
  summaryDetails: {
    title: 'Resumen de Selecciones',
    subtitle: 'TUS EXTRAS DEL VIAJE',
    seats: 'ASIENTOS',
    flightDestination: '{departure} hacia {arrival}',
    flightLength: 'Vuelo {first} de {last}',
    subtotal: 'Subtotal',
    taxes: 'Impuestos',
    total: 'Total',
    freeSeat: 'Gratis',
    freeTotal: 'Selección gratuita',
    exitRow: 'Salida de Emergencia',
    convenientSeat: 'Favorable',
    premiumSeat: 'Premium',
    noSelection: 'Sin selección',
    interlineSegment:
      'Este vuelo no es operado por Copa Airlines, la selección de asientos no está disponible.',
  },
  summaryDetailsWCAG: {
    title: 'Detalles de la selección de asientos por segmento, por pasajero',
    closeModal:
      'Presiona el botón para cerrar el modal de resumen de selección de asientos',
    summaryButtonDashBoard:
      'Presiona el botón para ver los detalles de los extras de tu viaje',
  },
  sellSeatsSuccess: {
    title: 'Compra exitosa',
    titleError: 'Confirmación de compra',
    subtitle:
      'Hemos cobrado {amount}. Recibirás una confirmación al correo {email}.',
    purchaseSummary: 'Resumen de compra',
    authCode: 'Código de autorización:',
    subtotal: 'Subtotal',
    taxes: 'Impuestos',
    total: 'Total',
    back: 'Regresar a la reserva',
    alertErrorSellSeats: {
      start:
        'Procesamos tu pago con éxito, pero no se pudieron asignar tus asientos. Por favor contacta a nuestro ',
      linkText: 'Centro de Reservaciones',
      link: '{copa}/es-gs/atencion-al-cliente/centro-de-llamadas/',
      end: ' para asistencia. Disculpa las molestias.',
    },
    WCAG: {
      title: 'Confirmación de pago. Modal.',
      closeModal: 'Cerrar. Cierra el modal de confirmación de pago',
      back: 'Regresar a la reserva.',
    },
  },
  sellSeatsError: {
    title: 'No pudimos procesar tu pago',
    notice:
      'Has agotado todos los intentos para procesar el pago. Por favor, contacta a nuestro ',
    back: 'Regresar a la reserva',
  },
  seats: {
    types: {
      [SeatTypes.PREMIUM]: 'Premium',
      [SeatTypes.CONVENIENT]: 'Favorable',
      [SeatTypes.EXIT_ROW]: 'Salida de Emergencia',
      [SeatTypes.REGULAR]: 'Regular',
      [SeatTypes.ECONOMY_EXTRA]: 'Economy Extra',
      [SeatTypes.BUSINESS]: 'Ejecutiva',
      [SeatTypes.DREAM_ROW]: 'Cama',
    },
    descriptionWithType: 'Asiento {type}',
  },
  paytmentsError: {
    title: 'No pudimos procesar tu pago',
    redemptionTitle: 'No pudimos procesar tu pago',
    notice: 'Ocurrió un error a la hora de procesar tu pago',
    redemptionNotice: 'Lo sentimos, algo falló al intentar redimir tus millas.',
    message: 'Por favor inténtalo de nuevo en otro momento',
    redemptionMessage: 'Por favor, intenta nuevamente más tarde.',
    goToMyTrips: 'Regresar a Mis Viajes',
    redemptionGoToMyTrips: 'Regresar a Mis Viajes',
    WCAG: {
      title: 'No pudimos procesar tu pago',
      back: 'Boton. Regresar a Mis Viajes',
    },
  },
  invalidAccount: {
    title: 'Inicia sesión con la cuenta correcta para continuar',
    message1:
      'Hemos detectado que iniciaste sesión con una cuenta distinta a la que usaste para hacer esta reserva con millas. Por favor, inicia sesión con la cuenta correcta ',
    message2: ' para completar la compra.',
    button: 'Iniciar sesión con otra cuenta',
  },
  differentAccountRedemption: {
    title: 'Inicia sesión con la cuenta correcta para continuar',
    message1:
      'Hemos detectado que iniciaste sesión con una cuenta distinta a la que usaste para hacer esta reserva con millas. Por favor, inicia sesión con la cuenta correcta ',
    message2: ' para continuar.',
    defaultMessage:
      'Hemos detectado que iniciaste sesión con una cuenta distinta a la que usaste para hacer esta reserva con millas. Por favor, inicia sesión con el número de cuenta correcta para completar la compra.',
    CTA: 'Iniciar sesión con otra cuenta',
  },
  modalBackOrigin: {
    modalTitle: '¿Deseas salir de esta página?',
    modalBody:
      'Todavía no has completado el pago. Si retrocedes ahora, no completarás la compra y tu selección no se confirmará.',
    stayPageCTA: 'Permanecer en esta página',
    backOriginCTA: 'Salir de esta página',
    backOriginTitleWCAG:
      'Modal. ¿Seguro que quieres salir de esta página? Si retrocedes ahora, no completarás la compra y tu selección no se confirmará.',
    stayPageButonWCAG:
      'Boton, presione enter para permanecer en esta página y continuar con el pago.',
    backOriginButonWCAG: 'Boton, presione enter para salir de la página',
  },
  rebooking: {
    newSrtOnboardingModal: {
      title:
        'Hemos hecho cambios al itinerario de este viaje. Tu acción es requerida.',
      subtitle:
        'Por favor, revisa el nuevo itinerario; tendrás la opción de aceptar los cambios o modificar tus fechas de viaje.',
      step1: {
        title: 'Paso 1',
        subtitle: 'Revisa el itinerario de viaje.',
      },
      step2: {
        title: 'Paso 2',
        subtitle: 'Acepta o modifica el itinerario.',
      },
      step3: {
        title: 'Paso 3',
        subtitle: 'Continúa a Mis Viajes para confirmar.',
      },
      cta: 'Continuar',
    },
    screenTitle:
      'Acción requerida: aceptar o modificar el nuevo itinerario de viaje',
    pageDescription:
      'El itinerario de tu próximo viaje a {destination} ha sido modificado. Te hemos reacomodado a la mejor alternativa de viaje disponible. Si este nuevo itinerario se ajusta a tus planes, por favor, elige la opción “Este itinerario funciona para mi”. De lo contrario, puedes modificar tus fechas de viaje usando la opción “Modificar itinerario"',
    newItinerary: 'Nuevo itinerario',
    noChanges: 'Sin cambios',
    buttons: {
      itineraryOK: 'Este itinerario funciona para mi',
      modifyItinerary: 'Modificar itinerario',
      acceptItinerary: 'Aceptar nuevo itinerario',
      seeFlightOptions: 'Ver opciones de vuelos',
    },
    stackableAlert: {
      title:
        '¿Solicitaste algún servicio especial? Lee con detenimiento esta información.',
      description: `###### Si ya habías solicitado algún servicio especial (equipaje especial, mascota a bordo, asistencia especial o similares) deberás ponerte en contacto con nuestro {link} para trasladar estos servicios a tus nuevos vuelos.`,
      linkDescription: 'Centro de Reservaciones',
      link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
    },
    search: {
      title: 'Hacia {destination}',
      info: 'Cambiar fechas de viaje',
      datePickLabel: 'Escoge una nueva fecha para tu vuelo a {destination}',
      dateLabel: 'Fecha de salida',
      flightOptions: 'Ver opciones de vuelo',
      notModifyInfo:
        'Si no deseas modificar este vuelo, puedes continuar al siguiente.',
      notModifyFlight: 'No modificar este vuelo',
      availableFlights: 'Vuelos disponibles',
      previousDay: 'Día anterior',
      nextDay: 'Día siguiente',
      flightAssigned: 'Vuelo actual',
      error: {
        info: '¡Ups! Ocurrió un error al momento de buscar los vuelos.',
        suggestion: 'Por favor intentalo de nuevo.',
        tryAgainBtn: 'Intentar de nuevo',
      },
      notFound: {
        info: 'Ups! No se encontraron vuelos para esta fecha',
        suggestion: 'Intente seleccionar otra fecha.',
      },
      pickFlightBtn: 'Seleccionar este vuelo',
      checkinFlightChanges: {
        header: {
          title: 'Tu viaje a {destination}',
          subtitle: 'Modificar Itinerario',
        },
        title: 'Vuelos disponibles',
        subtitle:
          'Selecciona el día que deseas ver los vuelos que tenemos disponible para tu cambio.',
        error: {
          200: {
            title: '¡Ups! No se encontraron vuelos para esta fecha',
            suggestion: 'Intenta de nuevo seleccionando otra fecha',
          },
          201: {
            title: 'No hay disponibilidad de vuelos de esta fecha',
            suggestion:
              'Por favor, intenta nuevamente seleccionando otra fecha',
          },
          202: {
            title: 'No hay disponibilidad en los vuelos de esta fecha',
            suggestion:
              'Por favor, intenta nuevamente seleccionando otra fecha',
          },
          203: {
            title: 'No hay disponibilidad en los vuelos de esta fecha',
            suggestion:
              'Por favor, intenta nuevamente seleccionando otra fecha',
          },
          204: {
            title:
              'Lo sentimos, algo falló al intentar cargar los vuelos disponibles',
            suggestion: 'Por favor, inténtalo nuevamente más tarde',
          },
          tryAgainBtn: 'Actualizar',
        },
        seatmapPreviewBtn: 'Ver asientos',
        outboundFlight: 'Vuelo de ida',
        selectFlightBtn: 'Seleccionar este vuelo',
        odNonStop: 'Sin escalas',
      },
    },
    confirmationModal: {
      title: 'Su reserva ha sido confirmada.',
      description:
        'Modificamos su reserva exitosamente. Puede ver los detalles de la reserva en la próxima página',
      continueBtn: 'Continuar a mi reservación',
      segmentAlert:
        'Confirmamos algunos de los vuelos en su reservación exitosamente; sin embargo, otros vuelos requieren de atención especial de uno de nuestros agentes de servicio. Por favor, entre en contacto con nuestra central de llamadas para recibir asistencia.',
    },
    // TODO: Remove acceptItineraryModal translations on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
    acceptItineraryModal: {
      title: '¿Deseas confirmar este itinerario?',
      subtitle:
        'Al presionar “Confirmar nuevo itinerario” procederemos a cambiar tu reserva.',
      descriptionBitModal:
        'Recuerda volver a seleccionar tus asientos y cualquier otro servicio especial que hayas adquirido.',
      continueBtnOld: 'Confirmar nuevo itinerario',
      descriptionOld:
        'Al presionar “Confirmar itinerario” aceptarás el nuevo itinerario de viaje que Copa Airlines te ha asignado.',
      continueBtn: 'Continuar y seleccionar asientos',
      description:
        'Al presionar “Continuar” procederemos a cambiar tu reserva. Luego del cambio serás dirigido al mapa de asientos para seleccionar tus asientos .',
      cancelBtn: 'Cancelar',
      cancelBtnBitModal: 'Regresar',
      WCAG: {
        continueBtn: 'Presione aqui para confirmar su itinerario.',
        cancelBtn:
          'Presione aqui para cancelar y volver a la pantalla anterior.',
        title: 'Título pendiente',
      },
    },
    cancelBanner: {
      title:
        '¿Ninguna de las opciones se ajusta a tus planes de viaje? Cancela ahora y viaja después',
      description:
        'Si el itinerario propuesto, o las opciones de vuelos, no se ajustan bien a tus planes de viaje, podrás cancelar tu itinerario y mantener el valor del boleto para viajar después.  Cuando estés listo, solo debes contactarnos para elegir un nuevo itinerario.',
      cta: {
        text: 'Ver otras opciones',
        link: '{copa_url}/es-gs/atencion-al-cliente/cambios-y-reembolsos/',
      },
    },
    nonRevenue: {
      title: 'Este es tu itinerario actual',
      subtitle:
        'A través de esta página podrás modificar los vuelos de tu reserva NR.',
      pills: {
        standby: 'En lista de espera',
        confirmed: 'Confirmado',
        inTransit: 'En tránsito',
      },
    },
    ovs: {
      screenTitle:
        '¿Tienes planes flexibles? Puedes cambiar de vuelo sin costo adicional.',
      pageDescription:
        'Si estás interesado, podrás modificar tu itinerario a través de este sitio sin costo adicional.',
      btnSearch: 'Buscar vuelos',
      disclaimerText:
        'Algunas cosas que debes saber antes de modificar tu itinerario',
      disclaimerItem1:
        'Podrás cambiar las fechas de viaje hasta 14 días antes o después de tu fecha de viaje original.',
      disclaimerItem2:
        'Al cambiar vuelos deberás seleccionar asientos nuevamente. Si ya tienes asientos comprados, podrás seleccionar asientos similares en tus nuevos vuelos, según su disponibilidad.',
      disclaimerItem3: 'Solo podrás realizar cambios en tu itinerario una vez.',
      disclaimerItem4:
        'Al proceder con los cambios recuerda ajustar tus planes de viaje al nuevo itinerario que elijas.',
    },
    minor02: {
      screenTitle: 'Tu itinerario ha sido ajustado',
      pageDescription:
        'Ha sido necesario hacer cambios menores a tu itinerario con destino {destination}. Por favor, revisa el detalle de los horarios de tus vuelos.',
    },
    srtRedemption: {
      disclaimer: {
        messages:
          '-  ###### Los cambios se aplicarán a todos los pasajeros en esta reserva.\n\n-  ###### Si ya tienes asientos seleccionados, al completar el cambio deberás seleccionar tus asientos nuevamente. Podrás seleccionar asientos equivalentes en tu nuevo vuelo.\n\n- ###### Podrás hacer cambios a máximo un año de la fecha de tu compra.\n\n- ###### El cambio no modificará los beneficios y atributos originales de la tarifa (ej. franquicia de equipaje).\n\n- ###### Los adelantos de viaje tienen restricciones, por lo que algunas fechas no estarán disponibles.\n\n- ###### Tus vuelos actuales no cambiarán hasta que confirmes tu nuevo itinerario, más adelante, en esta página.',
      },
      screenTitle: 'Este es tu itinerario actual',
      pageDescription:
        'A través de esta página podrás modificar los vuelos de tu reserva.',
      disclaimerText:
        'Algunas cosas que debes saber antes de modificar tu itinerario',
      newItineraryTitle: 'Este es tu nuevo itinerario',
      newItinerarySubTitle:
        'Por favor verifica la información antes de continuar.',
      newItineraryHeader: 'Modificar itinerario',
      newItineraryCTAConfirm: 'Confirmar nuevo itinerario',
    },
    checkinFlightChanges: {
      screenTitle: 'Este es tu itinerario actual',
      pageDescription:
        'A través de esta página podrás modificar los vuelos de tu reserva que están disponibles para Check-In. ',
      disclaimerText: 'Antes de modificar el itinerario es importante saber:',
      disclaimerAllPassengers:
        'Se podrá realizar un solo cambio y aplicará a todos los pasajeros en la reserva.',
      disclaimerSelectedSeats:
        'Al completar el cambio será necesario seleccionar los asientos nuevamente. Si se tienen asientos seleccionados previamente, puede que no estén disponibles para seleccionar luego del cambio.',
      disclaimerChanges:
        'Algunos servicios especiales previamente seleccionados pueden no estar disponibles en el nuevo vuelo ya que requieren ser solicitados con anticipación.',
      disclaimerSSR:
        'Si se han adquirido servicios con terceros (ej, seguro de viaje o alquiler de carro), será necesario que el pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.',
      disclaimerAll: 'Ver todos los Términos y Condiciones.',
      disclaimerAllLink:
        '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in/',
      checkoutPage: {
        title: 'Revisa y confirma tu nuevo itinerario de viaje',
        description:
          'Elegiste un nuevo itinerario para tu próximo viaje a {destination}. Revisa con detenimiento tu itinerario antes de continuar.',
        errorToast:
          'Lo sentimos, no pudimos actualizar tu itinerario. Intentalo de nuevo.',
        pills: {
          newItinerary: 'Nuevo itinerario',
          noChanges: 'Sin cambios',
        },
        orginalItinerary: 'Ver itinerario original',
        modifyButton: 'Modificar selección',
        confirmButton: 'Confirmar nuevo itinerario',
        modal: {
          title: '¿Deseas confirmar este itinerario?',
          description1:
            'Al presionar “Confirmar nuevo itinerario” procederemos a cambiar tu reserva.',
          description2:
            'Recuerda volver a seleccionar tus asientos y cualquier otro servicio especial que hayas adquirido.',
          staroverButton: 'Regresar',
          confirmButton: 'Confirmar nuevo itinerario',
        },
      },
      confirmationPage: {
        title: 'Tu nuevo itinerario ha sido confirmado.',
        modalTitle: '¡Tu cambio ha sido confirmado!',
        description:
          'Puedes revisar los detalles de tu reserva en la página a continuación.',
        modalSubTitle:
          'Podrás revisar los detalles de tu viaje en la sección de MyTrips en Copa.com y en nuestra aplicacion móvil.\n',
        modalDescriptionOld:
          'Podrás revisar los detalles de tu viaje en la sección de MyTrips en Copa.com y en nuestra aplicacion móvil.',
        modalDescription:
          'Luego del cambio serás dirigido al mapa de asientos para seleccionar tus asientos.',
        continueButton: 'Continuar',
        modalContinueButtonOld: 'Continuar con mi reservación',
        modalContinueButton: 'Seleccionar asientos',
      },
      wcag: {
        mainPageDescription:
          'Este es tu itinerario actual. A través de esta página podrás modificar los vuelos de tu reserva que están disponibles para Check-In. ',
        searchPageMainDescription:
          'Estos son los vuelos disponibles. Deberás seleccionar el día en que te gustaría viajar para obtener los vuelos disponibles y hacer el cambio',
        flightAppliesToChange: 'Este vuelo aplica para ser modificado.',
        flightDoesNotAppliesToChange:
          'Este vuelo no aplica para ser modificado.',
        termsAndConditionLink:
          'Redirección ver todos los términos y condiciones que aplican en una nueva pestaña.',
        btnAvaliableFlights: 'Presiona para ver los vuelos disponibles',
        searchPageDescription:
          'Estos son los vuelos disponibles. Deberás seleccionar el día en que te gustaría viajar para obtener los vuelos disponibles y hacer el cambio',
        btnSelectDays: 'Presione para seleccionar esta fecha',
        btnViewSeats:
          'Se abrirá una nueva página para validar la disponibilidad de asientos en este vuelo',
        btnSelectFlight: 'Presione para seleccionar este vuelo',
        flightNewItineraryDescription: 'Este es el nuevo itinerario',
        flightNoChangesDescription:
          'Este es el Itinerario que no sufrió cambios',
        originalItineraryCollapsed:
          'Presiona para expandir la sección de tu itinerario original',
        originalItineraryExpanded:
          'Presiona para contraer la sección de tu itinerario original',
        btnModifySelection: 'Presiona si deseas modificar tu selección',
        btnConfirmItinerary: 'Presiona para confirmar tu nuevo itinerario',
        btnReturnToFlow:
          'Presiona para continuar. Serás redirijido a la página principal de {redirectionPage}',
        redirectionPage: 'Mis Viajes',
        errorReservationCenterLink:
          'Redirección para ir a la página de centro de reservaciones. La página se abrirá en una nueva pestaña',
      },
    },
    srtOVS: {
      disclaimers: {
        allPassengers:
          'El cambio aplicará a todos los pasajeros en la reserva.',
        selectedSeats:
          'Al completar el cambio en la reserva, será necesario seleccionar los asientos nuevamente. Si se tienen ' +
          'asientos seleccionados previamente, puede que no estén disponibles para seleccionar luego del cambio.',
        SSR:
          'Algunos servicios especiales previamente seleccionados puede que no estén disponibles en el nuevo vuelo ya ' +
          'que requieren ser solicitados con anticipación.',
        thirdPartiesServices:
          'Si se han adquirido servicios con terceros (ej, seguro de viaje o alquiler de carro), será necesario que el' +
          ' pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.',
      },
      termsAndConditions: {
        text: 'Ver todos los Términos y Condiciones.',
        link: '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in-ovs/',
      },
    },
    odInfo: {
      flyover: `{stopsNumber} {stopsNumber, plural,
        one { escala}
        other { escalas}
      }`,
    },
  },
  rebook: {
    header: {
      infoText: 'Tu viaje a {destination}',
      title: 'Aceptar Itinerario o Modificar Reserva',
      ovsAndNrTitle: 'Modificar Itinerario',
      minor02Title: 'Cambio de Itinerario',
      checkinFlightChangesTitle: 'Modificar Itinerario',
    },
    subHeader: 'Información Importante sobre su Viaje',
    dateTrip: 'Fechas de viaje',
    reservationCode: 'Código de reserva',
    subTitle:
      'Por favor revisa el itinerario anterior antes de elegir una de las siguientes opciones.',
    information:
      'Debido a las restricciones de vuelo y requerimientos de viaje vigentes en muchos de nuestros destinos, nos hemos visto obligados a hacer cambios en varios de nuestros itinerarios de viaje. Como resultado de esta situación, el itinerario de su próximo vuelo ha cambiado.',
    itinerary: 'Itinerario de viaje actualizado',
    itinerarySubTitle:
      'Este es su itinerario actualizado. Si es conveniente para sus planes de viaje, por favor haga clic en Aceptar este itinerario al final de la página.',
    finalNote:
      'Al hacer click en Aceptar este itinerario, confirmo que estoy de acuerdo con el cambio de itinerario propuesto por Copa Airlines. Si el itinerario propuesto no es conveniente para sus planes de viaje, por favor haga clic en Modificar reservación para buscar alternativas de viaje.',
    finalNoteNewItinerary:
      'Al hacer clic en confirmar nueva reservación, estoy de acuerdo que la reservación nueva es conveniente para mis planes de viaje.',
    modifyItinerary: 'Modificar reserva',
    acceptItinerary: 'Aceptar este itinerario',
    errorMessage:
      'Lo sentimos, no pudimos actualizar tu itinerario. Intentalo de nuevo.',
    titleNotAllowed:
      'Lo sentimos, al menos una de las condiciones de tu reserva hace que no sea elegible para realizar el cambio de tu vuelo a través de esta aplicación.',
    messageNotAllowed: {
      start:
        'Por favor, visita Mis viajes para verificar las opciones de cambios o entra en contacto con nuestro ',
      linkText: 'Centro de Reservaciones',
      link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
      end: ' para procesar el cambio.',
    },
    options: 'Ver otras opciones',
    newItineraryHeader: 'Modificar reserva',
    newIntineraryTitle: 'Revisar su selección',
    newIntinerarySubTitle:
      'Por favor verifica la información antes de continuar.',
    oldIntinerary: 'Ver itinerario anterior',
    startOver: 'Comenzar de nuevo',
    confirmItinerary: 'Confirmar nueva reservación',
    messageConfirmItinerary:
      'Hemos modificado tu itinerario de viaje con éxito.',
    messageErrorItinerary:
      'Lo sentimos, no pudimos actualizar tu itinerario. Intenta de nuevo.',
    messageItineraryNoModify:
      'No realizaste ninguna modificación a tu itinerario. Selecciona “Comenzar de nuevo” para hacer cambios o selecciona “Confirmar nuevo itinerario”  para continuar con este itinerario.',
    redemption: {
      notElegibleTitle: 'Lo sentimos, no podemos procesar tu cambio.',
      notElegibleMessage:
        'Esta es una versión limitada que no procesa cambios a menos de 7 días del vuelo. Para realizar tu cambio, por favor, contáctenos a nuestro',
      notElegibleLinkDesc: 'Centro de Servicios de ConnectMiles.',
      notElegibleLink: '{copa_url}/en-gs/connectmiles',
      notElegibleButton: 'Regresar a Mis Viajes',
    },
  },
  confirmContactInfo: {
    title: 'Déjanos saber cómo prefieres ser contactado.',
    description:
      'Al brindarnos tu información de contacto, podremos mantenerte informado sobre cambios u otras actualizaciones importantes de tu itinerario. Puedes modificar esta información cuando lo desees.',
    privacyDisclaimer:
      'Usaremos estos datos solo para contactarte en relación a este viaje.',
    modal: {
      confirmBtn: 'Confirmar información de contacto',
    },
    form: {
      currentEmail: 'Correo electrónico',
      email: {
        label: 'Correo electrónico (obligatorio)',
        placeholder: 'Ingresar un correo electrónico',
        recommendation:
          'Recomendamos utilizar una dirección de correo personal.',
      },
      phone: {
        addLabel: 'Añadir teléfono',
        label: 'Número de teléfono',
        placeholder: 'Ingresar número',
      },
      countryCode: {
        placeholder: 'Código de país',
      },
      cancelBtn: 'Cancelar',
      editEmailBtn: 'Editar correo',
    },
  },
  GDPRBanner: {
    text: 'En nuestro sitio web, copa.com, utilizamos cookies para asegurar el funcionamiento correcto de esta página y mejorar tu experiencia. Si decides continuar navegando, entendemos que aceptas la política de cookies que forma parte de nuestra ',
    link: '{copa_url}/es-gs/legal/terminos-y-condiciones/politica-de-privacidad/',
    linkText: 'Política de Privacidad.',
    ctaButton: 'Aceptar',
    ctaWCAGButton:
      'Mensaje Importante. En nuestro sitio web, copa.com, utilizamos cookies para asegurar el funcionamiento correcto de esta página y mejorar tu experiencia. Si decides continuar navegando, entendemos que aceptas la política de cookies que forma parte de nuestra Política de Privacidad. Presiona Enter para aceptar.',
  },
  directChannelAlert: {
    indirectDescription:
      'Esta reserva fue realizada a través de un sistema de reservas de terceros. Para obtener mejores precios en tarifas, asientos y/o equipaje, te invitamos a que realices tu próxima compra directamente con Copa Airlines o a través de una agencia de viajes que tenga acceso directo.',
  },
  directChannelPill: {
    directDescription: 'Compra Directa',
    indirectDescription: 'Compra Externa',
  },
  CiamLoginBox: {
    title: 'Inicia sesión para continuar.',
    description:
      'Debes iniciar sesión con la cuenta que utilizaste inicialmente para hacer esta reserva.',
    loginBtn: 'Iniciar sesión',
    wcagBtn: 'Presiona para iniciar sesión',
  },
  srt: {
    flight: {
      layover: 'Escala:',
      freeChargeLabel: 'Cambios gratis',
    },
    notEligibleError: {
      title: 'En este momento no podemos procesar tu cambio',
      subtitle:
        'Por favor, inténtalo más tarde o contacta a nuestro Centro de Reservaciones para procesar el cambio.',
      link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
      cta: 'Regresar',
    },
    calendarServiceError: {
      title: 'Lo sentimos, algo falló al intentar modificar tu itinerario.',
      subtitle:
        'Por favor, inténtalo más tarde o contacta a nuestro Centro de llamadas para procesar el cambio.',
      cta: 'Ir al Centro de llamadas',
      link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
    },
    navBackBtnWCAG: 'Presiona para volver a Mis Viajes',
    wrongAccountModal: {
      title: 'Para continuar, inicia sesión con la cuenta correcta',
      subtitle:
        'Esta cuenta es distinta a la que usaste para hacer esta reserva con millas. Por favor inicia sesión con la cuenta correcta',
      cta: 'Iniciar sesión',
    },
    availableFlightsErrors: {
      201: {
        title: 'No hay disponibilidad de vuelos de esta fecha',
        suggestion: 'Por favor, intenta nuevamente seleccionando otra fecha',
      },
      202: {
        title: 'No hay disponibilidad en los vuelos de esta fecha',
        suggestion: 'Por favor, intenta nuevamente seleccionando otra fecha',
      },
      203: {
        title: 'No hay disponibilidad en los vuelos de esta fecha',
        suggestion: 'Por favor, intenta nuevamente seleccionando otra fecha',
      },
      204: {
        title:
          'Lo sentimos, algo falló al intentar cargar los vuelos disponibles',
        suggestion: 'Por favor, inténtalo nuevamente más tarde',
      },
    },
    sc: {
      default: {
        onboardingPage: {
          navTitle: 'Modificar itinerario',
          acceptItinerary: {
            title: 'Este es tu itinerario actual',
            subtitle: `A través de esta página podrás modificar los vuelos de tu reserva.`,
            modifyBtn: 'Modificar itinerario',
            acceptBtn: 'Este itinerario me funciona',
          },
          confirmDefaultItineraryModal: {
            title: '¿Deseas confirmar este itinerario?',
            subtitle:
              'Al presionar “Continuar” procederemos a cambiar tu reserva.',
            seatmapDescription:
              'Luego del cambio serás dirigido al mapa de asientos para seleccionar tus asientos.',
            oldDescription:
              'Recuerda volver a seleccionar tus asientos y cualquier otro servicio especial que hayas adquirido.',
            seatMapContinueBtn: 'Continuar y seleccionar asientos',
            oldContinueBtn: 'Confirmar nuevo itinerario',
            cancelBtn: 'Regresar',
            WCAG: {
              continueBtn: 'Presione aqui para confirmar su itinerario.',
              cancelBtn:
                'Presione aqui para cancelar y volver a la pantalla anterior.',
              title: 'Título pendiente',
            },
          },
        },
        resultSearchPage: {
          availableFlightSection: {
            nextCTA: 'Día siguiente',
            previousCTA: 'Día anterior',
            subtitle: 'Selecciona el vuelo de tu preferencia:',
            title: 'Vuelos disponibles',
          },
          dateSection: {
            ctaText: 'Ver opciones de vuelo',
            title: 'Escoge una nueva fecha',
          },
          flightSection: {
            buttonText: 'No modificar este vuelo',
            subtitle: '{date} · Hacia {destination}',
            title: 'Buscar alternativas para este viaje',
          },
          header: {
            title: 'Modificar itinerario',
          },
        },
        checkout: {
          confirmationModal: {
            title: '¡Tu cambio ha sido confirmado!',
            subtitle:
              'Podrás revisar los detalles de tu viaje en la sección de MyTrips en Copa.com y en nuestra aplicacion móvil.\n',
            oldDescription:
              'Podrás revisar los detalles de tu viaje en la sección de MyTrips en Copa.com y en nuestra aplicacion móvil.',
            seatmapDescription:
              'A continuación, serás dirigido al mapa de asientos para seleccionar tus nuevos asientos.',
            oldContinueButton: 'Continuar con mi reservación',
            seatContinueButton: 'Seleccionar asientos',
            redirectToMyTrips: 'Ir a Mis Viajes',
          },
        },
      },
      maj01: {
        onboardingPage: {
          navTitle: 'Aceptar o modificar itinerario',
          acceptItinerary: {
            title:
              'Acción requerida: aceptar o modificar el nuevo itinerario de viaje',
            subtitle:
              'Te hemos reacomodado en la mejor alternativa de viaje disponible.',
            modifyBtn: 'Modificar itinerario',
            acceptBtn: 'Este itinerario me funciona',
          },
          collapsable: {
            title: 'Itinerario original',
            subtitle:
              'Una vez confirmado el nuevo itinerario, este ya no sera válido.',
            btnOpen: 'Ver detalles',
            btnClose: 'Ocultar detalles',
          },
        },
      },
      rdp: {
        onboardingPage: {
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Podrás cambiar las fechas de viaje hasta 24 horas antes o después de tu hora de viaje original.\n\n- ###### Al cambiar vuelos deberás seleccionar asientos nuevamente. Podrás seleccionar los mismos asientos o similares en tus nuevos vuelos, según su disponibilidad y sin costo.\n\n- ###### A través de Web Check-In (24 horas antes de la salida de tu vuelo), podrás realizar cambios a tu itinerario una vez a la ida y una vez al regreso de ser necesario.\n\n- ###### Si adquiriste algún servicio adicional (ej. seguro de viaje o alquiler de carro) por favor contacta al proveedor correspondiente para modificar el servicio.\n\n- ###### Si adquiriste algún servicio especial (ej. silla de ruedas o comida vegetariana), deberás volver a solicitarlo a través de nuestros canales.`,
          },
          notEligibleError: {
            title: 'En este momento no podemos procesar tu cambio',
            subtitle:
              'Nuestra aplicación actualmente admite cambios para viajes con fecha de salida con al menos 7 días de anticipación. Para modificar tu itinerario, por favor conmunícate con nuestro Centro de Servicios ConnectMiles.',
            link: '{copa_url}/es-gs/connectmiles/',
            cta: 'Regresar a Mis Viajes',
          },
        },
      },
      adhoc: {
        onboardingPage: {
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Se podrá realizar un solo cambio y aplicará a todos los pasajeros en la reserva.\n\n- ###### Al completar el cambio en la reserva, será necesario seleccionar los asientos nuevamente. Si se tienen asientos seleccionados previamente, puede que no estén disponibles para seleccionar luego del cambio.\n\n- ###### Algunos servicios especiales previamente seleccionados puede que no estén disponibles en el nuevo vuelo ya que requieren ser solicitados con anticipación.\n\n- ###### Si se han adquirido servicios con terceros (ej, seguro de viaje o alquiler de carro), será necesario que el pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.`,
          },
          notEligibleError: {
            title: 'En este momento no podemos procesar tu cambio',
            subtitle:
              'Nuestra aplicación actualmente admite cambios para viajes con fecha de salida con al menos 7 días de anticipación. Para modificar tu itinerario, por favor conmunícate con nuestro Centro de Servicios ConnectMiles.',
            link: '{copa_url}/es-gs/connectmiles/',
            cta: 'Go back to My Trips',
          },
        },
      },
      nr: {
        onboardingPage: {
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Podrás cambiar tu vuelo hasta 45 minutos antes de la hora de la salida original.\n\n- ###### Podrás cambiar a vuelos cuya hora de salida esté a mínimo de 45 minutos de la hora en que se efectúa el cambio.\n\n- ###### Si ya registraste equipaje en bodega, es posible que el mismo quede rezegado al hacer el cambio. En estos casos te recomendamos contactar a un agente en el aeropuerto para recibir asistencia.\n\n- ###### Es responsabilidad de cada colaborador asegurarse de que el cambio a efectuar sea el correcto y de estar presente en la puerta de abordaje con suficiente antelación.`,
          },
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Se podrá realizar un solo cambio y aplicará a todos los pasajeros en la reserva.\n\n- ###### Podrás cambiar tu vuelo hasta 14 días antes o después de tu fecha de viaje original.\n\n- ###### Al cambiar vuelos deberás seleccionar asientos nuevamente. Podrás seleccionar los mismos asientos o similares en tus nuevos vuelos, según su disponibilidad.\n\n- ###### Algunos servicios especiales previamente seleccionados puede que no estén disponibles en el nuevo vuelo ya que requieren ser solicitados con anticipación.\n\n- ###### Si se han adquirido servicios con terceros (ej, seguro de viaje o alquiler de carro), será necesario que el pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.`,
            notEligibleError: {
              title: 'En este momento no podemos procesar tu cambio',
              subtitle:
                'Debido a las condiciones de tu reserva no podemos procesar el cambio a través de nuestra aplicación. Para modificar tu itinerario, por favor conmunícate con nuestro Centro de Reservaciones',
              link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
              cta: 'Ir al Centro de Reservaciones',
            },
          },
        },
      },
    },
    wci: {
      default: {
        onboardingPage: {
          navTitle: 'Modificar itinerario',
          acceptItinerary: {
            title: 'Este es tu itinerario actual',
            subtitle: `A través de esta página podrás modificar los vuelos de tu reserva que están disponibles para Check-In.`,
            ctaBtn: 'Ver vuelos disponibles',
          },
          itineraryCard: {
            availablePill: 'Disponible',
            notAvailablePill: 'No Disponible',
          },
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Se podrá realizar un solo cambio y aplicará a todos los pasajeros en la reserva.\n\n- ###### Al completar el cambio en la reserva, será necesario seleccionar los asientos nuevamente. Si se tienen asientos seleccionados previamente, puede que no estén disponibles para seleccionar luego del cambio.\n\n- ###### Algunos servicios especiales previamente seleccionados puede que no estén disponibles en el nuevo vuelo ya que requieren ser solicitados con anticipación.\n\n- ###### Si se han adquirido servicios con terceros (ej. seguro de viaje o alquiler de carro), será necesario que el pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.`,
          },
          termsAndConditions: {
            text: 'Ver todos los Términos y Condiciones.',
            ovsLink:
              '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in-ovs/',
            cfcLink:
              '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in/',
          },
          notEligibleError: {
            title: 'En este momento no podemos procesar tu cambio',
            subtitle:
              'Debido a las condiciones de tu reserva no podemos procesar el cambio a través de nuestra aplicación. Para modificar tu itinerario, por favor conmunícate con nuestro Centro de Reservaciones',
            link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
            cta: 'Ir al Centro de Reservaciones',
          },
        },
        resultPage: {
          title: 'Selecciona vuelo de ida',
          description: '{date} · Hacia {destination}',
          searchResult: {
            title: 'Vuelos disponibles',
            description: 'Selecciona el vuelo de tu preferencia:',
          },
          selectFlightBtn: 'Seleccionar vuelo',
          viewSeatsBtn: 'Ver asientos',
        },
        checkoutPage: {
          headerTitle: 'Revisar itinerario',
          title: 'Revisa tu selección',
          subtitle:
            'Asegúrate de que tu selección sea la correcta antes de continuar',
          modifyCTA: 'Modificar selección',
          confirmCTA: 'Confirmar nuevo itinerario',
        },
        confirmationPage: {
          headerTitle: 'Página de confirmación',
          title: 'Este es tu nuevo itinerario',
          subtitle: 'Resumen de tu nuevo itinerario de viaje',
          confirmCTA: 'Ver mi reserva',
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Se podrá realizar un solo cambio y aplicará a todos los pasajeros en la reserva.\n\n- ###### Al completar el cambio en la reserva, será necesario seleccionar los asientos nuevamente. Si se tienen asientos seleccionados previamente, puede que no estén disponibles para seleccionar luego del cambio.\n\n- ###### Algunos servicios especiales previamente seleccionados puede que no estén disponibles en el nuevo vuelo ya que requieren ser solicitados con anticipación.\n\n- ###### Si se han adquirido servicios con terceros (ej. seguro de viaje o alquiler de carro), será necesario que el pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.`,
          },
          termsAndConditions: {
            text: 'Ver todos los Términos y Condiciones.',
            ovsLink:
              '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in-ovs/',
            cfcLink:
              '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in/',
          },
        },
      },
      cfc: {
        cabinLabel: 'Cabina:',
        newCabinLabel: 'Nueva cabina:',
        businessCabin: 'Ejecutiva',
        economyCabin: 'Económica',
        onboardingPage: {
          disclaimers: {
            title: 'Algunas consideraciones a tener en cuenta:',
            messages: `- ###### Se podrá realizar un solo cambio y aplicará a todos los pasajeros en la reserva.\n\n- ###### Al completar el cambio en la reserva, será necesario seleccionar los asientos nuevamente. Si se tienen asientos seleccionados previamente, puede que no estén disponibles para seleccionar luego del cambio.\n\n- ###### Algunos servicios especiales previamente seleccionados puede que no estén disponibles en el nuevo vuelo ya que requieren ser solicitados con anticipación.\n\n- ###### Si se han adquirido servicios con terceros (ej, seguro de viaje o alquiler de carro), será necesario que el pasajero coordine directamente con los proveedores la disponibilidad del servicio en el nuevo vuelo.`,
          },
          termsAndConditions: {
            text: 'Ver todos los Términos y Condiciones.',
            ovsLink:
              '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in-ovs/',
            cfcLink:
              '{copa_url}/es-gs/legal/terminos-y-condiciones/cambios-check-in/',
          },
        },
        resultSearchPage: {
          upx: {
            title: 'Vuelos disponibles en Cabina Económica',
            alert:
              'Las alternativas de vuelos, tienen disponibilidad en Cabina Económica. Al realizar el cambio, seguirás siendo considerado para un ascenso de cabina si hay espacio disponible.',
            modal: {
              title: 'Estás cambiando la cabina de tu itinerario hacia {city}',
              text: 'Como miembro del programa ConnectMiles, tienes ascensos gratuitos a Cabina Ejecutiva. Al realizar el cambio, seguirás siendo elegible para un ascenso de cabina si hay espacio disponible.',
              goBackCta: 'Regresar',
              confirmCabinChangeCTA: 'Confirmar cambio de cabina',
              wcag: {
                scrollBar: 'Desliza para continuar',
                exitBtn: 'Presiona para cerrar la ventana emergente',
                goBackCta: 'Presiona para volver atrás',
                confirmCabinChangeCTA:
                  'Presiona para confirmar tu cambio de itinerario. Serás redirigido a la siguiente página',
              },
            },
          },
        },
      },
    },
  },
  upgrade: {
    errorPage: {
      wcag: 'Estás en una página de error. Esto puede ser por algún fallo en nuestros servicios, te recomendamos intentarlo nuevamente más tarde.',
    },
    saua: {
      errorPage: {
        title: 'Tu itinerario tiene vuelos operados por otra aerolínea',
        subtitle:
          'La solicitud de ascensos para vuelos operados por otras aerolineas, no esta disponible por el momento. Si tienes vuelos operados por Copa Airlines y otras aerolíneas, podrás solicitar el ascenso únicamente para los segmentos de Copa Airlines a tráves de nuestro [Centro de Reservaciones]({copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/)',
        cta: 'Regresar',
        ctaWcag:
          'Presiona para volver atrás. Serás redireccionado a otra página',
      },
      passengerForm: {
        title: 'Pasajeros',
        paxTitle: 'Pasajeros',
        nameField: 'Nombre',
        namePlaceholder: 'Ingresa nombre',
        lastNameField: 'Apellido',
        lastNamePlaceholder: 'Ingresa apellido',
        eTicketField: 'Número de e-ticket',
        eTicketPlaceholder: 'Ingresa número de e-ticket',
        connectMilesField: 'Número ConnectMiles',
        connectMilesPlaceholder: 'Ingresa número de ConnectMiles',
        addPaxBtn: 'Agregar pasajero',
        addPaxBtnWCAG:
          'Presiona para agregar un pasajero adicional. Tendrás que completar la información de viaje de cada pasajero que agregues ',
        removePaxBtn: 'Remover',
        removePaxBtnWCAG: 'Presiona para remover a este pasajero',
        invalidFormatMessage: 'Formato inválido',
        requireMessage: 'Este campo es requerido',
        minimumCharsAllowedMessage: 'El mínimo de caracteres es de {number}',
      },
      onboarding: {
        pnrLabel: 'Código de reserva',
        title: 'Ascensos en aerolíneas de Star Alliance',
        subtiTitle:
          'Copa Airlines te permite redimir tus millas y vouchers disponibles, por un ascenso a cabina ejecutiva en la aerolínea de tu preferencia dentro de la red de Star Alliance. Para solicitar este ascenso debes llenar los siguientes datos:',
        incontentAlertText:
          '###### La solicitud de ascenso aplica para todos los pasajeros en la reserva y para un máximo de dos vuelos.',
      },
      flightsForm: {
        title: 'Información de vuelo',
        flightTitle: 'Vuelo {number}',
        originToDestination: '{origin} hacia {destination}',
        airlineLabel: 'Aerolínea',
        airlinePlaceholder: 'Selecciona la aerolínea de tu vuelo ',
        flightNumber: 'Número de vuelo',
        flightNumberPlaceholder: 'Ingresa número de vuelo',
        pnr: 'Número de reserva {airline}',
        pnrPlaceholder: 'Ingresa número de reserva',
        pnrHelperText:
          'Si no tienes el número de reserva, contacta nuestro [Centro de Llamadas]({copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/).',
        originLabel: 'Origen',
        originPlaceholder: 'Selecciona tu ciudad de origen',
        destinationLabel: 'Destino',
        destinationPlaceholder: 'Selecciona tu ciudad de destino',
        flightDate: 'Fecha de vuelo',
        flightDatePlaceholder: { day: 'DD', month: 'MM', year: 'AAAA' },
        cabin: 'Cabina',
        cabinPlaceholder: 'Selecciona la cabina de tu ascenso ',
        clearForm: 'Limpiar formulario',
        addFlight: 'Añadir otro vuelo',
        removeFlight: 'Remover',
        airportsInlineError: 'Los campos no pueden ser iguales',
        dateInlineError: 'Solo puedes seleccionar fechas futuras',
        serviceError: 'Algo falló. Intenta más tarde',
        notFoundError: 'No se encontraron resultados',
        wcag: {
          clearForm:
            'Presiona para limpiar el formulario. Esto removerá la información introducida de los vuelos',
          addFlight:
            'Presiona para agregar un vuelo adicional para al cual deseas solicitar un ascenso. Al agregar un vuelo adicional tendrás que llenar información requerida de este vuelo.',
          removeFlight:
            'Presiona para remover este vuelo de la solicitud de ascenso',
          autocomplete:
            'Debes ingresar al menos 3 letras para buscar un aeropuerto o cuidad.',
        },
      },
      generalErrorPage: {
        title: 'No podemos procesar tu solicitud',
        subtitle:
          'Estamos experimentando incovenientes en nuestros servicios que nos impiden procesar tu solicitud de ascenso. Te invitamos a intentar nuevamente más tarde o comunicarte con nuestro [Centro de Llamadas]({copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/) para solicitar tu ascenso usando tus millas o certificados.',
        cta: 'Regresar',
        ctaWcag:
          'Presiona para volver atrás. Serás redireccionado a otra página',
      },
      noMilesErrorPage: {
        title: 'No podemos procesar tu solicitud',
        subtitle:
          'Para solicitar un ascenso necesitas tener millas o certificados disponibles.',
        cta: 'Volver al inicio',
        ctaWcag:
          'Presiona para volver al inicio. Serás redireccionado a otra página',
        headerWCAG:
          'Presiona para volver al inicio. Serás redireccionado a otra página',
        pageText:
          'Estás en una página de error. Para poder solicitar un ascenso, la cuenta debe tener millas o certificados disponibles.',
      },
    },
  },
  upgradeHub: {
    header: {
      title: 'Solicitar un ascenso',
      wcagText:
        'Presiona para volver atrás. Serás redireccionado a otra página',
    },
    onboarding: {
      destinationCard: {
        flightTo: 'Hacia {destination}',
      },
      loginBanner: {
        description:
          'Accede a tu cuenta ConnectMiles si deseas solicitar un ascenso utilizando millas o certificados.',
        ctaText: 'Iniciar Sesión',
        wcagText:
          'Presiona para iniciar sesión. Serás redireccionado a otra página',
      },
      title: '¿De qué forma vas a solicitar un ascenso?',
      subtitle:
        'Copa Airlines te brinda la oportunidad de solicitar un ascenso a Clase Ejecutiva y disfrutar de sus ventajas. Puedes adquirir tu asiento pagando con dinero o utilizando tus millas o certificados disponibles.',
      plusUpgradeCard: {
        title: 'Pagar con dinero',
        subtitle:
          'Realiza una oferta con el monto que estás dispuesto a pagar para un ascenso a Clase Ejecutiva.*',
        cta: 'Seleccionar',
        wcagText:
          'Presiona para solicitar el ascenso pagando con dinero. Serás redireccionado a otra página',
        logoTinyAlt:
          'FlyUp es el programa de Copa Airlines donde puedes optar por un ascenso a clase ejecutiva y disfrutar de sus beneficios',
        wcagTextDisableCard:
          'La opción para solicitar un ascenso a clase ejecutiva pagando con dinero no está disponible.',
        ariaIcon: 'Icono de Fly Up',
      },
      upgradeHub: {
        title: 'Usar millas o vouchers de ConnectMiles',
        subtitle:
          'Adquiere tu asiento utilizando tus millas o certificados disponibles.**',
        cta: 'Seleccionar',
        wcagText:
          'Presiona para solicitar el ascenso utilizando millas o certificados.',
        logoTinyAlt:
          'Puedes optar por un ascenso a clase ejecutiva utilizando tus millas o certificados disponibles.',
        wcagTextDisableCard:
          'La opción para optar por un ascenso a clase ejecutiva utilizando tus millas o certificados no está disponible.',
      },
      disclaimer:
        '*Este programa no afecta los ascensos de cortesía concedidos a los ConnectMiles PreferMembers. Se venderán los asientos restantes, luego de conceder los ascensos de cortesía de acuerdo con las reglas del programa.',
      disclaimer2:
        '**Los certificados son exclusivos para miembros Platinum y Presidential.',
    },
    generalError: {
      plusUpgrade:
        'Lo sentimos, su reserva no es elegible para participar de este programa.',
      title: 'En este momento no podemos procesar tu solicitud',
      subTitle:
        'Debido a inconvenientes en nuestro servicio, sugerimos que vuelvas a intentarlo más tarde',
      button: 'Regresar',
    },
    selectFlight: {
      title: 'Selecciona los vuelos para los que deseas solicitar un ascenso',
      destination: {
        subtitle: 'Estas son tus opciones de vuelo.',
      },
      memberCard: {
        miles: '{miles} Millas',
        expirationDate: 'Fecha de expiración: {date}',
        certificates: '{certificates} Certificados',
        noCertificates: 'Certificados no disponibles',
        globlalPlusRegional: '({global} Global + {regional} Regional)',
      },
      alert: {
        text:
          '- ###### La solicitud de ascensos aplica para todos los pasajeros de la reserva.\n' +
          '- ###### Asientos, maletas y otros servicios adicionales previamente adquiridos no serán reembolsables',
        link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
      },
      itineraryCard: {
        available: 'Seleccionar vuelo',
        notAvailable: 'Sin disponibilidad',
        ariaLabelCheckboxActivated: 'Presiona para seleccionar este vuelo',
        ariaLabelCheckboxDisabled:
          'Este vuelo ya no cuenta con disponibilidad para ascenso',
      },
      pricesSection: {
        initialTitle:
          'Selecciona y aplica tu método de pago para ver el costo en esta sección',
        initialSubtitle:
          'Verás el costo estimado del ascenso con el método de pago aplicado',
        title: 'Resumen de compra',
        subtitle: 'Extras de viaje',
        departureCodeAndArrivalCode: 'Ascenso {departureCode} - {arrivalCode} ',
        adultPassengerCount:
          '{count, plural, one {# adulto} other {# adultos}}',
        childPassengerCount: '{count, plural, one {# niño} other {# niños}}',
        certificatesCount:
          '{count, plural, one {# certificado} other {# certificados}}',
        milesCount: '{count} millas premio',
        regionalCertificatesCount:
          '{count, plural, one {# certificado regional} other {# certificados regional}}',
        globalCertificatesCount:
          '{count, plural, one {# global} other {# globales}}',
        subtotal: 'Subtotal',
        taxes: 'Tasas e impuestos',
        total: 'Total',
        ctaText: 'Solicitar Ascenso',
        ctaWcag:
          'Presiona para solicitar el ascenso. Serás redireccionado a otra página',
      },
      paxCardText: {
        memberText: 'ConnectMiles Member',
        connectMiles: 'PreferMember {CM}',
        noFFN: 'Sin número de viajero frecuente',
      },
      passengerText: {
        title: 'Pasajeros en la reserva',
        subtitle: 'Pasajeros que serán ascendidos a Clase Ejecutiva.',
      },
      errorToast:
        'Algo falló al modificar tu selección. Por favor, intenta nuevamente',
    },
    confirmation: {
      header: 'Confirmación',
      confirmationText: {
        title: {
          successfulUpgrade:
            '¡Felicidades, has sido ascendido a Clase Ejecutiva!',
          partialUpgrade:
            'Confirmación de ascenso a Clase Ejecutiva para los siguientes vuelos:',
          failedUpgrade: 'No logramos ascenderte en los vuelos seleccionados',
        },
        subtitle: {
          successfulUpgrade:
            'Su ascenso a Clase Ejecutiva puede tardar unos minutos en reflejarse.',
          partialUpgrade:
            'Su ascenso a Clase Ejecutiva puede tardar unos minutos en reflejarse.',
          failedUpgrade:
            'Puedes intentar nuevamente más tarde o llamar a nuestro [Centro de Reservaciones.]({copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/)',
        },
        successfulUpgradeAlert:
          '###### Recuerda que debes volver a seleccionar tus asientos al completar la solicitud de ascenso.',
        successfulUpgradeAlertWCAG: 'Presiona para eliminar la alerta',
      },
      pillStatus: {
        successful: 'Ascenso recibido',
        failed: 'Sin ascenso',
      },
      partialUpgrade: {
        title: 'Algunos vuelos no fueron ascendidos',
        subtitle:
          'Tu solicitud de ascenso a clase ejecutiva no pudo ser procesada para los siguientes vuelos. Puedes volver a intentarlo más tarde o comunícate con nuestro ',
        link: '{copa_url}/es-gs/atencion-al-cliente/centro-de-llamadas/',
        linkText: 'Centro de Reservaciones.',
      },
      passengerText: {
        title: 'Pasajeros en la reserva',
        subtitle: 'Pasajeros que serán ascendidos a Clase Ejecutiva.',
      },
      paxCardText: {
        connectMiles: 'PreferMember {CM}',
        noFFN: 'Sin número de viajero frecuente',
      },
      ctaText: {
        successfulUpgrade: 'Ir a Mis Viajes',
        partialUpgrade: 'Ir a Mis Viajes',
        failedUpgrade: 'Volver al inicio',
      },
      wcag: {
        pageText:
          'Estás en una página de confirmación parcial. Esto significa que algunos de tus segmentos de viaje pudieron ser ascendidos y otros lamentablemente no. Si deseas volver a hacer la solicitud de ascenso para los segmentos que no pudieron ser ascendidos, te recomendamos intentarlo más tarde.',
        goBackText:
          'Presiona para volver atrás. Serás redireccionado a otra página',
        errorPageDescription:
          'Estás en una página de error. Esto puede ser por algún fallo en nuestros servicios, te recomendamos intentarlo nuevamente más tarde.',
        ctaText: {
          successfulUpgrade:
            'Presiona para ir a Mis Viajes y gestionar tu reserva. Serás redireccionado a otra página.',
          partialUpgrade:
            'Presiona para ir a Mis Viajes y gestionar tu reserva. Serás redireccionado a otra página.',
          failedUpgrade:
            'Presiona para volver al inicio. Serás redireccionado a otra página',
        },
      },
    },
    upgradeBox: {
      title: 'Calcular valor de tu ascenso',
      subTitle:
        'Con tus itinerarios elegidos, selecciona el método de pago con el que quieres solicitar tu ascenso:',
      fopTitle: 'Selecciona método de pago:',
      fopPlaceHolder: 'Método de pago',
      totalCostTitle: 'Costo total:',
      totalCostPlaceHolder: 'Valor en millas o certificados',
      totalCostInlineHelper: 'Cálculo según tu selección de vuelos',
      regional: 'regional,',
      global: 'global',
      miles: 'millas premio',
      errors: {
        serviceError: 'No pudimos calcular el costo total. Intenta de nuevo.',
        voucherRequired: 'Aún no has elegido certificados de ascensos',
        milesRequired: 'No tienes suficientes millas.',
        notEnoughVouchers: 'No tienes suficientes certificados.',
      },
      fopDropDown: {
        regionalVoucher: 'Certificado Regional',
        available: 'Disponible',
        globalVoucher: 'Certificado Global',
        save: 'Guardar',
        error: 'Elige un certificado de ascenso para avanzar',
        miles: 'Millas premio',
        certificates: 'Certificados',
        helperText:
          'La selección que se muestra es sugerida, pero puedes ajustarla si deseas',
      },
      cta: {
        apply: 'Aplicar',
        reset: 'Limpiar',
        vouchersToRedim: 'Certificados a redimir',
      },
      banner: {
        milesSuccess:
          'Has seleccionado millas como la forma de pago para tu ascenso a Clase Ejecutiva',
        voucherSuccess:
          'Has seleccionado certificados como forma de pago para tu ascenso a Clase Ejecutiva',
        serviceError:
          'No pudimos aplicar tu método de pago. Verifica la información e intenta nuevamente',
      },
      upgradeBoxContainerWCAG:
        'En esta sección, podrás elegir tu método de pago, calcular el costo de tu ascenso utilizando este método y aplicarlo. Al aplicar tu método de pago, aún no se realizará el débito de millas o el uso del certificado. Deberás continuar en el flujo para obtener la confirmación del ascenso.',
      certificatesNotAvailable: 'no disponibles',
    },
    saua: {
      priceSummary: {
        ascendToArrival: 'Ascenso hacia {arrivalCode}',
      },
      confirmationPage: {
        header: 'Confirmación',
        pageDescription:
          'Estás en una página de confirmación. Para conocer el resultado de tu solicitud, revisa los detalles de la confirmación. Según la disponibilidad, tu solicitud puede haber sido aprobada, parcialmente aprobada o fallida. En caso de haber sido aprobada, es posible que el ascenso tarde unos minutos en reflejarse.',
        cases: {
          success: {
            title:
              'Hemos recibido tu solicitud de ascenso para los siguientes vuelos',
            alertText:
              'Tu solicitud fue recibida con éxito. Próximamente recibirás una confirmación de ascenso a tu correo.',
            pillText: 'Ascenso recibido',
          },
          partial: {
            title:
              'Hemos recibido la solicitud de ascenso para uno de tus vuelos',
            alertText:
              'No pudimos procesar tu solicitud de ascenso a Clase Ejecutiva para uno de tus vuelos. Por favor, vuelve a intentarlo más tarde.',
          },
          error: {
            title:
              'No pudimos procesar tu solicitud de ascenso para los siguientes vuelos',
            alertText:
              'No se pudo realizar la solicitud de ascenso para tus vuelos. Por favor, inténtalo de nuevo más tarde',
            pillText: 'Sin ascenso',
          },
        },
        errorTitle: 'Razones por las cuales falló tu solicitud de ascenso:',
        errorReasons: {
          error1:
            'El número de reserva no corresponde a la aerolínea que opera el vuelo. De no contar con el número de reserva, contacte al Centro de Llamadas.',
          error2:
            'La información de vuelo no es correcta. Ej. Fecha, número de vuelo, número de reserva o cabina.',
          error3:
            'La clase solicitada no es elegible para un ascenso o ya no hay disponibilidad.',
          error4: 'Ya se realizo una solicitud de ascenso para esta reserva.',
          error5:
            'No hay suficientes millas para completar la solicitud de ascenso.',
          error6:
            'La reserva incluye servicios especiales que no pueden ser honrados al hacer un ascenso de clase. Ej. Mascota en cabina.',
          error7:
            'La fecha de salida del vuelo proporcionada está fuera de las fechas disponibles para un ascenso.',
        },
        flight: {
          title: 'Vuelo {number}',
          origin: 'Aeropuerto de origen',
          destination: 'Aeropuerto de destino',
          airline: 'Aerolínea',
          number: 'Número de vuelo',
          date: 'Fecha de vuelo',
          cabin: 'Cabina',
        },
        passenger: {
          title: 'Pasajeros para los cuales solicitaste ascenso:',
          subtitle:
            'Estos son los pasajeros que podrán disfrutar de un asiento en la cabina ejecutiva.',
        },
        paxCard: {
          title: 'Tiquete: {eticket}',
        },
        cta: 'Regresar',
        failed:
          'No pudimos procesar tu solicitud de ascenso para los siguientes vuelos:',
      },
    },
  },
}
