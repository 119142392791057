import theme from '@copa/design-system-factory.theme'
import { ColorsPillModel, StatePillModel, StateLinkModel, ColorsLinkModel } from '../types'

const PillColorsByType = new Map<StatePillModel, ColorsPillModel>([
  [
    'SUCCESS',
    {
      background: theme.palette.success.faded,
      text: theme.palette.success.main,
    },
  ],
  [
    'ERROR',
    { background: theme.palette.error.faded, text: theme.palette.error.main },
  ],
  [
    'WARNING',
    {
      background: theme.palette.warning.faded,
      text: theme.palette.grey['800'],
    },
  ],
])

const LinkColorsByType = new Map<StateLinkModel, ColorsLinkModel>([
  ['SUCCESS', {color: theme.palette.success.main}],
  ['ERROR', {color: theme.palette.error.main}]
])

const paxCardStyles = (
  ellipsis: boolean,
  isXs: boolean,
  isXsSmallXs: boolean,
  linkColor: string
) => ({
  container: {
    borderRadius: '8px',
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: isXs ? '16px' : '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  containerBody: {
    display: 'flex',
    flexDirection: {
      md: 'row',
      sm: 'column',
      xs: 'column',
    },
    gap: isXs ? '16px' : '24px',
  },
  containerData: {
    display: 'flex',
    flexDirection: isXsSmallXs ? 'column' : 'row',
    gap: '16px',
    alignItems: isXs ? 'flex-start' : 'center',
    width: '100%',
  },
  containerInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px'
  },
  containerInfoMain: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: ellipsis ? '56vw' : 'auto',
  },
  title: ellipsis
    ? {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }
    : {},
  listDescription: {
    display: 'flex',
    flexDirection: isXs ? 'column' : 'row',
    gap: '4px',
  },  
  containerLink: {
    gap: '4px',
    display: 'flex',
    flexDirection: isXsSmallXs ? 'column' : 'row',
    alignItems: 'baseline',
    height: isXsSmallXs ? 'auto' : '16px',
  },
  link: {
    height: isXsSmallXs ? 'auto' : '16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  commonLink: {
    color: linkColor,
    textDecoration: 'underline',
    fontSize: 'unset',
    cursor: 'pointer',
    display: 'flex',
    ...(isXsSmallXs && {
      lineHeight: '1rem',
      textAlign: 'left'
    })
  },
  onClickIcon: {
    cursor: 'pointer',
    '& span': {
      display: 'flex'
    }
  },
  description: {
    gap: '4px',
    display: 'flex',
  },
  containerXs: {
    gap: '8px',
    display: 'flex',
    flexDirection: 'column',
  }
})

export { paxCardStyles, PillColorsByType, LinkColorsByType }
