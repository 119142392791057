import { forwardRef, useCallback, useMemo, ReactElement } from 'react'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import SvgIcon from '@mui/material/SvgIcon'

import { SizeType, Variants } from './types'
import {
  getVariantsStyles,
  materialVariantsMap,
  getLoadingSizes,
  loadingIndicatorStyles,
} from './helpers/utils'

export type ButtonProps = {
  /**
   * Variant to be used, based on Documentation
   */
  variant?: Variants
  /**
   * Size to be used, based on Documentation
   */
  size?: SizeType
  /**
   * Render button with rounded variant
   */
  rounded?: boolean
  /**
   * Icon to render as children
   */
  icon?: ReactElement
  /**
   * Render the button in loading mode
   */
  loading?: boolean
  /**
   * Force the hover styles according to the variant used
   */
  forceHoverStyles?: boolean
  /**
   * Indicate if the button must render just an Icon and all paddings are the same.
   */
  justIcon?: boolean,
} & Omit<MuiButtonProps, 'variant' | 'size'>

export const ButtonCo = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'solidPrimaryMain',
      size = 'default',
      rounded = false,
      icon: Icon,
      sx,
      startIcon: StartIcon,
      endIcon: EndIcon,
      children,
      loading = false,
      forceHoverStyles = false,
      justIcon = false,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const { onClick, ...restProps } = props
    const loadingSize = getLoadingSizes(size)
    const variantsStyles = getVariantsStyles(
      rounded,
      size,
      forceHoverStyles,
      justIcon
    )

    const handleOnClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!loading && !!onClick) {
          onClick(e)
        }
      },
      []
    )

    const [standardIcon, standardStartIcon, standardEndIcon] = useMemo(() => {
      if (size !== 'fancy') {
        return [Icon, StartIcon, EndIcon]
      }
      const getStandardIcon = (iconElement?: ReactElement) =>
        iconElement ? (
          <SvgIcon
            style={{ width: '40px', height: '40px' }}
            {...(iconElement.props && { viewBox: iconElement.props.viewBox })}
          >
            {iconElement}
          </SvgIcon>
        ) : (
          iconElement
        )
      return [
        getStandardIcon(Icon),
        getStandardIcon(StartIcon as ReactElement),
        getStandardIcon(EndIcon as ReactElement),
      ]
    }, [Icon, StartIcon, EndIcon, size])
    const loadingIcon = (
      <CircularProgress
        variant="indeterminate"
        color="primary"
        size="25"
        sx={
          loadingIndicatorStyles[
            loadingSize as keyof typeof loadingIndicatorStyles
          ]
        }
        aria-label="loading"
      />
    )
    
    const content = loading ? loadingIcon : children

    return (
      <MuiButton
        disableRipple
        ref={ref}
        onClick={handleOnClick}
        {...restProps}
        variant={materialVariantsMap.get(variant)}
        startIcon={!loading && standardStartIcon}
        endIcon={!loading && standardEndIcon}
        sx={[variantsStyles[variant], ...(Array.isArray(sx) ? sx : [sx])]}
      >
        {standardIcon || content}
      </MuiButton>
    )
  }
)
