import theme from '@copa/design-system-factory.theme'
import { Breakpoints, getSpacing } from '../../../../../../utils/spacing'

export const useStyles = (breakpoints: Breakpoints) => ({
  container: {
    marginBottom: getSpacing(breakpoints)?.spacious,
  },
  paperBackground: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    paddingTop: getSpacing(breakpoints)?.roomy,
    paddingBottom: {
      xs: getSpacing(breakpoints)?.roomy,
      md: getSpacing(breakpoints)?.spacious,
    },
  },
  currentFlightContainer: {
    marginTop: getSpacing(breakpoints)?.normal,
  },
})
