import React from 'react'
import { FormattedMessage } from 'react-intl'
import InputCO from '../input/textInput/inputCO'
import InputCounter from '../input/inputCounter/inputCounter'
import ErrorMessage from './errorMessage'
import { onlyLettersAllowed } from '../../../utils/utils'
import './editModalChildren.css'

class editModalChildren extends React.Component {
  state = {
    inputValue: '',
  }

  render() {
    const { formatMessage, values, onBlur, onChange, tripAliasValue } =
      this.props

    return (
      <div className="edit-modal__modal-content">
        <div className="edit-modal__modal-content-column edit-modal__modal-content-column-left">
          <p
            className="edit-modal__modal-trip-title"
            id="editModalTripSelected"
          >
            {tripAliasValue}
          </p>
          <h2
            className="edit-modal__modal-content-left-text"
            id="editModalTitle"
          >
            <FormattedMessage id="editModal.renameTrip" />
          </h2>
        </div>
        <div className="edit-modal__modal-content-column edit-modal__modal-content-column-right">
          <div className="edit-modal__input-wrap">
            <InputCO
              type="text"
              size="fullwidth"
              id="tripAlias"
              name="tripAlias"
              value={values.tripAlias}
              defaultValue={values.tripAlias}
              required={false}
              extraClass="edit-modal__input-text"
              label={formatMessage({
                id: 'editModal.tripName',
              })}
              onBlur={onBlur}
              onChange={onChange}
              maxlength={50}
              getInputValue={event => {
                this.setState({ inputValue: event.target.value })
              }}
              invalidCharacter={!onlyLettersAllowed(values.tripAlias)}
            />
            <div className="edit-modal__input-wrapper-info">
              <InputCounter
                maxlength={50}
                inputCharCounter={values.tripAlias.length}
                color={onlyLettersAllowed(values.tripAlias) ? 'gray' : 'error'}
              />
              {!onlyLettersAllowed(values.tripAlias) && (
                <ErrorMessage
                  message={formatMessage({ id: 'editModal.editModalError' })}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default editModalChildren
