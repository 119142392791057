import theme from '@copa/design-system-factory.theme'
import { Breakpoints, getSpacing } from '../../../../../../../../utils/spacing'

const useStyles = (breakpoints: Breakpoints) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    justifyContent: 'end',
    margin: `${getSpacing(breakpoints)?.spacious} 0`,
  },
  stickyContainer: {
    backgroundColor: theme.palette.grey['50'],
    position: 'sticky',
    bottom: 0,
    zIndex: 1000,
    padding: 2,
    boxShadow: 3,
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.grey['200']}`,
  },
  button: {
    width: {
      xs: '100%',
      sm: '75%',
      md: '31.5%',
    },
  },
})

export default useStyles
