import { Theme } from '@mui/system'
import { StylesType } from './types'

export const dividerStyles = (theme: Theme): StylesType => ({
  root: {
    width: '100%',
    height: '100%',
    '& .MuiDivider-flexItem': {
      width: 1,
      height: 'inherit',
    },
  },
  lightSolid: {
    backgroundColor: theme.palette.grey[300],
    height: 1,
    opacity: 0.5,
  },
  darkSolid: {
    backgroundColor: theme.palette.common.white,
    opacity: '32%',
  },
  lightDashed: {
    height: 1,
    opacity: 1,
    border: '1px dashed',
    borderColor: theme.palette.grey[300],
  },
  darkDashed: {
    height: 1,
    border: '1px dashed',
    borderColor: theme.palette.common.white,
  },
  lightWithOpSolid: {
    backgroundColor: theme.palette.grey[200],
    height: 1,
  },
})
