import React from 'react'

export const ArrowRight = ({ props }) => (
  <svg
    {...props}
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.8 0.7L9.6 4.5H0.5V5.5H9.6L5.8 9.3L6.5 10L11.5 5L6.5 0L5.8 0.7Z"
      fill="white"
    />
  </svg>
)
