import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import React from 'react'
import theme from '@copa/design-system-factory.theme'
import useBitBreakPoints from '../../../../../hooks/material/useBitBreakPoints'
import useStyles from './styles'

interface FlightDetailItemProps {
  label: string
  value: string
}

export default function FlightDetailItem({
  label,
  value,
}: FlightDetailItemProps) {
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles({ theme, breakpoints })

  return (
    <Box sx={classes.item}>
      <Typography color="grey.600" variant="body2">
        {label}
      </Typography>
      <Typography color="grey.700" variant="caption">
        {value}
      </Typography>
    </Box>
  )
}
