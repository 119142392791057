import { spacing } from '../../../../../../utils/spacing'

export const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    marginBottom: spacing.tiny,
  },
})
