import React from 'react'
import './editModalChildren.css'

const ErrorMessage = ({ message }) => (
  <span id="edit-modal__error-message" className="edit-modal__error-message">
    {message}
  </span>
)

export default ErrorMessage
