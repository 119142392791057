import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { redirecToSauaGeneralErrorPage } from '../utils'
import {
  useGetAirlinePartnersMutation,
  useGetCabinClassesMutation,
} from '../../../api/sauaSlice'
import { UpgradeCacheKey, UpgradeOrigin } from '../types'
import { QueryParams } from '../../../types/QueryParams'
import { isFetchingGeneral } from '../../../actions/generalSpinner'

type UseSauaDictionariesReturn = {
  getDictionaries: () => void
  isDictionariesLoading: boolean
  hasDictionaries: boolean
}

type UseSauaDictionariesParams = {
  origin: UpgradeOrigin | string
  useSpinner?: boolean
}

export default function useSauaDictionaries({
  origin,
  useSpinner = true,
}: UseSauaDictionariesParams): UseSauaDictionariesReturn {
  const history = useHistory()
  const { pnr, surname, language } = useParams<QueryParams>()
  const dispatch = useDispatch()

  const [
    getAirlinePartners,
    {
      data: airlines,
      isError: isAirlinesError,
      isLoading: isAirlinesLoading,
      isUninitialized: isAirlinesUninitialized,
    },
  ] = useGetAirlinePartnersMutation({
    fixedCacheKey: UpgradeCacheKey.AIRLINES,
  })

  const [
    getCabinClasses,
    {
      data: cabiClasses,
      isError: isCabinClassesError,
      isLoading: isCabinClassesLoading,
      isUninitialized: isCabinClasessUninitialized,
    },
  ] = useGetCabinClassesMutation({
    fixedCacheKey: UpgradeCacheKey.CABIN_CLASSES,
  })

  const hasDictionaries = useMemo(
    () => !!airlines && !!cabiClasses,
    [airlines, cabiClasses]
  )

  const hasUninitializedDictionaries = useMemo(
    () => isCabinClasessUninitialized && isAirlinesUninitialized,
    [isAirlinesUninitialized, isCabinClasessUninitialized]
  )

  const isDictionariesLoading = useMemo(
    () => isAirlinesLoading || isCabinClassesLoading,
    [isAirlinesLoading, isCabinClassesLoading]
  )

  const getDictionaries = useCallback(() => {
    if (!hasDictionaries && hasUninitializedDictionaries) {
      getAirlinePartners({})
      getCabinClasses({})
    }
  }, [
    getAirlinePartners,
    getCabinClasses,
    hasDictionaries,
    hasUninitializedDictionaries,
  ])

  useEffect(() => {
    if (useSpinner) {
      dispatch(isFetchingGeneral(isDictionariesLoading))
    }
  }, [useSpinner, dispatch, isDictionariesLoading])

  useEffect(() => {
    const hasAnyError = isAirlinesError || isCabinClassesError

    if (hasAnyError) {
      redirecToSauaGeneralErrorPage({ history, pnr, surname, language, origin })
    }
  }, [
    history,
    isAirlinesError,
    isCabinClassesError,
    language,
    origin,
    pnr,
    surname,
  ])

  return {
    getDictionaries,
    isDictionariesLoading,
    hasDictionaries,
  }
}
