import React, { Component } from 'react'
import { compose, setPropTypes } from 'react-recompose'
import PropTypes from 'prop-types'

// @Utils
import { getActiveElements, getArrowBehavior } from '../../../utils/dropDownCo'

class DropDownCO extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventDispached: '',
    }
  }

  arrowBehavior = (element, index, list) =>
    getArrowBehavior(element, index, list)
  checkActiveElement = list => getActiveElements(list)
  onCloseBehavior = (e, i, list) => {
    if (this.arrowBehavior(e, i, list)) return null
    if ((e && e.type === 'mousedown') || e.keyCode === 13) {
      if (e.type === 'mousedown') this.setState({ eventDispached: e.type })
      if (e.type === 'keydown')
        this.setState({ eventDispached: e.keyCode.toString() })
      return this.props.onChangeLang(e)
    }
    if (
      e.type === 'blur' &&
      !this.checkActiveElement(list) &&
      this.state.eventDispached !== 'mousedown' &&
      this.state.eventDispached !== '13'
    ) {
      this.setState({ eventDispached: e.type })
      return this.props.onClose()
    }
    return null
  }
  setArrowNavigation = list => {
    const { lang } = this.props
    for (let i = 0; i < list.length; i++) {
      list[i].addEventListener('keydown', e => this.onCloseBehavior(e, i, list))
      list[i].addEventListener('blur', e => this.onCloseBehavior(e, i, list))
      list[i].addEventListener('mousedown', e =>
        this.onCloseBehavior(e, i, list)
      )
      if (list[i].value === lang) {
        setTimeout(() => list[i].focus(), 0)
      }
    }
  }
  scrollBehavior = event => {
    if ([40, 38].some(e => event.keyCode === e)) event.preventDefault()
  }
  getLisElementsByInteractiveElement = list => {
    const { interactiveElement } = this.props
    if (!interactiveElement || interactiveElement === '')
      return list.getElementsByTagName('button')
    return list.getElementsByTagName(interactiveElement)
  }
  setListBehavior = list => {
    let listElements = []
    if (!list) return false
    listElements = this.getLisElementsByInteractiveElement(list)
    if (!listElements || (listElements && listElements.length === 0))
      return false
    this.setArrowNavigation(listElements)
    window.addEventListener('keydown', this.scrollBehavior)
    return true
  }
  displayDropDownBehavior = () => {
    const { dropDownContainer } = this.props
    if (dropDownContainer && dropDownContainer.current) {
      this.setState({ eventDispached: '' })
      this.setListBehavior(
        dropDownContainer.current.getElementsByTagName('ul')[0]
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { showDropDown, dropDownButton } = this.props
    if (showDropDown && !prevProps.showDropDown) {
      this.displayDropDownBehavior()
    }
    if (!showDropDown && prevProps.showDropDown) {
      if (dropDownButton && dropDownButton.current) {
        setTimeout(() => dropDownButton.current.focus(), 0)
      }
      window.removeEventListener('keydown', this.scrollBehavior)
    }
  }

  render() {
    const { wcagObject } = this.props
    return (
      <div>
        {this.props.children}
        {wcagObject && (
          <div
            id={wcagObject.id}
            className="select-container__wcag-description"
          >
            {wcagObject.message}
          </div>
        )}
      </div>
    )
  }
}

const _propTypes = {
  dropDownButton: PropTypes.objectOf(PropTypes.element),
  dropDownContainer: PropTypes.objectOf(PropTypes.element),
  showDropDown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeLang: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  wcagObject: PropTypes.object,
  interactiveElement: PropTypes.string,
}

const setPropTypesEnhance = setPropTypes(_propTypes)

export default compose(setPropTypesEnhance)(DropDownCO)
