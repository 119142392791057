import React, { useEffect, useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Layout } from '@copa/design-system-factory.layout'
import { Interstitial } from '@copa/design-system-factory.loaders.interstitial-loading'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { isEmpty } from 'rambda'
import {
  analyticsPageViewEvent,
  analyticsModifyItinerarySrtEvent,
} from '../../../../../../actions/analytics'
import OriginalItinerary from '../../../../components/OriginalItinerary/OriginalItinerary'
import AcceptItinerary from './components/acceptItinerary/AcceptItinerary'
import { createItineraryCards } from '../../../../utils/flights'
import useStyles from './styles'
import ModalSrt from '../../../../components/ModalSrt/ModalSrt'
import { HeaderNavSrt } from '../../../../components/HeaderNavSrt'
import { Flight } from '../../../../interfaces/flight.interface'
import {
  rebookingAcceptItineraryRequest,
  rebookingCalendarReset,
} from '../../../../../../actions/rebooking'
import { generateModifyPayload } from '../../../../../../utils/rebooking/acceptItinerary'
import { showErrorToast } from '../../../../../../utils/toastAlert'
import { notFlownLegs } from '../../../../../../selectors/destination'
import SRT_MODAL_VARIANTS from '../../../../components/ModalSrt/utils/variants'
import { REBOOKING_PAGE } from '../../../../../../constants/analytics'
import { redirectToTripDetail } from '../../../../utils/redirect'
import {
  handleNotMatchingAccount,
  handleUnauthenticatedUser,
  logoutAndRedirectToLogin,
  shouldShowContent,
} from './utils/utils'
import { useAuthentication } from '../../../../../../hooks/auth/useAuthentication'
import useBitBreakPoints from '../../../../../../hooks/material/useBitBreakPoints'
import ModifyItineraryBtn from './components/modifyItineraryBtn/ModifyItineraryBtn'
import { NotEligibleError } from './components/notEligibleError/NotEligibleError'
import { OnboardingPageProps } from '../../../../utils/types'
import DestinationCard from '../../../../components/destinationCard'
import { MyTripsState } from '../../../../../../types/state'
import getRebookingCalendar from '../../../../utils/requests/rebokingCalendar'
import { SrtPages } from '../../../../hook/navigate/types'
import useSrtNavigate from '../../../../hook/navigate/useSrtNavigate'
import { Disclaimers } from '../../../../../../components/commons/Disclaimers/Disclaimers'
import {
  FeatureFlag,
  getFeatureFlag,
} from '../../../../../../constants/featureFlags'
import { checkTypeModify } from '../../../../utils/utils'
import { templates } from '../../templates/templates.enum'
import useRedirectToSeatMap from '../../../../../../hooks/seatMap/useRedirectToSeatMap'
import env from '../../../../../../constants/env'
import { ScheduleChangeTemplate } from '../../templates/interfaces/schedule-change.template'

function OnboardingPageSC({
  template,
  isEligibleToRebook,
  flow,
  showInterstitial = false,
}: OnboardingPageProps<ScheduleChangeTemplate>) {
  const flight = useSelector((state: MyTripsState) => state.flight)
  const {
    modifyItinerary,
    srtCalendar: { ods: rebookingCalendarODs, error: rebookingCalendarError },
  } = useSelector((state: MyTripsState) => state.rebooking)
  const { redirectTo, pushTo } = useSrtNavigate({})
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  // @ts-ignore
  const { pnr, surname } = useParams()
  const history = useHistory()
  const auth = useAuthentication()
  const { isMediumOrLarge, isXsOrSmall, breakpoints } = useBitBreakPoints()
  const { selectSeatsForNextFlight } = useRedirectToSeatMap()

  const { name: typeModify, onboarding } = template
  const [showOnboardingModal, setShowOnboardingModal] = useState(
    onboarding.modal.display
  )
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showWrongAccountModal, setWrongAccountModal] = useState(false)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  const classes = useStyles(breakpoints)

  const shouldRedirectSeatMap = useMemo(
    () =>
      getFeatureFlag(FeatureFlag.SRT_SHOULD_REDIRECT_TO_SEATMAP) &&
      checkTypeModify(typeModify, [templates.MAJ01, templates.MAJ02]),
    [typeModify]
  )

  useEffect(() => {
    setShowOnboardingModal(onboarding.modal.display)
  }, [template, onboarding])

  useEffect(() => {
    handleUnauthenticatedUser({
      template: onboarding,
      pnr,
      surname,
      history,
      auth,
    })
    // TODO: There is an issue with useAuthentication bc it modifies response for every render
    // which leads to a infinite loop in every hook that use its output as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, history])

  useEffect(() => {
    if (flight.pnr && !showWrongAccountModal) {
      dispatch(analyticsPageViewEvent(REBOOKING_PAGE))

      handleNotMatchingAccount({
        flight,
        dispatch,
        template: onboarding,
        showModal: () => setWrongAccountModal(!showWrongAccountModal),
      })
    }

    if (flight.error) {
      showErrorToast(formatMessage({ id: 'rebook.errorMessage' }))
      history.push(`/`)
    }
  }, [
    flight,
    template,
    dispatch,
    formatMessage,
    history,
    onboarding,
    showOnboardingModal,
    showWrongAccountModal,
  ])

  useEffect(() => {
    if (modifyItinerary.acceptedItinerary) {
      if (shouldRedirectSeatMap) {
        selectSeatsForNextFlight({
          redirectLocation: `${env.REACT_APP_PUBLIC_URL}/trip-detail/${pnr}/${surname}`,
        })
      } else {
        redirectToTripDetail({ pnr, surname, history })
      }
    }
  }, [
    shouldRedirectSeatMap,
    modifyItinerary.acceptedItinerary,
    pnr,
    surname,
    history,
    selectSeatsForNextFlight,
  ])

  useEffect(() => {
    if (modifyItinerary.error) {
      showErrorToast(formatMessage({ id: 'rebook.messageErrorItinerary' }))
    }
  }, [formatMessage, modifyItinerary.error])

  useEffect(() => {
    rebookingCalendarReset()
  }, [])

  useEffect(() => {
    if (!isEmpty(rebookingCalendarODs) && shouldRedirect) {
      pushTo(SrtPages.RESULT_SEARCH, {
        flight,
        flow,
        templateName: template.name,
        odIndex: 0,
        rebookingCalendarODs,
        selectedOds: [],
      })
    }
  }, [
    rebookingCalendarODs,
    shouldRedirect,
    flight,
    flow,
    pushTo,
    template.name,
  ])

  useEffect(() => {
    if (rebookingCalendarError) {
      redirectTo(SrtPages.ERROR)
    }
  }, [rebookingCalendarError, redirectTo])

  const acceptThisItinerary = (acceptedFlight: Flight) => {
    const legs = notFlownLegs({ flight: acceptedFlight })
    const { rebooking, revenue, multipleWKSegments } = acceptedFlight

    setShowConfirmationModal(!showConfirmationModal)

    dispatch(
      rebookingAcceptItineraryRequest(
        generateModifyPayload(
          pnr,
          surname,
          legs,
          legs,
          revenue,
          rebooking,
          multipleWKSegments,
          '',
          template.name
        )
      )
    )
  }

  const goToResultSearch = useCallback(() => {
    setShouldRedirect(true)
    dispatch(analyticsModifyItinerarySrtEvent(template.name))
    getRebookingCalendar({ flight, dispatch, typeModify, pnr })
  }, [flight, dispatch, typeModify, setShouldRedirect, template, pnr])

  if (showInterstitial) {
    return (
      <Interstitial
        aria-busy={showInterstitial}
        role="status"
        data-testid="srt-initial-interstitial"
      />
    )
  }
  return (
    shouldShowContent({
      template,
      // @ts-ignore
      isLoggedIn: auth.isLoggedIn,
    }) && (
      <>
        <HeaderNavSrt template={onboarding.header} />
        {isEligibleToRebook ? (
          <>
            <Layout>
              <Box sx={classes.destinationCardContainer}>
                {/* @ts-ignore */}
                <DestinationCard
                  template={onboarding.destinationCard}
                  flight={flight}
                />
              </Box>

              <Box>
                <AcceptItinerary
                  template={onboarding.acceptItinerary}
                  suggestedItinerary={flight}
                  onModifyItinerary={() => goToResultSearch()}
                  onAcceptSuggestedItinerary={() =>
                    setShowConfirmationModal(!showConfirmationModal)
                  }
                  typeModify={template.name}
                  flow={flow}
                />
              </Box>

              <Box>
                <Disclaimers
                  template={{
                    ...onboarding.disclaimers,
                    subtitle: onboarding.disclaimers.title,
                  }}
                />
              </Box>

              {isMediumOrLarge && (
                <ModifyItineraryBtn
                  template={onboarding}
                  onClick={goToResultSearch}
                />
              )}
            </Layout>

            <Box sx={classes.originalItineraryContainer}>
              {/* @ts-ignore */}
              <OriginalItinerary template={onboarding.oldItinerary}>
                <Box sx={classes.itinerariesContainer}>
                  {createItineraryCards(flight, {
                    shouldShowExtraInfo: false,
                    formatMessage,
                    flow,
                  })}
                </Box>
              </OriginalItinerary>
            </Box>

            {isXsOrSmall && (
              <ModifyItineraryBtn
                sticky
                template={onboarding}
                onClick={goToResultSearch}
              />
            )}

            <ModalSrt
              variant={SRT_MODAL_VARIANTS.ONBOARDING}
              shouldOpen={showOnboardingModal}
              setShowModal={setShowOnboardingModal}
              primaryAction={() => setShowOnboardingModal(!showOnboardingModal)}
            />
            <ModalSrt
              variant={SRT_MODAL_VARIANTS.CONFIRM_ITINERARY}
              shouldOpen={showConfirmationModal}
              setShowModal={setShowConfirmationModal}
              primaryAction={() => acceptThisItinerary(flight)}
              secondaryAction={() =>
                setShowConfirmationModal(!showConfirmationModal)
              }
              shouldRedirectSeatMap={shouldRedirectSeatMap}
            />
            <ModalSrt
              variant={SRT_MODAL_VARIANTS.WRONG_ACCOUNT}
              shouldOpen={showWrongAccountModal}
              setShowModal={() =>
                redirectToTripDetail({ pnr, surname, history })
              }
              primaryAction={() => {
                setWrongAccountModal(false)
                logoutAndRedirectToLogin({ auth, dispatch, history })
              }}
              ownerFFN={flight?.redemption?.ownerFFN}
            />
          </>
        ) : (
          <NotEligibleError template={onboarding} />
        )}
      </>
    )
  )
}

export default OnboardingPageSC
