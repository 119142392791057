import { HeaderBarNavigation } from '@copa/design-system-factory.header-bar-navigation'
import { Layout } from '@copa/design-system-factory.layout'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Link } from '@mui/material'
import { ItineraryCard } from '@copa/design-system-factory.itinerary-card'
import { Typography } from '@copa/design-system-factory.typography'
import { ButtonCo } from '@copa/design-system-factory.button'
import { PaxCard } from '@copa/design-system-factory.cards.pax-card'
import { useIntl } from 'react-intl'
import { ExtraInfoOptions } from '@copa/design-system-factory.itinerary-card/dist/types'
import theme from '@copa/design-system-factory.theme'
import { TitleCombination } from '@copa/design-system-factory.title-combination'
import { useHistory, useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { pageViewEventUpgraHub, upgradeHubGoMT } from 'src/actions/analytics'
import { DataUpgradeBoxModel } from '@copa/design-system-factory.boxes.upgrade-box/types'
import { AlertBanner } from '@copa/design-system-factory.alert-banner'
import DestinationCard from '../../../srt/components/destinationCard/DestinationCard'
import useBitBreakPoints from '../../../../hooks/material/useBitBreakPoints'
import useStyles from './styles'
import { FlightStatus } from '../../../srt/utils/types'
import { useLocaleLanguage } from '../../../../hooks/useLocaleLanguage'
import { UpgradeInfo } from '../types'
import { UpgradeCacheKey } from '../../types'
import {
  UpgradeConfirmationResponseFlight,
  useGetUpgradeConfirmationMutation,
} from '../../../../api/upgradeHubSlice'
import {
  getPassengerDescription,
  getUniquePassengers,
  isLoyaltyAvailable,
} from '../../utils'
import { Leg, OD, Passenger } from '../../../srt/interfaces/flight.interface'
import { capitalizeFirstLetter } from '../../../srt/utils/flights'
import FailedUpgradeErrorIcon from '../../../../assets/images/failed-upgrade-error-icon.svg'
import { useGoBackWithOrigin } from '../../hooks/useGoBackToOrigin'
import { loadTripFlightToODAndSegments } from '../../../../utils/utils'
import { QueryParams } from '../../../../types/QueryParams'
import { formatSegmentToItineraryFlight } from '../select-flight/SelectFlightUPGH'

type UpgradeHubConfirmationProp = {
  upgradeInfo: Partial<UpgradeInfo>
  origin?: string | null
  upgradeBoxResponse?: DataUpgradeBoxModel
}

type UpghConfirmationPageItinerary = {
  od: OD
  segment: Leg
}

const getItinerariesByUpgradesConfirmation = (
  upgradesConfirmation: UpgradeConfirmationResponseFlight,
  ods: OD[]
): any =>
  upgradesConfirmation?.map(fight => {
    const matchingOd = ods.find(od =>
      od.segments.some(seg => seg.flightKey === fight.key)
    )

    return {
      od: matchingOd,
      segment: matchingOd?.segments.filter(
        seg => seg.flightKey === fight.key
      )[0],
    }
  })

function UpgradeHubConfirmation({
  upgradeInfo: { tripInfo, profileExtendInfo },
  origin,
  upgradeBoxResponse,
}: UpgradeHubConfirmationProp) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  const history = useHistory()
  const { pnr, surname } = useParams<QueryParams>()
  const redirectToOrigin = useGoBackWithOrigin()
  const [scenarioEvent, setScenarioEvent] = useState('')
  useLocaleLanguage()

  const [
    __,
    { data: upgradeConfirmation, isError: isErrorUpgradeConfirmation },
  ] = useGetUpgradeConfirmationMutation({
    fixedCacheKey: UpgradeCacheKey.CONFIRM_UPGRADE,
  })

  const [upgradedItineraries, setUpgradedItineraries] = useState<
  UpghConfirmationPageItinerary[]
  >([])
  const [failedUpgradeItineraries, setFailedUpgradeItineraries] = useState<
  UpghConfirmationPageItinerary[]
  >([])
  const [passengers, setPassengers] = useState<Passenger[]>([])

  const isSuccessfulUpgrade = useMemo(
    () => upgradeConfirmation?.flights.every(f => f.isUpgraded),
    [upgradeConfirmation]
  )

  const isFailUpgrade = useMemo(
    () => upgradeConfirmation?.flights.every(f => !f.isUpgraded),
    [upgradeConfirmation]
  )

  const isPartialUpgrade = useMemo(() => {
    let result = false

    if (upgradeConfirmation) {
      const upgradedFlights = upgradeConfirmation?.flights.filter(
        f => f.isUpgraded
      )
      result =
        upgradedFlights.length > 0 &&
        upgradedFlights.length < upgradeConfirmation.flights.length
    }

    return result
  }, [upgradeConfirmation])

  const statusByConfirmationRequest = useMemo(() => {
    let result = ''

    if (isSuccessfulUpgrade) {
      result = 'successfulUpgrade'
      setScenarioEvent('Success')
    } else if (isPartialUpgrade) {
      result = 'partialUpgrade'
      setScenarioEvent('Partial-Success')
    } else if (isFailUpgrade) {
      result = 'failedUpgrade'
      setScenarioEvent('No-Itineraries-Upgraded')
    }

    return result
  }, [isSuccessfulUpgrade, isPartialUpgrade, isFailUpgrade])

  const getItinerariesByUpgradeStatus = useCallback(() => {
    if (tripInfo) {
      const ods = loadTripFlightToODAndSegments(tripInfo.flights)

      const successfullyUpgradedFlights: UpgradeConfirmationResponseFlight =
        upgradeConfirmation?.flights.filter(f => f.isUpgraded)

      const failedUpgradeFlights: UpgradeConfirmationResponseFlight =
        upgradeConfirmation?.flights.filter(f => !f.isUpgraded)

      setUpgradedItineraries(
        getItinerariesByUpgradesConfirmation(successfullyUpgradedFlights, ods)
      )

      setFailedUpgradeItineraries(
        getItinerariesByUpgradesConfirmation(failedUpgradeFlights, ods)
      )
    }
  }, [tripInfo, upgradeConfirmation])

  useEffect(() => {
    if (isErrorUpgradeConfirmation) {
      setScenarioEvent('General-Error')
    }
  }, [isErrorUpgradeConfirmation])

  useEffect(() => {
    dispatch(
      pageViewEventUpgraHub({
        path: history.location.pathname,
        pageName: 'Confirmation',
        loggedIn: !!profileExtendInfo?.loyalty?.membershipID,
        ffTier: profileExtendInfo?.loyalty?.loyalLevel,
        referSource: origin,
        scenario: scenarioEvent,
        service:
          upgradeBoxResponse?.selectValue === 'miles'
            ? 'Miles'
            : 'Certificates',
      })
    )
  }, [
    history,
    profileExtendInfo,
    origin,
    upgradeBoxResponse,
    scenarioEvent,
    dispatch,
  ])

  useEffect(() => {
    if (tripInfo) {
      getItinerariesByUpgradeStatus()
      const uniquePassengers = getUniquePassengers(tripInfo)
      setPassengers(uniquePassengers)
    }
  }, [getItinerariesByUpgradeStatus, tripInfo])

  return isFailUpgrade ? (
    <Box
      tabIndex={0}
      aria-label={formatMessage({
        id: 'upgradeHub.confirmation.wcag.errorPageDescription',
      })}
    >
      <HeaderBarNavigation
        title={formatMessage({ id: 'upgradeHub.header.title' })}
        wcagText={formatMessage({ id: 'upgradeHub.header.wcagText' })}
        onClick={redirectToOrigin}
      />
      <Layout>
        <Box sx={classes.container}>
          <TitleCombination
            avatarProps={{
              id: 'destination-avatar',
              stroke: false,
              variant: 'huge',
              // @ts-ignore
              font: FailedUpgradeErrorIcon,
            }}
            titleProps={{
              variant: 'h2',
              color: theme.palette.grey['700'],
              children: formatMessage({
                id: `upgradeHub.confirmation.confirmationText.title.failedUpgrade`,
              }),
            }}
            descriptionProps={{
              variant: 'body1',
              color: theme.palette.grey['600'],
              children: formatMessage(
                {
                  id: `upgradeHub.confirmation.confirmationText.subtitle.failedUpgrade`,
                },
                { copa_url: process.env.REACT_APP_COPA_URL }
              ),
            }}
            buttonProps={{
              variant: 'outlinePrimaryLight',
              children: formatMessage({
                id: `upgradeHub.confirmation.ctaText.failedUpgrade`,
              }),
              onClick: redirectToOrigin,
              'aria-label': formatMessage({
                id: `upgradeHub.confirmation.wcag.ctaText.failedUpgrade`,
              }),
            }}
          />
        </Box>
      </Layout>
    </Box>
  ) : (
    <>
      <HeaderBarNavigation
        title={formatMessage({
          id: 'upgradeHub.confirmation.header',
        })}
        wcagText={formatMessage({
          id: 'upgradeHub.confirmation.wcag.goBackText',
        })}
        onClick={redirectToOrigin}
      />

      <Box>
        <Layout>
          <Box sx={classes.destinationCardContainer}>
            <DestinationCard
              template={{
                display: true,
                reservationCode: 'flightCard.reservationCode',
              }}
              flight={tripInfo}
            />
          </Box>
          <Box sx={classes.typographyBox}>
            <Typography variant="h2" color="primary.main">
              {formatMessage({
                id: `upgradeHub.confirmation.confirmationText.title.${statusByConfirmationRequest}`,
              })}
            </Typography>
            <Typography variant="body1" color="gray.600">
              {formatMessage({
                id: `upgradeHub.confirmation.confirmationText.subtitle.${statusByConfirmationRequest}`,
              })}
            </Typography>
          </Box>

          <Box sx={classes.alertBanner}>
            <AlertBanner
              data={[
                {
                  type: 'info',
                  description: formatMessage({
                    id: 'upgradeHub.confirmation.confirmationText.successfulUpgradeAlert',
                  }),
                  closeButton: true,
                  wcagText: formatMessage({
                    id: 'upgradeHub.confirmation.confirmationText.successfulUpgradeAlertWCAG',
                  }),
                },
              ]}
            />
          </Box>

          <Box sx={classes.itineraryCardBox}>
            {upgradedItineraries &&
              upgradedItineraries.map(itinerary => (
                <ItineraryCard
                  key={itinerary.segment.flightKey}
                  id={`itinerary-${itinerary.segment.flightKey}`}
                  flight={formatSegmentToItineraryFlight(itinerary.segment)}
                  extraInfo={{
                    [ExtraInfoOptions.flightStatus]: {
                      status: FlightStatus.Selected,
                      statusText: formatMessage({
                        id: 'upgradeHub.confirmation.pillStatus.successful',
                      }),
                    },
                  }}
                />
              ))}
          </Box>
          {isPartialUpgrade && (
            <>
              <Box sx={classes.typographyBox}>
                <Typography variant="h3" color="gray.700">
                  {formatMessage({
                    id: 'upgradeHub.confirmation.partialUpgrade.title',
                  })}
                </Typography>
                <Typography variant="body1" color="gray.600">
                  {formatMessage({
                    id: 'upgradeHub.confirmation.partialUpgrade.subtitle',
                  })}{' '}
                  <Link
                    sx={classes.link}
                    href={formatMessage(
                      {
                        id: 'upgradeHub.confirmation.partialUpgrade.link',
                      },
                      { copa_url: process.env.REACT_APP_COPA_URL }
                    )}
                  >
                    {formatMessage({
                      id: 'upgradeHub.confirmation.partialUpgrade.linkText',
                    })}
                  </Link>
                </Typography>
              </Box>

              <Box sx={classes.itineraryCardBox}>
                {failedUpgradeItineraries &&
                  failedUpgradeItineraries.map(itinerary => (
                    <ItineraryCard
                      key={itinerary.segment.flightKey}
                      id={`itinerary-${itinerary.segment.flightKey}`}
                      flight={formatSegmentToItineraryFlight(itinerary.segment)}
                      extraInfo={{
                        [ExtraInfoOptions.flightStatus]: {
                          status: FlightStatus.Delayed,
                          statusText: formatMessage({
                            id: 'upgradeHub.confirmation.pillStatus.failed',
                          }),
                        },
                      }}
                    />
                  ))}
              </Box>
            </>
          )}

          <Box sx={classes.typographyBox}>
            <Typography variant="h3" color="gray.700">
              {formatMessage({
                id: 'upgradeHub.confirmation.passengerText.title',
              })}
            </Typography>
            <Typography variant="body1" color="gray.600">
              {formatMessage({
                id: 'upgradeHub.confirmation.passengerText.subtitle',
              })}
            </Typography>
          </Box>

          <Box sx={classes.paxCardBox}>
            {passengers.map(passenger => (
              <PaxCard
                key={passenger.eTicket}
                avatar={{
                  id: 'basic',
                  font: capitalizeFirstLetter(
                    `${passenger.givenName} ${passenger.lastName}`
                  ),
                  stroke: true,
                  inverted: !isLoyaltyAvailable(passenger),

                  color:
                    // @ts-ignore
                    theme.palette.status[
                      passenger.loyalty?.statusConnectMiles.toLowerCase()
                    ],
                }}
                listDescription={[
                  getPassengerDescription(passenger, formatMessage),
                ]}
                wcagText=""
              />
            ))}
          </Box>
          <Box sx={classes.buttonBox}>
            <ButtonCo
              variant="solidPrimaryMain"
              rounded
              size="large"
              aria-label={formatMessage({
                id: `upgradeHub.confirmation.wcag.ctaText.${statusByConfirmationRequest}`,
              })}
              sx={{ width: { xs: '100%', md: '25%' } }}
              onClick={() => {
                dispatch(
                  upgradeHubGoMT({
                    service:
                      upgradeBoxResponse?.selectValue === 'miles'
                        ? 'Miles'
                        : 'Certificates',
                  })
                )
                window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/trip-detail/${pnr}/${surname}`
              }}
            >
              {formatMessage({
                id: `upgradeHub.confirmation.ctaText.${statusByConfirmationRequest}`,
              })}
            </ButtonCo>
          </Box>
        </Layout>
      </Box>
    </>
  )
}
export default UpgradeHubConfirmation
