
export const replaceString = (stringToBeReplaced: string) => {
    const htmlSpaceRegex = new RegExp('&nbsp;', 'i')
    if (htmlSpaceRegex.test(stringToBeReplaced)) return stringToBeReplaced.replace(/&nbsp;/gi, '')
    return stringToBeReplaced
}

const matchesRightAlignment = (stringToCompare: string) => {
    const rightAlignmentRegex = new RegExp('^>', '')
    return rightAlignmentRegex.test(stringToCompare)
}

const matchesCenterAlignment = (stringToCompare: string) => {
    const centerAlignmentRegex = new RegExp('^_', '')
    return centerAlignmentRegex.test(stringToCompare)
} 

export const validateAlignment = (stringToValidate: string) => {
    if (matchesCenterAlignment(stringToValidate)) return 'center'
    if (matchesRightAlignment(stringToValidate)) return 'right'
    return 'left'
}


export const matchesSomeCustomAlignment = (stringToValidate: string) => 
    matchesCenterAlignment(stringToValidate) || matchesRightAlignment(stringToValidate)