import React from 'react'
import { ToastContainer } from 'react-toastify'

import './toastCO.css'

const ToastContainerCO = () => (
  <div className="toast-co">
    <ToastContainer
      position="top-center"
      autoClose={9000}
      className="toast-container"
      closeButton={false}
      closeOnClick={false}
      hideProgressBar
    />
  </div>
)

export default ToastContainerCO
