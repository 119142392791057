import { makeStyles } from '@mui/styles'
import { Breakpoints, getSpacing, spacing } from '../../../../utils/spacing'
import { CopaTheme } from '../../../../types/material'

interface OriginalItineraryStylesProps {
  isOpen: boolean
  breakpoints: Breakpoints
}

export const useStyles = makeStyles((theme: CopaTheme) => ({
  container: {
    borderTop: `1px solid ${theme.palette.grey['200']}`,
    borderBottom: `1px solid ${theme.palette.grey['200']}`,
    width: '100%',
    background: theme.palette.white,
  },
  subtitle: {
    color: theme.palette.grey['600'],
  },
  headerContainer: ({ isOpen, breakpoints }: OriginalItineraryStylesProps) => ({
    paddingRight: '24px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      paddingRight: '0',
    },
    justifyContent: 'space-between',
    cursor: 'pointer',
    paddingTop: getSpacing(breakpoints)?.roomy,
    paddingBottom: isOpen ? '' : getSpacing(breakpoints)?.roomy,
  }),
  headerText: {
    marginRight: '4px',
    [theme.breakpoints.down('md')]: {
      marginRight: '0',
      marginBottom: spacing.petit,
    },
  },
  headerTextTitle: {
    marginBottom: '4px',
    [theme.breakpoints.down('md')]: {
      marginBottom: spacing.minimal,
    },
  },
  leftHeading: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  childContainer: ({ breakpoints }: OriginalItineraryStylesProps) => ({
    paddingTop: getSpacing(breakpoints)?.normal,
    paddingBottom: getSpacing(breakpoints)?.spacious,
  }),
}))
