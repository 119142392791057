import { Theme } from '@mui/system'
import { StylesObject } from './types'

export const styles = (theme: Theme, fullCardClickable: boolean): StylesObject => ({
  container: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    justifyContent: {
      md: 'space-between'
    },
    gap: '24px', // theme.spacing.petit
    padding: '24px 32px', // petit x normal
    borderRadius: '8px',
    border: `1px solid ${theme.palette.primary.faded}`,
  ...(fullCardClickable ? {
    background: theme.palette.common.white,
    ':hover': {
      background: theme.palette.background.lightBlue,
    },
    ':active': {
      background: theme.palette.primary.ultraLight,
    },
    cursor: 'pointer'
  } : {}),
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  login: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center'
  }
})
