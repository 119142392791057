import { useMediaQuery } from '@mui/material'
import theme from '@copa/design-system-factory.theme'

const useBitBreakPoints = () => {
  // Breakpoints
  // xs: 0
  // sm: 600
  // md: 960
  // lg: 1367
  // xl: 1920
  const isBPXS = useMediaQuery(theme.breakpoints.down('sm'))
  const isBPSM = useMediaQuery(theme.breakpoints.only('sm'))
  const isBPMD = useMediaQuery(theme.breakpoints.only('md'))
  const isBPLG = useMediaQuery(theme.breakpoints.only('lg'))
  const isBPBiggerThanMD = useMediaQuery(theme.breakpoints.up('lg'))

  const isXL = useMediaQuery(theme.breakpoints.up('xl'))
  const isLG = useMediaQuery(theme.breakpoints.between('lg', 'xl'))
  const isMD = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isSM = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isXS = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  const isLarge = isLG || isXL
  const isMediumOrLarge = isMD || isLarge
  const isXsOrSmall = isXS || isSM

  return {
    breakpoints: {
      isXL,
      isLG,
      isMD,
      isSM,
      isXS,
    },
    isBPXS,
    isBPSM,
    isBPMD,
    isBPLG,
    isBPBiggerThanMD,
    isXL,
    isLG,
    isMD,
    isSM,
    isXS,
    isLarge,
    isMediumOrLarge,
    isXsOrSmall,
  }
}

export default useBitBreakPoints
