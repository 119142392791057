/* eslint-disable react/button-has-type */
import React from 'react'
import { isBrowser, isMobile, isMobileOnly } from 'react-device-detect'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import {
  compose,
  lifecycle,
  mapProps,
  setPropTypes,
  withHandlers,
  withStateHandlers,
} from 'react-recompose'
import classNames from 'classnames'
import { toast } from 'react-toastify'

// @actions
import { setLocale } from '../../actions/locale'

// @commons
import SelectCO from '../commons/select/selectCO'
import ButtonCO from '../commons/button/buttonCO'
import DropDownCO from '../commons/dropDownCO/dropDownCO'

// @logos
import copaLogoExtended from '../../assets/images/logo-copa-extended.svg'
import copaLogoReduced from '../../assets/images/logo-copa-reduced.svg'
import { displayWcagAlert } from '../../utils/utils'

import './headerNavigation.css'
import { CONNECT_MILES_LOGOUT_FAILURE } from '../../constants/errors'
import ToastCO from '../commons/toastCO/toastCO'
import { HEADER_TOAST_ID } from '../../constants/globalAlerts'
import { getCookie } from '../../utils/cookies'
// TODO: Clean after 100% rollout of FF IS_CIAM_ON
import { getFeatureFlag, FeatureFlag } from '../../constants/featureFlags'
import { useAuthentication } from '../../hooks/auth/useAuthentication'

const HeaderNavigation = ({
  lang,
  intl: { formatMessage },
  changeLanguage,
  handleOpenModalLogin,
  handleOpenModalMemberMenu,
  logoRef,
  linkToPortal,
  idName,
  idLogo,
  idLinkLogo,
  idBtnLogin,
  idBtnMemberMenu,
  setShowDropDown,
  showDropDown,
  headerScrollmark = false,
  langSelect = '',
  languageSelectionClass,
  setFocusClass,
  hideDropDown,
  esLanguage,
  enLanguage,
  ptLanguage,
  frLanguage,
  connectMilesMembership,
  connectMilesFirstName,
  connectMilesLastName,
  connectMilesMiles,
}) => {
  const { loginWithRedirect } = useAuthentication()

  // TODO: Clean on french translation release
  const shouldHaveFrenchTranslations = getFeatureFlag(
    FeatureFlag.SHOULD_HAVE_FRENCH_TRANSLATION
  )

  // TODO: Clean after 100% rollout of FF IS_CIAM_ON
  const onLoginClick = getFeatureFlag('IS_CIAM_ON')
    ? () => loginWithRedirect()
    : handleOpenModalLogin

  const dropDownButton = React.createRef()
  const dropDownContainer = React.createRef()
  return (
    <header
      className={`header-navigation__header-container${
        headerScrollmark ? ' header-navigation__header-mark' : ''
      }`}
      role="application"
    >
      <article className="center-wrap-header" role="none">
        <article className="header-navigation" role="none" id={idName}>
          <section className="header-navigation__navigation-block">
            <section className="header-navigation__icon">
              <a
                className={idLinkLogo}
                id={idLinkLogo}
                href={linkToPortal}
                onFocus={() =>
                  displayWcagAlert(
                    formatMessage({ id: 'findReservationWCAG.copaLogoLink' })
                  )
                }
                ref={logoRef}
              >
                {isBrowser || isMobile ? (
                  <span>
                    <img
                      id={idLogo}
                      className="header-navigation__img-copa-logo-extended"
                      src={copaLogoExtended}
                      alt={formatMessage({
                        id: 'findReservationWCAG.extendedCopaLogo',
                      })}
                    />
                  </span>
                ) : (
                  isMobileOnly && (
                    <span>
                      <img
                        id={idLogo}
                        className="header-navigation__img-copa-logo-reduced"
                        src={copaLogoReduced}
                        srcSet={copaLogoReduced}
                        alt={formatMessage({
                          id: 'findReservationWCAG.copaLogoAltMobile',
                        })}
                      />
                    </span>
                  )
                )}
              </a>
            </section>
            <section className="header-navigation__sesion">
              <article
                className={`${languageSelectionClass} ${
                  isMobile
                    ? 'header-navigation__language-selection--mobile'
                    : ''
                }`}
                role="none"
              >
                {isBrowser && (
                  <DropDownCO
                    dropDownButton={dropDownButton}
                    dropDownContainer={dropDownContainer}
                    showDropDown={showDropDown}
                    onClose={() => hideDropDown()}
                    onChangeLang={e => changeLanguage(e)}
                    lang={lang}
                    wcagObject={{
                      id: 'language-selection-wcag-description',
                      message: formatMessage({
                        id: 'findReservationWCAG.languageSelection',
                      }),
                    }}
                    interactiveElement="button"
                  >
                    <>
                      <button
                        className="header-navigation__language-selection-button"
                        onClick={event => setShowDropDown(event)}
                        onKeyDown={event => setShowDropDown(event)}
                        ref={dropDownButton}
                        onFocus={() => setFocusClass(true)}
                        onBlur={() => setFocusClass(false)}
                        aria-describedby="language-selection-wcag-description"
                        id="languageSelection"
                      >
                        {langSelect}
                      </button>
                      {showDropDown && (
                        <div
                          className="header-navigation__language-selection-list-container"
                          ref={dropDownContainer}
                        >
                          {!shouldHaveFrenchTranslations && (
                            <label className="header-navigation__language-selection-label">
                              {formatMessage({
                                id: 'languages.dropdownDescription',
                              })}
                            </label>
                          )}
                          <ul className="header-navigation__language-selection-list">
                            <li>
                              <button value="es" className={esLanguage}>
                                {formatMessage({ id: 'languages.spanish' })}
                              </button>
                            </li>
                            <li>
                              <button value="en" className={enLanguage}>
                                {formatMessage({ id: 'languages.english' })}
                              </button>
                            </li>
                            <li>
                              <button value="pt" className={ptLanguage}>
                                {formatMessage({ id: 'languages.portuguese' })}
                              </button>
                            </li>
                            {shouldHaveFrenchTranslations && (
                              <li>
                                <button value="fr" className={frLanguage}>
                                  {formatMessage({ id: 'languages.french' })}
                                </button>
                              </li>
                            )}
                          </ul>
                        </div>
                      )}
                    </>
                  </DropDownCO>
                )}

                {isMobile && (
                  <SelectCO
                    id="languageSelection"
                    autoComplete="on"
                    label={formatMessage({ id: 'headerNavigation.language' })}
                    labelHidden
                    required={false}
                    extraClass="header-navigation__select-field"
                    meta={{ error: '', touched: false }}
                    input={{
                      value: lang,
                      onChange: changeLanguage,
                      onFocus: () => setFocusClass(true),
                      onBlur: () => setFocusClass(false),
                    }}
                    wcagObject={{
                      id: 'language-selection-wcag-description',
                      message: formatMessage({
                        id: 'findReservationWCAG.languageSelection',
                      }),
                    }}
                  >
                    <option value="en" className="header-navigation__option">
                      {formatMessage({ id: 'languages.english' })}
                    </option>
                    <option value="es" className="header-navigation__option">
                      {formatMessage({ id: 'languages.spanish' })}
                    </option>
                    <option value="pt" className="header-navigation__option">
                      {formatMessage({ id: 'languages.portuguese' })}
                    </option>
                    {shouldHaveFrenchTranslations && (
                      <option value="fr" className="header-navigation__option">
                        {formatMessage({ id: 'languages.french' })}
                      </option>
                    )}
                  </SelectCO>
                )}
              </article>
              {!connectMilesMembership ? (
                <article
                  className="header-navigation__connect-miles-block"
                  role="none"
                >
                  <div className="header-navigation__cm-sections">
                    <section className="header-navigation__right-cm-section">
                      <ButtonCO
                        id={idBtnLogin}
                        relevance="primary"
                        type="button"
                        disableFullContainer
                        extraClass="header-navigation__login-button"
                        onClick={onLoginClick}
                        wcagObject={{
                          id: 'cm-login-wcag-description',
                          message: formatMessage({
                            id: 'findReservationWCAG.cmLoginButton',
                          }),
                        }}
                      >
                        {formatMessage({
                          id: 'loginForm.loginButton',
                        })}
                      </ButtonCO>
                    </section>
                  </div>
                </article>
              ) : (
                <button
                  className="header-navigation__active-sesion"
                  aria-label={formatMessage({
                    id: 'memberMenuWCAG.pageDescription',
                  })}
                  onClick={handleOpenModalMemberMenu}
                >
                  <div
                    className="header-navigation__active-sesion__content"
                    aria-hidden="true"
                  >
                    <p
                      className="header-navigation__active-sesion__name"
                      id="header-navigation-active-sesion-name"
                      aria-hidden="true"
                    >
                      {connectMilesFirstName}
                    </p>
                    <p
                      className="header-navigation__active-sesion__miles"
                      id="header-navigation-active-sesion-miles"
                      aria-hidden="true"
                    >
                      {formatMessage(
                        { id: 'headerNavigation.miles' },
                        {
                          number:
                            parseFloat(connectMilesMiles).toLocaleString(),
                        }
                      )}
                    </p>
                  </div>

                  <div
                    id={idBtnMemberMenu}
                    className="header-navigation__avatar-button"
                    aria-hidden="true"
                  >
                    {connectMilesFirstName.substring(0, 1) +
                      connectMilesLastName.substring(0, 1)}
                  </div>
                </button>
              )}
            </section>
          </section>
        </article>
      </article>
    </header>
  )
}

const _propTypes = {
  setLanguage: PropTypes.func.isRequired,
  handleOpenModalLogin: PropTypes.func.isRequired,
  handleOpenModalMemberMenu: PropTypes.func.isRequired,
  logOutAction: PropTypes.func.isRequired,
  connectMilesToken: PropTypes.string,
  connectMilesMembership: PropTypes.string,
}

const setPropTypesEnhance = setPropTypes(_propTypes)

const reduxstate = connect(
  state => ({
    lang: state.locale.lang,
    connectMilesError: state.connectMiles.error,
    connectMilesMembership: getCookie('lp_ffn'),
    connectMilesFirstName:
      state.connectMiles.connectMilesProfile?.name?.givenName ?? '',
    connectMilesLastName:
      state.connectMiles.connectMilesProfile?.name?.surname ?? '',
    connectMilesMiles: getCookie('lp_miles'),
    connectMilesToken: getCookie('masterSessionId'),
  }),
  { setLanguage: setLocale }
)

const setDidMountBehavior = lifecycle({
  componentDidMount() {
    window.addEventListener('scroll', this.props.handleScroll)
    this.props.callLangSelected()
  },
  componentWillUnmount() {
    window.removeEventListener('scroll', this.props.handleScroll)
  },
  componentDidUpdate({ connectMilesError }) {
    const {
      connectMilesError: currentCMError,
      intl: { formatMessage },
    } = this.props
    if (
      connectMilesError !== currentCMError &&
      connectMilesError === CONNECT_MILES_LOGOUT_FAILURE &&
      !toast.isActive(HEADER_TOAST_ID)
    ) {
      toast.info(
        <ToastCO
          text={formatMessage({
            id: 'loginFormAlerts.logOutError',
          })}
        />,
        { toastId: HEADER_TOAST_ID }
      )
    }
  },
})

const withHandlersEnhance = withHandlers({
  changeLanguage:
    ({ setLanguage, hideDropDown, setLangSelected, intl: { formatMessage } }) =>
    event => {
      const { value } = event.target
      setLanguage(value)
      setLangSelected(formatMessage, value)
      if (isBrowser) hideDropDown()
    },
  onlogOutClick:
    ({ logOutAction, connectMilesToken }) =>
    () => {
      logOutAction({ token: connectMilesToken })
    },
  handleScroll:
    ({ setScrollmark }) =>
    () => {
      if (window.pageYOffset > 0) setScrollmark(true)
      if (window.pageYOffset === 0) setScrollmark(false)
    },
  callLangSelected:
    ({ setLangSelected, intl: { formatMessage }, lang }) =>
    () =>
      setLangSelected(formatMessage, lang),
})

const headerStates = withStateHandlers(
  ({
    initialScrollmark = false,
    initialShowDropDown = false,
    initLangSelect = '',
  }) => ({
    headerScrollmark: initialScrollmark,
    showDropDown: initialShowDropDown,
    langSelect: initLangSelect,
    languageSelectionClass: 'header-navigation__language-selection',
  }),
  {
    setScrollmark: () => value => ({
      headerScrollmark: value,
    }),
    setShowDropDown: () => value => {
      const { key, keyCode, type } = value
      if (
        key === 'ArrowDown' ||
        key === 'ArrowUp' ||
        keyCode === 13 ||
        type === 'click'
      )
        return { showDropDown: true }
      return {}
    },
    hideDropDown: () => () => ({ showDropDown: false }),
    setLangSelected: () => (message, lang) => {
      if (lang === 'fr')
        return { langSelect: message({ id: 'languages.french' }) }
      if (lang === 'es')
        return { langSelect: message({ id: 'languages.spanish' }) }
      if (lang === 'pt')
        return { langSelect: message({ id: 'languages.portuguese' }) }
      return { langSelect: message({ id: 'languages.english' }) }
    },
    setFocusClass: () => value => {
      if (value)
        return {
          languageSelectionClass:
            'header-navigation__language-selection header-navigation__language-selection--focus',
        }
      return {
        languageSelectionClass: 'header-navigation__language-selection',
      }
    },
  }
)

const withPropsEnhacer = mapProps(({ lang, ...rest }) => ({
  esLanguage: classNames('header-navigation__language', {
    [`header-navigation__language--selected`]: lang === 'es',
  }),
  enLanguage: classNames('header-navigation__language', {
    [`header-navigation__language--selected`]: lang === 'en',
  }),
  ptLanguage: classNames('header-navigation__language', {
    [`header-navigation__language--selected`]: lang === 'pt',
  }),
  frLanguage: classNames('header-navigation__language', {
    [`header-navigation__language--selected`]: lang === 'fr',
  }),
  lang,
  ...rest,
}))

export default compose(
  reduxstate,
  injectIntl,
  withPropsEnhacer,
  setPropTypesEnhance,
  headerStates,
  withHandlersEnhance,
  setDidMountBehavior
)(HeaderNavigation)
