import { getSpacing } from '../../../../../utils/spacing'
import { useStylesProps } from '../../../upgrade-hub/types'

const useStyles = ({ breakpoints }: useStylesProps) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    // @ts-ignore
    gap: getSpacing(breakpoints)?.minimal,
  },
  flightContainer: {
    display: 'flex',
    flexDirection: 'column',
    // @ts-ignore
    gap: getSpacing(breakpoints)?.minimal,
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // @ts-ignore
    marginTop: getSpacing(breakpoints)?.normal,
  },
  row: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    // @ts-ignore
    columnGap: getSpacing(breakpoints)?.petit,
  },
  input: {
    width: { xs: '100%', md: '49%' },
  },
  dropdown: {
    marginLeft: '0',
  },
  actions: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    // @ts-ignore
    gap: getSpacing(breakpoints)?.tiny,
  },
  composeHelperText: {
    '& div, p, a': {
      fontSize: '0.75rem',
      margin: 0,
      lineHeight: '1rem',
    },
  },
})

export default useStyles
