import { useHistory, useLocation, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { UpgradeCacheKey, UpgradeLocation, UpgradeOrigin } from '../types'
import { MyTripsState } from '../../../types/state'
import { useGetVouchersMutation } from '../../../api/upgradeHubSlice'
import {
  isMyAccountOrigin,
  isValidMemberForVouchers,
  redirectToErrorPage,
} from '../utils'
import Spinner from '../../../components/commons/spinner/spinner'
import UpgradeOnboarding from '../onboarding/UpgradeOnboarding'
import useSauaFlow from '../hooks/useSauaFlow'
import { QueryParams } from '../../../types/QueryParams'
import SelectFlightSAUA from './SelectFlightSAUA'
import { SauaErrorPage } from './SauaErrorPage'
import useSauaDictionaries from '../hooks/useSauaDictionaries'
import ConfirmationSAUA from './ConfirmationSAUA/ConfirmationSAUA'
import { SauaFlight } from './components/FlightForm/FlightForm'
import {
  PassengersInputs,
  PlusgradeInfoProp,
  UpgradeInfo,
} from '../upgrade-hub/types'
import { isFetchingGeneral } from '../../../actions/generalSpinner'
import { getUpgradeScenario } from './ConfirmationSAUA/utils'

export type UpgradePageProps = {
  plusgradeInfo: PlusgradeInfoProp
  upgradeInfo: Partial<UpgradeInfo>
}
export default function Saua({
  plusgradeInfo,
  upgradeInfo: { tripInfo, profileExtendInfo },
}: UpgradePageProps) {
  const locationRoute = useLocation()
  const queryParams = new URLSearchParams(locationRoute.search)
  const origin = queryParams.get('origin') as UpgradeOrigin
  const dispatch = useDispatch()
  const history = useHistory()
  const { pnr, surname, language } = useParams<QueryParams>()
  const { getDictionaries } = useSauaDictionaries({ origin, useSpinner: false })

  const { masterSessionId: sessionToken } = useSelector(
    (state: MyTripsState) => state.auth
  )

  const [formsFlights, setFormsFlights] = useState<SauaFlight[]>()
  const [formPassengers, setFormPassengers] = useState<PassengersInputs>()

  const { location, showContent } = useSauaFlow({
    origin,
    upgradeInfo: { tripInfo, profileExtendInfo },
  })

  const [upgradeLocation, setUpgradeLocation] = useState<UpgradeLocation>(
    location as UpgradeLocation
  )

  const [
    getVouchers,
    {
      data: vouchers,
      isLoading: isVouchersLoading,
      isUninitialized: isVouchersUninitialized,
    },
  ] = useGetVouchersMutation({
    fixedCacheKey: UpgradeCacheKey.VOUCHERS,
  })

  const [shouldGoToSelectFlight, setShouldGoToSelectFlight] = useState<boolean>(
    !vouchers && isMyAccountOrigin(origin)
  )

  const isEligibleForVouchers = useCallback(
    loyaltyInfo => isValidMemberForVouchers(loyaltyInfo),
    []
  )

  const fetchVouchers = useCallback(
    () =>
      getVouchers({
        costumerID: profileExtendInfo?.loyalty?.customerID!,
        token: sessionToken,
      }),
    [getVouchers, profileExtendInfo?.loyalty?.customerID, sessionToken]
  )

  const navigateToSelectFlights = useCallback(() => {
    setUpgradeLocation(UpgradeLocation.SAUA_SELECT_FLIGHT)
  }, [])

  const handleError = useCallback(() => {
    redirectToErrorPage({ history, pnr, surname, language, origin })
  }, [history, pnr, surname, language, origin])

  const handleVoucherRequest = useCallback(async () => {
    if (isVouchersUninitialized) {
      try {
        const { data } = await fetchVouchers()

        if (data) {
          navigateToSelectFlights()
        } else {
          handleError()
        }
      } catch (e) {
        handleError()
      }
    }
  }, [
    fetchVouchers,
    handleError,
    isVouchersUninitialized,
    navigateToSelectFlights,
  ])

  const goToSelectFlightsPage = useCallback(() => {
    getDictionaries()

    if (isEligibleForVouchers(profileExtendInfo?.loyalty)) {
      handleVoucherRequest()
    } else {
      navigateToSelectFlights()
    }
  }, [
    getDictionaries,
    isEligibleForVouchers,
    profileExtendInfo,
    handleVoucherRequest,
    navigateToSelectFlights,
  ])

  useEffect(() => {
    if (shouldGoToSelectFlight) {
      goToSelectFlightsPage()
    }
  }, [goToSelectFlightsPage, shouldGoToSelectFlight])

  useEffect(() => {
    if (!isMyAccountOrigin(origin)) {
      dispatch(isFetchingGeneral(isVouchersLoading))
    }
  }, [dispatch, isVouchersLoading, origin])

  useEffect(() => {
    setUpgradeLocation(location as UpgradeLocation)
  }, [location])

  const upgradeByLocation: { [p: string]: React.JSX.Element } = {
    [UpgradeLocation.BLANK]: <Spinner />,
    [UpgradeLocation.SAUA_ONBOARDING]: (
      <UpgradeOnboarding
        upgradeInfo={{ tripInfo, profileExtendInfo }}
        origin={origin}
        onNextPageClick={() => setShouldGoToSelectFlight(true)}
        plusgradeInfo={plusgradeInfo}
      />
    ),
    [UpgradeLocation.SAUA_SELECT_FLIGHT]: (
      <SelectFlightSAUA
        setLocation={setUpgradeLocation}
        setFlights={setFormsFlights}
        setPassengers={setFormPassengers}
        routeParams={{ pnr, surname, language, origin }}
        upgradeInfo={{ tripInfo, profileExtendInfo, vouchers }}
      />
    ),
    [UpgradeLocation.SAUA_CONFIRMATION]: (
      <ConfirmationSAUA
        origin={origin}
        flights={formsFlights}
        passengers={formPassengers}
        scenario={getUpgradeScenario(formsFlights)}
      />
    ),
    [UpgradeLocation.SAUA_ERROR]: (
      <SauaErrorPage routeParams={{ pnr, surname, language, origin }} />
    ),
  }

  return showContent ? upgradeByLocation[upgradeLocation] : <Spinner />
}
