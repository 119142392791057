import { compose, defaultProps } from 'react-recompose'
import React from 'react'
import './inputCounter.css'

const InputCounter = ({ maxlength, inputCharCounter, color }) => (
  <div className={`input-counter__counter--${color}`} id="characterCounter">
    {inputCharCounter}/{maxlength}.
  </div>
)

const _defaultProps = {
  color: 'gray',
}

export default compose(defaultProps(_defaultProps))(InputCounter)
