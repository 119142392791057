import { SeatTypes } from '../../utils/seatSelection/seatSelection'

export default {
  // ////////////////////////////// strat translations My trips //////////////////////////////////
  loading: 'A página está carregando',

  findReservation: {
    title: 'Minhas Viagens',
    description:
      'Em Minhas Viagens, você pode ver, gerenciar e salvar suas reservas de Copa Airlines.',
    welcome: 'Olá! Encontremos sua reserva.',
    innerDescription:
      'Em Minhas Viagens, você pode ver, gerenciar e salvar suas reservas de Copa Airlines.',
    connectMiles:
      'También puedes iniciar sesión en tu cuenta ConnectMiles para manejar tus reservas guardadas.',
    informationAlert:
      'Você está utilizando Minhas Viagens Beta. Tem comentários ou sugestões? ',
    linkInformationAlert: 'Deixe um comentario.',
    errorInvalidToken:
      'Sua sessão do ConnectMiles expirou. Faça login novamente.',
  },
  connectMiles: {
    description:
      'Também pode fazer login na sua conta do ConnectMiles para gerenciar suas reservas salvas.',
    loginButton: 'Iniciar Sessão',
  },

  findTrip: {
    title: 'Encontre uma reserva',
    buttonFind: 'Encontre sua reserva',
    checkLabel: 'Guardar em Minhas Viagens',
    alreadyAdded: 'Sua reservação já foi adicionada a Minhas Viagens.',
    succesfully: 'Viagem guardada com sucesso',
    notFound:
      'Desculpe! Não foi possível encontrar a reserva com as informações fornecidas. Confira as palavras usadas e tente novamente.',
  },

  emptyState: {
    title: 'Ainda não tem vaigens guardadas.',
    description:
      'Viagens associadas com seu número de ConnectMiles aparecerão aquí automáticamente. Tambem pode buscar uma reserva para adicioná-la aquí.',
    buttonAdd: 'Adicionar viagem',
  },

  holderCard: {
    title: 'Adicionar outra viagem',
  },
  holderCardWCAG: {
    description: 'Adicionar outra viagem.',
  },
  pastTrips: {
    title: 'Viagens Anteriores',
    destination: 'Destino',
    flightDates: 'Datas de voo',
    reservation: 'Reserva',
  },

  pastTripsWCAG: {
    wcagTitle: 'Painel de Viagens Anteriores',
    wcagDescription:
      'Viagems anteriores: Esta seção mostra viagens que já foram concluídas. Suas viagens anteriores desaparecerão após 2 meses.',
    wcagAvatarImage: 'Avatar de Destino: Este é o Avatar de {destinationCity}',
    wcagDestinationColumn:
      'Coluna de Destino: Esta coluna mostra os destinos das suas viagens anteriores.',
    wcagFlightColumn:
      'Coluna de Datas de voo: Esta coluna mostra as datas das suas viagens anteriores.',
    wcagReservationColumn:
      'Coluna de Reserva: Esta coluna mostra os códigos de reserva das suas viagens anteriores.',
    wcagPastTripsDetail:
      'Os detalhes da sua viagem são os seguintes: {pasengerInformation} + {aditionalPassengers} {od} - {destinationCity} Datas de voo {startDate} - {endDate} Código de reserva: {reservationCode}',
    wcagTripSelection:
      'Você está no Painel de Viagens Anteriores. Aquí, você pode navegar entre viagens escutar os detalhes da sua viagem.',
    od: 'para',
  },

  removeModal: {
    title: 'Deseja excluir esta viagem?',
    description:
      'Não se preocupe, sua reserva seguirá existindo - só deixará de aparecer na sua página de Minhas Viagens.',
    pastTripDescription:
      'Se você excluir sua viagem agora, não poderá ver mais os detalhes relacionados com ele. Tem certeza que deseja continuar?',
    cancelBtn: 'Cancelar',
    deleteBtn: 'Excluir',
    errorToastMessage:
      'Desculpe! Não conseguimos excluir sua viagem. Por favor, tente novamente mais tarde.',
    successToastMessage: 'Sua viagem foi excluida com sucesso.',
  },

  editModal: {
    renameTrip: 'Renomear esta viagem',
    tripName: 'Nome da viagem',
    renameButton: 'Renomear',
    editModalError: 'Não são permitidos caracteres especiais.',
  },

  fareRulesModal: {
    title: 'Regras tarifárias',
    description:
      'Certos aspectos de sua reserva como malas, assentos, mudanças e reembolsos podem ter restrições dependendo do tipo de tarifa que você comprou. Aqui, você poderá ler as regras tarifárias aplicáveis para cada um dos voos nesta reserva.',
    link: 'Saiba mais sobre nossas famílias tarifárias',
    href: '{copa_url}/pt-gs/informacoes-ao-viajar/familias-de-tarifas/',
    infoNonCopa:
      'Não temos informação de regras tarifárias para este voo dado que não é um voo operado por Copa Airlines.',
  },

  fareRulesModalWCAG: {
    description:
      'Modal de regras tarifárias: Desde aqui, você poderá ver as regras que se aplicam à tarifa que comprou.',
    previousButton:
      'Use este botão para ver as regras tarifárias que aplicam ao voo anterior.',
    nextButton:
      'Use este botão para ver as regras tarifárias que aplicam ao próximo voo.',
  },

  specialServicesModal: {
    title: 'Serviços especiais',
    subtitle:
      'Copa oferece servicços para passageiros com necessidades especiais. Pode solicitar comidas especiais, assistência durante o voo, entre otros.',
    introTitle: 'Adicionar serviços especiais',
    passengerLabel: 'Passageiro',
    specialServiceLabel: 'Serviço',
    appliesToLabel: 'Aplica-se para',
    serviceLabel: 'Tipo de serviço',
    passengerSelectPlaceholder: 'Selecione passageiro',
    serviceSelectPlaceholder: 'Selecione serviço',
    mealLabel: 'Tipo de refeição',
    chairLabel: 'Tipo de cadeira',
    mealPlaceholder: 'Escolher tipo de refeição',
    wheelChairPlaceholder: 'Motivo',
    addButton: '+ Adicionar',
    removeButton: 'Remover',
    disclaimer: {
      title: 'Serviços especiais',
      meal: {
        title: 'Comidas especiais',
        description:
          'Pedidos de refeições especiais podem ser feitos até 24 horas antes da partida do voo. Tipos de refeições especiais disponíveis:',
        errorAlreadyAdded:
          'Este serviço já foi adicionado para este passageiro.',
        item1: {
          title: 'Classe Executiva: ',
          description:
            'vegetariana, kosher e sem glúten em alguns voos. {linkDesc}',
          linkDesc: 'Conheça nosso serviço de alimentação e bebidas a bordo.',
          link: '{copa_url}/pt-gs/descubra-copa-airlines/experiencia-a-bordo/alimentacao-a-bordo/',
        },
        item2: {
          title: 'Cabine Principal: ',
          description:
            'sem glúten apenas em voos de e para Argentina e Paraguai.',
        },
      },
      assistance: {
        title: 'Assistência especial',
        item1: {
          title:
            'Assistência para pessoas com deficiência auditiva ou visual: ',
          description: 'Disponível em todos os nossos voos.',
        },
        item2: {
          title: 'Transporte de animais de serviço e apoio emocional: ',
          description:
            'Pode solicitá-lo através da nossa Central de Reservas, escritórios de vendas ou no aeroporto. {linkDesc}',
          linkDesc:
            'Conheça as condições de transporte de animais de serviço e apoio emocional.',
          link: '{copa_url}/pt-gs/informacoes-ao-viajar/assistencia-especial/viajando-com-animais/',
        },
      },
      footer: {
        description: 'Mais informações sobre nossos serviços especiais, aqui.',
        link: '{copa_url}/pt-gs/informacoes-ao-viajar/assistencia-especial/',
      },
    },
    goBackToReservationButton: 'Voltar para sua reserva',
    saveServicesButton: 'Guardar serviços',
    specialFood: 'Refeição especial',
    wheelChair: 'Cadeira de rodas',
    errorAlert:
      'Ocorreu um erro ao tentar guardar seus serviços especiais. Por favor, tente de novo mais tarde.',
    succesToastText: 'Serviços especiais guardados com sucesso.',
    partialErrorsToastText:
      'Ocorreu um erro ao tentar guardar agluns de seus serviços especiais. Por favor, tente de novo mais tarde.',
  },

  specialServicesModalWCAG: {
    description:
      'Modal de serviços especiais. Desde aqui, você pode solicitar serviços especiais para passageiros em sua reserva.',
    passengerSelectionDescription: 'MENÚ. Está no menu de passageiro.',
    serviceSelectionDescription: 'MENÚ. Está no menu de tipo de serviço.',
    addButtonDescription: 'Adicionar sua seleção a sua viagem.',
    removeButtonDescription: 'Remover o serviço selecionado de sua reserva.',
    goBackToReservationButton: 'Voltar para sua reserva,',
    saveServicesButton: 'Guardar os serviços selecionados em sua reserva',
  },

  sendReceiptModal: {
    title: 'Enviar recibo da reserva',
    subtitle:
      'Pode enviar o recibo da reserva para um o mais e-mails da sua preferência.',
    emailLabel: 'Insire e-mail(s)',
    emailPlaceholder: 'Insire um o mais e-mails',
    descriptionLabel:
      'Pode adicionar múltiplos e-mails utilizando as teclas de espaço ou vírgula (",").',
    goToSendReceipt: 'Enviar recibo',
    resultOk: 'Recibo enviado com sucesso.',
    resultError:
      'Ocorreu um erro ao tentar enviar seu recibo. Por favor, tente de novo mais tarde.',
  },

  sendReceiptModalWCAG: {
    description:
      'Pode enviar o recibo da reserva para um o mais e-mails da sua preferência.',
  },

  changeItinerary: {
    title: 'Seu itinerário mudou',
    subtitle_1: 'Ocorreu uma mudança de itinerário nos seus planos de viagem.',
    subtitle_2:
      'Verifique que o novo itinerário se ajuste a seus planos de viagem e que você tem tempo suficiente para fazer suas conexões.',
    alert: 'As mudanças no seu itinerário estão embaixo.',
    newItinerary: 'Novo itinerário de viagem',
    modifiedItinerary: 'Itinerário modificado',
    titleFlight: 'Voo',
    titleFrom: 'Desde',
    titleDeparture: 'Saída',
    titleTo: 'Para',
    titleArrival: 'Chegada',
    disclaimer_1:
      'Se a mudança de itinerário não funciona para seus planos de viagem, podem existir outras opções disponíveis.',
    disclaimer_2:
      'Por favor revise o novo itinerário acima antes de escolher uma opção embaixo.',
    btnLearnMore: 'Saiba mais sobre mudanças de itinerário',
    link: '{copa_url}/pt/web/gs/mudanca-de-itinerario',
    btnAcept: 'Aceitar itinerário e continuar',
  },

  confirmCancelReservation: {
    title: 'Tem certeza que deseja cancelar esta reserva?',
    warningLabel: 'Uma vez cancele sua reserva, não poderá recuperá-la.',
    subtitle:
      'Sua reserva será cancelada na sua totalidade e receberá um reembolso por {refundAmount} a seu cartão de crédito {creditCardFrachise} terminado em {creditCardNumberCensure}.',
    btnConfirmText: 'Cancelar esta reserva',
    btnBackText: 'Retroceder',
    flightDate: 'Datas de viagem',
    reservationCode: 'Código de reserva',
  },

  confirmCancelReservationWCAG: {
    description:
      'Sua reserva será cancelada na sua totalidade e receberá um reembolso por {refundAmount} a seu cartão de crédito {creditCardFrachise} terminado em {creditCardNumberCensure}.',
  },

  cancelledBooking: {
    title: 'Recebemos sua solicitação de reembolso',
    subtitle:
      'Em breve você receberá uma confirmação via e-mail, quando tivermos concluído o processo de cancelamento da sua reserva.',
    btnNext: 'Continuar',
    flightDate: 'Datas de viagem',
    reservationCode: 'Código de reserva',
  },

  cancelledBookingWCAG: {
    description:
      'Em breve você receberá uma confirmação via e-mail, quando tivermos concluído o processo de cancelamento da sua reserva.',
  },

  plusGrade: {
    resultError:
      'Não podemos acceder ao serviço de solicitação de upgrade nestes momentos. Por favor, tente novamente mais tarde.',
  },

  requestRefund: {
    title: 'Não podemos processar sua solicitação',
    description: {
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
      linkText: 'Central de Reservas',
      startText:
        'Não podemos processar o cancelamento web de sua reserva, mas pode fazer sua solicitação de reembolso através da nossa ',
      endText:
        '. Você também pode cancelar a sua reserva e manter o valor do seu bilhete para uma viagem futura; para optar por esta opção, clique no botão e siga os passos indicados.',
    },
    btnCta: 'Cancelar e planejar mais tarde',
    linkCta: '{copa_url}/pt-gs/atendimento-ao-cliente/mudancas-e-reembolsos/',
  },
  changeStatus: {
    onWindow: 'Disponível',
    outOfWindow: 'Não Disponível',
  },
  flightCard: {
    onTime: 'Na hora',
    advanced: 'Antecipado',
    delayed: 'Atrasado',
    canceled: 'Cancelado',
    duration: 'Duração',
    checkInOpen: 'Check-In abre em',
    completed: 'Concluído',
    alertSeatPassengers: 'Alguns passageiros não tem seleccionado assentos.',
    alertMissingPassengerInfo: 'Falta informação de passageiros',
    reservationCode: 'Código de reserva',
    nextFlight: 'Próximo vôo',
    atTime: 'às',
    numberReservation: '# de reserva:',
    to: 'para',
    btnCheckIn: 'Check-In',
    btnPayReservation: 'Pagar reserva',
    btnBoardingPasses: 'Cartões de embarque',
    btnAutomaticSubscription: 'Assine o check-in automático.',
    btnSeeReservation: 'Ver reserva',
    flightCancelled: 'Um voo na sua reserva foi cancelado',
    tripCancelled: 'Sua viagem foi cancelada.',
    flightPaidRequired: 'Pagamento Requerido. Sua reserva expirará em',
    missingPassengerInfo: 'Falta informação de passageiros',
    passengersNotSelectedSeats:
      'Alguns passageiros não tem seleccionado assentos.',
    yourUpcomingTrip: 'Sua próxima viagem',
  },
  ellipsisMenu: {
    remove: 'Excluir',
    rename: 'Renomear',
  },
  ellipsisMenuWCAG: {
    title: 'MENÚ.',
    remove:
      'EXCLUIR, botão. Use esta para remover esta viagem do seu Painel de Minhas Viagens.',
    rename: 'RENOMEAR, botão. Use esta opção para mudar o nome desta viagem.',
    descriptionPastTrips:
      'Está no menú com uma opção, pressione Enter para remover este voo do seu Painel de Viagens Anteriores, pressione a tecla Esc para cerrar este menu.',
    descriptionSaveCard: 'Está no menú com duas opções.',
    descriptionTripDetailCard: 'Está no menú.',
  },

  segmentCard: {
    layover: 'Escala',
    header: {
      to: 'Em direção a',
      segments: `{stopsNumber} {stopsNumber, plural,
        one {segmento}
        other {segmentos}
      }`,
    },
    flightDetailedInformation: {
      duration: 'Duração',
      flightNumberTitle: 'Voo',
      date: 'Data',
      departureTime: 'Hora de partida',
      arrivalTime: 'Hora de chegada',
    },
    flighBasicInformation: {
      emptySeats: 'Sem assentos seleccionados',
      title: 'Informação do voo',
      cabin: 'Cabine',
      fareFamily: 'Familia tarifaria',
      seats: 'Assentos',
      aircraft: 'Modelo de aeronave',
      meals: 'Refeições',
      entertainment: 'Entretenimento',
      interline: 'Interlínea',
    },
  },

  tripDetail: {
    sendReceipt: 'Enviar recibo',
    operated: 'Operado:',
    printItinerary: 'Imprimir itinerário',
    addCalendar: 'Adicionar a calendario',
    selectSeats: 'Selecionar assentos',
    requestUpgrade: 'Solicitar um Upgrade',
    cancelReservation: 'Cancelar reserva',
    changeNRItinerary: 'Mudar itinerário NR',
    titlePassengerMultitraveller: 'Passageiros nesta reserva',
    checkedIn: 'Check-In realizado',
    readyforCheckIn: 'Informação completa',
    infoNotcompleted: 'Informação não completa',
    addSpecialServices: 'Adicionar serviços especiais',
    noSpecialServices: 'Não há serviços especiais para este passageiro.',
    group: 'Grupo',
    tooltip: 'A seleção de assento não está disponível para este passageiro.',
    cancelledReservation: 'Reseva cancelada',
    exchangesAccess: 'Mudar itinerário',
    sectionTitle: 'Roteiro de Viagem',
    subtitle: 'Verifique os detalhes do seu voo e mantenha-se informado',
    flightDetailsTitle: 'Informação de vôo',
    cabin: 'Cabine {cabin_type}',
    gateLabel: 'Portão:',
    terminalLabel: 'Terminal:',
    noGate: '-',
    noTerminal: '-',
    gateAndTerminalLabel: 'Portão e Terminal:',
    noGateNoTerminal: 'Não Atribuído',
    noFlightInformation: 'Informação não disponível',
    fareFamilyAndEntertainment: {
      firstDescription: 'Para saber mais sobre sua família de tarifária,',
      secondDescription:
        'Se quiser saber como funciona o entretenimento a bordo e obter informações adicionais, ',
      firstCTA: 'clique aqui.',
      secondCTA: {
        text: 'visite nosso site para saber mais.',
        link: '{copa_url}/pt-gs/descubra-copa-airlines/experiencia-a-bordo/entretenimento/',
      },
    },
  },

  stackableAlerts: {
    economyBasic: {
      title: `Você está voando em classe Econômica Básica num o mais segmentos de sua viagem. Algumas opções podem estar limitadas:`,
      description: '',
      callToActionText: '',
      itemList1:
        'As bagagens despachadas estão disponíveis por um custo adicional (exceções se aplicam ao Venezuela)',
      itemList2: 'Alterações na reserva são disponível por uma taxa adicional',
      itemList3: 'A seleção de assentos está disponível por um custo adicional',
      itemList4: 'Reembolsos não estão disponíveis',
      itemList5: 'Membros do ConnectMiles acumularam 50% de milhas e segmentos',
      preferMemberNote:
        'Se você é um PreferMember, algumas das condições detalhadas podem ser diferentes.',
    },
    migratory: {
      title: 'Informação importante sobre sua viagem',
      descriptionStart:
        'Verifique os requisitos de imigração e saúde para sua próxima viagem em nosso ',
      descriptionEnd: '.',
      link: '{copa_url}/travel-preparation-hub/pt',
      linkText: 'centro de informações',
      callToActionEvent: '{copa_url}/travel-preparation-hub/pt',
      WCAGButtonClose: 'Botão. Fechar alerta.',
    },
    bookHold: {
      title: 'Pagamento necessário',
      description:
        'Sua reserva expirará em {time}. Para evitar que se cancele, lembre-se de completar sua compra.',
      callToActionText: 'Completar compra',
    },
    expiredBookAndHold: {
      title: 'Reseva expirada',
      description: {
        link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
        linkText: 'Central de Reservas.',
        startText:
          'O tempo para pagar esta reserva expirou. Por favor realize uma nova reserva ou entre em contato con nossa ',
        endText: ' para mais informação.',
      },
    },
    bookAndHoldWithoutSSR: {
      title: 'Pagamento necessário',
      description: {
        startText:
          'Para evitar que sua reserva se cancele, lembre-se de completar sua compra entrando em contato com nossa ',
        reservationCenter: {
          text: 'Central de Reservas',
          link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
        },
        middleText: ' ou visitando um de nossos ',
        salesOfices: {
          text: 'Escritórios de Vendas',
          link: '{copa_url}/pt-gs/atendimento-ao-cliente/escritorios-de-vendas/',
        },
      },
    },
    indirectChannel: {
      title: 'Informação importante sobre sua reserva',
      description:
        'Esta reserva foi comprada por meio de um intermediário. Para fazer mudanças, cancelar sua reserva ou outras transações, entre em contato com seu ponto de compra.',
    },
    cancelledReservation: {
      title: 'Esta reserva foi cancelada',
    },
    paymentAttemptsFailed: {
      title: 'Não pudemos processar seu pagamento',
      description:
        'Você esgotou todas as tentativas para processar seu pagamento. Por favor lige para nossa',
      textLink: 'Central de Reservas.',
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
    },
    pendingPayment: {
      title: 'Processando pagamento',
      description:
        'O pagamento da sua viagem para {to} está sendo processado. Este processo pode variar dependendo da forma de pagamento utilizada. No momento, algumas ações para a reserva não estão habilitadas.',
    },
    minor01Alert: {
      title: 'Seu itinerário foi ajustado',
      description:
        'Foi necessário fazer pequenas alterações no seu itinerário. Por favor, revise os detalhes dos horários dos seus voos.',
      button: 'Revisei os detalhes',
    },
    sharePNRAlert: {
      title: 'Um ou mais voos são operados por outra companhia aérea',
      description:
        'Este é o código de reserva: {pnr}. Para mais detalhes, visite o site deles.',
    },
  },

  tripDetailWCAG: {
    specialServicesSeeMoreWCAG:
      'Ver mais informação sobre suas solicitacões de serviços especiais para esta viagem.',
    specialServicesSeeLessWCAG:
      'Esconder a informação sobre suas solicitacões de serviços especiais para esta viagem.',
    addSpecialServices: 'Solicitar serviços especiais para esta viagem',
    sendReceiptWCAG: 'Enviar um recibo desta viage.',
    printItineraryWCAG: 'Imprimir uma cópia deste itinerário.',
    addCalendarWCAG:
      'Adicionar as datas desta viagem ao calendario de sua preferencia.',
    renameTripWCAG:
      'RENOMEAR, botão. Use esta opção para mudar o nome desta viagem.',
    removeTripWCAG:
      'EXCLUIR, botão. Use esta para remover esta viagem do seu Painel de Minhas Viagens.',
    selectSeatsWCAG:
      'Selecionar Assentos, botão. Use este botão para selecionar os assentos de cada passageiro nesta viagem',
    requestUpgradeWCAG:
      'Solicitar Upgrade, botão. Use este botão para Solicitar um upgrade para cada passageiro nesta viagem',
    cancelReservationWCAG:
      'Cancelar Reservação, botão. Use esta opção para ser redirecionado ao fluxo de cancelação de reservacão.',
    changeItineraryButtonWCAG:
      'Mudar itinerário, botão. Use esta opção para ser redirecionado ao fluxo de cancelação de reservacão',
  },

  tripsButtons: {
    backToTripHub: 'Voltar para Minhas Viagens',
    backToFindReservation: 'Encontrar outra reserva',
    save: 'Guardar em Minhas Viagens',
    saved: 'Viagem Guardada',
  },

  tripsButtonsWCAG: {
    backToTripHubWCAG: 'Voltar para Minhas Viagens',
    backToFindReservationWCAG: 'Voltar para Encontrar Sua Reserva',
    saveWCAG: 'Guardar esta viagem no seu painel de Minhas Viagens',
    savedWCAG: 'Trip Saved',
    findReservation: 'Pesquise sua reserva, botão.',
  },

  accordionSection: {
    seeMoreCTA: 'Ver mais',
    seeLessCTA: 'Ver menos',
    specialServices: 'Serviços especiais',
    specialServicesHyphen: '—',
    withMeal: 'Refeição',
    withSpecialAssistance: 'Assistencia especial',
    withoutSpecialServicesAdded: 'Sem serviços especiais',
  },

  pastTripsDetail: {
    itinerary: 'Itinerário de viagem',
    from: 'Desde',
    toward: 'Para',
    date: 'Data',
    flight: 'Voo',
    operated: 'Operado por',
    passenger: 'Passageiros em esta reserva',
    name: 'Nome do passageiro',
    number: 'Número de bilhete',
    nameAndNumber: 'Nome e número de bilhete',
  },
  memberMenu: {
    logOut: 'Desconectar',
    myAccount: 'Minha conta',
    greeting: 'Olá,',
    status: 'Status',
    connectMiles: '# ConnectMiles',
    balance: 'Saldo de milhas acumuladas',
  },
  memberMenuWCAG: {
    pageDescription:
      'Menu de membros ConnectMiles: Lhe permite se desconectar, acessar sua conta ConnectMiles e ver seu número e status de ConnectMiles',
    connectMilesLogo: 'Logotipo de ConnectMiles',
    logOut:
      'Botão desconectar: Pressione Enter para se desconectar da sessão de sua conta ConnectMiles',
    myAccount:
      'Botão minha conta: Pressione Enter para ser redirecionado à pagina de sua conta ConnectMiles',
    connectMiles: 'Número de ConnectMiles',
  },
  copaSPTooltip: {
    description:
      'Copa Showpass é nosso sistema de entretenimiento de bordo, que lhe permite reproducir conteúdos de forma sem fios em seu dispositivo através de nosso aplicativo Copa Airlines.',
    linkText: 'Aprenda mais',
  },
  touchScreenOnDemand: {
    description:
      'Tela de toque individual é nosso sistema de entretenimiento pessoal, onde você pode utilizar telas localizadas no seu assento para selecionar, controlar e reproduzir conteúdo de sua preferencia.',
  },
  overheadScreen: {
    description:
      'Tela suspensa é nossa opção compartilhada de entretenimiento de bordo. Veja algumas das melhores películas do momento e os shows mais populares em nossas telas suspensas.',
  },
  upcomingTripCardWCAG: {
    description:
      'Grupo Sua Próxima Viagem. Neste grupo poderá ver e gerir os detalhes da sua próxima viagem a {destination} no {departureDate}.',
    btnSeeReservation: 'Ver os detalhes desta viagem.',
    btnCheckIn: 'Check-In, botão',
  },
  saveTripWCAG: {
    description:
      'Botão. Viagem guardado para {destination} no {departureDate}. Use este botão para ver e gerir os detalhes desta viagem.',
  },

  FAQ: {
    title: 'Perguntas frequentes',
    viewMore: 'Mais perguntas frequentes',
    linkViewMore:
      'https://help.copaair.com/hc/pt-br/categories/360004256754-Perguntas-mais-freq%C3%BCentes',
    contact: 'Obter ajuda',
    linkContact: '{copa_url}/pt-gs/atendimento-ao-cliente/',
    listOfFQA: {
      equipmentAdd:
        'Posso levar mais malas das que aparecem na seção de Límite de Bagagem?',
      equipmentAddBody:
        'Pode, sim! Durante o registro de suas bagagens, nossos agentes podem lhe assistir com o registro de bagagens adicionais. Você deverá pagar uma taxa de bagagem adicional. Consulte nossas {link} para mais informação. Cuidando da sua saúde e aquila de nossos colaboradores, muitos aeroportos implementaram protocolos de biossegurança. Lembre-se de chegar ao aeroporto com suficiente tempo para realizar seu processo de registro de bagagens sem inconvenientes.',
      nameLinkequipmentAdd: 'políticas de bagagem',
      linkequipmentAdd:
        '{copa_url}/pt-gs/informacoes-ao-viajar/informacoes-sobre-bagagem/',
      webCheckIn: 'Posso fazer Web Check-In através de Minhas Viagens?',
      webCheckInBody:
        'Pode, sim! A opção de fazer Check-In se habilitará 24 horas antes do seu voo. Adiante o processo de Check-In preenchendo sua informação pessoal na seção de Informação de Passageiro.',
      accumulateMiles: 'Cómo posso acumular milhas por minha viagem?',
      accumulateMilesBody:
        'Para acumular valiosas milhas por sua viagem, insire o seu número de ConnectMiles ou o número do seu programa de passageiro frequente na seção de Informação de Passageiro. Se ainda não é membro de nosso programa ConnectMiles, pode preencher nosso {link} para se inscrever e depois adicionar seu novo número na reserva.',
      linkAccumulateMiles: '{copa_url}/pt-gs/enrollment',
      nameLinkAccumulateMiles: 'formulario de afiliação',
      selectSeat: 'Cómo posso selecionar um assento antes da minha viagem?',
      selectSeatBody:
        'Pode selecionar um assento através da seção de Informação de Passageiro. Dependendo da tarifa que você adquiriu, pode selecionar um assento de forma gratuita se aplicar, ou comprar um assento desde o momento que sua reserva fosse confirmada. Temos diferentes tipos de assentos para usa comodidade. Pode encontrar mais detalhes em nossa {link}.',
      linkSelectSeat:
        '{copa_url}/pt-gs/legal/termos-e-condicoes/termos-condicoes-reserva-avancada-de-assentos/',
      nameLinkSelectSeat: 'página de informação de assentos',
    },
  },

  alertScheduleChanges: {
    descriptionSing: {
      start:
        'Houve uma atualização no seu voo desde {from} para {to}. Por favor entre em contato com nossa ',
      end: ' para ajudá-lhe. Desculpe os inconvenientes.',
    },
    descriptionPlu: {
      start:
        'Houve uma atualização em mais de um dos seus voos. Por favor entre em contato com nossa ',
      end: ' para ajudá-lhe. Desculpe os inconvenientes.',
    },
    link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
    linkText: 'Central de Reservas',
  },

  addToCalendar: {
    subjectFlight: 'Voo CM',
    subjectFrom: 'de',
    subjectTo: ' para',
    reservationCode: 'Código de Reserva:',
    itinerary: 'ITINERÁRIO: CM Voo',
    departure: 'Saída:',
    on: 'no',
    at: 'às',
    arrival: 'Chegada:',
    fareFamily: 'Familia Tarifária:',
    travelerInfo: 'Información do passageiro:',
  },

  retract: {
    header: {
      title: 'Cancelar sua reserva',
      infoText: 'Sua viagem para {destination}',
    },
    title: 'Quer cancelar esta reserva?',
    subTitle:
      'Sua reserva está dentro do período de compra flexível de 24 horas. O que significa que ao fazer o cancelamento, você receberá o reembolso total do valor pago, conforme indicado em nossa política. Por favor, leia cuidadosamente as informações abaixo antes de proceder.',
    dateTrip: 'Datas de viagem',
    reservationCode: 'Código de reserva',
    fareFamily: 'Familia tarifária',
    refundDetails: 'Detalhes do reembolso',
    baseAirfare: 'Tarifa aérea',
    taxes: 'Impostos',
    seats: 'Assentos',
    subtotal: 'Subtotal',
    noRefundable: 'Não reembolsável',
    refundTotal: 'Total a reembolsar',
    infoImportant: 'Informações importantes sobre seu cancelamento',
    item1: {
      start:
        'Copa Airlines oferece reembolsos completos para as reservas que cumpram os requerimentos de nossa política de compra flexível de 24 horas. Conheça os detalhes desta política, ',
      linkText: 'aqui',
      link: '{copa_url}/pt-gs/legal/termos-e-condicoes/termos-condicoes-cancelamento-flexivel/',
      end: '.',
    },
    item2:
      'O valor da reserva será reembolsado na forma de pagamento utilizada para efetuar a compra.',
    item3:
      'O valor do seu reembolso deve se refletir num prazo de não mais de dois (2) estados da sua conta bancaria ou seu cartão de crédito após a data do seu pedido de reembolso.',
    cancelReservation: 'Cancelar esta reserva ',
    insuranceRefund1:
      'Você comprou um seguro de viagem fornecido por Chubb por um montante de {value} {currency}. Para processar o reembolso de este serviço, deverá comunicarse diretamente com Chubb através de seus',
    insuranceRefundLink: 'pontos de contato',
    insuranceRefundUrl:
      'https://copa.chubbtravelinsurance.com/PA/Contact-Chubb?sessionLocale=en',
    insuranceRefund2:
      ', utilizando seu código de reserva como referência de compra.',
    cancelSummary:
      'Sua reserva será cancelada na sua totalidade e você receberá um reembolso por {amount}',
    refundLink: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
    error: {
      toast: 'Ocorreu um erro, tente novamente.',
      alert: {
        start:
          'Desculpe! Devido a um erro, não pudemos cancelar sua reserva. Por favor, tente novamente mais tarde ou entre em contato com nossa ',
        end: '.',
        link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
        linkText: 'Central de Reservas',
      },
    },
  },

  shoppingCart: {
    title: 'Extras de viagem',
    subtitulo:
      'Estes são os extras de viagem que você escolhou. Pode pagá-los agora ou continuar gerenciando sua viagem.',
    verDetalle: 'Ver detalhes',
    subtotal: 'Subtotal',
    impuestos: 'Impostos',
    total: 'Total ',
    cerrar: 'Fechar',
    pagoTotal: 'TOTAL A PAGAR',
    impuetso: ' em impostos',
    tiempoRestante: 'Tempo restante:',
    pagarExtras: 'Pagar extras de viagem',
    seatsPremium: 'Assento Premium ',
    barraTitle:
      'Sua seleção de assentos expirará em {time}. Complete sua compra agora.',
    expireTitle: 'Sua seleção de assentos expirou',
    expireSubTitle:
      'O tempo máximo para completar a compra dos assentos selecionados expirou. Você pode selecionar assentos novamente.',
    expireBtn: 'Voltar para a reserva',
    WCAGBoton: 'Botão. Voltar para a reserva.',
    WCAGClose: 'Botão. Fechar modal.',
    WCAGTitle: 'Modal de seleção expirada',
  },

  extraTrip: {
    title: 'Extras de viagem comprados',
    subtitle: 'Estes são os extras de viagem que você já comprou.',
    tableDescription: 'Descripção',
    tableTraveler: 'Passageiro',
    tableFlight: 'Voo',
    tableValue: 'Valor',
    seat: 'Assento',
    'extra-bag': 'Bagagem',
    'pet-in-cabin': 'Animal de estimação na cabine',
    'sport-equipment': 'Bagagem esportiva',
    'music-instrument': 'Bagagem de musica',
  },

  reservationConfirmed: {
    title: 'Estamos processando sua reserva',
    subtitle1: 'Estamos processando sua viagem para {to}.',
    subtitle2: ' Seu código de reserva é ',
    subtitle3:
      'Uma vez processemos seu pagamento, você receberá um email de comfirmação a ',
    subtitle4: '.',
    summary: 'Resume de compra',
    successfully: 'Estamos processando seu pagamento de {monto}.',
    authorizationCode: 'Código de autorização',
    nowYouCan: 'Agora você pode:',
    downloadCopaApp: 'Baixar o Aplicativo de Copa Airlines  ',
    downloadCopaAppText:
      'Com o Aplicativo de Copa Airlines, você pode receber notificações sobre o estado do seu voo em tempo real, fazer Check-In e acessar ao entretenimento a bordo',
    downloadCopaAppSubText:
      '* O entretenimento a bordo só aplica para voos que tenham o sistema Copa Showpass.',
    laptobTitle: 'Manejar sua reserva através de Minhas Viagens ',
    laptobText:
      'Através da seção de Minhas Viangens em copa.com, você poderá escolher ou mudar seus assentos, ver seu itinerário de viagem e fazer mudanças em sua reserva.',
    qrTitle: 'Fazer Web Check-In 24 horas antes do seu voo',
    qrText:
      'Poupe tempo no aeroporto fazendo Web Check-In desde 24 horas antes do seu voo. Você poderá escolher ou mudar seus assentos e obter seus cartões de embarque digitais.',
    btnGoToReservation: 'Ir à reserva',
    lateBookingFee: 'Taxa de reserva atrasada',
    serviceFee: 'Taxa de serviço',
  },

  payReservation: {
    titleBar: 'Pagar reserva',
    title: 'Detalhes de viagem',
    subTitle:
      'Revise cuidadosamente sua reserva antes de proceder com a compra.',
    resumen: 'Resume da reserva',
    btn: 'Continuar à seleção de assentos',
    layover: 'escala',
    airfare: 'Tarifa aérea',
    uplift: {
      description: 'Você completou esta compra usando Uplift.',
      linkText: 'Mais informação.',
      link: '{copa_url}/pt-gs/descubra-copa-airlines/uplift/',
    },
    alerts: {
      missingMilesTitle: 'Você está a {miles} milhas para comprar esta reserva',
      missingMilesText:
        'Garanta esta viagem! Temos diferentes opções para que você possa completar esta reserva: {link}',
      buyMiles: 'Comprar Milhas',
      buyMilesLink:
        'https://miles.copaair.com/login.html?product=buy&language=pt',
      restrictedAccountTitle: 'Sua conta está restrita',
      restrictedAccountText:
        'Por questões de segurança, sua conta foi bloqueada temporariamente. Entre em contato com a {link} do ConnectMiles para obter assistência.',
    },
    redemptions: {
      miles: 'Milhas',
      others: 'Outros',
      passengersGrouped: `{itemCount} {itemCount, plural,
        one { passageiro}
        other { passageiros}
      }`,
    },
  },

  taxes: {
    smallTitle: 'TOTAL DA RESERVA',
    bigTitle: 'Detalhe de taxas e impostos',
    titleDescription: 'DESCRIPÇÃO',
    titleMonto: 'MONTANTE',
    total: 'Total taxas e impostos',
  },

  pseInformation: {
    title: 'Informação de pagamento (PSE Colômbia)',
    subtitle:
      'Estas são as informações relacionadas à compra para referência futura.',
    bank: 'Banco',
    date: 'Data de transação',
    status: 'Status',
    pendingStatus:
      'Transação pendente (verifique se o débito foi efetivado no banco)',
    approvedStatus: 'Transação aprovada',
    orderReference: 'Referência do pedido',
    ipAddress: 'Endereço IP',
    value: 'Valor',
    transaction: 'Transação',
    descriptionLabel: 'Descrição do pagamento',
    descriptionText: 'Compra na Copa Airlines Colômbia',
    transactionNumber: 'Número de referência',
    company: {
      title: 'Contato da empresa',
      companyLabel: 'O negócio',
      companyText: 'COMPAÑÍA PANAMEÑA DE AVIACIÓN S.A.',
      nitLabel: 'NIT',
      nitText: '860.025.338-2',
      addressLabel: 'Endereço',
      addressText:
        'AV EL DORADO N° 103 – 08 INT 1 TERMINAL AEREO SIMON BOLIVAR Bogotá',
      phoneNumberLabel: 'Telefone',
      phoneNumberText: '(571) 3578080',
    },
  },

  originalItinerary: {
    accordiontitle: 'Ver itinerário original',
    subtitle: 'Uma vez confirmado o novo itinerário, este não será mais válido',
    close: 'Ver detalhes',
    open: 'Ocultar detalhes',
    noChanges: 'Sem mudança',
    flightModified: 'O voo {flight} sofreu uma mudança',
  },

  onBoardingModal: {
    title:
      'Fizemos alterações no itinerário desta viagem. Sua ação é necessária.',
    descriptionBold:
      'Tivemos que fazer uma mudança de itinerário em um ou mais segmentos da sua viagem para {destination}.',
    description2:
      'Por favor reveja o novo itinerário para esta reserva; você terá a opção de aceitar as mudanças ou modificar as datas da viagem.',
    subtitle: 'Siga estas etapas para aceitar ou modificar o novo itinerário:',
    step1: 'Etapa 1',
    step1des: '— Revise o itinerário de viagem atualizado.',
    step2: 'Etapa 2',
    step2des: '— Aceite ou modifique o novo itinerário.',
    step3: 'Etapa 3',
    step3des: '— Continue para Minhas viagens para confirmar sua seleção.',
    newItineraryBtn: 'Ver novo itinerário',
    ctaWcag: 'Clique aqui para revisar seu novo itinerário',
    buttonWcag: 'Clique aqui para fechar e ir para o seu roteiro',
  },

  loginBox: {
    title:
      'Faça login na sua conta ConnectMiles para continuar com esta reserva',
    disclaimer:
      'Você deve estar logado com a conta com a qual originalmente fez esta reserva.',
    button: 'Iniciar Sessão e continuar',
  },

  loginSRT: {
    title: 'Faça login na sua conta ConnectMiles para continuar.',
    userBox: 'Insira seu usuário',
  },

  // ////////////////////////////// end translations My trips //////////////////////////////////
  languages: {
    english: 'English',
    spanish: 'Español',
    portuguese: 'Português',
    french: 'Français',
    dropdownDescription: 'IDIOMA/LANGUAGE/LÍNGUA',
  },

  headerNavigation: {
    signup: 'Afilie-se',
    language: 'Língua',
    loggedInAs: 'Olá, {name}!',
    logout: 'Sair',
    miles: '{number} milhas',
  },
  loginForm: {
    user: 'E-mail, número de ConnectMiles ou nome de usuário',
    passwordField: 'Senha',
    loginButton: 'Iniciar Sessão',
    logoutButton: 'Finalizar sessão',
    inputPlaceHolderUser: 'Insira seu usuário',
    inputPlaceHolderPrivatePhrase: 'Insira sua senha',
    forgotPrivatePhrase: 'Esqueceu sua senha?',
    account: 'Não tem uma conta?',
    accountLink: ' Crie uma agora.',
  },
  loginFormAlerts: {
    loginError:
      'Desculpe! As informações fornecidas não estão de acordo com nossos registros. Confire as palavras usadas e tente novamente.',
    logOutError: 'Houve um erro ao tentar fechar sua sessão. Tenta de novo.',
    logInUnknownError: 'Ocorreu um erro ao atualizar. Tente novamente.',
    invalidCredentials:
      'As informações fornecidas não estão de acordo com nossos registros. Confira as palavras usadas e tente novamente.',
    blockedAccount: {
      title:
        'Por questões de segurança, sua conta foi bloqueada temporariamente. Entre em contato com a central de atendimento do ConnectMiles para obter ajuda. {link}',
      link: '{copa_url}/pt-gs/',
      text: 'Central de Atendimento',
    },
    wrongInformation:
      'As informações fornecidas não estão de acordo com nossos registros. Confire as palavras usadas e tente novamente.',
  },
  clientService: {
    callCenter: 'central de atenção',
    linkCallCenter:
      '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
    serviceCenter: 'Central de Atendimento',
    linkServiceCenter: '{copa_url}/pt-gs/connectmiles',
  },
  findReservationAlerts: {
    notFound:
      'Viaja em um grupo grande que requere assistência de um agente. Dirige-te ao aeroporto para fazer Check-In.',
    tooEarly:
      'Ainda é muito cedo para fazer o check-in do seu voo. Retorne quando o check-in estiver aberto, 24 horas antes do embarque.',
    tooLate:
      'Não é mais possível realizar o Check-In. Consulte um agente do aeroporto para obter ajuda.',
    otherCarrier:
      'Seu primeiro voo será operado por uma companhia aérea parceira. Acesse entrar através do seu site para fazer o check-in.',
    largeGroup:
      'Sua viagem está associada a um grupo grande, o que requer a assistência de um agente do aeroporto. Faça o check-in no guichê do aeroporto.',
    outOfSync:
      'Parece que a sua reserva precisa ser analisada por um agente de serviço. Entre em contato com a {link} para obter ajuda.',
    noETKT:
      'Parece que a sua reserva precisa ser analisada por um agente de serviço. Entre em contato com a {link} para obter ajuda.',
    cancelledFlights:
      'Parece que a sua reserva precisa ser analisada por um agente de serviço. Entre em contato com a {link} para obter ajuda.',
    restrictedScenarios:
      'Há uma solicitação especial associada à sua reserva. Faça o check-in no guichê do aeroporto.',
    wrongLastName:
      'O sobrenome informado não está de acordo com os nossos registros. Confira as palavras usadas e tente novamente.',
    incorrectETKT:
      'Desculpe! Não foi possível encontrar a reserva com as informações fornecidas. Confira as palavras usadas e tente novamente.',
    eTicketRequired:
      'Parece que a sua reserva precisa ser analisada por um agente de serviço. Entre em contato com a {link} para obter ajuda.',
    groupLarge:
      'Sua viagem está associada a um grupo grande, o que requer a assistência de um agente do aeroporto. Faça o check-in no guichê do aeroporto.',
    PNRNoSegments:
      'Parece que a sua reserva precisa ser analisada por um agente de serviço. Entre em contato com a {link} para obter ajuda.',
    serviceUnavailable:
      'Ocorreu um erro ao encontrar sua reserva. Tente novamente.',
  },
  findReservationWCAG: {
    pageTitle: 'Minhas viagens',
    pageDescription:
      'Primeira página do fluxo de Minhas Viagens, você precisa fornecer o seu número de reserva e sobrenome para poder encontrar sua reserva.',
    textSubtitle:
      'Desde Minhas Viagens, você pode ver, gerar e guardar suas reservas de Copa Airlines.',
    textWellcome: 'Para começar, encontremos sua reserva.',
    feebackBetaLink:
      'Você está usando a versão beta do web check in. Pressione Enter para enviar um e-mail com seus comentários e sugestões do seu aplicativo de e-mail',
    oldCheckinUrl: 'To check-in using the old version, click here ',
    copaLogoLink: 'Logo de Copa. Na página de início da Copa',
    copaLogoAltMobile: 'Logotipo da Copa Ir para a página inicial da Copa',
    extendedCopaLogo: 'Logotipo da Copa Ir para a página inicial da Copa',
    cmLogoAlt: 'Logotipo da ConnectMiles',
    cmSignupButton:
      'Si você não tiver uma conta do ConnectMiles, pressione Enter para criar uma agora.',
    cmLoginButton:
      'Faça o login, você pode usar o seu perfil de ConnectMiles para encontrar suas viagens anda mais rápido. Pressione Enter para inserir as informações',
    cmCloseLoginButton:
      'Presione enter para cerrar este panel de Connect miles',
    cmLogoutButton: 'Pressione enter para sair de sua sesion de ConnectMiles.',
    languageSelection:
      'Você está em um campo suspenso com 3 opções, use as teclas para cima e para baixo e pressione Enter para selecionar seu idioma preferido',
    reservationSearchCodeField:
      'Você pode digitar um código alfanumérico de 6 dígitos no caso de ter um código PNR ou um código numérico de 13 dígitos, caso você tenha um número de bilhete eletrônico ou um número de 9 dígitos, se for um número do ConnectMiles.',
    reservationSearchLastNameField:
      'Digite o sobrenome que aparece na sua reserva',
    searcReservationButton:
      'Pressione Enter para pesquisar sua reserva e continue com o processo de check-in.',
  },
  loginFormWCAG: {
    pageDescription:
      'Caixa de Login Connect Miles: permite que você tenha acesse ao seu perfil de membro. Por favor, use a tecla Escape sempre que quiser voltar ao menu principal.',
    userNameField: 'Insire seu e-mail, número de ConnectMiles ou usuário',
    passwordField: 'Insire sua senha do ConnectMiles',
    loginButton:
      'Pressione Enter para fazer login com as credenciais digitadas',
    forgotPrivatePhrase:
      'Pressione enter para ir para ConnectMiles e recuperar sua senha',
    joinNow:
      'Se você não tiver uma conta do ConnectMiles, pressione Enter para criar uma agora',
  },
  customModalWCAG: {
    closeButton: 'Botão fechar, pressione enter para fechar o pop up.',
  },
  searchNumberForm: {
    inputLabelNumber: `Código de reserva ou número de bilhete eletrônico`,
    inputPlaceholderNumber: 'Insira o código/número',
    inputPlaceholderLastName: 'Insire o sobrenome',
    inputLabelLastName: 'Sobrenome',
    search: 'Encontre sua reserva',
  },
  ancillaries: {
    title: 'Opções de Viagem',
    description: 'Melhore a sua experiência de viagem com estas opções.',
    carTitle: 'Aluguel de carro',
    carDescription: 'Diferentes opções para aproveitar do nossos destinos.',
    carLinkDescription: 'Reservar agora',
    carLink: 'https://cars.cartrawler.com/copa/br/?clientId=538733',
    hotelTitle: 'Reservar hotel',
    hotelDescription: 'Encontre a acomodação perfeita para sua viagem.',
    hotelLinkDescription: 'Pesquisar hotéis',
    hotelLink:
      'https://sp.booking.com/index.html?aid=2027762;label=pagemanagebooking-ban-2027762-click_brand-mmbcanales',
    insuranceTitle: 'Seguro de viagem',
    insuranceDescription: 'Você já tem seu seguro de viagem?',
    insuranceLinkDescription: 'Assegure a seu viagem aqui',
    redemption: {
      milesTitle: 'Reabasteça seu saldo de milhas',
      milesDescription:
        'Mais milhas agora, mais aventuras mais tarde. Reabasteça seu saldo de milhas e comece a planejar suas próximas férias.',
      milesCTA: 'Compre milhas',
      milesLink: 'https://miles.copaair.com/?product=buy&language=pt',
      hotelsTitle: 'Ganhe mais milhas nas suas estadías!',
      hotelsDescription:
        'Plataforma criada exclusivamente para membros do ConnectMiles onde poderá ganhar desde 500 até 15.000 milhas por noite com suas reservas de hotel. Seleccione entre mais de 400.000 hotéis e ganhe milhas que permitir-lhe-ão continuar viajando a seu destino sonhado.',
      hotelsCTA: 'Reserve agora!',
      hotelsLink:
        'https://connectmileshotels.copaair.com/cm/home/pt-BR/?t=1625603496179',
    },
  },
  ancillariesWCAG: {
    contentDescription:
      'Seção de Opções de Viagem. Nesta seção, você poderá escolher entre nossas opções de viagem adicionais para melhorar sua viagem.',
    carImageDescription: 'Imagem. Familia num carro.',
    carLinkDescription: 'Reservar agora.',
    hotelImageDescription: 'Imagem. Homem num quarto de hotel.',
    hotelLinkDescription: 'Pesquisar hotéis.',
    insuranceImageDescription: 'Imagem. Mulher perto de um rio num bosque',
    insuranceLinkDescription: 'Assegure sua viagem aqui.',
  },
  entertainment: {
    title: 'Entretenimento',
    description:
      'Explore nossas opções de entretenimento e desfrute de toda nossa seleção de conteúdo.',
    copaShow: 'Copa Showpass',
    showpassDescription:
      'Nosso sistema de entretenimento a bordo permitirá que você curta filmes, séries, músicas e revistas usando seu dispositivo pessoal.',
    showpassLinkDescription: 'Saiba mais aqui',
    showpassLink:
      '{copa_url}/pt-gs/descubra-copa-airlines/experiencia-a-bordo/entretenimento/',
    panoramaTitle: 'Panorama de las Américas',
    panoramaDescription:
      'Descubra nossa revista digital e desfrute de sua ampla gama de conteúdos em qualquer lugar. O conteúdo só está disponível em espanhol e inglês.',
    panoramaLinkDescription: 'Descubra aqui',
    panoramaLink: 'https://www.revistapanorama.com/digital/',
  },
  entertainmentWCAG: {
    contentDescription:
      'Seção de Entretenimento. Nesta seção, você poderá escolher entre nossas opções de entretenimento para desfrutar de sua ampla gama de conteúdos.',
    showpassImageDescription: 'Imagem. Pessoa segurando uma tablet',
    showpassLinkDescription:
      'Link para a página do Copa Showpass. Toque duas vezes para ir para nossa página de entretenimento a bordo e saber do que se trata.',
    panoramaImageDescription:
      'Imagem. Praia e telefone com o título da revista.',
    panoramaLinkDescription:
      'Link para nossa revista digital. Toque duas vezes para ir para a página de Panorama de las Américas. O conteúdo só está disponível em espanhol e inglês.',
  },
  whoPage: {
    title: 'Quem viaja com você?',
    description:
      'Você está viajando em uma reserva de grupo; e encontramos mais de um viajante com as informações fornecidas',
    instruction: 'Selecione os passageiros que viajam com você:',
    chooseWhoButton: 'Prossiga com passageiros selecionados',
  },
  whoPageWCAG: {
    modalContainer:
      'Você está viajando em uma reserva de grupo e há mais de um passageiro com as mesmas informações fornecidas. Por favor, selecione na lista o seu nome e o nome dos passageiros para os quais você deseja fazer  para continuar.',
    checkboxWho: 'nombre de pasajero ',
    buttonSelectedPassengers: 'Pressione Enter para passageiros selecionados.',
    closeModal: 'Pressione Enter para fechar esta seção.',
  },
  travelerInfoForm: {
    year: 'Ano',
    month: 'Mês',
    day: 'Dia',
    month01: 'Janeiro',
    month02: 'Fevereiro',
    month03: 'Março',
    month04: 'Abril',
    month05: 'Maio',
    month06: 'Junho',
    month07: 'Julho',
    month08: 'Agosto',
    month09: 'Setembro',
    month10: 'Outubro',
    month11: 'Novembro',
    month12: 'Dezembro',
    reviewTravelerInfo: 'Informações do viajante',
    travelerNOfN: 'Viajante {current} do {total}',
    flightCheckIn: 'Minhas Viagens',
    passportInformation: 'Informações Sobre o Passaporte',
    travelerInfoIntro:
      'Verifique se as informações fornecidas sobre o passaporte e a viagem de cada passageiro estão completas e precisas. Caso você não atenda a essa exigência, as autoridades podem não permitir que você viaje ou entre na área de embarque.',
    ESTAText1:
      'É necessário que todos os cidadãos que planejem viajar aos Estados Unidos utilizando o “Visa Waiver Program” recebam uma autorização através da ESTA, “Electronic System for Travel Authorization”, antes de embarcar em um avião com destino aos Estados Unidos. ',
    ESTAText2:
      'Por favor, certifique-se de ter a autorização ESTA antes de concluir o seu Check-In.',
    multipleProfiles:
      'Você pode selecionar um viajante no seu perfil para preencher automaticamente as informações abaixo:',
    passportOptionUseProfile: 'Usar Perfil',
    inputPlaceHolderProfile: 'Selecione o Perfil',
    passportOptionManual: 'Inserir manualmente informações do passaporte',
    selectPassport:
      'Você tem dois passaportes salvos. Selecione o que deseja usar:',
    inputLabelFirstName: 'Nome',
    inputPlaceHolderFirstName: 'Digite o Nome',
    inputLabelLastName: 'Sobrenome',
    inputPlaceHolderLastName: 'Insire o sobrenome',
    dateOfBirth: 'Data de Nascimento',
    dateOfBirthDescription:
      'O formato da data é: 2 dígitos para o dia de 01 a 31, 2 dígitos para o mês de 01 a 12, 4 dígitos ao ano',
    residencyCardDateDescription:
      'O formato da data é: 2 dígitos para o dia de 01 a 31, 2 dígitos para o mês de 01 a 12, 4 dígitos ao ano',
    genderOptionMale: 'Masculino',
    genderOptionFemale: 'Feminino',
    nationality: 'País de Nacionalidade',
    inputPlaceHolderNationality: 'Insira a Nacionalidade',
    passportNumber: 'Número do Passaporte',
    inputPlaceHolderPassportNumber: 'Insira o Número do Passaporte',
    expirationDate: 'Data de Expiração',
    expirationDateDescription:
      'O formato da data é: 2 dígitos para o dia de 01 a 31, 2 dígitos para o mês de 01 a 12, 4 dígitos ao ano',
    expirationDateError: 'Insira uma data de expiração válida',
    countryOfIssue: 'País de emissão',
    inputPlaceHolderCountryOfIssue: 'Insira o país de emissão',
    email: 'E-mail para enviar o Cartão de Embarque',
    emailError: 'Inserir um endereço de e-mail válido',
    useEmailForEverybody: 'Use este e-mail para todos os viajantes',
    emergencyContactTitle: 'Contato de Emergência',
    emergencyContactLabel: 'Nome e Sobrenome',
    phoneCodeLabel: 'Código do País',
    phoneLabel: 'Número de Telefone',
    knownTravelerInformation: 'Número de Viajante Conhecido',
    travelerNumber: 'Número de Viajante Conhecido',
    inputPlaceHolderTravelerNumber: 'Insira o número',
    redressNumber: 'Número de retificação',
    redressNumberInputLabel: 'Número de retificação',
    inputPlaceHolderRedressNumber: 'Insira o número',
    frequentFlyerProgram: 'Programa Passageiro Frequente',
    inputPlaceHolderFrequentFlyerProgram: 'Selecione o programa',
    frequentFlyerNumber: 'Número de Passageiro Frequente',
    inputPlaceHolderFrequentFlyerNumber: 'Insira o número',
    warningFrequentFlyerNumber:
      'Este é um bilhete prêmio, se este não for o seu número de passageiro frequente e você o alterar, poderá perder os benefícios do proprietário da conta.',
    emergencyContact: 'Contato de Emergência',
    fullName: 'Nome Completo',
    inputPlaceHolderFullName: 'Digite Nome Completo',
    PhoneNumber: 'Número de Telefone',
    inputPlaceHolderPhoneNumber: 'Digite Número de Telefone',
    countryOfResidence: 'País de Residência',
    inputPlaceHolderCountryOfResidence: 'Insira o País de Residência',
    noResultMsgCountryOfIssue: 'Nenhum resultado é exibido',
    noResultMsgNationality: 'Nenhum resultado encontrado',
    noResultMsgCountryOfResidence: 'Nenhum resultado encontrado',
    noResultMsgState: 'Nenhum resultado encontrado',
    gender: 'Gênero',
    whereAreYouStaying: 'Onde você irá se hospedar en EA?',
    stayingStreetAddress: 'Endereço',
    inputPlaceHolderStreetAddress: 'Insira o endereço',
    stayingZipCode: 'Código postal',
    inputPlaceHolderZipCode: 'Insira o Código postal',
    stayingCity: 'Cidade',
    inputPlaceHolderCity: 'Entre na Cidade',
    stayingState: 'Estado',
    inputPlaceHolderState: 'Digite o Estado',
    usResidencyCard: 'Cartão de residência dos EUA',
    residencyCardNumber: 'Número do cartão',
    inputPlaceHolderCardNumber: 'Insira o número de Cartão',
    residencyCardDate: 'Data de Expiração',
    sentenceInfo:
      'Por favor, certifique-se de fornecer informações completas e precisas sobre os detalhes de viagem e passaporte de cada passageiro. Se você não cumprir este requisito, as autoridades podem não permitir que você viaje ou entre na área de embarque.',
    addButton: 'Adicionar',
    removeButton: 'Remover',
    estaText:
      'É necessário que todos os cidadãos que planejem viajar aos Estados Unidos utilizando o “Visa Waiver Program” recebam uma autorização através da ESTA, “Electronic System for Travel Authorization”, antes de embarcar em um avião com destino aos Estados Unidos.Por favor, certifique-se de ter a autorização ESTA antes de concluir seu registro',
    enterFFProgram: 'Entre no programa de passageiro frequente',
    optionalSectionLabel: '(Opcional)',
    saveButton: 'Continuar',
    inputLabelEmail: 'E-mail para enviar o Cartão de Embarque',
    inputEmailWCAG:
      'Type all the Emails to which will send you the Boarding Passs, separating with commas.',
    closeInputEmailWCAG: 'Press enter to delete {email} from the list',
    multiEmailWcag:
      'Adicionalmente, puedes reenviar los pases de abordar a cualquier otro correo electrónico que desees. Para agregar varias direcciones utiliza la coma  para separarlas.',
    inputPlaceHolderemail: 'Digite E-mail',
    redemptionText:
      'Este é um bilhete prêmio, se este não for o seu número de passageiro frequente e você o alterar, poderá perder os benefícios do proprietário da conta.',
    connectMilesSectionTitle:
      'Você já tem informações armazenadas em seu perfil, selecione o perfil desejado para preencher automaticamente as informações do passaporte necessárias abaixo.',
    prePopulateEmail: 'Use este email para todos os passageiros',
    prePopulatedWhereAreYouStaying:
      'Use este endereço para todos os passageiros',
    cmEnrollmentTitle:
      'Ainda não é membro ConnectMiles? Afilie-se agora gratuitamente e receba 500 milhas extras nesta viagem!',
    cmEnrollmentParagraph:
      'Afilie-se ao ConnectMiles e comence a ganhar milhas e beneficios hoje mesmo. Quando você se inscrever, enviaremos um email ao {email} para que finalize a ativação da sua conta. ConnectMiles solicita que cada membro utilize un e-mail único para criar a sua conta.',
    cmEnrollmentAccept: 'Quero afiliar-me ao ConnectMiles e aceito os {link}',
    cmEnrollmentLink:
      '{copa_url}/pt-gs/legal/termos-e-condicoes/termos-condicoes-connectmiles/',
    cmEnrollmentLinkText: 'Termos e Condições do programa.',
    linkAgreeWcag:
      'Pressione enter para ver os Termos e Condições do programa.',
    updaProfileCheckBox:
      'Salve os dados atualizados no meu perfil do ConnectMiles',
    inTransitCheckBox: 'Em trânsito',
    inTransitCheckBoxWCAG:
      'Pressione para indicar que você está em trânsito nos Estados Unidos',
  },
  formValidations: {
    requiredFieldError: 'Este campo é obrigatório',
    max20CharactersError:
      'Tem insirado muitos caracteres. O número máximo de caracteres es 20.',
    max32CharactersError: 'Máximo de 32 caracteres permitidos',
    max50CharactersError: 'Máximo de 50 caracteres permitidos',
    max64CharactersError: 'Máximo de 64 caracteres permitidos',
    max100CharactersError:
      'Tem insirado muitos caracteres. O número máximo de caracteres es 100.',
    max255CharactersError: 'Máximo de 255 caracteres permitidos',
    onlyLettersError: 'Apenas letras são permitidas',
    onlyNumbersError: 'Apenas números são permitidos',
    min3AndMax12CharactersError:
      'Mínimo de 3 e máximo de 12 caracteres permitidos',
    alphaNumericError: 'Letras e números permitidos',
    dateFormatError: 'Formato de data inválido',
    dateLowerThanCurrentDateError:
      'É necessária uma data antes da data de hoje',
    dateGreaterThanCurrentDateError: 'Deve ser maior que a data atual',
    dateOfBirthError: 'Insira uma data válida de nascimento',
    emailFormatError: 'Formato de  e-mail inválido',
    invalidFormat: 'Formato inválido',
    lengthZipCode: 'Mínimo de 5 e máximo de 10 caracteres permitidos',
    lengthAdress: 'Mínimo de 1 e máximo de 31 caracteres permitidos',
    lengthCity: 'Mínimo de 1 e máximo de 15 caracteres permitidos',
    lengthEmergencyContact: 'Máximo de 15 caracteres permitidos',
    lengthPhoneCode: 'Máximo 8 caracteres permitidos',
    lengthPhoneNumber: 'Máximo de 10 caracteres permitidos',
    ssrRepeatedValue: 'Este serviço já foi adicionado para este passageiro.',
  },
  travelerInfoFormAlerts: {
    FFNChanged:
      'Suas informações de Passageiro frequente foram atualizadas. Obtenha um cartão de embarque atualizado com essas alterações.',
    UpdateErrorFFN:
      'Ocorreu um erro ao atualizar as informações de passageiro frequente deste viajante. Tente novamente.',
    UpdateError:
      'Algo falhou ao recuperar sua reserva. Por favor, tente novamente mais tarde.',
    UpdateDefaultError:
      'Ocorreu um erro ao atualizar as informações de passageiro frequente deste viajante. Tente novamente.',
    UpdateErrorAPIS:
      'Ocorreu um erro ao atualizar as informações de passageiro frequente deste viajante. Tente novamente.',
    saveSuccess: 'As informações do viajante foram salvas com sucesso',
    saveError:
      'Ocorreu um erro ao atualizar as informações de passageiro frequente deste viajante. Tente novamente.',
    leaveScreenWarning:
      'Você não preencheu todos os campos obrigatórios. Se voltar, terá que começar novamente. Tem certeza de que deseja voltar para o itinerário?',
    repeatedPassport:
      'O número de passaporte informado está sendo usado por outro passageiro do grupo. Digite um número único de passaporte.',
    FFNMatchError:
      'Algo deu errado! O número de passageiro frequente inserido não está de acordo com o nome do viajante. Confira as palavras usadas e tente novamente.',
    FFNNotFoundError:
      'Algo deu errado! Não é possível encontrar o número de passageiro frequente inserido. Confira as palavras usadas e tente novamente.',
    expiredPassport:
      'Seu passaporte está vencido. Digite uma data de expiração válida.',
    expiredResidenceCard:
      'Seu visto de permanência expirou. Digite uma data de expiração válida.',
    emailError:
      'Ocorreu um erro ao atualizar suas informações de e-mail. Tente novamente.',
    leavePageTitle: 'Você tem certeza que quer sair desta pagina?',
    leavePageMessage:
      'Você não terminou de preencher todos os campos obrigatórios, se voltar, perderá todas as informações e terá que recomeçar mais tarde. Tem certeza de que deseja retornar ao itinerário?',
    leavePageCTA: 'Deixe esta página',
    stayCTA: 'Fique nesta página',
    leaveTraveler: 'Tem certeza de que deseja sair?',
    leavePageTravelerMessage:
      'Você não salvou as informações do passageiro, se você for para o próximo viajante, você perderá todas as informações. Tem certeza de que quer continuar?',
    leaveTravelerCTA: 'Continuar',
    stayOnTravelerCTA: 'Fique nesta página',
    fillOutInfantInfo:
      'Você está viajando com um bebê, por favor, certifique-se de que as informações dele estejam completas antes de continuar.',
    fillOutAdultInfo:
      'Você está viajando com uma criança, por favor preencha primeiro o formulário de informações para adultos.',
    genericEnterError:
      'Algo deu errado ao carregar a pagina de informações do passageiro. Por favor, tente novamente.',
    cmEnrollmentConfirmation: 'Obrigado por escolher o ConnectMiles!',
    cmEnrollmentContinue: 'Continue',
    cmEnrollmentNumber: 'O seu número ConnectMiles é',
    cmEnrollmentEmail: 'O email da sua conta é',
    cmEnrollmentInstrucction:
      'Enviámos-lhe um email com instruções para concluir a configuração do seu novo perfil',
    cmEnrollmentError:
      'Ocorreu um erro ao criar sua conta de passageiro frequente. Tenta de novo.',
  },
  travelerInfoFormWCAG: {
    pageTitle: 'Página de informação do passageiros',
    pageDescription:
      'Para completar o fluxo de Check-In, você precisa fornecer informações, alguns campos são obrigatórios e outros são opcionais. Você está na seção de informações do {name}',
    travelerName:
      'Está na seção de informações de {name},  onde você precisa fornecer todas as informações do passaporte; Se você não concordar com isso, as autoridades não poderão permitir que você viaje ou entre na área de embarque. Se Pressionar Enter retornará ao painel.',
    backButton:
      'Se Pressionar Enter retornará ao painel, para concluir as informações, pressione Tab',
    backButtonAlt: 'Ícone de volta',
    firstName:
      'Digite seu nome, apenas letras e espaços em branco são permitidos',
    lastName:
      'Digite seu nome da familia ou "sobrenome", apenas letras e espaços em branco são permitidos',
    gender:
      'Gênero, Use a tecla de toque para mudar entre sexo masculino e feminino, depois disso pressione enter',
    passportNumber:
      'Digite seu número de passaporte, este é um campo alfanumérico, mínimo 3, máximo de 12 caracteres são permitidos',
    countryOfIssue:
      'Campo de Autocompletar, digite as três primeiras letras do seu país de emissão e pressione Enter',
    nationality:
      'Campo de Autocompletar, digite as 3 primeiras letras do seu país de nascimento e pressione Enter.',
    countryOfResidence:
      'Campo de Autocompletar, digite as 3 primeiras letras do seu país de residência e pressione Enter.',
    emergencyContact:
      'Informações de contato para uma pessoa que não está viajando com você.',
    address: 'Digite o endereço onde você vai ficar nos Estados Unidos.',
    city: 'Digite a cidade de destino',
    state: 'Campo de Autocompletar, digite as três primeiras letras do estado.',
    residentCardNumber:
      'Digite o número do seu cartão de residência. este é um campo alfanumérico, mínimo 3, máximo de 12 caracteres são permitidos.',
    residentCardExpirationDay:
      'Use as teclas de setas para cima e para baixo para selecionar o dia do expiração do seu cartão de residência.',
    residentCardExpirationMonth:
      'Use as teclas de setas para cima e para baixo para selecionar o mês do expiração do seu cartão de residência.',
    residentCardExpirationYear:
      'Use as teclas de seta para cima e para baixo para selecionar o ano do expiração do seu cartão de residência.',
    useProfileRadioB:
      'Seção de botão de rádio. Use as teclas para cima e para baixo para alternar entre as opções, a Opção 1 use seu perfil para auto completar informações.',
    manualInfoRadioB:
      'Seção de botão de rádio. Use as teclas para cima e para baixo para alternar entre as opções, Opção 2 insira manualmente as informações.',
    radioButtonPassportOptions:
      'Seção de botão de rádio. Este perfil tem mais de um passaporte, use você para cima e para baixo para selecionar qual passaporte você quer usar.',
    selectProfile:
      'Suspenso, use as teclas de seta para cima e para baixo para selecionar o perfil para autocompletar.',
    knownTravelerNumberAdd:
      'O número Traveler conhecido, é um registro de identificação dos EUA para o programa US PreCheck dos EUA, permitindo que os viajantes passem por uma inspeção de segurança acelerada em alguns aeroportos dos EUA. Pressione Enter.',
    emergencyContactAdd: 'Pressione para adicionar contato de emergência',
    emergencyContactRemove: 'Pressione para ocultar o contato de emergência',
    enterEmergencyContact: 'Nome e Sobrenome',
    enterCodeEmergencyContact: 'Código do País',
    enterPhoneEmergencyContact: 'Número de Telefone',
    knownTravelerNumberRemove:
      'Remove Known Traveler number button, press enter.',
    knownTravelerNumber:
      'Escreva o seu Número de Viajante Conhecido (TSA), é um campo alfanumérico com um mínimo de 3 caracteres e um máximo de 12 caracteres',
    redressNumberAdd:
      'O número de retificação, é um número de autorização de segurança emitido pelo Departamento de Segurança Interna para certos viajantes. pressione Enter.',
    redressNumberRemove: 'Redress number remove button.',
    redressNumber:
      'Digite o seu número de retificação, é um campo alfanumérico, mínimo de 3 e máximo de 12 caracteres são permitidos.',
    frequentFlyerAdd:
      'Se você tiver um programa de passageiro frequente, você pode inseri-lo. pressione Enterr',
    frequentFlyerRemove: 'frequent flyer remove button.',
    frequentFlyerNumber:
      'Digite seu número de passageiro frequente, é um campo alfanumérico.',
    frequentFlyerProgram:
      'Lista suspensa do programa de passageiro frequente. Use as teclas de seta para cima e para baixo para mover e selecione o programa correspondente.',
    dayOfBirth:
      'Use as teclas de seta para cima e para baixo para alterar o dia selecionado e, em seguida, pressione enter para escolher o dia do nascimento do passageiro.',
    monthOfBirth:
      'Use as teclas de seta para cima e para baixo para alterar o mês selecionado e, em seguida, pressione enter para escolher o mês do nascimento do passageiro.',
    yearOfBirth:
      'Use as teclas de seta para cima e para baixo para alterar o ano selecionado e, em seguida, pressione enter para escolher o ano do nascimento do passageiro.',
    dayOfExpiration:
      'Use as teclas de seta para cima e para baixo para alterar o dia selecionado e, em seguida, pressione enter para escolher o dia de vencimento do passaporte do passageiro.',
    monthOfExpiration:
      'Use as teclas de seta para cima e para baixo para alterar o mês selecionado e, em seguida, pressione enter para escolher o mês de vencimento do passaporte do passageiro.',
    yearOfExpiration:
      'Use as teclas de seta para cima e para baixo para alterar o ano selecionado e, em seguida, pressione enter para escolher o ano de vencimento do passaporte do passageiro.',
    email:
      'Digite o e-mail em que você gostaria de receber seu cartão de embarque.',
    saveTIFButton:
      'Pressione Enter para salvar as informações e voltar para o painel de controle. Se houver mais de um passageiro, ele será redirecionado para o próximo passageiro.',
    continueModal:
      'Você não completou todas as informações do passaporte, se você pressionar enter, você perderá as informações. Se você clicar Tab você pode ficar nesta página e preencher as informações.',
    stayModal:
      'Pressione enter para permanecer na página de informações do viajante e preencha os campos obrigatórios.',
    zipCode:
      'Digite o código postal do endereço onde você vai ficar nos Estados Unidos.',
  },
  dashboard: {
    pendingPayment: 'Pagamento pendente',
    greetingTitle: 'Bem-vindo! Check-In está disponível para sua viagem',
    checkInCompleteTitle:
      'Check-in concluído! Você pode obter seu cartão de embarque ou trocar seu assento',
    reviewUpcomingFlights: 'Analisar detalhes do voo',
    layover: 'Parada',
    date: 'Data',
    flight: 'Voo',
    airline: 'companhia aérea',
    terminalGate: 'Terminal / Portão',
    onTime: 'No horário',
    advanced: 'antecipado',
    delayed: 'Atrasado',
    canceled: 'Cancelado',
    duration: 'Duração',
    operatedBy: 'Operado por',
    travelerInformation: 'INFORMAÇÕES DOS VIAJANTES',
    seatTo: 'ASSENTO PARA {}',
    SeatSelectedButtonName: 'Mudança',
    altImagen: 'Imagem selecione',
    NoSelectedSeatButtonName: 'Selecione',
    readyCheckIn: 'Pronto para check-in',
    checkedIn: 'Check-In realizado',
    failedCheckIn: 'O check-in falhou',
    edit: 'Editar',
    getBoardingPass: 'Cartão de embarque',
    selected: 'Selecionado',
    noSelected: 'Escolha seu assento',
    infoApis:
      'Você precisa completar as informações deste viajante antes de fazer check-in',
    updateInformation: 'Complete as informações',
    titleSegment: '{departure} UM {arrival}',
    changeSeat: 'Alterar',
    businessSeat: 'Executiva',
    emergencySeat: 'Assento de emergência',
    seatSelected:
      'Traveler {passengerName}, tem o assento {seatCode} selecionado para o voo {origin} and {destination}. Pressione enter para alterar o assento.',
    seatNotSelected:
      'Passageiro {passengerName}, assento não selecionado para o voo {origin} and {destination}. Pressione enter se quiser trocar de lugar.',
    editInformation: 'Editar informações',
    starAllianceMemberAltText: 'Copa is a member of the Star Alliance network',
    copyRight:
      'Copyright © 2020 Copa Airlines, Incorporated. Todos os direitos reservados.',
    LAXAirport:
      'Dado o trabalho de construção no LAX, recomendamos que você chegue pelo menos quatro horas antes da partida do seu voo para ter tempo suficiente para chegar à sua porta.',
  },
  dashboardAlerts: {
    scheduleChange:
      'Houve uma atualização no seu itinerário. Analise os novos detalhes do voo. Lamentamos a inconveniência.',
    delay:
      'Houve uma atualização no seu itinerário. Analise os novos detalhes do voo. Lamentamos a inconveniência.',
    paxINF: 'Seleção de assentos não disponível para infantes.',
    paxNRS:
      'Seleção de assento não disponível para passageiros stand-by. Seu assento será atribuído na porta de embarque.',
    interline:
      'Voo não operado pela Copa Airlines. Seu assento será atribuído pela companhía aérea operadora.',
  },
  connectMilesBlock: {
    message: 'Faça login para agilizar ainda mais o check-in!',
  },
  wcag: {
    pageTitle: 'Página principal do Web Check-In',
    pageDescription: 'PPágina principal do Web Check-In',
    openPanel: 'Adicionar {title} Sessão',
    closePanel: 'Remover {title} Sessão',
    seatSelectedDashboardMessage:
      'Viajante {passengerName}, cadeira {seat} do voo {flightNumber} operado por {airlineName} de aeroporto {departureAirportName} da {departureCity} ao aeroporto {arrivalAirportName} de {arrivalCity}. Pressione enter para alterar esta cadeira',
    seatNoSelectedDashboardMessage:
      'Viajante {passengerName}, cadeira não selecionada do voo {flightNumber} operado por {airlineName} de aeroporto {departureAirportName} da {departureCity} ao aeroporto {arrivalAirportName} de {arrivalCity}. Pressione enter para escolher uma cadeira',
    editTravelerInfoButtonMessage:
      'Viajante {passengerName}, Para editar as informações relacionadas a este e outros viajantes, por favor, pressione enter',
    boardingPassButtonMessage:
      'Viajante {passengerName}, para obter o seu cartão de embarque para este viajante, por favor pressione enter',
    updateinfoButtonMessage:
      'Viajante {passengerName}, Para update as informações relacionadas a este e outros viajantes, por favor, pressione enter',

    termsAndConditionsCheckboxDescription:
      'Materiais Restritos: Alguns produtos do dia-a-dia podem ser perigosos quando transportados na aeronave na bagagem de mão e / ou despachada. Por favor, certifique-se que não são proibidos mercadorias perigosas na sua bagagem como: armas, baterías de lítio, gases, venenos e outro perigoso. Documentos exigidos: O passageiro é responsável por todos os documentos de viagem necessários, incluindo passaporte, vistos e qualquer outro documento adicional exigido por lei ou regulamento nos países de destino ou trânsito. Recomenda-se que todos os passageiros estejam cientes de sua vacinação e histórico de saúde, especificamente, o Certificado Internacional de Vacinação contra Febre Amarela, se estiverem viajando para um país endêmico.',
    nextSegmentsBTN: 'Próximos segmentos',
    previousSegmentsBTN: 'Segmentos anteriores',
    subscribeACI:
      'Você tem voos futuros qualificados para o Check-in Automático. Todos os passageiros serão automaticamente registrados quando o período de check-in for aberto, 24 horas antes da partida. Pressione enter se você deseja se inscrever.',
    restrictedItemsLink:
      'Some everyday products can be dangerous when transported on the aircraft in carry-on and/or checked baggage. Please ensure there are no forbidden dangerous goods in your baggage like: Weapons, Batteries, Gases, Flammables liquids or solids, Powder Substances, Other Dangerous',
    segmentsmessage:
      'Vôo {current} de {total}, Vôo número  {flightNumber} dia {flightDate} Saída do  {departureCity} Aeroporto {departureAirport} às {departureTime} Terminal {departureTerminal} porta {departureGate}  cadeira: {seatCode}  duração do voo {duration} com chegada ao {arrivalCity} Aeroporto {arrivalCityAirport}  às {arrivalTime}  Terminal {arrivalTerminal}, porta: {arrivalGate}',
  },
  seatMap: {
    norecline: 'Sem reclinação',
    seat: 'Assento {seatType}',
    mouseoverPrice: 'De',
    legendTitle: 'Legenda',
    section: 'Sección {section}',
    descriptionTravellerList: 'Selecione Passageiro',
    yourFlight: 'Seu voo',
    flightNOfN: 'Voo {current} de {total}',
    flightTo: 'para {destination}',
    seatN: 'Assento {seat}',
    noSeatSelected: 'Nenhum assento selecionado',
    tapOnSeat: 'Escolha quem sentará aqui',
    tapOnSeatMobile: 'Clique em um assento para selecioná-lo',
    chooseWhoSitsHere: 'Escolha quem sentará aqui',
    travelTime: 'Duração da viagem: ',
    notApplicable: 'N/A',
    businessClass: 'Classe Executiva',
    economyClass: 'Classe Econômica',
    dreamsBusinessClass: 'Classe executiva Dreams',
    backToTop: 'Voltar ao topo',
    restroom: 'Banheiro',
    galley: 'Cozinha',
    seatDetails: 'Detalhes do assento',
    extraLeg: 'Mais espaço para as pernas',
    favorableLocation: 'Localização privilegiada',
    businessSeat: 'Executiva',
    unavailable: 'Indisponível',
    preferMembersAlert:
      'ConnectMiles PreferMembers usufruam de pré-seleção de assentos gratuitamente',
    premiumSeat: 'Premium',
    convenientSeat: 'Favorável',
    regularSeat: 'Regular',
    exitRow: 'Saída de Emergência',
    row: 'Fileira {row}',
    boardingTittle: 'Embarque',
    boardingSeatGroup: 'Embarque {group}',
    boardingGroup: 'Grupo de embarque: {group}',
    pitch: 'Espaço: {pitch}',
    flatBed: 'Leito cama',
    seatRecline: '{recline} Assento reclinável',
    readingLight: 'Luz para leitura',
    copaShowPass: 'Copa Showpass',
    outlets: 'Entrada USB e tomada CA',
    usbPort: 'Porta USB',
    rests: 'Descanso para pernas e pés',
    sharedAcOutlet: 'Tomada CA compartilhada',
    electronicDeviceHolder: 'Suporte para dispositivo eletrônico',
    inFlightEntertainment: 'Entretenimento a bordo',
    overheadScreen: 'Tela suspensa',
    dispositivoPersonal: 'Dispositivo pessoal',
    onDemandScreen: 'Tela de toque individual',
    onDemandScreen16: 'Tela de toque individual de 16"',
    seatbackOnDemand: 'Tela do assento',
    scroll: 'Rolar',
    exitSeat: 'Saída',
    legendPrice: 'De {price}',
    legendFreePrice: 'Grátis',
    addFFNModalTitle: 'Inclua seu número ConnectMiles',
    addFFNModalLabel: 'Número ConnectMiles',
    addFFNModalPlaceHolder: 'Insira o número ConnectMiles',
    addFFNModalParagraph:
      'ConnectMiles PreferMembers disfrutam de beneficios exclusivos com a Copa Airlines, incluindo pré-seleção de assentos de forma gratuita. Incluindo o número ConnectMiles ahora para recibir este beneficio.',
    previousFlight: 'Voo Anterior',
    nextFlight: 'Próximo Aoo',
  },
  seatMapButtonBar: {
    addConnetMilesNumber: 'Incluir',
  },
  seatMapWCAG: {
    seat: 'Você está no assento  {seat} {location}, , {seatType}, {topPerk}, {norecline}, De: {price}. Pressione enter se você deseja atribuir este assento a qualquer passageiro.',
    pageDescription:
      'Página de seleção de lugares a {arrival}. Você pode selecionar assento para todos os passageiros na reserva (exceto para bebês); lembre-se que a seleção do assento é por voo.',
    backToDashboard: 'Botão, se Pressionar Enter retornará ao Trip Detail.',
    passengerNumberAndSeat: 'passageiro {number}, eu concordo {seat}',
    passengerNumber: 'passageiro {number}',
    passengersList:
      'Selecione um passageiro da lista: {passengers}. Para navegar entre passageiros aperte Tab e para selecionar aperte Entre.',
    continueButton: 'Continue button.',
    backArrowButton:
      'Botão voltar, pressionando Enter retornará à lista de passageiros.',
    addConnetMiles:
      'Pressione Enter para para adicionar o número do ConnectMiles',
    openLegend: 'Pressione enter para ver a legenda do mapa de lugares.',
    addFFNTitleModal:
      'Adicione o seu número ConnectMiles para desfrutar da seleção de lugares gratuitamente. Você pode adicionar um número de viajante para cada passageiro',
    addFFNCloseModal: 'Botão fechar, pressione enter para fechar o pop up',
    addFFNContinueModal:
      'Pressione enter para continuar com a seleção de assentos',
    addFFNFieldWCAG:
      'Digite o número do passageiro frequente para {passenger}. Lembre-se de que é um campo alfanumérico',
  },
  exitRow: {
    title: 'Você selecionou um assento na fila de saída',
    intro:
      'Por motivos de segurança, para se sentar em um lugar de saída de emergência, você deve atender a requisitos específicos. Se você não atender a esses requisitos, deverá selecionar outro assento.',
    description:
      'Todos os irmãos sentenciados na fila de emergência de emergência devem cumprir com as condições condicionadas:',
    condition1: 'ter 15 anos de idade o mais',
    condition2:
      'Esteja disposto e seja capaz de ajudar no caso de uma emergência',
    condition3:
      'Não viajar com um infante, criança, recipiente para animais de estimação, animal de serviço de vídeos médicos',
    condition4:
      'Entender as instruções para operar a saída de emergência em espanhol',
    condition5: 'Consigo levantar 50 libras (23 kg)',
    condition6:
      'Sou forte e flexível o suficiente para operar a porta de saída e a escorregadeira',
    condition7:
      'Sou capaz de ajudar outros passageiros a sair pelas escorregadeiras',
    condition8:
      'Não uso extensor de cinto de segurança (que poderia ficar preso em uma emergência)',
    acceptButton: 'Aceitar',
  },
  pageNotFound: {
    error: 'ERRO 404',
    description: 'Desculpe, esta página não foi encontrada.',
    btn: 'Voltar para Minhas Viagens',
  },
  scrollAdvisor: {
    text: 'Rolar',
  },
  baggageAllowance: {
    priceLockMessage:
      'Por favor, no te que suas informações de limite de bagagem estará disponível depois que você concluir o pagamento de sua reserva.',
    title: 'Limite de Bagagem',
    checkedBag: {
      plural: '{amount} Bagagem despachada',
      singular: '{amount} Bagagem despachada',
    },
    carryOnBag: {
      plural: '{amount} Bagagem de mão',
      singular: '{amount} Bagagem de mão',
    },
    personalItems: {
      plural: '{amount} Items pessoais',
      singular: '{amount} Item pessoal',
    },
    moreInfoBanner: {
      startText: 'Para mais informações, consulte a ',
      linkText: 'Política de Bagagem da Copa Airlines',
      endText: '.',
      link: '{copa_url}/pt-gs/informacoes-ao-viajar/informacoes-sobre-bagagem/',
    },
    interline: {
      startText:
        'Um dos seus voos não é operado pela Copa Airlines. Por favor verifique a nossa referência às ',
      linkText: 'Política de Bagagem Interline das empresas aéreas parceiras ',
      endText: ' ou entre em contato com um de nossos agentes de serviço.',
      link: '{copa_url}/pt-gs/descubra-copa-airlines/alianzas-con-aerolineas/acordos-entre-companhias-aereas/',
    },
    infant: {
      startText:
        'Bebês não podem carregar malas despachadas. Para mais informações, consulte a ',
      linkText: 'Política de Bagagem Infantil da Copa Airlines',
      endText: '.',
      link: '{copa_url}/pt-gs/informacoes-ao-viajar/informacoes-sobre-bagagens/bagagem-para-bebes/',
    },
    baggageInfoNotAvailable: 'Informação não disponível',
    link: 'Link.',
    WCAG: {
      checkedBag:
        'Tem permitidas {amount} peças de bagagem registradas de {weight}{unit}',
      carryOnBag:
        'Tem permitidas {amount} peças de bagagem de mão de {weight}{unit}',
      personalItem: 'Tem permitida {amount} ítem pessoal',
      openSection:
        'Ver mais informação sobre seu limite de bagagem para esta viagem, {baggage}',
      closeSection:
        'Esconder a informação sobre seu limite de bagagem para esta viagem.',
      title: 'Este é o límite de bagagem para sua viagem para',
    },
    error: {
      message:
        'Não podemos recuperar suas informações de limite de bagagem no momento.',
      startText: 'Para mais informações, não deixe de consultar a ',
      linkText: 'Política de Bagagem da Copa Airlines',
      link: '{copa_url}/pt-gs/informacoes-ao-viajar/informacoes-sobre-bagagem/',
      endText: '.',
    },
  },
  summaryDetails: {
    title: 'Resumo de Seleção',
    subtitle: 'SEUS EXTRAS DE VIAGEM',
    seats: 'ASSENTOS',
    flightDestination: '{departure} para {arrival}',
    flightLength: 'Voo {first} de {last}',
    subtotal: 'Subtotal',
    taxes: 'Impostos',
    total: 'Total',
    freeSeat: 'Grátis',
    freeTotal: 'Seleção grátis',
    exitRow: 'Saída de Emergência',
    convenientSeat: 'Favorável',
    premiumSeat: 'Premium',
    noSelection: 'Sem seleção',
    interlineSegment:
      'Este voo não é operado pela Copa Airlines, a seleção de assento não está disponível.',
  },
  summaryDetailsWCAG: {
    title: 'Detalhes da seleção de assentos por segmento, por passageiro',
    closeModal: 'Pressione o botão para fechar o resumo de seleção de assento',
    summaryButtonDashBoard:
      'Pressione o botão para ver os detalhes dos extras da sua viagem',
  },
  sellSeatsSuccess: {
    title: 'Compra com sucesso',
    titleError: 'Confirmação de compra',
    subtitle:
      'Cobramos {amount}. Você receberá uma confirmação ao email {email}.',
    purchaseSummary: 'Resumo de compra',
    authCode: 'Código de atuorização:',
    subtotal: 'Subtotal',
    taxes: 'Impostos',
    total: 'Total',
    back: 'Voltar para a reserva',
    alertErrorSellSeats: {
      start:
        'Processamos seu pagamento com suceso, mais não se puderam atribuir seus assentos. Por favor, entre em contato com nosso ',
      linkText: 'Central de Reservas',
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
      end: ' para ajuda. Desculpe as moléstias.',
    },
    WCAG: {
      title: 'Confirmação de pagamento. Modal.',
      closeModal: 'Fechar. Fecha o modal de confirmação de pagamento.',
      back: 'Voltar para a reserva.',
    },
  },
  sellSeatsError: {
    title: 'Não pudemos processar seu pagamento',
    notice:
      'Você esgotou todas as tentativas para processar seu pagamento. Por favor lige para nossa ',
    back: 'Voltar para a reserva',
  },
  seats: {
    types: {
      [SeatTypes.PREMIUM]: 'Premium',
      [SeatTypes.CONVENIENT]: 'Favorável',
      [SeatTypes.EXIT_ROW]: 'Saída de Emergência',
      [SeatTypes.REGULAR]: 'Regular',
      [SeatTypes.ECONOMY_EXTRA]: 'Economy Extra',
      [SeatTypes.BUSINESS]: 'Executiva',
      [SeatTypes.DREAM_ROW]: 'Leito cama',
    },
    descriptionWithType: 'Assento {type}',
  },
  paytmentsError: {
    title: 'Não pudemos processar seu pagamento',
    redemptionTitle: 'Não foi possível processar seu pagamento.',
    notice: 'Ocorreu um erro ao processar seu pagamento',
    redemptionNotice:
      'Desculpe, algo deu errado ao tentar resgatar suas milhas.',
    message: 'Por favor, tente novamente em outro momento',
    redemptionMessage: 'Por favor, tente novamente mais tarde.',
    goToMyTrips: 'Voltar para  Minhas Viagens',
    redemptionGoToMyTrips: 'Voltar para Minhas Viagens',
    WCAG: {
      title: 'Não pudemos processar seu pagamento',
      back: 'Botão. Voltar para  Minhas Viagens',
    },
  },
  invalidAccount: {
    title: 'Faça login com a conta correta para continuar',
    message1:
      'Detectamos que você fez login com uma conta diferente daquela usada para fazer esta reserva com milhas. Faça login com a conta correta ',
    message2: ' para efetuar a compra.',
    button: 'Entrar com outra conta',
  },
  differentAccountRedemption: {
    title: 'Faça login com a conta correta para continuar',
    message1:
      'Detectamos que você fez login com uma conta diferente daquela usada para fazer esta reserva com milhas. Faça login com a conta correta ',
    message2: ' para continuar.',
    defaultMessage:
      'Detectamos que você fez login com uma conta diferente daquela usada para fazer esta reserva com milhas. Faça login com o número de conta correto para efetuar a compra.',
    CTA: 'Iniciar sessão com outra conta',
  },
  modalBackOrigin: {
    modalTitle: 'Deseja sair desta página?',
    modalBody:
      'Você ainda não concluiu o pagamento. Se você voltar agora, não concluirá a compra e sua seleção não será confirmada.',
    stayPageCTA: 'Fique nesta página',
    backOriginCTA: 'Sair desta página',
    backOriginTitleWCAG:
      'Modal. Tem certeza de que deseja sair desta página? Se você voltar agora, não concluirá a compra e sua seleção não será confirmada.',
    stayPageButonWCAG:
      'Botão, pressione enter para permanecer nesta página e continuar com o pagamento.',
    backOriginButonWCAG: 'Botão, pressione enter para sair desta página',
  },
  rebooking: {
    newSrtOnboardingModal: {
      title:
        'Fizemos alterações no itinerário desta viagem. Sua ação é necessária.',
      subtitle:
        'Por favor, revise o novo itinerário; você terá a opção de aceitar as alterações ou modificar suas datas de viagem.',
      step1: {
        title: 'Passo 1',
        subtitle: 'Revise o itinerário de viagem.',
      },
      step2: {
        title: 'Passo 2',
        subtitle: 'Aceitar ou modificar o itinerário',
      },
      step3: {
        title: 'Passo 3',
        subtitle: 'Vá para Minhas Viagens para confirmar.',
      },
      cta: 'Continuar',
    },
    screenTitle:
      'Ação necessária: aceitar ou modificar o novo itinerário de viagem',
    pageDescription:
      'O itinerário de sua próxima viagem para {destination} mudou. Nós reorganizamos você para a melhor alternativa de viagem disponível. Se este novo itinerário se adéqua aos seus planos, escolha a opção “Este itinerário funciona para mim”. Caso contrário, você pode modificar suas datas de viagem com a opção "Modificar itinerário".',
    newItinerary: 'Novo itinerário',
    noChanges: 'Sem mudança',
    buttons: {
      itineraryOK: 'Este itinerário funciona para mim',
      modifyItinerary: 'Modificar itinerário',
      acceptItinerary: 'Aceitar ou novo itinerário',
      seeFlightOptions: 'Ver opções de voo',
    },
    stackableAlert: {
      title:
        'Você solicitou algum serviço especial? Leia estas informações com atenção.',
      description: `###### Se você já solicitou um serviço especial (bagagem especial, animal de estimação a bordo, assistência especial ou similar), deverá ligar para nossa {link} para transferir esses serviços para seus novos voos.`,
      linkDescription: 'Central de Reservas',
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
    },
    search: {
      title: 'Em direção a {destination}',
      info: 'Mudar datas de viagem',
      datePickLabel: 'Escolha uma nova data para seu voo a {destination}',
      dateLabel: 'Data de saída',
      flightOptions: 'Ver opções de voo',
      notModifyInfo:
        'Se não deseja modificar este voo, pode continuar ao seguinte',
      notModifyFlight: 'Não modificar este voo',
      availableFlights: 'Voos disponíveis',
      previousDay: 'Dia anterior',
      nextDay: 'Dia siguiente',
      flightAssigned: 'Voo atual',
      error: {
        info: 'Opa! Ocorreu um erro ao pesquisar voos.',
        suggestion: 'Por favor, tente novamente.',
        tryAgainBtn: 'Tentar de novo',
      },
      notFound: {
        info: 'Ops! Nâo se econtraram voos para esta data',
        suggestion: 'Tente selecionar outra data.',
      },
      pickFlightBtn: 'Selecionar este voo',
      checkinFlightChanges: {
        header: {
          title: 'Sua viagem para {destination}',
          subtitle: 'Mudar itinerário',
        },
        title: 'Voos disponíveis',
        subtitle:
          'Selecione o dia em que deseja ver os voos que temos disponíveis para sua mudança.',
        error: {
          200: {
            title: 'Ops! Não foram encontrados voos para esta data',
            suggestion: 'Por favor, tente novamente selecionando outra data.',
          },
          201: {
            title: 'Não há voos disponíveis para esta data',
            suggestion: 'Por favor, tente novamente selecionando outra data',
          },
          202: {
            title: 'Os voos para esta data estão lotados',
            suggestion: 'Por favor, tente novamente selecionando outra data',
          },
          203: {
            title: 'Os voos para esta data estão lotados',
            suggestion: 'Por favor, tente novamente selecionando outra data',
          },
          204: {
            title:
              'Desculpe, algo deu errado ao tentar carregar os voos disponíveis',
            suggestion: 'Por favor, tente novamente mais tarde',
          },
          tryAgainBtn: 'Actualizar',
        },
        seatmapPreviewBtn: 'Ver assento',
        outboundFlight: 'Vuelo de ida',
        selectFlightBtn: 'Selecionar este voo',
        odNonStop: 'Sem escalas',
      },
    },
    confirmationModal: {
      title: 'Sua reserva foi confirmada.',
      description:
        'Modificamos sua reserva com suceso. Pode ver os detalhes da reserva na próxima página.',
      continueBtn: 'Continuar para minha reserva',
      segmentAlert:
        'Confirmamos alguns voos na sua reserve com suceso; no entanto, outros voos requerem atenção especial de um de nossos agentes de serviço. Por favor entre em contato com nossa central de reservas para receber assistência.',
    },
    // TODO: Remove acceptItineraryModal translations on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
    acceptItineraryModal: {
      title: '¿Quer confirmar este itinerário?',
      subtitle:
        "Ao pressionar 'Confirmar novo itinerário', procederemos a alterar sua reserva.",
      descriptionBitModal:
        'Lembre-se de selecionar novamente seus assentos e quaisquer outros serviços especiais que você tenha adquirido.',
      continueBtnOld: 'Confirmar novo itinerário',
      continueBtn: 'Continuar e selecionar assentos.',
      descriptionOld:
        'Ao clicar em "Confirmar itinerário" você aceitará o novo itinerário de viagem que a Copa Airlines lhe atribuiu.',
      description:
        'Ao pressionar "Continuar", procederemos a alterar a sua reserva. Após a alteração, será direcionado para o mapa de assentos para selecionar os seus assentos.',
      cancelBtn: 'Cancelar',
      cancelBtnBitModal: 'Cancelar',
      WCAG: {
        continueBtn: 'Clique aqui para confirmar seu itinerário.',
        cancelBtn: 'Pressione aqui para cancelar e retornar à tela anterior.',
        title: 'Título pendiente',
      },
    },
    cancelBanner: {
      title:
        'Nenhuma das opções se adéqua aos seus planos de viagem? Cancele agora e viaje mais tarde',
      description:
        'Se o itinerário proposto, ou opções de voo, não se adequarem aos seus planos de viagem, você pode cancelar o seu itinerário e manter o valor do bilhete para viajar posteriormente. Quando estiver pronto para remarcar sua viagem, fale conosco para escolher um novo itinerário.',
      cta: {
        text: 'Ver outras opções',
        link: '{copa_url}/pt-gs/atendimento-ao-cliente/mudancas-e-reembolsos/',
      },
    },
    nonRevenue: {
      title: 'Este é o seu itinerário atual',
      subtitle:
        'Através desta página você pode modificar os voos em sua reserva NR.',
      pills: {
        standby: 'Em lista de espera',
        confirmed: 'Confirmado',
        inTransit: 'Em trânsito',
      },
    },
    ovs: {
      screenTitle:
        'Tem planos flexíveis? Você pode mudar de voo sem custo adicional',
      pageDescription:
        'Se você estiver interessado, você pode modificar seu itinerário através desta site sem nenhum custo adicional.',
      btnSearch: 'Pesquisar voos',
      disclaimerText:
        'Algumas coisas que você deve saber antes de modificar seu itinerário',
      disclaimerItem1:
        'Você pode alterar as datas da viagem ate 14 dias antes ou depois da data original da viagem.',
      disclaimerItem2:
        'Ao mudar de voo, você deve selecionar assentos em seus novos voos. Se você já comprou assentos, pode selecionar assentos semelhantes, dependendo de sua disponibilidade',
      disclaimerItem3:
        'Você só pode fazer alterações em seu itinerário uma vez.',
      disclaimerItem4:
        'Ao prosseguir com a mudança, lembre-se de ajustar seus planos de viagem ao novo itinerário que você escolher.',
    },
    minor02: {
      screenTitle: 'Seu itinerário foi ajustado',
      pageDescription:
        'Foi necessário fazer pequenas alterações no seu itinerário com destino {destination}. Por favor, verifique os detalhes dos horários dos seus voos.',
    },
    srtRedemption: {
      screenTitle: 'Este é o seu itinerário atual',
      pageDescription:
        'Através desta página você pode modificar os voos em sua reserva.',
      disclaimerText:
        'Algumas coisas que você deve saber antes de modificar seu itinerário',
      disclaimerAllPassengers:
        'As alterações serão aplicadas a todos os passageiros desta reserva.',
      disclaimerSelectedSeats:
        'Se você já selecionou os assentos, ao concluir a mudança, terá que selecionar seus assentos novamente. Você poderá selecionar assentos equivalentes em seu novo voo.',
      disclaimerChanges:
        'Você pode fazer alterações até um ano a partir da data da sua compra.',
      disclaimerBenefits:
        'A mudança não modificará os benefícios e atributos originais da tarifa (por exemplo, franquia de bagagem).',
      disclaimerDateRestrictions:
        'Se você deseja antecipar sua viagem, algumas datas podem não estar disponíveis.',
      disclaimerConfirmation:
        'Seus voos atuais não mudarão até que você confirme seu novo itinerário, mais adiante nesta página.',
      newItineraryTitle: 'Revisar sua seleção',
      newItinerarySubTitle:
        'Por favor verifique a informação antes de continuar.',
      newItineraryHeader: 'Modificar itinerário',
      newItineraryCTAConfirm: 'Confirmar novo itinerário',
    },
    checkinFlightChanges: {
      screenTitle: 'Este é o seu itinerário atual',
      pageDescription:
        'Através desta página você pode modificar os voos da sua reserva que estão disponíveis para Check-In.',
      disclaimerText: 'Antes de mudar o itinerário, é importante saber:',
      disclaimerAllPassengers:
        'Somente uma mudança poderá ser feita e será aplicada a todos os passageiros na reserva.',
      disclaimerSelectedSeats:
        'Ao completar a mudança, será necessário selecionar novamente os assentos. Se você já tiver selecionado assentos anteriormente, eles podem não estar disponíveis para seleção após a mudança.',
      disclaimerChanges:
        'Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser solicitados com antecedência.',
      disclaimerSSR:
        'Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será necessário coordenar com os provedores para a disponibilidade do serviço no novo vôo.',
      disclaimerAll: 'Ver todos os Termos e Condições.',
      disclaimerAllLink:
        '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in/',
      checkoutPage: {
        title: 'Revise e confirme seu novo itinerário de viagem',
        description:
          'Você escolheu um novo itinerário para sua próxima viagem a {destination}. Revise seu itinerário cuidadosamente antes de continuar.',
        errorToast:
          'Desculpe, não foi possível atualizar seu itinerário. Tenta novamente.',
        pills: {
          newItinerary: 'Novo itinerário',
          noChanges: 'Sem mudança',
        },
        orginalItinerary: 'Ver itinerário original',
        modifyButton: 'Alterar seleção',
        confirmButton: 'Confirmar novo itinerário',
        modal: {
          title: 'Deseja confirmar este itinerário?',
          description1:
            'Ao clicar em confirmar novo itinerário, eu concordo que o itinerário novo é conveniente para meus planos de viagem.',
          description2:
            'Lembre-se de selecionar novamente seus assentos e quaisquer outros serviços especiais solicitados.',
          staroverButton: 'Começar de novo',
          confirmButton: 'Confirmar novo itinerário',
        },
      },
      // TODO: Remove confirmationPage translation on env var SRT_SHOULD_REDIRECT_TO_SEATMAP removal
      confirmationPage: {
        title: 'Seu novo itinerário foi confirmado.',
        modalTitle: '¡Sua alteração foi confirmada!',
        description:
          'Modificamos sua reserva com suceso. Pode ver os detalhes da reserva na próxima página.',
        modalSubTitle:
          'Você pode revisar os detalhes da sua viagem na seção MyTrips em Copa.com e no nosso aplicativo móvel.',
        modalDescriptionOld:
          'Você pode revisar os detalhes da sua viagem na seção MyTrips em Copa.com e no nosso aplicativo móvel.',
        modalDescription:
          'Após a alteração, será direcionado para o mapa de assentos para selecionar os seus assentos.',
        continueButton: 'Continuar',
        modalContinueButtonOld: 'Continuar com a minha reserva',
        modalContinueButton: 'Selecionar assentos',
      },
      wcag: {
        mainPageDescription:
          'Este é seu itinerário atual. Através desta página você poderá modificar os vôos em sua reserva que estão disponíveis para o Check-In.',
        searchPageMainDescription:
          'Estes são os voos disponíveis. Você deve selecionar o dia que gostaria de viajar para obter os voos disponíveis e fazer a alteração.',
        flightAppliesToChange: 'Este vôo se aplica para modificações.',
        flightDoesNotAppliesToChange:
          'Este vôo não se aplica para modificações.',
        termsAndConditionLink:
          'Redirecionamento para ver todos os termos e condições aplicáveis ​​em uma nova guia.',
        btnAvaliableFlights: 'Pressione para ver os vôos disponíveis',
        searchPageDescription:
          'Estes são os voos disponíveis. Você deve selecionar o dia que gostaria de viajar para obter os voos disponíveis e fazer a alteração.',
        btnSelectDays: 'Pressione para selecionar esta data',
        btnViewSeats:
          'Uma nova página será aberta para validar a disponibilidade de assentos neste voo',
        btnSelectFlight: 'Pressione para selecionar este vôo',
        flightNewItineraryDescription: 'Este é o novo itinerário',
        flightNoChangesDescription:
          'Este é o roteiro que não sofreu alterações',
        originalItineraryCollapsed:
          'Pressione para expandir sua seção de itinerário original',
        originalItineraryExpanded:
          'Pressione para recolher a seção do seu itinerário original',
        btnModifySelection: 'Pressione se você deseja modificar sua seleção',
        btnConfirmItinerary: 'Pressione para confirmar seu novo itinerário',
        btnReturnToFlow:
          'Pressione para continuar. Você será redirecionado para a página principal de {redirectionPage}',
        redirectionPage: 'Minhas Viagens',
        errorReservationCenterLink:
          'Redirecionamento para a página da central de reservas. A página será aberta em uma nova aba',
      },
    },
    srtOVS: {
      disclaimers: {
        allPassengers:
          'A mudança se aplicará a todos os passageiros na reserva.',
        selectedSeats:
          'Após a conclusão da mudança na reserva, os assentos terão de ser re-selecionados. Se você já tiver ' +
          'selecionado assentos anteriormente, eles podem não estar disponíveis para seleção após a mudança.',
        SSR:
          'Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser' +
          ' solicitados com antecedência.',
        thirdPartiesServices:
          'Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será' +
          ' necessário que o passageiro coordene diretamente com os prestadores para a disponibilidade do serviço no novo vôo.',
      },
      termsAndConditions: {
        text: 'Ver todos os Termos e Condições.',
        link: '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in-ovs/',
      },
    },
    odInfo: {
      flyover: `{stopsNumber} {stopsNumber, plural,
        one { escala}
        other { escalas}
      }`,
    },
  },
  rebook: {
    header: {
      infoText: 'Sua viagem para {destination}',
      title: 'Aceitar Itinerário ou Modificar Reserva',
      ovsAndNrTitle: 'Modificar Itinerário',
      minor02Title: 'Mudança de itinerário',
      checkinFlightChangesTitle: 'Modificar Itinerário',
    },
    subHeader: 'Informação Importante sobre sua Viagem',
    dateTrip: 'Datas de viagem',
    reservationCode: 'Código de reserva',
    subTitle:
      'Por favor revisa el itinerario anterior antes de elegir una de las siguientes opciones.',
    information:
      'Devido às restrições aos voos e requerimentos de viagem vigentes em muitos de nossos destinos, fomos obrigados a fazer mudanças em nossos itinerários. Como resultado desta situação, o itinerário de seu próximo voo mudou.',
    itinerary: 'Itinerário de viagem atualizado',
    itinerarySubTitle:
      'Este é seu itinerário atualizado. Se é conveniente para seus planos de viagem, por favor faça clic em Aceitar este itinerário no final desta página.',
    finalNote:
      'Ao fazer clic em Aceitar este itinerário, eu confirmo que concordo com a mudança de itinerário porposta pela Copa Airlines. Se o itinerário proposto não é conveniente para seus planos de viagem, por favor faça clic em Modificar reserva para pesquisar alternativas de viagem.',
    finalNoteNewItinerary:
      'Ao fazer clic em confirmar nova reserva, eu concordo que a reserva nova é conveniente para meus planos de viagem.',
    modifyItinerary: 'Modificar reserva',
    acceptItinerary: 'Aceitar este itinerário',
    errorMessage:
      'Desculpe, não foi possível atualizar seu itinerário. Tenta novamente.',
    titleNotAllowed:
      'Desculpe, uma ou mais das condições da reserva tornam inelegível a mudança do seu voo através deste aplicativo.',
    messageNotAllowed: {
      start:
        'Visite Minhas Viagens para verificar as opções de mudança ou entre em contato com nossa ',
      linkText: 'Central de Reservas',
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
      end: ' para processar a mudança.',
    },
    options: 'Ver outras opções',
    optionsLink: '{copa_url}/pt/web/gs/options',
    newItineraryHeader: 'Modificar reserva',
    newIntineraryTitle: 'Revisar sua seleção',
    newIntinerarySubTitle:
      'Por favor verifique a informação antes de continuar.',
    oldIntinerary: 'Veja o itinerário anterior',
    startOver: 'Começar de novo',
    confirmItinerary: 'Confirmar nova reserva',
    messageConfirmItinerary: 'Modificamos seu itinerário de viagem com suceso.',
    messageErrorItinerary:
      'Não foi possível atualizar seu itinerário. Tenta de novo.',
    messageItineraryNoModify:
      'Você não realizou nenhuma modificação a seu itinerário. Selecione "Começar de novo" para fazer mudanças ou selecione "Confirmar novo itinerário" para continuar com este itinerário.',
    redemption: {
      notElegibleTitle: 'Desculpe, não podemos processar sua mudança.',
      notElegibleMessage:
        'Esta é uma versão limitada que não processa alterações com menos de 7 dias antes do voo. Para mudar sua reserva, por favor, entre em contato conosco no',
      notElegibleLinkDesc: 'Centro de Atendimento ConnectMiles.',
      notElegibleLink: '{copa_url}/en-gs/customer-service/call-center/',
      notElegibleButton: 'Voltar para Minhas Viagens',
    },
  },
  confirmContactInfo: {
    title: 'Deixe-nos saber como você prefere ser contatado.',
    description:
      'Ao nos fornecer suas informações de contato, podemos mantê-lo informado sobre alterações ou outras atualizações importantes em seu itinerário. Você pode modificar essas informações sempre que quiser.',
    privacyDisclaimer:
      'Usaremos essas informações apenas para contatá-lo sobre esta viagem.',
    modal: {
      confirmBtn: 'Confirme as informações de contato',
    },
    form: {
      currentEmail: 'Correio eletrônico',
      email: {
        label: 'Email (obrigatório)',
        placeholder: 'Digite um e-mail',
        recommendation: 'Recomendamos o uso de um endereço de e-mail pessoal.',
      },
      phone: {
        addLabel: 'Adicionar número do celular',
        label: 'Número do telefone',
        placeholder: 'Insira numeros',
      },
      countryCode: {
        placeholder: 'Código do país',
      },
      cancelBtn: 'Cancelar',
      editEmailBtn: 'Editar correio',
    },
  },
  GDPRBanner: {
    text: 'Em nosso site copa.com, usamos cookies para garantir o funcionamento correto desta página e para melhorar a sua experiência. Ao continuar navegando, você concorda com a política de cookies que faz parte da nossa ',
    link: '{copa_url}/pt-gs/legal/termos-e-condicoes/politica-privacidade/',
    linkText: 'Política de Privacidade.',
    ctaButton: 'Aceitar',
    ctaWCAGButton:
      'Mensagem importante. Em nosso site copa.com, usamos cookies para garantir o funcionamento correto desta página e para melhorar a sua experiência. Se decidir continuar a navegar, entendemos que aceita a política de cookies que faz parte da nossa Política de Privacidade. Pressione Enter para aceitar.',
  },
  directChannelAlert: {
    indirectDescription:
      'Esta reserva foi feita através de um sistema de reserva de terceiros. Para obter preços melhores nas tarifas totais, assentos e/ou bagagens, da próxima vez recomendamos que você compre diretamente com a Copa Airlines ou com uma agência de viagens com acesso direto.',
  },
  directChannelPill: {
    directDescription: 'Compra Direta',
    indirectDescription: 'Compra Externa',
  },
  CiamLoginBox: {
    title: 'Faça login para continuar.',
    description:
      'Você deve fazer login com a conta que você originalmente usou para fazer esta reserva.',
    loginBtn: 'Faça login para continuar',
    wcagBtn: 'Pressione para entrar',
  },
  srt: {
    flight: {
      layover: 'Escala:',
      freeChargeLabel: 'Mudanças gratuitas',
    },
    notEligibleError: {
      title: 'Neste momento não podemos processar a sua alteração',
      subtitle:
        'Por favor, tente novamente mais tarde ou entre em contato com nosso Central de Reservas para processar a alteração',
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
      cta: 'Volte',
    },
    calendarServiceError: {
      title: 'Desculpe, algo deu errado ao tentar modificar o seu itinerário.',
      subtitle:
        'Por favor, tente novamente mais tarde ou entre em contato com nosso Centro de Atendimento para processar a alteração.',
      cta: 'Dirigir-se ao Centro de Chamadas',
      link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
    },
    navBackBtnWCAG: 'Pressione para retornar a Minhas Viagens',
    wrongAccountModal: {
      title: 'Para continuar, faça login com a conta correta',
      subtitle:
        'Esta conta é diferente daquela que você utilizou para fazer esta reserva com milhas. Faça login com a conta correta',
      cta: 'Iniciar sessão',
    },
    availableFlightsErrors: {
      201: {
        title: 'Não há voos disponíveis para esta data',
        suggestion: 'Por favor, tente novamente selecionando outra data',
      },
      202: {
        title: 'Os voos para esta data estão lotados',
        suggestion: 'Por favor, tente novamente selecionando outra data',
      },
      203: {
        title: 'Os voos para esta data estão lotados',
        suggestion: 'Por favor, tente novamente selecionando outra data',
      },
      204: {
        title:
          'Desculpe, algo deu errado ao tentar carregar os voos disponíveis',
        suggestion: 'Por favor, tente novamente mais tarde',
      },
    },
    sc: {
      default: {
        onboardingPage: {
          navTitle: 'Modificar itinerário',
          acceptItinerary: {
            title: 'Este é o seu itinerário atual',
            subtitle: `Através desta página você pode modificar os voos da sua reserva.`,
            modifyBtn: 'Modificar itinerário',
            acceptBtn: 'Este itinerário me serve',
          },
          confirmDefaultItineraryModal: {
            title: '¿Quer confirmar este itinerário?',
            subtitle:
              'Ao pressionar "Continuar", procederemos a alterar a sua reserva. ',
            seatmapDescription:
              'Após a alteração, será direcionado para o mapa de assentos para selecionar os seus assentos.',
            oldDescription:
              'Lembre-se de selecionar novamente seus assentos e quaisquer outros serviços especiais que você tenha adquirido.',
            oldContinueBtn: 'Confirmar novo itinerário',
            seatMapContinueBtn: 'Continuar e selecionar assentos.',
            cancelBtn: 'Cancelar',
            WCAG: {
              continueBtn: 'Clique aqui para confirmar seu itinerário.',
              cancelBtn:
                'Pressione aqui para cancelar e retornar à tela anterior.',
              title: 'Título pendiente',
            },
          },
        },
        resultSearchPage: {
          availableFlightSection: {
            nextCTA: 'Dia seguinte',
            previousCTA: 'Dia anterior',
            subtitle: 'Selecione o voo de sua preferência:',
            title: 'Voos disponíveis',
          },
          dateSection: {
            ctaText: 'Ver opções de voo',
            title: 'Escolha uma nova data',
          },
          flightSection: {
            buttonText: 'Não modificar este voo',
            subtitle: '{date} · Para o {destination}',
            title: 'Encontre alternativas para esta viagem',
          },
          header: {
            title: 'Modificar itinerário',
          },
        },
        checkout: {
          confirmationModal: {
            title: '¡Sua alteração foi confirmada!',
            subtitle:
              'Você pode revisar os detalhes da sua viagem na seção MyTrips em Copa.com e no nosso aplicativo móvel.',
            oldDescription:
              'Você pode revisar os detalhes da sua viagem na seção MyTrips em Copa.com e no nosso aplicativo móvel.',
            seatmapDescription:
              'Em seguida, você será direcionado ao mapa de assentos para selecionar seus novos assentos',
            oldContinueButton: 'Continuar com a minha reserva',
            seatContinueButton: 'Selecionar assentos',
            redirectToMyTrips: 'Vá para Minhas Viagens',
          },
        },
      },
      maj01: {
        onboardingPage: {
          navTitle: 'Aceitar ou modificar itinerário',
          acceptItinerary: {
            title:
              'Ação necessária: aceitar ou modificar o novo itinerário de viagem',
            subtitle:
              'Acomodamos você na melhor alternativa de viagem disponível.',
            modifyBtn: 'Modificar itinerário',
            acceptBtn: 'Este itinerário me serve',
          },
          collapsable: {
            title: 'Itinerário original',
            subtitle:
              'Assim que o novo itinerário for confirmado, este não será mais válido.',
            btnOpen: 'Veja detalhes',
            btnClose: 'Ocultar detalles',
          },
        },
      },
      rdp: {
        onboardingPage: {
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Você pode alterar as datas da viagem até 24 horas antes ou depois do horário original da viagem.\n\n- ###### Ao mudar de voo, será necessário selecionar novamente os assentos. Você poderá escolher assentos iguais ou semelhantes em seus novos voos, sujeito à disponibilidade e sem nenhum custo.\n\n- ###### Através do Web Check-In (24 horas antes da saída do seu voo), você pode fazer alterações no seu itinerário uma vez na ida e uma vez na volta, se necessário.\n\n- ###### Se você adquiriu algum serviço adicional (ou seja, seguro de viagem ou aluguel de carro), entre em contato com o respectivo fornecedor para fazer modificações.\n\n- ###### Caso tenha adquirido algum serviço especial (por exemplo, assistência para cadeira de rodas ou refeições vegetarianas), será necessário solicitá-lo novamente através dos nossos canais.`,
          },
          notEligibleError: {
            title: 'Neste momento não podemos processar a sua alteração',
            subtitle:
              'Nosso aplicativo atualmente suporta alterações para viagens com data de saída com pelo menos 7 dias de antecedência. Para modificar seu itinerário, entre em contato com nossa Central de Atendimento ConnectMiles.',
            link: '{copa_url}/pt-gs/connectmiles/',
            cta: 'Voltar para Minhas Viagens',
          },
        },
      },
      adhoc: {
        onboardingPage: {
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Só poderá ser feita uma alteração e será aplicada a todos os passageiros da reserva.\n\n- ###### Após a conclusão da mudança na reserva, os assentos terão de ser re-selecionados. Se você já tiver selecionado assentos anteriormente, eles podem não estar disponíveis para seleção após a mudança.\n\n- ###### Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser solicitados com antecedência.\n\n- ###### Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será necessário que o passageiro coordene diretamente com os prestadores para a disponibilidade do serviço no novo vôo.`,
          },
          notEligibleError: {
            title: 'Neste momento não podemos processar a sua alteração',
            subtitle:
              'Nosso aplicativo atualmente suporta alterações para viagens com data de saída com pelo menos 7 dias de antecedência. Para modificar seu itinerário, entre em contato com nossa Central de Atendimento ConnectMiles.',
            link: '{copa_url}/pt-gs/connectmiles/',
            cta: 'Go back to My Trips',
          },
        },
      },
      nr: {
        onboardingPage: {
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Você poderá alterar seu voo até 45 minutos antes do horário de partida original.\n\n- ###### Você pode mudar para voos com horário de partida de pelo menos 45 minutos a partir do horário da alteração.\n\n- ###### Se você já despachou bagagem, ela poderá atrasar na hora de fazer a troca. Nestes casos, recomendamos contactar um agente no aeroporto para obter assistência.\n\n- ###### É da responsabilidade de cada colaborador garantir que a alteração a efetuar está correta e estar presente na porta de embarque com tempo suficiente.\n\n`,
          },
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Somente uma mudança poderá ser feita e será aplicada a todos os passageiros na reserva.\n\n- ###### Você pode alterar seu voo até 14 dias antes ou depois da data original da viagem.\n\n- ###### Ao mudar de voo, será necessário selecionar novamente os assentos. Você poderá escolher assentos iguais ou semelhantes em seus novos voos, sujeito à disponibilidade\n\n- ###### Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser solicitados com antecedência.\n\n- ###### Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será necessário coordenar com os provedores para a disponibilidade do serviço no novo vôo.`,
          },
        },
      },
    },
    wci: {
      default: {
        onboardingPage: {
          navTitle: 'Modificar itinerário',
          acceptItinerary: {
            title: 'Este é o seu itinerário atual',
            subtitle: `Através desta página você poderá modificar os vôos em sua reserva que estão disponíveis para o Check-In.`,
            ctaBtn: 'Ver voos disponíveis',
          },
          itineraryCard: {
            availablePill: 'Disponível',
            notAvailablePill: 'Não Disponível',
          },
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Só poderá ser feita uma alteração e será aplicada a todos os passageiros da reserva.\n\n- ###### Após a conclusão da mudança na reserva, os assentos terão de ser re-selecionados. Se você já tiver selecionado assentos anteriormente, eles podem não estar disponíveis para seleção após a mudança.\n\n- ###### Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser solicitados com antecedência.\n\n- ###### Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será necessário que o passageiro coordene diretamente com os prestadores para a disponibilidade do serviço no novo vôo.`,
          },
          termsAndConditions: {
            text: 'Consulte todos os Termos e Condições.',
            ovsLink:
              '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in-ovs/',
            cfcLink:
              '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in/',
          },
          notEligibleError: {
            title: 'Neste momento não podemos processar a sua alteração',
            subtitle:
              'Devido às condições da sua reserva não podemos processar a alteração através da nossa aplicação. Para modificar seu itinerário, entre em contato com nossa Central de Reservas',
            link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
            cta: 'Acesse a Central de Reservas',
          },
        },
        resultPage: {
          title: 'Selecione o voo de ida',
          description: '{date} - Para o {destination}',
          searchResult: {
            title: 'Voos disponíveis',
            description: 'Selecione o voo de sua preferência:',
          },
          selectFlightBtn: 'Selecione voo',
          viewSeatsBtn: 'Ver assentos',
        },
        checkoutPage: {
          headerTitle: 'Revisar itinerário',
          title: 'Revise sua seleção',
          subtitle:
            'Certifique-se de que sua seleção esteja correta antes de continuar',
          modifyCTA: 'Modificar seleção',
          confirmCTA: 'Confirmar novo itinerário',
        },
        confirmationPage: {
          headerTitle: 'Página de confirmação',
          title: 'Este é o seu novo itinerário',
          subtitle: 'Resumo do seu novo itinerário de viagem',
          confirmCTA: 'Ver minha reserva',
        },
      },
      ovs: {
        onboardingPage: {
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Só poderá ser feita uma alteração e será aplicada a todos os passageiros da reserva.\n\n- ###### Após a conclusão da mudança na reserva, os assentos terão de ser re-selecionados. Se você já tiver selecionado assentos anteriormente, eles podem não estar disponíveis para seleção após a mudança.\n\n- ###### Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser solicitados com antecedência.\n\n- ###### Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será necessário que o passageiro coordene diretamente com os prestadores para a disponibilidade do serviço no novo vôo.`,
          },
          termsAndConditions: {
            text: 'Consulte todos os Termos e Condições.',
            ovsLink:
              '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in-ovs/',
            cfcLink:
              '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in/',
          },
        },
      },
      cfc: {
        cabinLabel: 'Cabine:',
        newCabinLabel: 'Nova cabine:',
        businessCabin: 'Executiva',
        economyCabin: 'Econômica',
        onboardingPage: {
          disclaimers: {
            title: 'Algumas considerações a ter em conta:',
            messages: `- ###### Somente uma mudança poderá ser feita e será aplicada a todos os passageiros na reserva.\n\n- ###### Ao completar a mudança, será necessário selecionar novamente os assentos. Se você já tiver selecionado assentos anteriormente, eles podem não estar disponíveis para seleção após a mudança.\n\n- ###### Alguns serviços especiais previamente selecionados podem não estar disponíveis no novo vôo, pois precisam ser solicitados com antecedência.\n\n- ###### Se os serviços tiverem sido adquiridos com terceiros (ou seja, seguro de viagem ou aluguel de carro), será necessário coordenar com os provedores para a disponibilidade do serviço no novo vôo.`,
          },
          termsAndConditions: {
            text: 'Consulte todos os Termos e Condições.',
            ovsLink:
              '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in-ovs/',
            cfcLink:
              '{copa_url}/pt-gs/legal/termos-e-condicoes/alteracoes-check-in/',
          },
        },
        resultSearchPage: {
          upx: {
            title: 'Voos disponíveis em Cabine Econômica',
            alert:
              'Alternativas de voo estão disponíveis na Cabine Econômica. Ao fazer a alteração, você ainda será considerado para um upgrade de cabine se houver espaço disponível.',
            modal: {
              title:
                'Você está alterando a cabine do seu itinerário para {city}',
              text: 'Como membro do ConnectMiles, você recebe upgrades gratuitos para a Classe Executiva. Se você fizer uma alteração, continuará elegível para um upgrade de cabine, se houver disponibilidade.',
              goBackCta: 'Volte',
              confirmCabinChangeCTA: 'Confirmar mudança de cabine',
              wcag: {
                scrollBar: 'Deslize para continuar',
                exitBtn: 'Pressione para fechar a janela pop-up',
                goBackCta: 'Pressione para voltar',
                confirmCabinChangeCTA:
                  'Pressione para confirmar a alteração do seu itinerário. Você será redirecionado para a próxima página.',
              },
            },
          },
        },
      },
    },
  },
  upgrade: {
    errorPage: {
      wcag: 'Você está em uma página de erro. Isto pode ser devido a alguma falha em nossos serviços, recomendamos que você tente novamente mais tarde.',
    },
    saua: {
      errorPage: {
        title: 'Seu itinerário em voos operados por outra companhia aérea',
        subtitle:
          'Solicitações de upgrade para voos operados por outras companhias aéreas não estão disponíveis no momento. Se você possui voos operados pela Copa Airlines e outras companhias aéreas, poderá solicitar upgrade somente para trechos da Copa Airlines através de nossa [Central de Reservas]({copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/).',
        cta: 'Volte',
        ctaWcag:
          'Pressione para voltar. Você será redirecionado para outra página',
      },
      passengerForm: {
        title: 'Passageiros',
        paxTitle: 'Passageiros',
        nameField: 'Nome',
        namePlaceholder: 'Insira o nome',
        lastNameField: 'Sobrenome',
        lastNamePlaceholder: 'Inisra o sobrenome',
        eTicketField: 'Número do bilhete eletrônico',
        eTicketPlaceholder: 'Insira o número do bilhete',
        connectMilesField: 'Número ConnectMiles',
        connectMilesPlaceholder: 'Insira o número do ConnectMiles',
        addPaxBtn: 'Adicionar passageiro',
        addPaxBtnWCAG:
          'Pressione para adicionar um passageiro adicional. Você terá que preencher as informações de viagem para cada passageiro adicionado',
        removePaxBtn: 'Remover',
        removePaxBtnWCAG: 'Pressione para remover este passageiro',
        invalidFormatMessage: 'Formato inválido',
        requireMessage: 'Este campo é obrigatório',
        minimumCharsAllowedMessage: 'O número mínimo de caracteres é {number}',
      },
      onboarding: {
        pnrLabel: 'Código de reserva',
        title: 'Promoções em companhias aéreas da Star Alliance',
        subtiTitle:
          'A Copa Airlines permite que você troque suas milhas e vouchers disponíveis por um upgrade para a cabine executiva na companhia aérea de sua escolha dentro da rede Star Alliance. Para solicitar esta promoção você deve preencher os seguintes dados:',
        incontentAlertText:
          '###### O pedido de upgrade aplica-se a todos os passageiros da reserva e para um máximo de dois voos.',
      },
      flightsForm: {
        title: 'Informação de vôo',
        flightTitle: 'Voo {number}',
        originToDestination: '{origin} para o {destination}',
        airlineLabel: 'Companhia Aérea',
        airlinePlaceholder: 'Selecione a companhia aérea do seu voo',
        flightNumber: 'Número do voo',
        flightNumberPlaceholder: 'Insira o número do voo',
        pnr: 'Número de reserva {airline}',
        pnrPlaceholder: 'Insira o número da reserva',
        pnrHelperText:
          'Se você não tiver o número da reserva, entre em contato com nosso [Centro de Atendimento]({copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/).',
        originLabel: 'Origem',
        originPlaceholder: 'Selecione sua cidade de origem',
        destinationLabel: 'Destino',
        destinationPlaceholder: 'Selecione sua cidade de destino',
        flightDate: 'Data do Voo',
        flightDatePlaceholder: { day: 'DD', month: 'MM', year: 'AAAA' },
        cabin: 'Cabine',
        cabinPlaceholder: 'Selecione a cabine do seu upgrade',
        clearForm: 'Formulário limpo',
        addFlight: 'Adicionar outro voo',
        removeFlight: 'Remover',
        airportsInlineError: 'Os campos não podem ser iguais',
        dateInlineError: 'Você só pode selecionar datas futuras',
        serviceError: 'Algo falhou. Tente novamente mais tarde',
        notFoundError: 'Nenhum resultado encontrado',
        wcag: {
          clearForm:
            'Pressione para limpar o formulário. Isso removerá as informações de voo inseridas',
          addFlight:
            'Pressione para adicionar um voo adicional para o qual deseja solicitar um upgrade. Ao adicionar um voo adicional você deverá preencher as informações necessárias para este voo',
          removeFlight:
            'Pressione para remover este voo da solicitação de upgrade',
          autocomplete:
            'Você deve inserir pelo menos 3 letras para pesquisar um aeroporto ou cidade.',
        },
      },
      generalErrorPage: {
        title: 'Não podemos processar sua solicitação',
        subtitle:
          'Estamos enfrentando problemas no nosso serviço que nos impedem de processar seu pedido de upgrade. Por favor, tente novamente mais tarde ou entre em contato com o nosso [Centro de Chamadas]({copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/) para solicitar seu upgrade usando suas milhas ou certificados.',
        cta: 'Volte',
        ctaWcag:
          'Pressione para voltar. Você será redirecionado para outra página',
      },
      noMilesErrorPage: {
        title: 'Não podemos processar sua solicitação',
        subtitle:
          'Para solicitar um upgrade, você precisa ter milhas ou certificados disponíveis.',
        cta: 'Voltar ao início',
        ctaWcag:
          'Pressione para retornar ao início. Você será redirecionado para outra página',
        pageText:
          'Você está em uma página de erro. Para solicitar um upgrade, a conta deverá ter milhas ou certificados disponíveis.',
      },
    },
  },
  upgradeHub: {
    header: {
      title: 'Solicitar um upgrade',
      wcagText:
        'Pressione para voltar. Você será redirecionado para outra página',
    },
    onboarding: {
      destinationCard: {
        flightTo: 'Em direção ao {destination}',
      },
      loginBanner: {
        description:
          'Acesse sua conta ConnectMiles se quiser solicitar um upgrade usando milhas ou certificados.',
        ctaText: 'Iniciar Sessão',
        wcagText:
          'Pressione para fazer login. Você será redirecionado para outra página',
      },
      title: 'Como você vai pedir uma upgrade',
      subtitle:
        'A Copa Airlines lhe dá a oportunidade de solicitar um upgrade para a Classe Executiva e desfrutar de seus benefícios. Você pode comprar seu assento pagando em dinheiro ou usando suas milhas ou certificados disponíveis.',
      plusUpgradeCard: {
        title: 'Pagar com dinheiro',
        subtitle:
          'Faça uma oferta com o valor que você está disposto a pagar por um upgrade para Classe Executiva.*',
        cta: 'Selecione',
        wcagText:
          'Pressione para solicitar a promoção pagando em dinheiro. Você será redirecionado para outra página',
        logoTinyAlt:
          'FlyUp é o programa da Copa Airlines onde você pode optar por um upgrade para a classe executiva e aproveitar seus benefícios',
        wcagTextDisableCard:
          'A opção de solicitar um upgrade para a classe executiva pagando com dinheiro não está disponível.',
        ariaIcon: 'Ícone de Fly Up',
      },
      upgradeHub: {
        title: 'Use milhas ou vouchers ConnectMiles',
        subtitle:
          'Compre seu assento usando suas milhas ou certificados disponíveis.**',
        cta: 'Selecione',
        wcagText:
          'Pressione para solicitar o upgrade usando milhas ou certificados.',
        logoTinyAlt:
          'Você pode solicitar um upgrade para a classe executiva usando suas milhas ou certificados disponíveis.',
        wcagTextDisableCard:
          'A opção de fazer upgrade para a classe executiva usando suas milhas ou certificados não está disponível.',
      },
      disclaimer:
        '*Este programa não afeta upgrades gratuitos concedidos aos ConnectMiles PreferMembers. Os assentos restantes serão vendidos após a concessão de upgrades gratuitos de acordo com as regras do programa.',
      disclaimer2:
        '**Os certificados são exclusivos para membros Platinum e Presidential.',
    },
    generalError: {
      plusUpgrade:
        'Desculpe-nos, Sua reserva não é elegível para participar deste programa.',
      title: 'Não foi possível processar sua solicitação',
      subTitle:
        'Devido a problemas com nosso serviço, sugerimos que você tente novamente mais tarde',
      button: 'Volte',
    },
    selectFlight: {
      title: 'Selecione os voos para os quais deseja solicitar um upgrade',
      destination: {
        subtitle: 'Estas são suas opções de voo.',
      },
      memberCard: {
        miles: '{miles} Milhas',
        expirationDate: 'Data de expiração: {date}',
        certificates: '{certificates} Certificados',
        noCertificates: 'Certificados no disponibles',
        globlalPlusRegional: '({global} Global + {regional} Regional)',
      },
      alert: {
        text:
          '- ###### A solicitação de upgrade se aplica a todos os passageiros da reserva.\n' +
          '- ###### Assentos, bagagem e outros serviços adicionais adquiridos anteriormente não serão reembolsáveis',
        link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
      },
      itineraryCard: {
        available: 'Selectionar voo',
        notAvailable: 'Sem disponibilidade',
        ariaLabelCheckboxActivated: 'Pressione para selecionar este voo',
        ariaLabelCheckboxDisabled:
          'Este voo não tem mais disponibilidade para upgrade',
      },
      pricesSection: {
        initialTitle:
          'Selecione e aplique sua forma de pagamento para ver o  custo nesta seção',
        initialSubtitle:
          'Você verá o custo estimado do upgrade com a forma de pagamento aplicada',
        title: 'Resumo da compra',
        subtitle: 'Extras de viagem',
        departureCodeAndArrivalCode: 'Upgrade {departureCode} - {arrivalCode} ',
        adultPassengerCount:
          '{count, plural, one {# adulto} other {# adultos}}',
        childPassengerCount:
          '{count, plural, one {# criança} other {# crianças}}',
        certificatesCount:
          '{count, plural, one {# certificado} other {# certificados}}',
        milesCount: '{count} milhas prêmio',
        regionalCertificatesCount:
          '{count, plural, one {# certificado regional} other {# certificados regional}}',
        globalCertificatesCount: '{count} global',
        subtotal: 'Subtotal',
        taxes: 'Impostos e taxas',
        total: 'Total',
        ctaText: 'Solicitar upgrade',
        ctaWcag:
          'Pressione para solicitar a promoção. Você será redirecionado para outra página',
      },
      paxCardText: {
        memberText: 'ConnectMiles Member',
        connectMiles: 'PreferMember {CM}',
        noFFN: 'Sem número de passageiro frequente',
      },
      passengerText: {
        title: 'Passageiros na reserva',
        subtitle: 'Passageiros que farão upgrade para Classe Executiva',
      },
      errorToast:
        'Algo deu errado ao modificar sua seleção. Por favor, tente novamente',
    },
    confirmation: {
      header: 'Confirmação',
      confirmationText: {
        title: {
          successfulUpgrade:
            'Parabéns, você foi atualizado para a Classe Executiva!',
          partialUpgrade:
            'Confirmação de upgrade para Classe Executiva para os seguintes voos:',
          failedUpgrade: 'Não conseguimos promovê-lo nos voos selecionados.',
        },
        subtitle: {
          successfulUpgrade:
            'Seu upgrade para a Classe Executiva pode levar alguns minutos para ser refletido.',
          partialUpgrade:
            'Seu upgrade para a Classe Executiva pode levar alguns minutos para ser refletido.',
          failedUpgrade:
            'Recomendamos que tente novamente mais tarde ou ligue para nosso [Centro de Reservas.]({copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/)',
        },
        successfulUpgradeAlert:
          '###### Lembre-se de selecionar seus assentos ao concluir o pedido de upgrade.',
        successfulUpgradeAlertWCAG: 'Pressione para excluir o alerta',
      },
      pillStatus: {
        successful: 'Upgrade recebida',
        failed: 'Sem upgrade',
      },
      partialUpgrade: {
        title: 'Alguns voos não receberam upgrade',
        subtitle:
          'Sua solicitação de upgrade para a classe executiva não pôde ser processada nos voos a seguir. Você pode tentar novamente mais tarde ou entrar em contato com nossa ',
        link: '{copa_url}/pt-gs/atendimento-ao-cliente/centro-de-chamadas/',
        linkText: 'Central de Reservas.',
      },
      passengerText: {
        title: 'Passageiros na reserva',
        subtitle: 'Passageiros que farão upgrade para Classe Executiva',
      },
      paxCardText: {
        connectMiles: 'PreferMember {CM}',
        noFFN: 'Sem número de passageiro frequente',
      },
      ctaText: {
        successfulUpgrade: 'Vá para Minhas Viagens',
        partialUpgrade: 'Vá para Minhas Viagens',
        failedUpgrade: 'Voltar ao início',
      },
      wcag: {
        pageText:
          'Você está em uma página de confirmação parcial. Isso significa que alguns de seus segmentos de viagem puderam ser atualizados e outros, infelizmente, não. Se desejar se inscrever novamente em segmentos que não puderam ser promovidos, recomendamos tentar novamente mais tarde.',
        goBackText:
          'Pressione para voltar. Você será redirecionado para outra página',
        errorPageDescription:
          'Você está em uma página de erro. Isto pode ser devido a alguma falha em nossos serviços, recomendamos que você tente novamente mais tarde.',
        ctaText: {
          successfulUpgrade: 'Pressione para retornar a Minhas Viagens.',
          partialUpgrade: 'Pressione para retornar a Minhas Viagens',
          failedUpgrade:
            'Pressione para voltar ao início. Você será redirecionado para outra página',
        },
      },
    },
    upgradeBox: {
      title: 'Calcule o valor do seu upgrade',
      subTitle:
        'Com os itinerários escolhidos, selecione o método de pagamento que deseja usar para obter seu upgrade:',
      fopTitle: 'Selecione o método de pagamento:',
      fopPlaceHolder: 'Forma de pagamento',
      totalCostTitle: 'Custo total:',
      totalCostPlaceHolder: 'Valor em milhas ou certificados',
      totalCostInlineHelper: 'Cálculo de acordo com sua seleção de voo',
      regional: 'regional,',
      global: 'global',
      miles: 'milhas prêmio',
      errors: {
        serviceError: 'Não conseguimos calcular o custo total. Tenta de novo.',
        voucherRequired: 'Você ainda não escolheu certificados de upgrade',
        milesRequired: 'Você não tem milhas suficientes.',
        notEnoughVouchers: 'Você não tem certificados suficientes.',
      },
      fopDropDown: {
        regionalVoucher: 'Certificado Regional',
        available: 'Disponível',
        globalVoucher: 'Certificado Global',
        save: 'Salvar',
        error: 'Escolha um certificado de promoção para avançar',
        miles: 'Milhas prêmio',
        certificates: 'Certificados',
        helperText:
          'A seleção mostrada é sugerida, mas você pode personalizá-la conforme desejar',
      },
      cta: {
        apply: 'Aplicar',
        reset: 'Limpar',
        vouchersToRedim: 'Certificados para resgatar',
      },
      banner: {
        milesSuccess:
          'Você selecionou milhas como forma de pagamento para seu upgrade para a Classe Executiva',
        voucherSuccess:
          'Você selecionou certificados como forma de pagamento para seu upgrade para a Classe Executiva',
        serviceError:
          'Não foi possível aplicar sua forma de pagamento. Verifique as informações e tente novamente.',
      },
      upgradeBoxContainerWCAG:
        "Dans cette section, vous pourrez choisir votre mode de paiement, calculer le coût de votre surclassement grâce à ce mode et l'appliquer. Lors de l'application de votre mode de paiement, les miles ne seront pas débités ou le certificat sera encore utilisé. Vous devrez continuer dans le flux pour obtenir la confirmation de la mise à niveau.",
      certificatesNotAvailable: 'não disponíveis',
    },
    saua: {
      priceSummary: {
        ascendToArrival: 'Ascensão a {arrivalCode}',
      },
      confirmationPage: {
        header: 'Confirmação',
        title: 'Recebemos sua solicitação de upgrade para os seguintes voos',
        pageDescription:
          'Você está em uma página de confirmação. Para conhecer o resultado da sua solicitação, verifique os detalhes da confirmação. Dependendo da disponibilidade, sua solicitação pode ter sido aprovada, parcialmente aprovada ou rejeitada. Caso tenha sido aprovada, o promoção pode demorar alguns minutos para ser refletido.',
        cases: {
          success: {
            title:
              'Recebemos sua solicitação de upgrade para os seguintes voos',
            alertText:
              'Sua solicitação foi recebida com sucesso. Em breve, você receberá uma confirmação de upgrade no seu e-mail.',
            pillText: 'Upgrade recebido',
          },
          partial: {
            title: 'Recebemos a solicitação de upgrade para um dos seus voos.',
            alertText:
              'Não conseguimos processar sua solicitação de upgrade para a Classe Executiva em um dos seus voos. Por favor, tente novamente mais tarde.',
          },
          error: {
            title:
              'Não foi possível processar sua solicitação de upgrade para os seguintes voos:',
            alertText:
              'Não foi possível processar a solicitação de upgrade para seus voos. Por favor, tente novamente mais tarde.',
            pillText: 'Sem upgrade',
          },
        },
        errorTitle: 'Razões pelas quais sua solicitação de upgrade falhou:',
        errorReasons: {
          error1:
            'O número da reserva não corresponde à companhia aérea que opera o voo. Se você não tiver o número da reserva, entre em contato com o Centro de Atendimento.',
          error2:
            'As informações do voo estão incorretas (por exemplo, data, número do voo, número da reserva ou cabine).',
          error3:
            'A classe solicitada não é elegível para um upgrade ou não há disponibilidade.',
          error4: 'Já foi feita uma solicitação de upgrade para esta reserva.',
          error5:
            'Não há milhas suficientes para completar a solicitação de upgrade.',
          error6:
            'A reserva inclui serviços especiais que não podem ser atendidos ao fazer um upgrade de classe (por exemplo, animal de estimação na cabine).',
          error7:
            'A data de partida do voo fornecida está fora das datas disponíveis para um upgrade.',
        },
        flight: {
          title: 'Voo {number}',
          origin: 'Aeroporto de origem',
          destination: 'Aeroporto de destino',
          airline: 'Companhia Aérea',
          number: 'Número do voo',
          date: 'Data do Voo',
          cabin: 'Cabine',
        },
        passenger: {
          title: 'Passageiros para quem solicitou o upgrade:',
          subtitle:
            'São estes os passageiros que poderão usufruir de assento na cabine executiva.',
        },
        paxCard: {
          title: 'Bilhete: {eticket}',
        },
        cta: 'Volte',
        failed:
          'Não foi possível processar sua solicitação de upgrade para os seguintes voos:',
      },
    },
  },
}
