import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { getLanguage } from '../flows/srt/utils/utils'
import { setLocaleLanguage } from '../actions/locale'

export const useLocaleLanguage = () => {
  const dispatch = useDispatch()
  // @ts-ignore
  const { language } = useParams()

  useEffect(() => {
    const lang = getLanguage(language ?? 'en')
    dispatch(setLocaleLanguage(lang))
  }, [])

  return {
    language: getLanguage(language ?? 'en'),
  }
}
