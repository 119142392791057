// @vendors
import React from 'react'
import PropTypes from 'prop-types'
import { compose, mapProps, setPropTypes } from 'react-recompose'
import classNames from 'classnames'

// @logos
import closeIcon from '../../../../assets/images/cancel.svg'

import './closeButtonCO.css'

const closeButtonBasicCO = ({ className, onClick, wcagObject }) => (
  <div>
    <button
      className={className}
      onClick={onClick}
      aria-label={wcagObject ? wcagObject.message : ''}
    >
      <img src={closeIcon} alt="close imagen" />
    </button>
  </div>
)

const _propTypes = {
  onClick: PropTypes.func.isRequired,
  wcagObject: PropTypes.object,
}

const withPropsEnhacer = mapProps(({ extraClass, ...rest }) => ({
  className: classNames('close-button', {
    [extraClass]: extraClass,
  }),
  ...rest,
}))

const mainEnhancer = compose(
  setPropTypes(_propTypes),
  withPropsEnhacer
)(closeButtonBasicCO)

export { mainEnhancer as default, closeButtonBasicCO }
