import React from 'react'
import { useIntl } from 'react-intl'
import { Box, useMediaQuery } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import theme from '@copa/design-system-factory.theme'
import styles from './styles'
import { CalendarIcon } from '../../../../../../assets/images/srt/modalSrt/CalendarIcon'
import { TicketIcon } from '../../../../../../assets/images/srt/modalSrt/TicketIcon'
import { LaptopIcon } from '../../../../../../assets/images/srt/modalSrt/LaptopIcon'

const OnboardingModalContent = () => {
  const { formatMessage } = useIntl()
  const classes = styles()
  const isXS = useMediaQuery(theme.breakpoints.between('xs', 'md'))

  return (
    <Box sx={classes.container}>
      <Box sx={classes.title}>
        <Typography color="primary.main" variant="h2">
          {formatMessage({ id: 'rebooking.newSrtOnboardingModal.title' })}
        </Typography>

        <Typography color="grey.600" variant={isXS ? 'caption' : 'body1'}>
          {formatMessage({ id: 'rebooking.newSrtOnboardingModal.subtitle' })}
        </Typography>
      </Box>

      <Box sx={classes.stepsContainer}>
        <Box sx={classes.step}>
          <Box sx={classes.icon}>
            <CalendarIcon />
          </Box>
          <Box sx={classes.stepText}>
            <Typography sx={classes.stepTitle} color="grey.700" variant="body2">
              {formatMessage({
                id: 'rebooking.newSrtOnboardingModal.step1.title',
              })}
            </Typography>
            <Typography color="grey.600" variant="body2">
              {formatMessage({
                id: 'rebooking.newSrtOnboardingModal.step1.subtitle',
              })}
            </Typography>
          </Box>
        </Box>

        <Box sx={classes.step}>
          <Box sx={classes.icon}>
            <TicketIcon />
          </Box>
          <Box sx={classes.stepText}>
            <Typography sx={classes.stepTitle} color="grey.700" variant="body2">
              {formatMessage({
                id: 'rebooking.newSrtOnboardingModal.step2.title',
              })}
            </Typography>
            <Typography color="grey.600" variant="body2">
              {formatMessage({
                id: 'rebooking.newSrtOnboardingModal.step2.subtitle',
              })}
            </Typography>
          </Box>
        </Box>

        <Box sx={classes.step}>
          <Box sx={classes.icon}>
            <LaptopIcon />
          </Box>
          <Box sx={classes.stepText}>
            <Typography sx={classes.stepTitle} color="grey.700" variant="body2">
              {formatMessage({
                id: 'rebooking.newSrtOnboardingModal.step3.title',
              })}
            </Typography>
            <Typography color="grey.600" variant="body2">
              {formatMessage({
                id: 'rebooking.newSrtOnboardingModal.step3.subtitle',
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OnboardingModalContent
