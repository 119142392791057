import Immutable from 'seamless-immutable'
import { uniqBy } from 'lodash'
import { uniq } from 'rambda'
import { SSR_TYPE_SPECFOOD, SSR_TYPE_WHEEL_CHAIR } from '../constants/variables'

const getPassengers = flights => {
  let passengers = []

  if (flights.length) {
    if (flights[0].passengers.length) {
      passengers = flights[0].passengers
    }
  }

  return passengers
}

const defineGroup = code => {
  switch (code) {
    case 'DEAF':
    case 'BLND':
    case 'WCHR':
    case 'WCHC':
      return 'PSGR'
    default:
      return 'MEAL'
  }
}

export const mapServicesPerUniqPassenger = ssrPssgrArr => {
  const passengersUniq = uniqBy(ssrPssgrArr, 'passengerSelection').map(
    psgr => psgr.passengerSelection
  )

  const newArr = passengersUniq.map(passenger => {
    const serviceList = ssrPssgrArr
      .filter(psgr => psgr.passengerSelection === passenger)
      .map(psgr => {
        switch (psgr.serviceSelection) {
          case SSR_TYPE_SPECFOOD:
            return psgr.mealSelection
          case SSR_TYPE_WHEEL_CHAIR:
            return psgr.chairSelection
          default:
            return psgr.serviceSelection
        }
      })
    return {
      passengerSelection: passenger,
      specialServicesList: serviceList,
    }
  })
  return newArr
}

const filterServicesArrFromResponse = (servicesArrFromResponse, pass) =>
  servicesArrFromResponse
    .flat()
    .filter(ser => ser.passengerKey === pass.travelerKey)
    .map(ser => ({
      code: ser.code,
      group: defineGroup(ser.code),
    }))

export const updateSpecialServices = (flights, servicesArrFromResponse) => {
  const newFlights = Immutable.asMutable(flights, { deep: true })
  const passengers = getPassengers(newFlights)

  const newPassengers = passengers.map(pass => {
    const newSpecialRequests = pass.specialRequests
      ? [
          ...pass.specialRequests,
          ...filterServicesArrFromResponse(servicesArrFromResponse, pass),
        ]
      : filterServicesArrFromResponse(servicesArrFromResponse, pass)
    return { ...pass, specialRequests: newSpecialRequests }
  })

  Object.assign(newFlights[0].passengers, newPassengers)
  return newFlights
}

// TODO: Eventually it would be nice to fix this messy payload
export const specialServicesFromResponse = apiResponse => [
  apiResponse.specialServices.map(ss => [ss]),
]

export const onlySpecialServicesSuceeded = apiResponseSpecialServices => {
  const onlySuceeded = apiResponseSpecialServices
    .flat()
    .map(specialServices =>
      specialServices.filter(spsrvs => spsrvs.operationCompleted)
    )
  return onlySuceeded
}

export const evaluateAddedSpecialServices = apiResponseSpecialServices => {
  const finalEv = apiResponseSpecialServices.map(arr => {
    const evaluation = arr.map(specialServices => {
      if (specialServices.find(ss => !ss.operationCompleted)) return true
      return false
    })
    return evaluation
  })
  if (finalEv.flat().find(fe => fe)) return true
  return false
}

export const transformPayload = (
  passengers,
  flight,
  servicesAddedFromModal
) => {
  const servicePerPassengerArr = mapServicesPerUniqPassenger(
    servicesAddedFromModal
  )
  const ssrArr = servicePerPassengerArr.map(sa => ({
    givenName: passengers.find(p => p.travelerKey === sa.passengerSelection)
      .altGivenName,
    surname: passengers.find(p => p.travelerKey === sa.passengerSelection)
      .lastName,
    passengerType: passengers.find(p => p.travelerKey === sa.passengerSelection)
      .typeCode,
    passengerKey: sa.passengerSelection,
    flightsInfo: flight.flights
      .filter(
        f =>
          f.specialServicesPerFlight &&
          f.specialServicesPerFlight
            .map(sspf => sspf.code)
            .some(code => sa.specialServicesList.includes(code))
      )
      .map(f => ({
        locationDepart: f.departureCode,
        flightNumber: f.flightNumber,
        airlineOperating: f.airlineCode,
        flightKey: f.flightKey,
        departureDate: f.flightDate.toString().split('T')[0],
        locationArrive: f.arrivalCode,
        status: f.status,
        specialServices: sa.specialServicesList
          .filter(
            ss =>
              f.specialServicesPerFlight &&
              f.specialServicesPerFlight.map(sspf => sspf.code).includes(ss)
          )
          .map(ss => ({ code: ss })),
      })),
  }))
  const payloadData = {
    pnr: flight.pnr,
    passengers: ssrArr,
  }

  return payloadData
}

export const getPassengerSSRs = (passenger, flights) => {
  const ssrsForPassenger = flights.reduce((ssrs, f) => {
    const flightPassenger = f.passengers.find(
      p => p.travelerKey === passenger.travelerKey
    )
    return flightPassenger && flightPassenger.specialRequests
      ? ssrs.concat(flightPassenger.specialRequests)
      : ssrs
  }, [])
  return {
    passenger: passenger.travelerKey,
    specialServices: uniq(ssrsForPassenger),
  }
}
