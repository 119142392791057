import React, { useRef, useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import ItemsPopper from './components/ItemsPopper';
import { Box, ClickAwayListener, TextFieldProps } from '@mui/material';
import Option from './components/Option';
import { Item } from './types';
import { autoCompleteStyles } from './styles/styles';
import NoOptions from './components/NoOption';
import { LoadingIndicator } from './components/LoadingIndicator';
import AutocompleteInput from './components/AutocompleteInput';
import debounce from 'lodash/debounce'
import ErrorPropper, { ErrorPropperProps } from './components/ErrorPopper';
import defaultFilter from './utils/defaultFilter';
import CheckIcon, { ClearIcon } from './components/CheckIcon';
import theme from '@copa/design-system-factory.theme';

export type AutocompleteRegularProps = {
  id: string;
  /**
   * Label of the component
   */
  label: string;
  /**
   * Indicates if the field is required or not
   */
  required?: boolean;
  /**
   * Default selected value. This property wont udpate if the selecte value changed
   */
  value?: Item;
  /**
   * Items to be displayed on the autocomplete list
   */
  items?: Array<Item>;
  /**
   * Text to display when no content is set on the input
   */
  placeholder?: string;
  /**
   * Set the list of item in the loading state
   */
  isLoading?: boolean;

  /**
   * Defines the strings for the not found box
   */
  notFoundErrorText: string,

  /**
   * Input props
   */
  inputProps?: TextFieldProps["inputProps"],
  /**
   * The helper text content.
   */
  helperText?: string,
  /**
   * Error help text. Render when the hasErrors property is equals to true
   */
  errorHelperText?: string;
  /**
   * Set the component with the error state
   */
  hasErrors?: boolean,
  /**
   * Set the component in a disabled state
   */
  disabled?: boolean

  /**
   * Triggers when the selected item changes
   * @param item Item that changed to
   * @param index Index of the selected item
   * @param previousItem Previous selected item
   * @returns 
   */
  onChange?: (item: Item, index: number, previousItem: Item) => void;

  /***
   * Triggers when the user clears the selected item by clicking on the x icon
   */
  onClear?: () => void;

  /**
   * Overrides the default filter that determinates which items are eligible to be displayed.
   * This function wont execute if you are using the asyncSearchOptions. See the docs for more info.
   * @param items Provided items  
   * @param userInput User's input
   * @returns Filtered items
   */
  filter?: (items: Item[], userInput: string) => Array<Item>;

  /**
   * Provides utilities to execute an async search while the user types
   * any criteria on the input.
   */
  asyncSearchOptions?: {
    /**
     * Executes on each user key press to
     * @param userInput User's input value
     * @param userInput User's input value
     * @returns The items result or the erro state
     */
    execute: (userInput: string, options: { filter: AutocompleteRegularProps["filter"] }) => Promise<{
      /**
       * Client error response
       */
      errorText? : string;
      /**
       * Result items from the async search
       */
      result?: Array<Item>;
    }>,
    /**
     * Defines the wait time to execute the request after the 
     * user press a key. Value is in miliseconds
     */
    keyStrokeDebounceTime?: number;
    /**
     * Indicates the min characters required to run the execute method
     */
    minRequiredCharacters?: number
  },
  /**
   * Accesibility label for the input component
   */
  ariaLabel?: string,
  /**
   * Order function that runs afters the filter function.
   * @param items Items to order
   * @returns Ordered items
   */
  order?: "ascending" | "descending",

  /**
   * Change the checkmark color when an item is selected
   */
  checkMarkColor?: string;
}

export function AutocompleteRegular(props: AutocompleteRegularProps) {
  const {
    asyncSearchOptions,
    ariaLabel,
    disabled,
    items: defaultItems,
    notFoundErrorText,
    hasErrors: defaultHasErrors,
    label,
    helperText,
    errorHelperText,
    isLoading: defaultIsLoading,
    placeholder,
    value: defaultValue,
    onChange,
    filter,
    order = "ascending",
    checkMarkColor
  } = props;

  const [value, setValue] = useState<Item>(null);
  const [items, setItems] = useState<Array<Item>>([]);
  const [hasErrors, setHasError] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const inputRef = useRef<HTMLDivElement>(null);
  const [errorPopper, setErrorPopper] = useState<Omit<ErrorPropperProps, "anchor">>(null);

  /**
   * When doing async search we dont want the no option popper to be displayed while the user types
   * because the search is executed asynchrously and at load time there is not items to match.
   * With this state we can decide when to show the no option popper
   */
  const [notOptionComponent, setNoOptionComponent] = useState<React.ReactNode>(null);

  

   /**
   * Store the user input and we use this as cache key to know when 
   * to trigger another search or not. See https://copavsts.visualstudio.com/digital-products/_workitems/edit/528126
   */
  const userSearchCacheKey = useRef<string>("");

  const isError = !!errorPopper;

  // flag that will indicate if the dropdown should be visible or not
  // mostly use if the user has typed any character on the input, if not the dropdown should be hidden 
  // even if the input has the focused
  const [isDropdowOpen, setIsDropDownOpen] = useState<boolean>(false);

  useEffect(() => {
    setValue(defaultValue || null);
    setItems(defaultItems || [])
    setHasError(defaultHasErrors);
  }, [defaultValue, defaultItems, defaultHasErrors])

  useEffect(() => {
    setIsLoading(defaultIsLoading);
  }, [defaultIsLoading])

  useEffect(() => {
    // if not async search option then
    // we set the default not found error option
    if (!asyncSearchOptions)
      setNoOptionComponent(<NoOptions title={notFoundErrorText} />)
  }, [asyncSearchOptions, notFoundErrorText])

  const onItemChange = (item: Item, e: React.SyntheticEvent<Element, Event>) => {
 
    if (onChange)
      onChange(item, items.indexOf(item), value);
    setValue(item);
  }

  useEffect(() => {
    if (props.onClear && value === null)
      props.onClear()
  }, [value])

  const executeRequest = debounce(async (input: string) => {

    
    // base on https://copavsts.visualstudio.com/digital-products/_workitems/edit/528126
    // if the first specified characters match the current cached key we dont execute the async search
    // but use the same array returned previously
    if(asyncSearchOptions.minRequiredCharacters)
    {
      const key = input.substring(0, asyncSearchOptions.minRequiredCharacters!).toLowerCase()
      if(items && items.length > 0 && userSearchCacheKey.current == key) {
          setNoOptionComponent(<NoOptions title={notFoundErrorText} />)
          return Promise.resolve();
      }
      else {
        userSearchCacheKey.current = key;
      }
    }

    try {

      setIsLoading(true);
      setItems([])
      // hide the component because the user still searching
      const { result, errorText } = await asyncSearchOptions.execute(input, { filter: props.filter });
      setNoOptionComponent(<NoOptions title={notFoundErrorText} />)

      if (result) {
        setItems(result);
        setErrorPopper(null);
      }
      else {
        setHasError(true);
        setErrorPopper({text: errorText});
      }
    } catch (e: any) {

      setErrorPopper({ text: errorHelperText })
      setHasError(true);

    }
    finally {
      setIsLoading(false)
    }
  }, asyncSearchOptions?.keyStrokeDebounceTime || 10);

  const onUserInputChange = async (e: React.SyntheticEvent<Element, Event>, value: string, reason: string) => {

    const eventKey = e ? parseInt(e.nativeEvent["which"] || 0, 10) : 0;
    const isEnterKey = eventKey === 13 || eventKey === 1;
    const isValuePresent = value && value.trim().length > 0;
    // this event can also be trigger by setting the default value 
    // if so, we dont show the dropdown
    const wasTriggerByEvent = e !== null;

    setIsDropDownOpen(wasTriggerByEvent && isValuePresent && !isEnterKey)
    setErrorPopper(null)

    // if the user clears the input, we unselect the selected value
    if (value && value.length === 0)
      setValue(null);
    /**
     * The motive to do this is because the user could implement his custom filter and filter by property 'a' of the 
     * list and on select render property 'b' on the input but this will cause the onInputChange to trigger again which 
     * will trigger another async search since the input change with the value of property 'b' and this is an incorrect behavior.
     * By checking the reason prop if its in status reset we can prevent the search again
     */
    if(reason === "reset"){
      setNoOptionComponent(null);
      return;
    }
    if (reason === "clear" && props.onClear) {
      props.onClear();
    }
    if (asyncSearchOptions) {
      if (asyncSearchOptions.minRequiredCharacters) {
        if (value.length >= asyncSearchOptions.minRequiredCharacters) {
          setNoOptionComponent(null);
          executeRequest(value);
        }
        else // close the dropdown if the min characters criteria is not met
          setIsDropDownOpen(false);
      }

      else
        executeRequest(value);
    }
  }

  const onInputBlur = () => {
    if (inputRef.current && inputRef.current.querySelector("input").value.length === 0)
      setValue(null)
  }

  const internalFilter = (items: Array<Item>, value: string) => {
    const results = filter ? filter(items, value) : defaultFilter(items, value.trim().toLocaleLowerCase());
    const result =  order === "ascending" ? [...results].sort((a,b)=> a.text.localeCompare(b.text)) :  [...results].sort((a,b)=> a.text.localeCompare(b.text)).reverse()

    return result;
  }

  const getAriaLabel = () => {
    const currVal = (inputRef?.current as HTMLDivElement)?.querySelector('input')?.value || "";
    if (errorPopper)
      return `${errorPopper.text}`;
    if (hasErrors)
      return errorHelperText;
    // if there is not elements on filtered items, we must display the not found error
    // previously this used to be state inside the internalFilter but was causing issues
    // because of updating component while rendering 
    if (internalFilter(items, currVal.trim()).length === 0)
      return notFoundErrorText;
    else
      return ariaLabel;
  }

  const getClasses = ()=>{
    const classes : string[] = [];
    if(hasErrors)
      classes.push('has-errors');
    if(value)
      classes.push('value-selected');

    return classes.join(' ');
  }

  return (
    <ClickAwayListener onClickAway={() => {
      setErrorPopper(null);
      setIsDropDownOpen(false);
    }}>
      <Box position="relative">
        <Autocomplete
          clearOnEscape
          clearIcon={<ClearIcon />}
          popupIcon={value && !hasErrors ? <CheckIcon color={checkMarkColor || theme.palette.success.main} /> : null}
          ListboxProps={{
            style: {
              maxHeight: "300px"
            }
          }}
          value={value}
          getOptionLabel={x=> x.text}
          isOptionEqualToValue={(option, val)=> option.text === val.text}
          open={isDropdowOpen}
          options={items}
          clearOnBlur={false}
          PopperComponent={ItemsPopper}
          onInputChange={onUserInputChange}
          onBlur={onInputBlur}
          filterOptions={(items, state) => internalFilter(items, state.inputValue.trimEnd())}
          loading={isLoading}
          autoHighlight
          sx={autoCompleteStyles.base()}
          className={getClasses()}
          disabled={disabled}
          componentsProps={{
            paper: {
              sx: autoCompleteStyles.paper(!isError)
            },
          }}
          renderInput={(params) => (
            <AutocompleteInput
              ref={inputRef}
              label={label}
              helperText={helperText}
              helperTextError={errorHelperText}
              disabled={disabled}
              params={{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  onBlur: (e) => {
                    if (props?.inputProps?.onBlur)
                        props.inputProps.onBlur(e);
                    if (params?.inputProps?.onBlur)
                      params.inputProps.onBlur(e);

                    setIsDropDownOpen(false);
                    e.target.value = e.target.value.trim();
                  }
                }
              }}
              hasErrors={hasErrors}
              placeholder={placeholder}
              ariaLabel={getAriaLabel()}
              required={props.required}
            />
          )}
          noOptionsText={notOptionComponent}
          loadingText={<LoadingIndicator />}
          renderOption={(props, item) => <Option key={item.key} state={item} optionsProps={props} />}
          onChange={(a, b) => onItemChange(b as Item, a)}
        ></Autocomplete>
        {errorPopper && <ErrorPropper {...errorPopper} anchor={inputRef} />}
      </Box>
    </ClickAwayListener>
  )
}
