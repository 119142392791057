import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { QueryParams } from 'src/types/QueryParams'
import { MyTripsState } from '../../../types/state'
import {
  isMyAccountOrigin,
  isValidLoyalty,
  redirecToSauaGeneralErrorPage,
} from '../utils'
import { UpgradeHooksParams, useFlowReturn } from '../upgrade-hub/types'
import { UpgradeErrorPage, UpgradeLocation } from '../types'
import { useAuthentication } from '../../../hooks/auth/useAuthentication'

export default function useSauaFlow({
  origin,
  upgradeInfo: { tripInfo, profileExtendInfo },
}: UpgradeHooksParams): useFlowReturn {
  const history = useHistory()
  const { pnr, surname, language } = useParams<QueryParams>()
  const { isLoggedIn } = useAuthentication()

  const { masterSessionId: sessionToken, lp_ffn: username } = useSelector(
    (state: MyTripsState) => state.auth
  )

  const [showContent, setShowContent] = useState(false)
  const [location, setLocation] = useState<UpgradeLocation>(
    UpgradeLocation.BLANK
  )

  useEffect(() => {
    function isValidString(value: string | undefined) {
      return value !== '' && value !== null
    }

    if (profileExtendInfo) {
      const isValidProfileExtendResponse =
        profileExtendInfo?.loyalty &&
        isValidString(profileExtendInfo.loyalty.loyalLevel) &&
        isValidString(profileExtendInfo.loyalty.customerID) &&
        isValidString(profileExtendInfo.loyalty.membershipID)

      const isAbleToUpgradeWithMiles = isValidLoyalty({
        loyalty: {
          loyalLevel: profileExtendInfo?.loyalty?.loyalLevel,
          balance: profileExtendInfo?.loyalty?.balance as number,
        },
        eligibleUpgrade: true,
        isLoggedIn: !!isLoggedIn,
      })

      if (!isValidProfileExtendResponse) {
        redirecToSauaGeneralErrorPage({
          history,
          pnr,
          surname,
          language,
          origin,
        })
      }

      if (isMyAccountOrigin(origin) && !isAbleToUpgradeWithMiles) {
        redirecToSauaGeneralErrorPage({
          history,
          pnr,
          surname,
          language,
          origin,
          type: UpgradeErrorPage.NO_MILES,
        })
      }
    }
  }, [
    history,
    isLoggedIn,
    language,
    origin,
    pnr,
    profileExtendInfo,
    sessionToken,
    surname,
    username,
  ])

  useEffect(() => {
    if (isMyAccountOrigin(origin) && profileExtendInfo) {
      setShowContent(true)
    } else if (!isMyAccountOrigin(origin) && tripInfo) {
      setShowContent(true)
      setLocation(UpgradeLocation.SAUA_ONBOARDING)
    }
  }, [origin, profileExtendInfo, tripInfo])

  return {
    upgradeInfo: { tripInfo, profileExtendInfo },
    showContent,
    location,
  }
}
