const focusClassName = (element, add) => {
  const elementButton = element
  if (!element || !element.className) return false
  if (add) {
    elementButton.className = `${elementButton.className} focused`
    return false
  }
  if (element.className.split(' ').some(c => c === 'focused')) {
    const focusedRemoved = element.className.replace(/focused/gi, '')
    elementButton.className = focusedRemoved
    return true
  }
  return false
}

export const getArrowBehavior = (element, index, list) => {
  const listElement = list
  if (element.keyCode === 27) return listElement[index].blur()
  if (element.keyCode === 9) return element.preventDefault()
  if (element.keyCode === 40 || element.keyCode === 38) {
    let newIndex = 0
    if (element.keyCode === 40 && index + 1 === list.length) {
      focusClassName(listElement[newIndex], true)
      return listElement[0].focus()
    }
    if (element.keyCode === 40) {
      newIndex = index + 1
      focusClassName(listElement[newIndex], true)
      return listElement[newIndex].focus()
    }
    if (element.keyCode === 38 && index === 0) {
      focusClassName(listElement[listElement.length - 1], true)
      return listElement[listElement.length - 1].focus()
    }
    newIndex = index - 1
    focusClassName(listElement[newIndex], true)
    return listElement[newIndex].focus()
  }
  return null
}

export const getActiveElements = list => {
  let activeElement = false
  const listElement = list
  for (let i = 0; i < list.length; i++) {
    if (focusClassName(listElement[i])) {
      activeElement = true
    }
  }
  return activeElement
}
