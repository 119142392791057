import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '16px',
  },
  icon: {
    width: '96px',
    height: '96px',
  },
  title: {
    marginBottom: '24px',
    width: '100%',
    textAlign: 'center',
  },
  description: {
    marginBottom: '16px',
    width: '100%',
    textAlign: 'center',
  },
}))
