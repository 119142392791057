import theme from '@copa/design-system-factory.theme'
import { Box } from '@mui/material'
import { HeaderBarNavigation } from '@copa/design-system-factory.header-bar-navigation'
import { InlineAlert } from '@copa/design-system-factory.inline-alert'
import { Markdown } from '@copa/design-system-factory.markdown'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router'
import { Layout } from '@copa/design-system-factory.layout'
import { Typography } from '@copa/design-system-factory.typography'
import { PaxCard } from '@copa/design-system-factory.cards.pax-card'
import { ButtonCo } from '@copa/design-system-factory.button'
import { useDispatch } from 'react-redux'
import { redirectToOrigin } from '../../utils'
import useStyles from './styles'
import useBitBreakPoints from '../../../../hooks/material/useBitBreakPoints'
import { QueryParams } from '../../../../types/QueryParams'
import DestinationCard from '../../../srt/components/destinationCard'
import { useGetTripInfoMutation } from '../../../../api/upgradeHubSlice'
import { UpgradeCacheKey } from '../../types'
import FlightInfo from './components/FlightInfo'
import { capitalizeFirstLetter } from '../../../srt/utils/flights'
import { PassengersInputs } from '../../upgrade-hub/types'
import { SauaFlight } from '../components/FlightForm/FlightForm'
import { flightClean } from '../../../../actions/flight'
import { getUpgradeScenarioConfig, SAUAConfirmationScenario } from './utils'

interface ConfirmationSauaProps {
  flights: SauaFlight[] | undefined
  passengers: PassengersInputs | undefined
  origin: string
  scenario: SAUAConfirmationScenario
}

const TOTAL_ERROR_REASONS = 7

export default function ConfirmationSAUA({
  flights,
  origin,
  passengers,
  scenario,
}: ConfirmationSauaProps) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles({ theme, breakpoints })
  const { pnr, surname, language } = useParams<QueryParams>()
  const history = useHistory()

  const [_, { data: tripInfo }] = useGetTripInfoMutation({
    fixedCacheKey: UpgradeCacheKey.TRIP_INFO,
  })

  const alertConfig = getUpgradeScenarioConfig(scenario)

  const goToOrigin = useCallback(() => {
    dispatch(flightClean())
    redirectToOrigin({ pnr, surname, language, origin, history })
  }, [dispatch, history, language, origin, pnr, surname])

  const errorReasons = useMemo(() => {
    if (alertConfig.variant !== 'error') {
      return ''
    }
    const totalErrors = Array.from({ length: TOTAL_ERROR_REASONS })
    const errorReasonsMessages = totalErrors.map(
      (__, idx) =>
        `* ${formatMessage({
          id: `upgradeHub.saua.confirmationPage.errorReasons.error${idx + 1}`,
        })}`
    )
    return errorReasonsMessages.join('\n')
  }, [alertConfig.variant, formatMessage])

  return (
    <Box
      tabIndex={0}
      aria-label={formatMessage({
        id: 'upgradeHub.saua.confirmationPage.pageDescription',
      })}
    >
      <HeaderBarNavigation
        title={formatMessage({ id: 'upgradeHub.saua.confirmationPage.header' })}
        wcagText={formatMessage({
          id: 'upgradeHub.confirmation.wcag.ctaText.failedUpgrade',
        })}
        onClick={() =>
          redirectToOrigin({ pnr, surname, language, origin, history })
        }
      />

      <Box sx={classes.container}>
        <Layout>
          <Box sx={classes.contentContainer}>
            {tripInfo && (
              <DestinationCard
                flight={tripInfo}
                template={{
                  display: true,
                  reservationCode: 'rebook.reservationCode',
                }}
              />
            )}

            <Box sx={classes.title}>
              <Typography color="primary.main" variant="h2">
                {formatMessage({ id: alertConfig.title })}
              </Typography>
              <Box sx={classes.alert}>
                <InlineAlert
                  message={formatMessage({ id: alertConfig.alertText })}
                  variant={alertConfig.variant}
                  icon={alertConfig.icon}
                />
              </Box>
            </Box>

            <FlightInfo flights={flights} />

            {alertConfig.variant === 'error' && (
              <Box sx={classes.errorDetails}>
                <Typography color="grey.700" variant="h3">
                  {formatMessage({
                    id: 'upgradeHub.saua.confirmationPage.errorTitle',
                  })}
                </Typography>
                <Markdown>{errorReasons}</Markdown>
              </Box>
            )}

            <Box>
              <Box sx={classes.paxTitle}>
                <Typography color="grey.700" variant="h3">
                  {formatMessage({
                    id: 'upgradeHub.saua.confirmationPage.passenger.title',
                  })}
                </Typography>

                <Typography color="grey.600" variant="body1">
                  {formatMessage({
                    id: 'upgradeHub.saua.confirmationPage.passenger.subtitle',
                  })}
                </Typography>
              </Box>

              <Box sx={classes.paxCardBox}>
                {passengers?.map(({ name, lastName, eTicket }) => (
                  <Box key={eTicket} sx={{ background: 'white' }}>
                    <PaxCard
                      key={eTicket}
                      avatar={{
                        id: 'basic',
                        font: capitalizeFirstLetter(`${name} ${lastName}`),
                        stroke: true,
                        inverted: true,
                      }}
                      listDescription={[
                        formatMessage(
                          {
                            id: 'upgradeHub.saua.confirmationPage.paxCard.title',
                          },
                          {
                            eticket: eTicket,
                          }
                        ),
                      ]}
                      wcagText=""
                    />
                  </Box>
                ))}
              </Box>
            </Box>

            <Box sx={classes.buttonBox}>
              <ButtonCo
                variant="solidPrimaryMain"
                rounded
                size="large"
                aria-label={formatMessage({
                  id: 'upgradeHub.confirmation.wcag.ctaText.failedUpgrade',
                })}
                sx={{ width: { xs: '100%', md: '25%' } }}
                onClick={goToOrigin}
              >
                {formatMessage({ id: 'upgradeHub.saua.confirmationPage.cta' })}
              </ButtonCo>
            </Box>
          </Box>
        </Layout>
      </Box>
    </Box>
  )
}
