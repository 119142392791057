import React, { ReactElement } from 'react'
import OnboardingModalContent from '../components/OnboardingModalContent/OnboardingModalContent'
import ConfirmDefaultItinerary from '../components/ConfirmDefaultItinerary/ConfirmDefaultItinerary'
import CompleteConfirmation from '../components/CompleteConfirmation/CompleteConfirmation'
import SRT_MODAL_VARIANTS from './variants'
import WrongAccountContent from '../components/WrongAccount/WrongAccount'
import CabinDowngradedContent from '../components/CabinDowngraded/CabinDowngraded'
import { SrtModalOptions } from '../ModalSrt'

type ModalType = 'normal' | 'wide' | 'narrow'
type CloseOnKeysType = 'backdropClick' | 'exitButton'

interface IModalButton {
  id: string
  text: string
  ariaLabel: string
  variant?: CloseOnKeysType
}

interface IModalVariant {
  type: ModalType
  content: ReactElement
  primaryBtn: IModalButton
  secondaryBtn?: IModalButton
  wcag?: {
    scrollBar: string
    closeBtn: string
  }
  avoidCloseOn: string
}

const getType = (type: ModalType): ModalType => {
  if (type === 'wide') {
    return 'wide'
  }
  if (type === 'narrow') {
    return 'narrow'
  }
  return 'normal'
}

const getVariantOptions = (
  variant: SRT_MODAL_VARIANTS,
  ownerFFN: string,
  shouldRedirectSeatMap: boolean | undefined,
  options: SrtModalOptions | undefined,
  isXS = false
): IModalVariant => {
  const confirmationModalContinueBtnKey = shouldRedirectSeatMap
    ? 'seatContinueButton'
    : 'oldContinueButton'

  const continueBtnKey = shouldRedirectSeatMap
    ? 'seatMapContinueBtn'
    : 'oldContinueBtn'

  const variantOptions = {
    [SRT_MODAL_VARIANTS.ONBOARDING]: {
      type: isXS ? getType('normal') : getType('wide'),
      content: <OnboardingModalContent />,
      avoidCloseOn: 'exitButton',
      primaryBtn: {
        id: 'button-modal-onboarding',
        text: 'rebooking.newSrtOnboardingModal.cta',
        ariaLabel: 'onBoardingModal.buttonWcag',
        size: 'fancySmall',
      },
      wcag: {
        scrollBar: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.scrollBar',
        closeBtn: 'onBoardingModal.ctaWcag',
      },
    },
    [SRT_MODAL_VARIANTS.CONFIRM_GENERAL]: {
      type: getType('narrow'),
      content: (
        <CompleteConfirmation shouldRedirectSeatMap={shouldRedirectSeatMap} />
      ),
      avoidCloseOn: 'no-avoid-close',
      primaryBtn: {
        id: 'button-modal-confirm',
        text: `srt.sc.default.checkout.confirmationModal.${confirmationModalContinueBtnKey}`,
        ariaLabel: `srt.sc.default.checkout.confirmationModal.${confirmationModalContinueBtnKey}`,
        variant: 'solidPrimaryMain',
      },
      wcag: {
        scrollBar: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.scrollBar',
        closeBtn: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.goBackCta',
      },
      ...(shouldRedirectSeatMap
        ? {
          secondaryBtn: {
            id: 'button-modal-go-to-mytrips',
            text: 'srt.sc.default.checkout.confirmationModal.redirectToMyTrips',
            ariaLabel:
                'srt.sc.default.checkout.confirmationModal.redirectToMyTrips',
            variant: 'outlinePrimaryMain',
          },
        }
        : {}),
    },
    [SRT_MODAL_VARIANTS.CONFIRM_ITINERARY]: {
      type: getType('narrow'),
      content: (
        <ConfirmDefaultItinerary
          shouldRedirectSeatMap={shouldRedirectSeatMap}
        />
      ),
      primaryBtn: {
        id: 'button-modal-acceptItinerary',
        text: `srt.sc.default.onboardingPage.confirmDefaultItineraryModal.${continueBtnKey}`,
        ariaLabel: `srt.sc.default.onboardingPage.confirmDefaultItineraryModal.${continueBtnKey}`,
      },
      avoidCloseOn: 'backdropClick escapeKeyDown',
      secondaryBtn: {
        id: 'button-modal-cancelItinerary',
        text: 'srt.sc.default.onboardingPage.confirmDefaultItineraryModal.cancelBtn',
        ariaLabel:
          'srt.sc.default.onboardingPage.confirmDefaultItineraryModal.WCAG.cancelBtn',
        variant: 'outlinePrimaryMain',
      },
      wcag: {
        scrollBar: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.scrollBar',
        closeBtn: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.goBackCta',
      },
    },
    [SRT_MODAL_VARIANTS.WRONG_ACCOUNT]: {
      type: getType('normal'),
      content: <WrongAccountContent ownerFFN={ownerFFN} />,
      avoidCloseOn: 'exitButton',
      primaryBtn: {
        id: 'button-modal-wrong-account',
        text: 'srt.wrongAccountModal.cta',
        ariaLabel: 'srt.wrongAccountModal.cta',
      },
      wcag: {
        scrollBar: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.scrollBar',
        closeBtn: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.goBackCta',
      },
    },
    [SRT_MODAL_VARIANTS.CABIN_DOWNGRADED]: {
      type: getType('normal'),
      content: <CabinDowngradedContent city={options ? options.city : ''} />,
      avoidCloseOn: 'exitButton',
      primaryBtn: {
        id: 'primary-button-modal-cabin-downgraded',
        text: 'srt.wci.cfc.resultSearchPage.upx.modal.goBackCta',
        ariaLabel: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.goBackCta',
        variant: 'outlinePrimaryMain',
      },
      secondaryBtn: {
        id: 'secondary-button-modal-cabin-downgraded',
        text: 'srt.wci.cfc.resultSearchPage.upx.modal.confirmCabinChangeCTA',
        ariaLabel:
          'srt.wci.cfc.resultSearchPage.upx.modal.wcag.confirmCabinChangeCTA',
      },
      wcag: {
        scrollBar: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.scrollBar',
        closeBtn: 'srt.wci.cfc.resultSearchPage.upx.modal.wcag.goBackCta',
      },
    },
  }
  return variantOptions[variant] as IModalVariant
}

export default getVariantOptions
