import { forwardRef } from 'react'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import isEmpty from 'lodash/isEmpty'
import { InlineHelperText } from '@copa/design-system-factory.inline-helper-text'
import { theme } from '@copa/design-system-factory.theme'
import { Props } from './types'
import { inputBigStyles } from './styles'

export const InputBig = forwardRef<HTMLInputElement, Props>(
  (
    {
      helperTextIcon = null,
      onChange = () => {},
      hasErrors = false,
      helperText = '',
      id = '',
      inverted = false,
      label = '',
      labelClassName = {},
      placeholder = '',
      WCAGHelperText = '',
      InputProps = {},
      children = null,
      inputLogo = null,
      variant = 'standard',
      value = '',
      ...props
    }: Props,
    ref
  ) => {
    const inputStyles = inputBigStyles(inverted)
    const errorClass = hasErrors ? inputStyles.error : null
    const labelClass = !inverted
      ? {
          ...inputStyles.label,
          color: theme.palette.grey[600],
        }
      : {
          ...inputStyles.label,
          color: theme.palette.common.white,
        }

    const errorLogo = () => (
      <svg
        width="12"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0.5C2.25 0.5 0 2.75 0 5.5C0 8.25 2.25 10.5 5 10.5C7.75 10.5 10 8.25 10 5.5C10 2.75 7.75 0.5 5 0.5ZM5 8C4.7 8 4.5 7.8 4.5 7.5C4.5 7.2 4.7 7 5 7C5.3 7 5.5 7.2 5.5 7.5C5.5 7.8 5.3 8 5 8ZM4.5 6H5.5V3H4.5V6Z"
          fill="#D52525"
        />
      </svg>
    )

    const normalLogo = () => (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0C2.25 0 0 2.25 0 5C0 7.75 2.25 10 5 10C7.75 10 10 7.75 10 5C10 2.25 7.75 0 5 0ZM5 7.5C4.7 7.5 4.5 7.3 4.5 7C4.5 6.7 4.7 6.5 5 6.5C5.3 6.5 5.5 6.7 5.5 7C5.5 7.3 5.3 7.5 5 7.5ZM4.5 5.5H5.5V2.5H4.5V5.5Z"
          fill="#AAAAAB"
        />
      </svg>
    )

    const invertedLogo = () => (
      <svg
        width="10"
        height="11"
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 0.5C2.25 0.5 0 2.75 0 5.5C0 8.25 2.25 10.5 5 10.5C7.75 10.5 10 8.25 10 5.5C10 2.75 7.75 0.5 5 0.5ZM5 8C4.7 8 4.5 7.8 4.5 7.5C4.5 7.2 4.7 7 5 7C5.3 7 5.5 7.2 5.5 7.5C5.5 7.8 5.3 8 5 8ZM4.5 6H5.5V3H4.5V6Z"
          fill="#AAAAAB"
        />
      </svg>
    )

    const properLogo = !inverted ? normalLogo : invertedLogo

    return (
      <Box sx={{ display: 'flex' }}>
        {inputLogo && (
          <Box style={{ marginRight: '0.5rem', alignSelf: 'center' }}>
            {inputLogo}
          </Box>
        )}
        <Box sx={[inputStyles.root, errorClass]}>
          {label && (
            <InputLabel
              data-cy={`label-${id}`}
              htmlFor={id}
              sx={[labelClass && labelClassName]}
            >
              {label}
            </InputLabel>
          )}
          <TextField
            ref={ref}
            sx={inputStyles.input}
            id={id}
            aria-errormessage={`${id}-helper-text`}
            placeholder={placeholder}
            helperText={null}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              ...InputProps,
              'aria-describedby': `${id}-helper-text`,
            }}
            FormHelperTextProps={{ classes: inputStyles.helperText }}
            onChange={onChange}
            fullWidth
            error={hasErrors}
            variant={variant}
            value={value}
            {...props}
          >
            {children}
          </TextField>
          <InlineHelperText
            id={`${id}-helper-text`}
            showMessage={hasErrors || !isEmpty(helperText)}
            helperText={helperText}
            WCAGHelperText={WCAGHelperText}
            inverted={inverted}
            isError={hasErrors}
            icon={helperTextIcon || (hasErrors && errorLogo()) || properLogo()}
          />
        </Box>
      </Box>
    )
  }
)
