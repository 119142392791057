import { spacing } from '../../../../utils/spacing'

export const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    justifyContent: 'space-between',
    marginBottom: spacing.normal,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.minimal,
    marginBottom: spacing.petit,
  },
  carouselActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: spacing.petit,
  },
  actionBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.minimal,
  },
  flights: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.petit,
  },
})
