import en from './en'
import es from './es'
import pt from './pt'
import fr from './fr'
import { flatten } from '../helpers/object'

export default {
  en: flatten({ ...en }),
  es: flatten({ ...es }),
  pt: flatten({ ...pt }),
  fr: flatten({ ...fr }),
}
