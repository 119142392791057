import React, { FC, SVGAttributes, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import theme from '@copa/design-system-factory.theme'
import { Box } from '@mui/material'
import { HeaderBarNavigation } from '@copa/design-system-factory.header-bar-navigation'
import { TitleCombination } from '@copa/design-system-factory.title-combination'
import { useIntl } from 'react-intl'
import { isFetchingGeneral } from 'src/actions/generalSpinner'
import { useGoBackWithOrigin } from '../../../hooks/useGoBackToOrigin'
import FlightErrorIcon from '../../../../../assets/flightError.svg'
import { UpgradeErrorPage } from '../../../types'
import Icon from '../../../../../utils/icon'
import useStyles from './styles'
import useBitBreakPoints from '../../../../../hooks/material/useBitBreakPoints'

type GeneralErrorPageTranslation = {
  avatar: FC<SVGAttributes<SVGElement>> | string
  title: string
  subtitle: string
  cta: string
  ctaWcag: string
}

const errorPageTranslation: { [p: string]: GeneralErrorPageTranslation } = {
  [UpgradeErrorPage.DEFAULT]: {
    avatar: FlightErrorIcon,
    title: `upgrade.saua.generalErrorPage.title`,
    subtitle: `upgrade.saua.generalErrorPage.subtitle`,
    cta: 'upgrade.saua.generalErrorPage.cta',
    ctaWcag: 'upgradeHub.header.wcagText',
  },
  [UpgradeErrorPage.NO_MILES]: {
    avatar: Icon.ALERT_B,
    title: `upgrade.saua.noMilesErrorPage.title`,
    subtitle: `upgrade.saua.noMilesErrorPage.subtitle`,
    cta: 'upgrade.saua.noMilesErrorPage.cta',
    ctaWcag: 'upgrade.saua.noMilesErrorPage.ctaWcag',
  },
}

type SauaGeneralErrorProps = {
  type: UpgradeErrorPage | string
}

function SauaGeneralError({ type }: SauaGeneralErrorProps) {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const goBack = useGoBackWithOrigin()
  const { breakpoints } = useBitBreakPoints()

  const classes = useStyles(breakpoints)

  const translations: GeneralErrorPageTranslation = useMemo(() => {
    let result = errorPageTranslation[UpgradeErrorPage.DEFAULT]

    if (type) {
      result = errorPageTranslation[type]
    }

    return result
  }, [type])

  useEffect(() => {
    dispatch(isFetchingGeneral(false))
  }, [dispatch])

  return (
    <Box>
      <Box sx={classes.header}>
        <HeaderBarNavigation
          title={formatMessage({ id: 'upgradeHub.header.title' })}
          onClick={() => {
            goBack()
          }}
          wcagText={formatMessage({ id: translations.ctaWcag })}
        />
      </Box>
      <Box sx={classes.titleCombination}>
        <TitleCombination
          avatarProps={{
            id: 'flight-avatar',
            stroke: false,
            variant: 'huge',
            font: translations.avatar.toString(),
          }}
          titleProps={{
            variant: 'h2',
            color: theme.palette.grey['700'],
            children: formatMessage({
              id: translations.title,
            }),
          }}
          descriptionProps={{
            variant: 'body1',
            color: theme.palette.grey['600'],
            children: formatMessage(
              {
                id: translations.subtitle,
              },
              { copa_url: process.env.REACT_APP_COPA_URL }
            ),
          }}
          buttonProps={{
            variant: 'outlinePrimaryMain',
            children: formatMessage({
              id: translations.cta,
            }),
            'aria-label': formatMessage({ id: translations.ctaWcag }),
            onClick: () => {
              goBack()
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default SauaGeneralError
