import React, { useCallback, useEffect, useMemo } from 'react'
import { Layout } from '@copa/design-system-factory.layout'
import { Box } from '@mui/material'
import theme from '@copa/design-system-factory.theme'
import { LoginCard } from '@copa/design-system-factory.login-card'
import { Typography } from '@copa/design-system-factory.typography'
import { SectionCard } from '@copa/design-system-factory.section-card'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AlertToast } from '@copa/design-system-factory.alert-toast'
import { HeaderBarNavigation } from '@copa/design-system-factory.header-bar-navigation'
import { pageViewEventUpgraHub } from 'src/actions/analytics'
import { useGetUpgradeEligibleInfoMutation } from 'src/api/upgradeHubSlice'
import { PlusgradeInfoProp, UpgradeInfo } from '../upgrade-hub/types'
import DestinationCard from '../../srt/components/destinationCard'
import useBitBreakPoints from '../../../hooks/material/useBitBreakPoints'
import useStyles from './styles'
import CashIcon from '../upgrade-hub/assets/images/Cash.svg'
import FlyUpLogo from '../upgrade-hub/assets/images/FlyUpLogo.svg'
import DisabledCheckedStar from '../upgrade-hub/assets/images/DisabledCheckedStar.svg'
import CheckedStar from '../upgrade-hub/assets/images/CheckedStar.svg'
import { useAuthentication } from '../../../hooks/auth/useAuthentication'
import { MyTripsState } from '../../../types/state'
import { useErrorToast } from '../upgrade-hub/hooks/useErrorToast'
import { isValidLoyalty, redirectToOrigin } from '../utils'
import { ALERT_TOAST_HIDE_OUT_DURATION } from '../../../constants/variables'
import { QueryParams } from '../../../types/QueryParams'
import { FeatureFlag, getFeatureFlag } from '../../../constants/featureFlags'
import { EligibleTypes, UpgradeCacheKey } from '../types'

interface UpgradeOnboardingProps {
  upgradeInfo: Partial<UpgradeInfo>
  origin: string | null
  onNextPageClick: () => void
  plusgradeInfo: PlusgradeInfoProp
}

export default function UpgradeOnboarding({
  upgradeInfo: { tripInfo, profileExtendInfo },
  origin,
  onNextPageClick,
  plusgradeInfo,
}: UpgradeOnboardingProps) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { pnr, surname, language } = useParams<QueryParams>()
  const { isLoggedIn, loginWithRedirect } = useAuthentication()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles({ theme, breakpoints })

  const { lp_ffn: lpFfn } = useSelector((state: MyTripsState) => state.auth)

  const { open, handleClose, setOpen } = useErrorToast({
    isError: false,
  })

  const [_, { data: upgradeEligibleInfo }] = useGetUpgradeEligibleInfoMutation({
    fixedCacheKey: UpgradeCacheKey.ELIGIBLE,
  })

  useEffect(() => {
    if ((lpFfn && profileExtendInfo) || (!lpFfn && !profileExtendInfo)) {
      dispatch(
        pageViewEventUpgraHub({
          path: history.location.pathname,
          pageName: 'Onboarding page',
          loggedIn: !!profileExtendInfo?.name,
          ...(profileExtendInfo?.loyalty?.loyalLevel
            ? { ffTier: profileExtendInfo?.loyalty?.loyalLevel }
            : {}),
          referSource: origin,
        })
      )
    }
  }, [profileExtendInfo, origin, history, dispatch, lpFfn])

  const goToPlusUpgradePage = useCallback(() => {
    if (plusgradeInfo?.isEligible) {
      window.open(`${plusgradeInfo?.offerUrl}?lang=${language}`, '_blank')
    } else {
      setOpen(true)
    }
  }, [plusgradeInfo, language, setOpen])

  const isSaua = upgradeEligibleInfo?.eligibleType === EligibleTypes.SAUA

  const isAbleToUpgradeWithMiles = useMemo(
    () =>
      isValidLoyalty({
        loyalty: {
          loyalLevel: profileExtendInfo?.loyalty?.loyalLevel,
          balance: profileExtendInfo?.loyalty?.balance as number,
        },
        eligibleUpgrade: tripInfo?.isUpgradeEligible.eligibleUpgrade,
        isLoggedIn: !!isLoggedIn,
      }),
    [profileExtendInfo, tripInfo, isLoggedIn]
  )

  const shouldEnableOnSaua = useCallback(() => {
    let shouldEnable = false
    if (getFeatureFlag(FeatureFlag.IS_SAUA_ON)) {
      shouldEnable = isAbleToUpgradeWithMiles
    }
    return shouldEnable
  }, [isAbleToUpgradeWithMiles])

  return (
    <>
      <HeaderBarNavigation
        title={formatMessage({ id: 'upgradeHub.header.title' })}
        wcagText={formatMessage({ id: 'upgradeHub.header.wcagText' })}
        onClick={() =>
          redirectToOrigin({ pnr, surname, language, origin, history })
        }
      />
      <Box sx={classes.container}>
        <Layout>
          <AlertToast
            type="error"
            text={formatMessage({ id: 'upgradeHub.generalError.plusUpgrade' })}
            autoHideDuration={ALERT_TOAST_HIDE_OUT_DURATION}
            open={open}
            handleClose={() => handleClose()}
          />
          <Box sx={classes.contentContainer}>
            <DestinationCard
              flight={tripInfo}
              template={{
                display: true,
                reservationCode: 'rebook.reservationCode',
              }}
            />
            {!isLoggedIn && (
              <LoginCard
                fullCardClickable
                description={formatMessage({
                  id: 'upgradeHub.onboarding.loginBanner.description',
                })}
                btnText={formatMessage({
                  id: 'upgradeHub.onboarding.loginBanner.ctaText',
                })}
                onClick={() =>
                  loginWithRedirect({
                    redirectTo:
                      window.location.pathname + window.location.search,
                  })
                }
                wcagText={formatMessage({
                  id: 'upgradeHub.onboarding.loginBanner.wcagText',
                })}
              />
            )}

            <Box>
              <Typography color="primary.main" variant="h2">
                {formatMessage({
                  id: 'upgradeHub.onboarding.title',
                })}
              </Typography>
              <Typography color="grey.700" variant="body1">
                {formatMessage({
                  id: 'upgradeHub.onboarding.subtitle',
                })}
              </Typography>
            </Box>

            <Box sx={classes.sectionCardContainer}>
              <SectionCard
                id="plusUpgradeCard"
                // @ts-ignore
                image={CashIcon}
                imageAlt=""
                title={formatMessage({
                  id: 'upgradeHub.onboarding.plusUpgradeCard.title',
                })}
                description={formatMessage({
                  id: 'upgradeHub.onboarding.plusUpgradeCard.subtitle',
                })}
                ctaUrl="#"
                template="vertical"
                onClick={goToPlusUpgradePage}
                variant="tiny"
                logoTinyAlt={formatMessage({
                  id: 'upgradeHub.onboarding.plusUpgradeCard.ariaIcon',
                })}
                // @ts-ignore
                logoTiny={FlyUpLogo}
                wcagText={{
                  textButton: formatMessage({
                    id: 'upgradeHub.onboarding.plusUpgradeCard.wcagText',
                  }),
                  textContainer: formatMessage({
                    id: 'upgradeHub.onboarding.plusUpgradeCard.logoTinyAlt',
                  }),
                  textDisabledContainer: formatMessage({
                    id: 'upgradeHub.onboarding.plusUpgradeCard.wcagTextDisableCard',
                  }),
                }}
                disabled={!tripInfo?.isUpgradeEligible?.eligiblePlusGrade}
                ctaText={formatMessage({
                  id: 'upgradeHub.onboarding.plusUpgradeCard.cta',
                })}
              />
              <SectionCard
                disabled={
                  !isSaua ? !isAbleToUpgradeWithMiles : !shouldEnableOnSaua()
                }
                id="upgradeHubCard"
                // @ts-ignore
                image={
                  isAbleToUpgradeWithMiles ? CheckedStar : DisabledCheckedStar
                }
                imageAlt=""
                title={formatMessage({
                  id: 'upgradeHub.onboarding.upgradeHub.title',
                })}
                description={formatMessage({
                  id: 'upgradeHub.onboarding.upgradeHub.subtitle',
                })}
                ctaUrl="#"
                ctaText={formatMessage({
                  id: 'upgradeHub.onboarding.upgradeHub.cta',
                })}
                template="horizontal"
                onClick={onNextPageClick}
                variant="tiny"
                wcagText={{
                  textContainer: formatMessage({
                    id: 'upgradeHub.onboarding.upgradeHub.wcagText',
                  }),
                  textDisabledContainer: formatMessage({
                    id: 'upgradeHub.onboarding.upgradeHub.wcagTextDisableCard',
                  }),
                  textButton: formatMessage({
                    id: 'upgradeHub.onboarding.upgradeHub.logoTinyAlt',
                  }),
                }}
              />

              <Box>
                <Typography color="grey.600" variant="body1">
                  {formatMessage({
                    id: 'upgradeHub.onboarding.disclaimer',
                  })}
                </Typography>

                <Typography color="grey.600" variant="body1">
                  {formatMessage({
                    id: 'upgradeHub.onboarding.disclaimer2',
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Layout>
      </Box>
    </>
  )
}
