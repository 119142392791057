import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import { useStyles } from './styles'
import checkIcon from '../../../../../../assets/images/srt/checkIcon.svg'

function CompleteConfirmation({
  shouldRedirectSeatMap,
}: {
  shouldRedirectSeatMap: boolean | undefined
}) {
  const { formatMessage } = useIntl()
  const classes = useStyles()

  return (
    <>
      <Box className={classes.header}>
        {/* @ts-ignore */}
        <img className={classes.icon} alt="" src={checkIcon} />
      </Box>
      <Box className={classes.title}>
        <Typography variant="h2" color="primary.main">
          {formatMessage({
            id: 'srt.sc.default.checkout.confirmationModal.title',
          })}
        </Typography>
      </Box>

      {shouldRedirectSeatMap && (
        <Box className={classes.description}>
          <Typography variant="body1" color="grey.600">
            {formatMessage({
              id: `srt.sc.default.checkout.confirmationModal.subtitle`,
            })}
          </Typography>
        </Box>
      )}

      <Box className={classes.description}>
        <Typography variant="body1" color="grey.600">
          {formatMessage({
            id: `srt.sc.default.checkout.confirmationModal.${
              shouldRedirectSeatMap ? 'seatmapDescription' : 'oldDescription'
            }`,
          })}
        </Typography>
      </Box>
    </>
  )
}

export default CompleteConfirmation
