import { theme } from '@copa/design-system-factory.theme'
import { SxProps } from '@mui/material'
import {
  Variants,
  Styles,
  StyledSizeType,
  LoadingSizeType,
  SizeType,
} from '../types'

const { palette } = theme

const WIDTH_SIZE = 20
const TRANSPARENT = 'transparent'

const LOADING_SIZES = {
  small: 'loadingSmall',
  medium: 'loadingMedium',
  large: 'loadingLarge',
} as LoadingSizeType

const roundedSizes = (justIcon: boolean) =>
  ({
    default: {
      paddingY: '10px',
      paddingX: justIcon ? '10px' : '16px',
      fontSize: '14px',
      borderRadius: '40px',
      lineHeight: '20px',
      gap: '4px',
    },
    large: {
      paddingY: '16px',
      paddingX: justIcon ? '16px' : '24px',
      fontSize: '16px',
      borderRadius: '56px',
      lineHeight: '24px',
      gap: '4px',
    },
    slim: {
      padding: '8px',
      fontSize: '12px',
      borderRadius: '24px',
      lineHeight: '16px',
      gap: '4px',
    },
    giant: {
      paddingY: '24px',
      paddingX: justIcon ? '24px' : '32px',
      fontSize: '20px',
      borderRadius: '80px',
      lineHeight: '32px',
      gap: '8px',
    },
    fancy: {
      paddingY: '0px',
      paddingX: justIcon ? '0px' : '18px',
      maxHeight: '64px',
      minHeight: '64px',
      borderRadius: '64px',
      fontSize: '14px',
      lineHeight: '20px',
      minWidth: '73px',
      gap: '8px',
    },
    fancySmall: {
      paddingY: '0px',
      paddingX: justIcon ? '0px' : '18px',
      maxHeight: '40px',
      minHeight: '40px',
      fontSize: '14px',
      lineHeight: '20px',
      borderRadius: '64px',
      minWidth: '73px',
      fontWeight: '500',
      gap: '8px',
    },
  }) as StyledSizeType

const regularSizes = (justIcon: boolean) =>
  ({
    default: {
      paddingY: '8px',
      paddingX: justIcon ? '8px' : '24px',
      fontSize: '16px',
      borderRadius: '8px',
      lineHeight: '24px',
      gap: '4px',
    },
    large: {
      paddingY: '12px',
      paddingX: justIcon ? '12px' : '24px',
      fontSize: '16px',
      borderRadius: '8px',
      lineHeight: '24px',
      gap: '4px',
    },
    slim: {
      paddingY: '4px',
      paddingX: justIcon ? '4px' : '16px',
      fontSize: '12px',
      borderRadius: '4px',
      lineHeight: '16px',
      gap: '4px',
    },
    giant: {
      padding: '24px',
      fontSize: '20px',
      borderRadius: '8px',
      lineHeight: '32px',
      gap: '8px',
    },
    fancy: {
      paddingY: '0px',
      paddingX: justIcon ? '0px' : '18px',
      maxHeight: '64px',
      minHeight: '64px',
      fontSize: '14px',
      lineHeight: '20px',
      borderRadius: '8px',
      minWidth: '73px',
      gap: '8px',
    },
    fancySmall: {
      paddingY: '0px',
      paddingX: justIcon ? '0px' : '18px',
      maxHeight: '40px',
      minHeight: '40px',
      fontSize: '14px',
      lineHeight: '20px',
      borderRadius: '8px',
      minWidth: '73px',
      fontWeight: '500',
      gap: '8px',
    },
  }) as StyledSizeType

class VariantBuilder {
  idleFontColor = ''

  idleBGColor = ''

  borderColor = this.idleBGColor

  hoverFontColor = ''

  hoverBGColor = ''

  hoverBorderColor = ''

  activeFontColor = ''

  activeBGColor = ''

  activeOutlineColor = ''

  activeInsetLineColor = ''

  focusedFontColor = ''

  focusedBGColor = ''

  focusedOutlineColor = ''

  focusedInsetLineColor = ''

  disabledBGColor = palette.grey['200']

  disabledFontColor = palette.grey['500']

  disabledShowBorder = true

  disabledBorderColor = true

  paddings

  forceHoverStyles = false

  justIcon = false

  constructor(paddings: SxProps, forceHoverStyles: boolean, justIcon: boolean) {
    this.paddings = paddings
    this.forceHoverStyles = forceHoverStyles
    this.justIcon = justIcon
  }

  setBorderColor(borderColor) {
    this.borderColor = borderColor
    return this
  }

  getVariantBase = () => {
    const hoverStyles = {
      bgcolor: this.hoverBGColor,
      color: this.hoverFontColor,
      borderColor: this.hoverBorderColor,
      boxShadow: 'none',
    }

    const justIconStyles = {
      minWidth: 'fit-content',
      borderRadius: '50%',
    }

    return {
      ...this.paddings,
      bgcolor: this.idleBGColor,
      textTransform: 'none',
      height: 'fit-content',
      width: 'fit-content',
      fontWeight: '500',
      color: this.idleFontColor,
      borderColor: this.borderColor,
      fontFamily: 'SuisseIntl, sans-serif',
      boxShadow: 0,

      '.MuiButton-endIcon': {
        marginLeft: '0px !important',
        marginRight: 'unset !important',
      },
      '.MuiButton-startIcon': {
        marginRight: '0px !important',
        marginLeft: 'unset !important',
      },
      '&:hover': {
        bgcolor: this.hoverBGColor,
        color: this.hoverFontColor,
        borderColor: this.hoverBorderColor,
        boxShadow: 'none',
      },
      '&:focus': {
        bgcolor: this.focusedBGColor,
        color: this.focusedFontColor,
        outline: `1px solid ${this.activeOutlineColor}`,
        outlineOffset: '2px',
      },
      '&:active': {
        bgcolor: this.activeBGColor,
        color: this.activeFontColor,
        outline: `1px solid ${this.activeOutlineColor}`,
        outlineOffset: '2px',
      },
      '&:disabled': {
        bgcolor: this.disabledBGColor,
        color: this.disabledFontColor,
        ...(this.disabledShowBorder
          ? { border: `1px solid ${this.disabledBorderColor}` }
          : { border: 'unset important!' }),
      },
      ...(this.forceHoverStyles && hoverStyles),
      ...(this.justIcon && justIconStyles),
    }
  }

  setIdleStyle({ bgColor, fontColor }: Styles) {
    this.idleBGColor = bgColor
    this.idleFontColor = fontColor
    return this
  }

  setHoverStyle({ bgColor, fontColor }: Styles) {
    this.hoverBGColor = bgColor
    this.hoverFontColor = fontColor
    return this
  }

  setActiveStyle({
    bgColor,
    fontColor,
    outlineColor = bgColor,
    insetLineColor = palette.common.white,
  }: Styles) {
    this.activeBGColor = bgColor
    this.activeFontColor = fontColor
    this.activeInsetLineColor = insetLineColor
    this.activeOutlineColor = outlineColor
    return this
  }

  setFocusedStyle({
    bgColor,
    fontColor,
    outlineColor = bgColor,
    insetLineColor = palette.common.white,
  }: Styles) {
    this.focusedBGColor = bgColor
    this.focusedFontColor = fontColor
    this.focusedInsetLineColor = insetLineColor
    this.focusedOutlineColor = outlineColor
    return this
  }

  setDisabledStyle({
    bgColor,
    fontColor,
    showBorder = false,
    borderColor,
  }: Styles) {
    this.disabledBGColor = bgColor
    this.disabledFontColor = fontColor
    this.disabledShowBorder = showBorder
    this.disabledBorderColor = Boolean(borderColor || fontColor) as boolean
    return this
  }

  build() {
    return this.getVariantBase()
  }
}

export const loadingIndicatorStyles = {
  button: {
    paddingRight: 'theme.spacing(3)',
    paddingLeft: 'theme.spacing(3)',
  },
  loadingLarge: {
    width: WIDTH_SIZE,
    height: WIDTH_SIZE,
    marginTop: 'theme.spacing(0.25)',
    marginBottom: 'theme.spacing(0.25)',
    marginRight: 'theme.spacing(4.1)',
    marginLeft: 'theme.spacing(4.1)',
    color: 'inherit',
  },
  loadingMedium: {
    width: WIDTH_SIZE,
    height: WIDTH_SIZE,
    marginTop: 'theme.spacing(0.25)',
    marginBottom: 'theme.spacing(0.25)',
    marginRight: 'theme.spacing(3.8)',
    marginLeft: 'theme.spacing(3.8)',
    color: 'inherit',
  },
  loadingSmall: {
    width: 14,
    height: 14,
    marginTop: 'theme.spacing(0.25)',
    marginBottom: 'theme.spacing(0.25)',
    marginRight: 'theme.spacing(3.6)',
    marginLeft: 'theme.spacing(3.6)',
    color: 'inherit',
  },
}

type ButtonVariantType = 'text' | 'contained' | 'outlined'

export const materialVariantsMap = new Map<Variants, ButtonVariantType>([
  ['solidPrimaryLight', 'contained'],
  ['solidPrimaryUltraDark', 'contained'],
  ['solidPrimaryLight', 'contained'],
  ['outlinePrimaryMain', 'outlined'],
  ['outlinePrimaryLight', 'outlined'],
  ['outlineGray', 'outlined'],
  ['outlineInvert', 'outlined'],
  ['transparentPrimaryMain', 'text'],
  ['transparentGray', 'text'],
  ['transparentInvert', 'text'],
])

export const getVariantsStyles = (
  rounded: boolean,
  size: SizeType,
  forceHoverStyles: boolean,
  justIcon
) => {
  const paddings: SxProps = (
    rounded ? roundedSizes(justIcon) : regularSizes(justIcon)
  )[size]

  return {
    solidPrimaryMain: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setIdleStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
      })
      .setHoverStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
        outlineColor: palette.primary.main,
      })
      .setFocusedStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
        outlineColor: palette.primary.main,
      })
      .setDisabledStyle({
        bgColor: palette.grey['100'],
        fontColor: palette.grey['500'],
      })
      .build(),
    solidPrimaryUltraDark: new VariantBuilder(
      paddings,
      forceHoverStyles,
      justIcon
    )
      .setIdleStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
      })
      .setHoverStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
      })
      .setFocusedStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
      })
      .setDisabledStyle({
        bgColor: palette.grey['100'],
        fontColor: palette.grey['500'],
        showBorder: false,
      })
      .build(),
    solidPrimaryLight: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setIdleStyle({
        bgColor: palette.primary.light,
        fontColor: palette.common.white,
      })
      .setHoverStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: palette.primary.light,
        fontColor: palette.common.white,
        outlineColor: palette.common.white,
      })
      .setFocusedStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
      })
      .setDisabledStyle({
        bgColor: palette.grey['100'],
        fontColor: palette.grey['500'],
        showBorder: false,
      })
      .build(),
    outlinePrimaryMain: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setBorderColor(palette.primary.main)
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.primary.main })
      .setHoverStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: palette.primary.ultraDark,
        fontColor: palette.common.white,
        outlineColor: palette.primary.main,
      })
      .setFocusedStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
        outlineColor: palette.primary.main,
      })
      .setDisabledStyle({
        bgColor: palette.grey['200'],
        fontColor: palette.grey['500'],
        showBorder: true,
        borderColor: palette.grey['500'],
      })
      .build(),
    outlinePrimaryLight: new VariantBuilder(
      paddings,
      forceHoverStyles,
      justIcon
    )
      .setBorderColor(palette.primary.light)
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.primary.light })
      .setHoverStyle({
        bgColor: palette.primary.light,
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: palette.primary.main,
        fontColor: palette.common.white,
      })
      .setFocusedStyle({
        bgColor: palette.primary.light,
        fontColor: palette.common.white,
      })
      .setDisabledStyle({
        bgColor: palette.grey['200'],
        fontColor: palette.grey['500'],
        showBorder: true,
        borderColor: palette.grey['500'],
      })
      .build(),
    outlineGray: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setBorderColor(palette.grey['600'])
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.grey['600'] })
      .setHoverStyle({
        bgColor: palette.grey['600'],
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: palette.grey['700'],
        fontColor: palette.common.white,
      })
      .setFocusedStyle({
        bgColor: palette.grey['600'],
        fontColor: palette.common.white,
      })
      .setDisabledStyle({
        bgColor: palette.grey['200'],
        fontColor: palette.grey['500'],
        showBorder: true,
        borderColor: palette.grey['500'],
      })
      .build(),
    outlineInvert: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setBorderColor(palette.common.white)
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.common.white })
      .setHoverStyle({
        bgColor: palette.common.white,
        fontColor: palette.primary.light,
      })
      .setActiveStyle({
        bgColor: palette.common.white,
        fontColor: palette.primary.light,
        outlineColor: palette.common.white,
      })
      .setFocusedStyle({
        bgColor: palette.common.white,
        fontColor: palette.primary.light,
        outlineColor: palette.common.white,
      })
      .setDisabledStyle({
        bgColor: TRANSPARENT,
        fontColor: `${palette.common.white}80`,
        showBorder: true,
        borderColor: `${palette.common.white}80`,
      })
      .build(),
    transparentPrimaryMain: new VariantBuilder(
      paddings,
      forceHoverStyles,
      justIcon
    )
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.primary.main })
      .setHoverStyle({
        bgColor: palette.background.lightBlue,
        fontColor: palette.primary.main,
      })
      .setActiveStyle({
        bgColor: palette.background.lightBlue,
        fontColor: palette.primary.main,
        outlineColor: palette.primary.faded,
        insetLineColor: palette.primary.faded,
      })
      .setFocusedStyle({
        bgColor: palette.background.lightBlue,
        fontColor: palette.primary.main,
        outlineColor: palette.primary.faded,
        insetLineColor: palette.primary.faded,
      })
      .setDisabledStyle({
        bgColor: TRANSPARENT,
        fontColor: palette.grey['500'],
        showBorder: false,
      })
      .build(),
    transparentGray: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.grey['500'] })
      .setHoverStyle({
        bgColor: palette.background.lightBlue,
        fontColor: palette.primary.main,
      })
      .setActiveStyle({
        bgColor: palette.background.lightBlue,
        fontColor: palette.primary.main,
        outlineColor: TRANSPARENT,
        insetLineColor: palette.primary.faded,
      })
      .setFocusedStyle({
        bgColor: palette.background.lightBlue,
        fontColor: palette.primary.main,
        outlineColor: TRANSPARENT,
        insetLineColor: palette.primary.faded,
      })
      .setDisabledStyle({
        bgColor: TRANSPARENT,
        fontColor: palette.grey['500'],
      })
      .build(),
    transparentInvert: new VariantBuilder(paddings, forceHoverStyles, justIcon)
      .setIdleStyle({ bgColor: TRANSPARENT, fontColor: palette.common.white })
      .setHoverStyle({
        bgColor: `${palette.common.white}1A`,
        fontColor: palette.common.white,
      })
      .setActiveStyle({
        bgColor: `${palette.common.white}33`,
        fontColor: palette.common.white,
        outlineColor: palette.common.white,
      })
      .setFocusedStyle({
        bgColor: `${palette.common.white}1A`,
        fontColor: palette.common.white,
        outlineColor: palette.common.white,
      })
      .setDisabledStyle({
        bgColor: TRANSPARENT,
        fontColor: palette.grey['300'],
      })
      .build(),
  }
}

export const getLoadingSizes = (size: SizeType) =>
  LOADING_SIZES[size] || LOADING_SIZES.medium
