import theme from '@copa/design-system-factory.theme'
import { Box, SvgIcon } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import React, { useCallback } from 'react'
import { Divider } from '@copa/design-system-factory.divider'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { get } from 'lodash'
import CheckCircleIcon from 'src/assets/images/tripExtrasIcons/CheckCircleIcon'
import useBitBreakPoints from '../../../../../hooks/material/useBitBreakPoints'
import useStyles from './styles'
import FlightDetailItem from './FlightDetailItem'
import { SauaFlight } from '../../components/FlightForm/FlightForm'
import { SauaDictionary, UpgradeCacheKey } from '../../../types'
import {
  useGetAirlinePartnersMutation,
  useGetCabinClassesMutation,
} from '../../../../../api/sauaSlice'
import {
  SAUAConfirmationScenario,
  SAUA_ALERTCONFIG_TYPE,
  getUpgradeScenario,
  getUpgradeScenarioConfig,
} from '../utils'
import { TriangleAlert } from '../../../../../assets/images/tripExtrasIcons'

interface FlightInfoProps {
  flights: SauaFlight[] | undefined
}

function capitalizeWords(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

function formatMonthFirstLetterToUppercase(date: string) {
  const formattedDate = moment(date)
    .format('DD MMM YYYY')
    .replace('.', '')
    .split(' ')

  formattedDate[1] =
    formattedDate[1].charAt(0).toUpperCase() + formattedDate[1].slice(1)

  return formattedDate.join(' ')
}

const showIcon = (config: SAUA_ALERTCONFIG_TYPE) => {
  if (config.type === SAUAConfirmationScenario.Success) {
    return <CheckCircleIcon fill={get(theme.palette, config.color)} />
  }
  return <TriangleAlert fill={get(theme.palette, config.color)} />
}

export default function FlightInfo({ flights }: FlightInfoProps) {
  const { formatMessage } = useIntl()
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles({ theme, breakpoints })

  const [__, { data: airlines }] = useGetAirlinePartnersMutation({
    fixedCacheKey: UpgradeCacheKey.AIRLINES,
  })

  const [___, { data: cabinClasses }] = useGetCabinClassesMutation({
    fixedCacheKey: UpgradeCacheKey.CABIN_CLASSES,
  })

  const getCabinClassByCode = useCallback(
    (code: string) => {
      let cabinClassesName = ''

      if (cabinClasses) {
        const selectedCabinClass = cabinClasses.find(
          (cabinClass: SauaDictionary) => cabinClass.code === code
        )
        cabinClassesName = selectedCabinClass.name.replace(`[${code}]`, '')
      }

      return cabinClassesName
    },
    [cabinClasses]
  )

  const getAirlineByCode = useCallback(
    (code: string) => {
      let airlinesName = ''

      if (airlines) {
        const selectedAirlines = airlines.find(
          (airline: SauaDictionary) => airline.code === code
        )
        airlinesName = selectedAirlines.name
      }
      return capitalizeWords(airlinesName)
    },
    [airlines]
  )

  return (
    <Box sx={classes.container}>
      {flights?.map((flight, index) => {
        const confirmationScenario = getUpgradeScenario([flight])
        const alertConfig = getUpgradeScenarioConfig(confirmationScenario)

        return (
          <Box key={flight.number}>
            {index !== 0 && (
              <Box sx={{ margin: '16px 0 16px 0' }}>
                <Divider base="lightSolid" />
              </Box>
            )}
            <Box sx={classes.flightTitle}>
              <Typography color="grey.700" variant="h4">
                {formatMessage(
                  { id: 'upgradeHub.saua.confirmationPage.flight.title' },
                  {
                    number: index + 1,
                  }
                )}
              </Typography>
              <Typography variant="body2">
                &nbsp;&nbsp;&middot;&nbsp;&nbsp;
              </Typography>
              <SvgIcon sx={{ fontSize: '16px', marginRight: '4px' }}>
                {showIcon(alertConfig)}
              </SvgIcon>
              <Typography color={alertConfig.color} variant="captionSmall">
                {formatMessage({ id: alertConfig.pillText })}
              </Typography>
            </Box>
            <Box sx={classes.flightDetail}>
              <FlightDetailItem
                label={formatMessage({
                  id: 'upgradeHub.saua.confirmationPage.flight.origin',
                })}
                value={flight?.originAirportName}
              />
              <FlightDetailItem
                label={formatMessage({
                  id: 'upgradeHub.saua.confirmationPage.flight.destination',
                })}
                value={flight?.destinationAirportName}
              />
              <FlightDetailItem
                label={formatMessage({
                  id: 'upgradeHub.saua.confirmationPage.flight.airline',
                })}
                value={getAirlineByCode(flight.airline)}
              />
              <FlightDetailItem
                label={formatMessage({
                  id: 'upgradeHub.saua.confirmationPage.flight.number',
                })}
                value={flight.number}
              />
              <FlightDetailItem
                label={formatMessage({
                  id: 'upgradeHub.saua.confirmationPage.flight.date',
                })}
                value={formatMonthFirstLetterToUppercase(flight.date)}
              />
              <FlightDetailItem
                label={formatMessage({
                  id: 'upgradeHub.saua.confirmationPage.flight.cabin',
                })}
                value={getCabinClassByCode(flight.cabinClass)}
              />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
