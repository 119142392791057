import { useMemo } from 'react'
import { Typography } from '@copa/design-system-factory.typography'
import { Markdown } from '@copa/design-system-factory.markdown'
import { ButtonCo } from '@copa/design-system-factory.button'
import { Avatar } from '@copa/design-system-factory.avatar'
import theme from '@copa/design-system-factory.theme'
import { Box, SxProps, Theme } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import { sectionCardStyles } from './utils/styles'

export type SectionCardProps = {
  /** This property is the identifier of the component */
  id: string
  /** This property is the image url of the component */
  image: string
  /** This property is the image alt of the component */
  imageAlt: string
  /** This property is the title of the component */
  title: string
  /** This property is the description of the component */
  description: string
  /** This property is the url that redirect the button of the component */
  ctaUrl?: string
  /** This property is the button text of the component */
  ctaText?: string
  /** This property indicate the orientation of the component */
  template?: 'horizontal' | 'vertical'
  /** This property indicate the variant of the component */
  variant?: 'normal' | 'tiny'
  /** This property indicate if the component is disabled */
  disabled?: boolean
  /** This property indicate whether the inverted styles should be applied */
  isInverted?: boolean
  /** This property indicate onClick function on the component */
  onClick?: () => void
  /** This property is used to assign styles to the component */
  sx?: SxProps<Theme>
  /** This property is used to assign background color to the component */
  backgroundColor?: string
  /** This property is the logo Tiny url of the component */
  logoTiny?: string
  /** This property is the logo Tiny alt of the component */
  logoTinyAlt?: string
  /** This property is the component's wcag texts */
  wcagText: {
    textContainer: string
    textDisabledContainer: string
    textButton: string
  }
}

export function SectionCard({
  id,
  image,
  imageAlt,
  title,
  description,
  ctaUrl,
  ctaText,
  template,
  variant = 'normal',
  disabled = false,
  isInverted = false,
  onClick = undefined,
  sx,
  backgroundColor,
  logoTiny,
  logoTinyAlt,
  wcagText,
}: SectionCardProps) {
  const isHorizontalTemplate = useMemo(
    () => template !== 'vertical',
    [template]
  )
  const showButton = useMemo(
    () => ctaText && ctaUrl && onClick,
    [ctaText, ctaUrl]
  )

  const styles = sectionCardStyles(
    isInverted,
    isHorizontalTemplate,
    disabled,
    variant,
    backgroundColor
  )
  const stylesContainer = { ...sx, ...styles.container }

  const isLg = useMediaQuery(theme.breakpoints.up('md'))
  const isXS = useMediaQuery(theme.breakpoints.down('sm'))
  const showImage = () => {
    if (variant === 'tiny') {
      return !isXS
    }
    return true
  }

  return (
    <Box
      sx={stylesContainer}
      tabIndex={0}
      aria-label={
        disabled ? wcagText.textDisabledContainer : wcagText.textContainer
      }
    >
      {showImage() && (
        <Box sx={styles.imageContainer}>
          {variant === 'tiny' ? (
            <Avatar
              id="basic"
              variant={isLg ? "huge" : "big"}
              font={image}
              color={theme.palette.background.lightBlue}
            />
          ) : (
            <Box
              id={`${id}-img-section-card-component`}
              data-testid={`${id}-img-section-card-component`}
              component="img"
              src={image}
              alt={imageAlt}
              tabIndex={0}
              sx={{ ...styles.image }}
            />
          )}
        </Box>
      )}
      <Box sx={styles.bodyContainer}>
        <Box sx={styles.descriptionContainer}>
          <Box sx={styles.textContainer}>
            <Typography
              sx={styles.title}
              variant={variant === 'tiny' ? 'h3' : 'h2'}
              tabIndex={0}
            >
              {title}
              {variant === 'tiny' && logoTiny && logoTinyAlt && (
                <Box
                  id={`${id}-logo-tiny-section-card-component`}
                  data-testid={`${id}-logo-tiny-section-card-component`}
                  component="img"
                  src={logoTiny}
                  alt={logoTinyAlt}
                  tabIndex={0}
                  sx={{ ...styles.logoTiny }}
                />
              )}
            </Typography>
            <Markdown isDarkTheme={isInverted} tabIndex={0}>
              {description}
            </Markdown>
          </Box>
        </Box>
        {showButton && (
          <Box>
            <ButtonCo
              role="button"
              tabIndex={0}
              href={(!disabled && ctaUrl) || undefined}
              rounded
              variant={isInverted ? 'solidPrimaryLight' : 'solidPrimaryMain'}
              onClick={onClick}
              sx={styles.button}
              disabled={disabled}
              aria-label={wcagText.textButton}
            >
              {ctaText}
            </ButtonCo>
          </Box>
        )}
      </Box>
    </Box>
  )
}
