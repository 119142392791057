import { theme } from '@copa/design-system-factory.theme'
import { StylesType } from './types'


export const inputBigStyles = (inverted: boolean): StylesType => ({
    root: {
        width: '100%',
        '& > *': {
            width: '100%',
        },
        '& label.Mui-focused': {
            color: 'blue',
        },
        // focused color for input with variant='standard'
        '& .MuiInput-underline': {
            fontWeight: 500,
            ' > div': {
                borderBottomStyle: 'solid',
                padding: '4px 0 4px',
            },
            ' > div:focus': {
                backgroundColor: !inverted
                    ? `${theme.palette.background.lightBlue} !important`
                    : `rgba(255,255,255,0.15)`,
            },
            ' > input': {
                borderBottomStyle: 'solid',
                padding: '4px 0 4px',
                paddingLeft: '4px',
            },
            ' > input:focus': {
                backgroundColor: !inverted
                    ? `${theme.palette.background.lightBlue} !important`
                    : `rgba(255,255,255,0.15)`,
                borderBottomColor: '#D6E3FF',
            },
            '> .MuiSvgIcon-root': {
                color: `${theme.palette.grey[400]}`,
            },
            '& .MuiInputAdornment-root': {
                borderBottomStyle: 'none',
            },
        },
        '& .MuiBox-root': {
            '> .MuiFormHelperText-root': {
                paddingTop: '6.5px',
                '& .MuiBox-root': {
                    gap: '10px',
                    maxHeight: '14px',
                },
            },
        },
        '& .MuiBox-root  span:nth-child(2n)': {
            color: !inverted
                ? `${theme.palette.grey[600]}`
                : `${theme.palette.common.white}`,
        },
        '& .MuiInput-underline:before': {
            borderBottomWidth: '1.5px',
            borderBottomColor: !inverted
                ? `${theme.palette.grey[100]}`
                : `${theme.palette.common.white}`,
            borderBottomStyle: 'solid !important',
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomWidth: '1.5px !important',
            borderBottomColor: !inverted
                ? `${theme.palette.primary.faded} !important`
                : `${theme.palette.primary.ultraLight} !important`,
        },
        '& .MuiInput-underline:focus:before': {
            borderBottomWidth: '1.5px !important',
            borderBottomColor: !inverted
                ? `${theme.palette.primary.main} !important`
                : `${theme.palette.primary.ultraLight} !important`,
        },
        '& .MuiInput-underline:active:before': {
            borderBottomWidth: '1.5px !important',
            borderBottomColor: !inverted
                ? `${theme.palette.primary.main} !important`
                : `${theme.palette.primary.ultraLight} !important`,
        },
        '& .MuiInputBase-root:after': {
            borderBottomWidth: '1.5px !important',
            borderBottomColor: !inverted
                ? `${theme.palette.primary.main} !important`
                : `${theme.palette.primary.ultraLight} !important`,
        },
        // focused color for input with variant='filled'
        '& .MuiFilledInput-underline:after': {
            borderBottomColor: theme.palette.primary.main,
        },
        // focused color for input with variant='outlined'
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    label: {
        ...theme.typography.body2,
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '1rem',
        textAlign: 'left',
    },
    helperText: {
        fontSize: '0.75rem',
        marginTop: 0,
        display: 'flex',
        alignItems: 'start',
        paddingTop: 5,
    },
    input: {
        textAlign: 'left',
        '& > div': {
            color: !inverted
                ? `${theme.palette.grey[500]}`
                : `${theme.palette.grey[200]}`,
        },
        '& > div > svg': {
            color: !inverted
                ? `${theme.palette.grey[500]}`
                : `${theme.palette.grey[200]}`,
        },
    },
    error: {
        '& .MuiInput-underline:after': {
            borderBottomWidth: 1,
            borderBottomColor: theme.palette.error.main,
        },
    },
})