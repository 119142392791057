import env from '../constants/env'

export function getIconSrc(iconDirectory: string, isPictogram = true): string {
  const PICTOGRAMS_PATH = 'webassets/pictogramas'
  const ICONS_PATH = 'webassets/icons'
  const folder = isPictogram ? PICTOGRAMS_PATH : ICONS_PATH
  return `${env.REACT_APP_COPA_URL}/${folder}/${iconDirectory}.svg`
}

const Icon = {
  ALERT_B: getIconSrc('adicionales/alert_b', true),
  WARNING_FILLED: getIconSrc('regular/rounded_warning_filled', false),
  INFO_FILLED: getIconSrc('regular/info_filled', false),
}

export default Icon
