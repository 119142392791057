import { Box } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { createItineraryCards } from '../../../../../../utils/flights'
import useStyles from '../titleWCI/styles'
import useBitBreakPoints from '../../../../../../../../hooks/material/useBitBreakPoints'
import { flows } from '../../../../../SC/templates/templates.enum'
// @ts-ignore
function ItineraryWCI({ suggestedItinerary, shouldShowCabin }) {
  const { breakpoints } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  const { formatMessage } = useIntl()

  return (
    <Box sx={classes.itinerariesContainer}>
      {createItineraryCards(suggestedItinerary, {
        formatMessage,
        flow: flows.WCI,
        shouldShowCabin,
      })}
    </Box>
  )
}

export default ItineraryWCI
