import React from 'react'

import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import { theme } from '@copa/design-system-factory.theme'

import { InlineHelperTextProps } from './types'

const inlineHelperStyles = {
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: '8px',
  },
  helper: {
    display: 'flex',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 400,
  },
  textNormal: {
    color: `${theme.palette.grey[600]} !important`,
  },
  textInverted: {
    color: theme.palette.common.white,
  },
  icon: {
    display: 'flex',
    paddingTop: '2px',
    alignItems: 'flex-start',
    gap: '10px',
    marginRight: '5px',
  },
  text: {
    margin: 0,
    ...theme.typography.body2,
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
}

function textStyle(inverted: boolean) {
  if (inverted) return inlineHelperStyles.textInverted
  return inlineHelperStyles.textNormal
}

export const InlineHelperText = ({
  helperText,
  icon,
  id,
  inverted = false,
  isError,
  showMessage,
  WCAGHelperText,
  sx = {},
  ...props
}: InlineHelperTextProps) => {

  return (
    <Box data-cy={`inline-${id}`} sx={inlineHelperStyles.container}>
      {showMessage && (
        <FormHelperText
          id={id}
          aria-label={WCAGHelperText}
          sx={{
            ...inlineHelperStyles.helper,
            ...sx
          }}
          error={isError}
          {...props}
        >
          {icon && (
            <Box component="span" sx={inlineHelperStyles.icon}>
              {icon}
            </Box>
          )}
          <Box component="span" sx={[inlineHelperStyles.text, textStyle(inverted)]}>
            {helperText}
          </Box>
        </FormHelperText>
      )}
    </Box>

  )
}
