import theme from '@copa/design-system-factory.theme'
import { Breakpoints, getSpacing } from '../../../../../../utils/spacing'

const useStyles = (breakpoints: Breakpoints) => ({
  title: {
    marginBottom: getSpacing(breakpoints)?.normal,
  },
  dateWrapper: {
    backgroundColor: theme.palette.background.default,
    position: 'absolute',
    zIndex: 100,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    left: 0,
    width: { xs: '100%', sm: '75%' },
    borderRadius: '4px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: getSpacing(breakpoints)?.tiny,
    justifyContent: 'end',
    marginTop: getSpacing(breakpoints)?.roomy,
  },
  emptyContainer: {
    marginTop: getSpacing(breakpoints)?.roomy,
  },
  button: {
    width: '100%',
    marginBottom: getSpacing(breakpoints)?.roomy,
  },
  calendar: {
    position: 'relative',
    width: '100%',
    paddingRight: getSpacing(breakpoints)?.normal,
  },
  buttonContainer: {
    width: '100%',
  },
  itinerariesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(breakpoints)?.normal,
  },
  container: {
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    paddingTop: getSpacing(breakpoints)?.roomy,
    backgroundColor: theme.palette.background.paper,
  },
  paperBackground: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  currentFlightContainer: {
    marginTop: getSpacing(breakpoints)?.normal,
    marginBottom: getSpacing(breakpoints)?.spacious,
  },
  availableFlightsContainer: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    paddingTop: getSpacing(breakpoints)?.spacious,
    marginBottom: getSpacing(breakpoints)?.spacious,
  },
})

export default useStyles
