import React from 'react'
import { useIntl } from 'react-intl'
import { TitleCombination } from '@copa/design-system-factory.title-combination'
import Destination from '../../../../../assets/images/destination.svg'
import { SearchAvailabilityErrorCode } from "../../../utils/types";

const AvailableFlightError = ({ errorCode }) => {
  const { formatMessage } = useIntl()
  const validErrorCodes = [
    SearchAvailabilityErrorCode.EMPTY_NODE,
    SearchAvailabilityErrorCode.NO_AVAILABILITY_IN_FLIGHTS,
    SearchAvailabilityErrorCode.NO_FLIGHTS_FOUND,
    SearchAvailabilityErrorCode.NO_AVAILABILITY_IN_FLIGHTS_SECOND,
  ]
  const title = formatMessage({
    id: `srt.availableFlightsErrors.${errorCode}.title`,
  })
  const titleMessage = validErrorCodes.includes(errorCode)
    ? title
    : formatMessage({
        id: `srt.availableFlightsErrors.${SearchAvailabilityErrorCode.EMPTY_NODE}.title`,
      })

  const suggestion = formatMessage({
    id: `srt.availableFlightsErrors.${errorCode}.suggestion`,
  })
  const suggestionMessage = validErrorCodes.includes(errorCode)
    ? suggestion
    : formatMessage({
        id: `srt.availableFlightsErrors.${SearchAvailabilityErrorCode.EMPTY_NODE}.suggestion`,
      })

  return (
    <TitleCombination
      avatarProps={{
        id: 'destination-icon',
        variant: 'big',
        stroke: false,
        font: Destination,
      }}
      titleProps={{
        children: titleMessage,
        color: 'primary.main',
      }}
      descriptionProps={{
        children: suggestionMessage,
      }}
    />
  )
}

export default AvailableFlightError
