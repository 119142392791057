import React from 'react'

export const LaptopIcon = ({ props }) => (
  <svg
    {...props}
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_298_50882)">
      <path
        d="M48.5 23.9434C48.5 37.1982 37.7548 47.9434 24.5 47.9434C11.2452 47.9434 0.5 37.1982 0.5 23.9434C0.5 10.6885 11.2452 -0.0566406 24.5 -0.0566406C37.7548 -0.0566406 48.5 10.6885 48.5 23.9434Z"
        fill="#EFF5FF"
      />
      <path
        d="M12.5 14.6654C12.5 13.929 13.097 13.332 13.8333 13.332H35.1667C35.903 13.332 36.5 13.929 36.5 14.6654V25.9987C36.5 26.7351 35.903 27.332 35.1667 27.332H13.8333C13.097 27.332 12.5 26.7351 12.5 25.9987V14.6654Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8359 14.666C11.8359 13.5614 12.7314 12.666 13.8359 12.666H35.1693C36.2738 12.666 37.1693 13.5614 37.1693 14.666V25.9993C37.1693 27.1039 36.2738 27.9993 35.1693 27.9993H13.8359C12.7314 27.9993 11.8359 27.1039 11.8359 25.9993V14.666ZM13.8359 13.9993C13.4677 13.9993 13.1693 14.2978 13.1693 14.666V25.9993C13.1693 26.3675 13.4677 26.666 13.8359 26.666H35.1693C35.5375 26.666 35.8359 26.3675 35.8359 25.9993V14.666C35.8359 14.2978 35.5375 13.9993 35.1693 13.9993H13.8359Z"
        fill="#0032A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7144 27.5042C11.9169 26.998 12.4071 26.666 12.9524 26.666H36.0496C36.5949 26.666 37.0851 26.998 37.2876 27.5042L39.4209 32.8375C39.7713 33.7133 39.1263 34.666 38.183 34.666H17.501C17.1328 34.666 16.8343 34.3675 16.8343 33.9994C16.8343 33.6312 17.1328 33.3327 17.501 33.3327H38.183L36.0496 27.9993L12.9524 27.9993L10.819 33.3327H13.501C13.8692 33.3327 14.1677 33.6312 14.1677 33.9994C14.1677 34.3675 13.8692 34.666 13.501 34.666H10.819C9.87574 34.666 9.23073 33.7133 9.58105 32.8375L11.7144 27.5042Z"
        fill="#0032A0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 34.6654L12.5 34.6654L12.5 33.332L13.5 33.332C13.8682 33.332 14.1667 33.6305 14.1667 33.9987C14.1667 34.3669 13.8682 34.6654 13.5 34.6654ZM14.8333 33.9987C14.8333 33.6305 15.1318 33.332 15.5 33.332L17.5 33.332C17.8682 33.332 18.1667 33.6305 18.1667 33.9987C18.1667 34.3669 17.8682 34.6654 17.5 34.6654L15.5 34.6654C15.1318 34.6654 14.8333 34.3669 14.8333 33.9987ZM18.8333 33.9987C18.8333 33.6305 19.1318 33.332 19.5 33.332L20.5 33.332L20.5 34.6654L19.5 34.6654C19.1318 34.6654 18.8333 34.3669 18.8333 33.9987Z"
        fill="#0032A0"
      />
      <path
        d="M35.1479 28.666H13.6831L12.5 31.9993H36.5L35.1479 28.666Z"
        fill="#00A3E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_298_50882">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="matrix(-1 0 0 1 48.5 0)"
        />
      </clipPath>
    </defs>
  </svg>
)
