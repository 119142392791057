import { Box } from '@mui/material'
import { Typography } from '@copa/design-system-factory.typography'
import React from 'react'
import { useIntl } from 'react-intl'
import useStyles from './styles'
import useBitBreakPoints from '../../../../../../../../hooks/material/useBitBreakPoints'
// @ts-ignore
function TitleWCI({ title, description }) {
  const { formatMessage } = useIntl()
  const { breakpoints, isBPXS } = useBitBreakPoints()
  const classes = useStyles(breakpoints)
  return (
    <Box sx={classes.title}>
      <Typography color="primary.main" variant="h2">
        {formatMessage({ id: title })}
      </Typography>
      {isBPXS ? (
        <Typography color="grey.600" variant="caption">
          {formatMessage({ id: description })}
        </Typography>
      ) : (
        <Typography color="grey.600" variant="body2">
          {formatMessage({ id: description })}
        </Typography>
      )}
    </Box>
  )
}
export default TitleWCI
