/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
// @vendors
import React from "react";
import PropTypes from "prop-types";
import { compose, mapProps, setPropTypes, setStatic } from "react-recompose";
import classNames from "classnames";

import infoIcon from "../../../assets/images/Info.svg";
import checkIcon from "../../../assets/images/check_icon.svg";
import alertIcon from "../../../assets/images/alert-outline-white.svg";

import "./infoLabelCO.css";

const infoLabelCO = ({
  id,
  divClassName,
  iconClassName,
  textClassName,
  children,
  icon,
}) => {
  let srcIcon = ''
  if (icon) {
    if (icon === 'check') {
      srcIcon = checkIcon
    } else if (icon === 'alert') {
      srcIcon = alertIcon
    } else if (icon === 'info') {
      srcIcon = infoIcon
    }
  }
  return (
    <div id={id || 'conten-alert'} className={divClassName}>
      <div
        id={`${id}-wrapp` || 'wrapp'}
        className="info-label__flex-wrapper"
        role="alert"
      >
        <img
          id={`${id}-img-alert` || 'img-alert'}
          alt=""
          className={iconClassName}
          src={srcIcon}
        />
        <p
          id={`${id}-text-alert` || 'text-alert'}
          className={textClassName}
          tabIndex="0"
          aria-label={children}
        >
          {children}
        </p>
      </div>
    </div>
  )
}

const _propTypes = {
  icon: PropTypes.string,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'danger',
    'transparent',
    'alert',
    'regular',
    'blue',
    'gray',
    'vertical',
    'vertical-left',
    'orange',
    'green',
  ]),
  children: PropTypes.node.isRequired,
  extraClass: PropTypes.string,
}

const _defaultProps = {
  children: 'default text',
}

const withPropsEnhacer = mapProps(({ icon, type, extraClass, ...rest }) => ({
  divClassName: classNames('info-label', {
    [`info-label--${type}`]: type,
    [extraClass]: extraClass,
  }),
  textClassName: classNames('info-label__text', {
    [`info-label__text--${type}`]: type,
  }),
  iconClassName: classNames({
    'info-label__icon': icon,
    'info-label__icon--mini': icon === 'check',
    'info-label__hidden-icon': !icon,
  }),
  icon,
  ...rest,
}))

export default compose(
  setPropTypes(_propTypes),
  withPropsEnhacer,
  setStatic('defaultProps', _defaultProps)
)(infoLabelCO)
