import { ButtonCo } from '@copa/design-system-factory.button'
import { Typography } from '@copa/design-system-factory.typography'
import { theme } from '@copa/design-system-factory.theme'
import { Box } from '@mui/system'
import { StylesObject } from './types'
import { styles } from './styles'
import LoginIcon from './login-icon'

export type LoginCardProps = {
  /**
   * Description text for the login card. This should be a brief summary or context for the login action.
   */
  description: string;

  /**
   * Text to display on the login button. This should be action-oriented, like 'Log In' or 'Sign In'.
   */
  btnText: string;

  /**
   * Callback function to be executed when the login button is clicked. This should handle the login logic.
   */
  onClick: () => void;

  /**
   * Optional. If true, the entire card is clickable. Defaults to false, making only the button clickable.
   */
  fullCardClickable?: boolean;

  /**
   * Text used for accessibility compliance, such as WCAG (Web Content Accessibility Guidelines).
   * It provides an accessible name or description for screen readers.
   */
  wcagText: string;
};

export function LoginCard({ description, btnText, onClick, fullCardClickable = false, wcagText }: LoginCardProps) {
  const classes: StylesObject = styles(theme, fullCardClickable)
  return (
    <Box
      sx={classes.container}
      onClick={fullCardClickable ? onClick : () => {}}
      role={fullCardClickable ? 'button' : ''}
      tabIndex={fullCardClickable ? 0 : undefined}
      aria-label={fullCardClickable ? wcagText : ''}
    >
      <Box sx={classes.description}>
        <Typography color="grey.700" variant='captionSmall' sx={{lineHeight: {md: '2.25rem'}}}>
          {description}
        </Typography>
      </Box>
      <Box sx={classes.login}>
        <ButtonCo
          variant='transparentPrimaryMain'
          size="fancySmall"
          startIcon={<LoginIcon/>}
          rounded
          onClick={onClick}
          aria-label={!fullCardClickable ? wcagText: ''}
          aria-hidden={fullCardClickable}
        >
          {btnText}
        </ButtonCo>
        </Box>
    </Box>
  );
}
